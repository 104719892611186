(function (angular) {
	'use strict';

	function phone(utilityService) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ctrl) {

				ctrl.$validators.phone = function (modelValue, viewValue) {
					viewValue = viewValue || '';

					if (ctrl.$isEmpty(modelValue)) {
						// consider empty models to be valid
						return true;
					}

					/*var firstNumber = viewValue.slice(0, 1);

					 if (/^\d+$/.test(viewValue)
					 && (firstNumber === '1' && viewValue.length === 11 || firstNumber !== '1' && viewValue.length === 10)) {
					 // it is valid
					 return true;
					 }*/

					if (utilityService.PHONE_REGEXP.test(viewValue)) {
						return true;
					}

					// it is invalid
					return false;
				};
			}
		};
	}

	phone.$inject = ['utilityService'];

	angular.module('common').directive('phone', phone);

})(window.angular);