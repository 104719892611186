(function (angular) {
	'use strict';

	function googleTagManagerService($window, EventEmitter, $rootScope, $location) {
		var service = Object.create(EventEmitter.prototype),
			cache = {};

		service.tryRun = tryRun;

		return service;

		function tryRun(googleTagManagerKey) {

			if (!googleTagManagerKey || cache[googleTagManagerKey] || googleTagManagerKey == '1555301151429437') {
				return false;
			}

			(function (w, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({
					'gtm.start': new Date().getTime(), event: 'gtm.js'
				});
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
				j.async = true;
				j.src =
					'//www.googletagmanager.com/gtm.js?id=' + i + dl;
				f.parentNode.insertBefore(j, f);
			})($window, $window.document, 'script', 'dataLayer', googleTagManagerKey);

			cache[googleTagManagerKey] = true;

			if (!cache['bindToRouteChanges']) {
				bindToRouteChanges();
				cache['bindToRouteChanges'] = true;
			}
		}

		function bindToRouteChanges() {
			// Safely instantiate dataLayer
			var dataLayer = $window.dataLayer || [];

			$rootScope.$on('$stateChangeSuccess', function(event, toState) {
				dataLayer.push({
					event: 'uiRouteChange',
					attributes: {
						route: $location.path(),
						stateName: toState.name
					}
				});
			});
		}
	}

	googleTagManagerService.$inject = [
		'$window',
		'EventEmitter',
		'$rootScope',
		'$location'
	];

	angular.module('common').factory('googleTagManagerService', googleTagManagerService);

})(window.angular);