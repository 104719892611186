(function (angular) {
	'use strict';

	function ScreensaverService($document, $rootScope, $compile, defaultOptions, $timeout) {
		var service = {},
			$body = $document.find('body'),
			scope,
			active = false,
			template = '<div id="screensaver" class="screensaver {{options.className || \'\'}}">\
							<uib-carousel interval="options.interval" no-pause="true">\
								<uib-slide ng-repeat="slide in options.slides" active="slide.active">\
									<img class="slide-img" ng-if="slide.mediaType==\'Image\'" ng-src="{{slide.mediaUrl.value}}">\
									<oc-video class="slide-img" ng-if="slide.mediaType==\'Video\'" attributes="{src: slide.mediaUrl.value, preload: true}" play="slide.active" on-play="pause()" on-error="play()" on-ended="next()"></oc-video>\
								</uib-slide>\
							</uib-carousel>\
						</div>';

		service.show = show;
		service.hide = hide;
		service.isActive = isActive;

		return service;

		function show(options) {
			var html,
				compiledHtml;

			if (active) {
				return false;
			}

			scope = $rootScope.$new(true);

			scope.options = angular.extend({}, defaultOptions, options);

			scope.options = prepareOptions(scope.options);

			html = angular.element(template);

			compiledHtml = $compile(html)(scope);

			$body.append(compiledHtml);

			active = true;

			scope.$watch('options.slides', onChangeActiveSlide, true);

			scope.pause = function () {
				$timeout(function () {
					scope.options.interval = 0;
				});
			};

			scope.play = function () {
				$timeout(function () {
					var activeSlide = getActiveSlide(scope.options.slides);
					scope.options.interval = activeSlide.interval;
				});
			};

			scope.next = function () {
				$timeout(function () {
					var slides = scope.options.slides,
						activeSlide = getActiveSlide(slides),
						activeIndex = slides.indexOf(activeSlide),
						nextIndex = activeIndex + 1 < slides.length ? activeIndex + 1 : 0;

					slides[activeIndex].active = false;
					slides[nextIndex].active = true;

					scope.options.interval = slides[nextIndex].interval;
				});
			};
		}

		function hide() {
			if (!active) {
				return false;
			}

			angular.element($document[0].getElementById('screensaver')).remove();

			active = false;
		}

		function isActive() {
			return active;
		}

		function prepareOptions(options) {
			var slides = [],
				interval = options.interval;

			options.slides.forEach(function (slide) {
				slides.push({
					interval: interval,
					mediaType: slide.mediaType,
					mediaUrl: {
						value: slide.mediaUrl
					}
				});
			});

			options.slides = slides;

			return options;
		}

		function onChangeActiveSlide(slides) {
			var activeSlide = getActiveSlide(slides);

			if (activeSlide && scope && activeSlide.interval !== scope.interval) {
				scope.options.interval = activeSlide.interval;
			}
		}

		function getActiveSlide(slides) {
			return slides.filter(function (slide) {
				return slide.active;
			})[0];
		}
	}

	function screensaverProvider() {
		var that = this;

		that.defaultOptions = {
			interval: 5000
		};

		that.$get = ['$document', '$rootScope', '$compile', '$timeout', function ($document, $rootScope, $compile, $timeout) {
			return new ScreensaverService($document, $rootScope, $compile, that.defaultOptions, $timeout);
		}];
	}

	angular.module('common').provider('screensaverService', screensaverProvider);

})(window.angular);