(function (angular) {
  'use strict';

  function AnswerInfoModalCtrl(
    $uibModalInstance,
    isCorrect,
    explanation,
    explanationTime,
    $filter,
    $timeout,
    title,
    questionType
  ) {
    var vm = this,
      translate = $filter('translate');

    vm.isCorrect = isCorrect;
    vm.explanation = explanation;
    vm.explanationTime = explanationTime;
    vm.title = isCorrect ? translate('Correct!') : translate('Incorrect!');
    vm.questionType = questionType;
    if (title) {
      vm.title += ' ' + title;
    }
    vm.next = next;
    vm.isShownExplanation = isNotEmptyExplanation;
    vm.isNextEnabled = false;

    if (vm.explanationTime) {
      $timeout(function () {
        vm.isNextEnabled = true;
      }, vm.explanationTime * 1000);
    } else {
      vm.isNextEnabled = true;
    }

    function next() {
      return $uibModalInstance.close();
    }

    function isNotEmptyExplanation(explanation) {
      return (
        explanation &&
        (explanation.title ||
          explanation.text ||
          explanation.image ||
          explanation.video)
      );
    }
  }

  AnswerInfoModalCtrl.$inject = [
    '$uibModalInstance',
    'isCorrect',
    'explanation',
    'explanationTime',
    '$filter',
    '$timeout',
    'title',
    'questionType'
  ];

  angular.module('game').controller('AnswerInfoModalCtrl', AnswerInfoModalCtrl);
})(window.angular);
