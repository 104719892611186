(function (angular) {
	'use strict';

	function screenHeightDirective($window) {
		return {
			restrict: 'AE',
			link: function (scope, element) {
				$window.addEventListener('resize', onResize);

				scope.$on('$destroy', function () {
					$window.removeEventListener('resize', onResize);
				});

				onResize();

				function onResize() {
					element[0].style.height = $window.document.documentElement.clientHeight + 'px';
				}
			}
		};
	}

	screenHeightDirective.$inject = ['$window'];

	angular.module('common').directive('screenHeight', screenHeightDirective);

})(window.angular);