(function (angular) {
	'use strict';

	function fancySpinnerDirective() {
		return {
			restrict: 'AE',
			transclude: true,
			replace: true,
			templateUrl: 'app/common/directives/fancy-spinner.tmpl.html',
			scope: {
				loading: '=fancySpinnerLoading',
				style: '@fancySpinnerStyle'
			}
		};
	}

	angular.module('common').directive('fancySpinner', fancySpinnerDirective);

})(window.angular);