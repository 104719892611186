(function (angular) {
	'use strict';

    angular.module('common').directive('ngShadow', function () {
        return {
            restrict: 'A',
            transclude: true,
            scope: {
                shadowStyles: '=',
                shadowClass: '='
            },
            template: '<div ng-transclude></div>',
            link: function (scope, elem) {
                !angular.element.prototype.shadow && (angular.element.prototype.shadow = function () {
                    var t = angular.element(this);
                    var g = (t[0]).attachShadow({ mode: 'open' });
                    return g;
                });

                var t = angular.element(angular.element(elem)[0]).shadow();
                var g = angular.element(elem).children()[0];
                t.appendChild(g);

                angular.element(g).addClass(scope.shadowClass || 'shadow');
                angular.element(g).css('height', '100%');

                if (scope.shadowStyles && scope.shadowStyles.length > 0) {
                    scope.shadowStyles.forEach(function (s) {
                        angular.element(t).append('<link href="' + s + '" rel="stylesheet" />');
                    });
                }
            }
        };
    });

})(window.angular);