(function (angular) {
  'use strict';

  function ChooseRewardListItemCtrl($uibModal, authService, $filter) {
    var vm = this;
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = authService.dateTimeFormat();

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[1]
            : date_components[0];
        var month =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[0]
            : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }

    vm.selectReward = function () {
      vm.onSelectReward(vm.model);
    };

    vm.toggleRewardDescription = function (e) {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.parentElement.parentElement.parentElement.classList.toggle(
        'hover'
      );
    };

    vm.showRewardDetails = function (reward, event) {
      event && event.preventDefault && event.preventDefault();
      event && event.stopPropagation && event.stopPropagation();
      $uibModal.open({
        templateUrl: 'app/common/directives/selectable-reward-detail.tmpl.html',
        controller: 'SelectableRewardsDetailCtrl',
        controllerAs: 'swapDetail',
        keyboard: true,
        windowClass: vm.theme && vm.theme.toLowerCase(),
        backdrop: 'true',
        resolve: {
          reward: function () {
            return reward;
          }
        }
      });
    };
  }

  ChooseRewardListItemCtrl.$inject = ['$uibModal', 'authService', '$filter'];

  function chooseRewardListItem() {
    return {
      restrict: 'AE',
      controller: ChooseRewardListItemCtrl,
      controllerAs: 'reward',
      bindToController: true,
      templateUrl:
        'app/chooseReward/components/choose-reward-list-item.tmpl.html',
      scope: {
        mode: '=',
        model: '=rewardItem',
        theme: '=',
        selectedReward: '=',
        onSelectReward: '&'
      }
    };
  }

  angular
    .module('chooseReward')
    .directive('chooseRewardListItem', chooseRewardListItem);
})(window.angular);
