(function (angular) {
    'use strict';

    function AppleWalletCtrl(utilityService, $window, apiSettings, $http) {
        var vm = this,
            currentLanguage = $window.navigator.language;

        vm.loading = true;

        if (utilityService.isIOS() && vm.rewardCode) {
            vm.link = currentLanguage === 'en_MX' ? 'images/appleWallet/en_MX.svg' : 'images/appleWallet/en_US.svg';
            vm.loading = false;
            vm.downloadLink = apiSettings.apiServiceBaseUri + 'api/rewardlookup/' + vm.rewardCode + '/download/apple';
        }
        vm.process = function () {
            vm.loading = true;
            $http.get(vm.downloadLink).then(function () {
                window.open(vm.downloadLink, '_self');
            }).catch(function () {
            }).finally(function () {
                vm.loading = false;
            });
        };
    }

    AppleWalletCtrl.$inject = [
        'utilityService',
        '$window',
        'apiSettings',
        '$http'
    ];

    function appleWallet() {
        return {
            restrict: 'AE',
            scope:{
                rewardCode: '=',
                rewardData: '='
            },
            controller: AppleWalletCtrl,
            controllerAs: 'wallet',
            bindToController: true,
            templateUrl: 'app/common/directives/apple-wallet.tmpl.html'
        };
    }

    angular.module('common').directive('appleWalletButton', appleWallet);

})(window.angular);