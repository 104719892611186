(function (angular) {
	'use strict';

	function formService() {
		var service = {};

		service.makeFieldsDirty = makeFieldsDirty;
		service.makeFieldsPristine = makeFieldsPristine;

		return service;

		function makeFieldsDirty(form) {
			if (!form || !form.$setDirty) {
				return false;
			}

			form.$setDirty();

			for (var key in form) {
				if (form.hasOwnProperty(key) && !/^\$.+/.test(key)) {
					if (form[key] instanceof form.constructor) {
						makeFieldsDirty(form[key]);
					} else {
						form[key].$dirty = true;
					}
				}
			}
		}

		function makeFieldsPristine(form) {
			if (!form || !form.$setPristine) {
				return false;
			}

			form.$setPristine();

			for (var key in form) {
				if (form.hasOwnProperty(key) && !/^\$.+/.test(key) && form[key].$setPristine) {
					if (form[key] instanceof form.constructor) {
						makeFieldsPristine(form[key]);
					} else {
						form[key].$setPristine();
					}
				}
			}
		}
	}

	angular.module('common').factory('formService', formService);

})(window.angular);