(function (angular) {
	'use strict';

	function config($stateProvider, utilityServiceProvider) {
		var parsedSearchQuery = utilityServiceProvider.parseSearchQuery();
		var aToken = parsedSearchQuery.lobbytoken;
		var openOnLoad = parsedSearchQuery.openonload;
		// var externalId = utilityServiceProvider.parseSearchQuery().externalid;
        var accessToken = parsedSearchQuery.id;
        var accessRequestId = parsedSearchQuery.accessrequestid;
        var schedulerId = parsedSearchQuery.schedulerid;
        var apiKey = parsedSearchQuery.apikey;

		$stateProvider

			.state('lobby', {
				abstract: true,
				url: '/lobby',
				templateUrl: 'app/lobby/layout/lobby.html',
				resolve: {
                    kioskConfig: ['lobbyService', function (lobbyService) {
						return lobbyService.getLobbyConfig(aToken);
					}]
				}
			})

			//It's mediator state. The state defines what next step should be (based on kioskConfig)
			.state('lobby.init', {
				url: '?rewardCode',
				resolve: {
                    step: ['lobbyService', 'accessControlService', 'kioskConfig', '$state', '$q', '$timeout', function (lobbyService, accessControlService, kioskConfig, $state, $q, $timeout) {
                        accessControlService.interactionsCount = 0;

                        if (accessToken && accessRequestId && aToken && schedulerId) {
							$timeout(function() {
								return $state.go('lobby.cooked', {
									accessRequestId: accessRequestId,
									schedulerId: schedulerId,
									accessToken: accessToken,
									aToken: aToken,
									isPreviewMode: lobbyService.isPreviewMode
								});
							});
						} else if ((kioskConfig.skipScreensaver && !lobbyService.config.isFacilitated) || openOnLoad) {
							$timeout(function() {
								if (lobbyService.isLandingPageLobby) {
									return $state.go('lobby.campaigns-ac', {
										isPreviewMode: lobbyService.isPreviewMode
									});
								}
								return $state.go('lobby.ac.interaction', {
									accessControlId: lobbyService.accessControlId,
									isPreviewMode: lobbyService.isPreviewMode
								});
							});
						} else {
							$timeout(function() {
								lobbyService.clearDynamicCouponBookConfigurations();
								lobbyService.setIsAccessControlPage(false);
								$state.go('lobby.start');
							});
						}
						return null;
					}]
				}
			})

            .state('lobby.cooked', {
                url: '/cooked',
                templateUrl: 'app/lobby/layout/lobby.campaigns-ac-interaction.html',
				resolve: {
					cooked: ['$stateParams', 'sessionService', 'lobbyService', function($stateParams, sessionService, lobbyService){//TODO: why $stateParams is empty?
                        return sessionService.setCookedData({
                            accessRequestId: accessRequestId,
							schedulerId: schedulerId,
                            accessToken: accessToken,
                            aToken: aToken,
                            isPreviewMode: lobbyService.isPreviewMode,
                            apiKey: apiKey
                        });
					}]
                }
            })

			.state('lobby.start', {
				url: '/start?rewardCode',
				templateUrl: 'app/lobby/layout/lobby.start.html',
				resolve: {
					slidesShow: ['lobbyService', 'kioskConfig', function (lobbyService) {
						return lobbyService.getCampaignsSlides(aToken);
					}]
				}
			})

			.state('lobby.ac', {
				abstract: true,
				url: '/ac/:accessControlId?isPreviewMode',
				templateUrl: 'app/lobby/layout/lobby.ac.html'
			})

			.state('lobby.ac.interaction', {
				url: '',
				templateUrl: 'app/lobby/layout/lobby.ac.interaction.html',
				resolve: {
					startAccessControl: ['$stateParams', 'accessControlService', 'lobbyService', 'kioskConfig', function ($stateParams, accessControlService, lobbyService) {
						return accessControlService
							.startAccessControlForLobby($stateParams.accessControlId, $stateParams.isPreviewMode, lobbyService.pinCode, lobbyService.config)
							.then(function () {
								lobbyService.clearDynamicCouponBookConfigurations();
                                lobbyService.setIsAccessControlPage(true);
                                lobbyService.setIsAccessControlPage(true);

                                //lobbyService.getDynamicLobbyConfiguration(res.accessRequestId, aToken);
                            })
							.catch(lobbyService.onErrorRollbackToState('lobby.init'));
					}]
				}
			})

			.state('lobby.ac.campaigns', {
				url: '/campaigns',
				templateUrl: 'app/lobby/layout/lobby.ac.campaigns.html',
				resolve: {
					campaigns: ['lobbyService', 'kioskConfig', function (lobbyService) {
						return lobbyService
							.getCampaigns(aToken, lobbyService.accessRequestId)
                            .then(function () {
								lobbyService.setIsAccessControlPage(false);
                                lobbyService.getDynamicLobbyConfiguration(lobbyService.accessRequestId, aToken || lobbyService.aToken);
                                lobbyService.setOpenOnLoadToken(openOnLoad);
                            })
							.catch(lobbyService.onErrorRollbackToState('lobby.init'));
					}]
				}
			})

			.state('lobby.campaigns', {
				url: '/campaigns',
				templateUrl: 'app/lobby/layout/lobby.campaigns.html',
				resolve: {
					campaigns: ['lobbyService', 'kioskConfig', function (lobbyService) {
						return lobbyService
							.getCampaignsWithoutAC()
                            .then(function () {
                                lobbyService.getDynamicLobbyConfiguration(lobbyService.accessRequestId, aToken);
                            })
							.catch(lobbyService.onErrorRollbackToState('lobby.init'));
					}]
				}
			})

			.state('lobby.campaigns-ac', {
				url: '/landing/campaigns-ac?isPreviewMode',
				templateUrl: 'app/lobby/layout/lobby.campaigns-ac.html',
				resolve: {
					campaigns: ['lobbyService', '$stateParams', 'kioskConfig', function (lobbyService, $stateParams) {
						return lobbyService
							.getLandingPageCampaigns(lobbyService.lobbyId, $stateParams.isPreviewMode, lobbyService.pinCode)
							.catch(lobbyService.onErrorRollbackToState('lobby.init'));
					}]
				}
			})

            .state('lobby.person-lookup', {
                url: '/person-lookup',
                templateUrl: 'app/lobby/layout/lobby.person-lookup.html',
                resolve: {
                    //campaigns: ['lobbyService', '$stateParams', 'kioskConfig', function (lobbyService, $stateParams) {
                    //    return lobbyService
                    //        .getLandingPageCampaigns(lobbyService.lobbyId, $stateParams.isPreviewMode, lobbyService.pinCode)
                    //        .catch(lobbyService.onErrorRollbackToState('lobby.init'));
                    //}]
                }
            })

            .state('lobby.account-activation', {
                url: '/account-activation',
                templateUrl: 'app/lobby/layout/lobby.account-activation.html'
            })

			.state('lobby.campaigns-ac-interaction', {
				url: '/landing/campaigns-ac-interaction?isPreviewMode',
				templateUrl: 'app/lobby/layout/lobby.campaigns-ac-interaction.html',
				resolve: {
					interaction: ['accessControlService', 'lobbyService', '$stateParams', 'kioskConfig', function (accessControlService, lobbyService, $stateParams) {
						return accessControlService
							.startAccessControlForCampaign(lobbyService.campaignAccessControlId, $stateParams.isPreviewMode)
							.catch(lobbyService.onErrorRollbackToState('lobby.init'));
					}]
				}
			});

	}

	config.$inject = [
		'$stateProvider',
		'utilityServiceProvider'
	];

	angular.module('lobby').config(config);

})(window.angular);