(function (angular) {
	'use strict';

	function run($window, utilityService, $document, iosSafeAudioContextService) {

		var searchParams = utilityService.parseSearchQuery($window.location.search);

		iosSafeAudioContextService.createSafeAudioContext();

		(function () {
			var isMobileBrowser = utilityService.isMobile();

			if (isMobileBrowser) {
				angular.element('body', $document).addClass('is-mobile');
			}
		})();

		if (searchParams.ctoken) {
			return angular.bootstrap($document[0].documentElement, ['campaignMode']);
		}

		if (searchParams.atoken) {
			return angular.bootstrap($document[0].documentElement, ['campaignModeAc']);
		}

		angular.bootstrap($document[0].documentElement, ['commonMode']);
	}

	run.$inject = [
		'$window',
		'utilityService',
		'$document',
		'iosSafeAudioContextService'
	];

	angular.module('fa').run(run);

})(window.angular);