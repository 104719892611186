(function (angular) {
	'use strict';

	angular.module('game', [
		'common',
		'session',
		'behavior',
		'ui.router'
	]);

})(window.angular);