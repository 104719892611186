(function (angular) {
  'use strict';

  function DescriptionModalCtrl($uibModalInstance, offer) {
    var vm = this;
    vm.offer = offer;

    vm.close = close;

    function close() {
      $uibModalInstance.dismiss();
    }
  }

  DescriptionModalCtrl.$inject = ['$uibModalInstance', 'offer'];

  angular
    .module('redemption')
    .controller('DescriptionModalCtrl', DescriptionModalCtrl);
})(window.angular);
