(function (angular) {
	'use strict';

	function scrollService($window) {
		var service = {};

		service.scrollToTop = scrollToTop;
		service.scrollToBottom = scrollToBottom;
		service.scrollIntoView = scrollIntoView;

		return service;

		function scrollToTop() {
			return scrollIntoView($window.document.body, true);
		}

		function scrollToBottom() {
			return scrollIntoView($window.document.body, false);
		}

		function scrollIntoView(element, top) {
			if (element && typeof element.scrollIntoView == 'function') {
				element.scrollIntoView(!!top);
			}
		}
	}

	scrollService.$inject = [
		'$window'
	];

	angular.module('common').factory('scrollService', scrollService);

})(window.angular);