(function (angular) {
	'use strict';

	function infoErrorInterceptorService($q, infoService) {
		var service = {};

		service.responseError = responseError;

		return service;

		function responseError(error) {
			if (error
				&& (error.config && error.config.showErrorInSeparateState)
				&& error.status != 401) {

				error.config.ignoreDefaultErrorNotifier = true;

				infoService.catchErrorAndShowAlert(error);
			}

			return $q.reject(error);
		}
	}

	infoErrorInterceptorService.$inject = [
		'$q',
		'infoService'
	];

	angular.module('info').factory('infoErrorInterceptorService', infoErrorInterceptorService);

})(window.angular);