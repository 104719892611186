(function (angular) {
	'use strict';

	function pinCodeFilter() {
		return function (str, character) {
			character = character ? character : '*';

			return (str || '').replace(/.+?/g, character);
		};
	}

	angular.module('pin').filter('pinCode', pinCodeFilter);

})(window.angular);