(function (angular) {
	'use strict';

	angular.module('chooseReward', [
		'common',
		'session',
		'ui.router',
		'appStorage',
		'info'
	]);

})(window.angular);