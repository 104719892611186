(function (angular) {
	'use strict';

	function stopEventPropogation() {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				if (attrs.stopEventPropagation && attrs.stopEventPropagation.length) {
					element.on(attrs.stopEventPropagation, function (event) {
						event.stopPropagation();
					});
				}
			}
		};
	}

	angular.module('common').directive('stopEventPropagation', stopEventPropogation);

})(window.angular);