(function (angular) {
	'use strict';

	function eventService($window) {
		return $window.EventEmitter;
	}

	eventService.$inject = ['$window'];

	angular.module('common').factory('EventEmitter', eventService);

})(window.angular);