(function (angular) {
  'use strict';

  function RedemptionSearchCtrl(
    $scope,
    $log,
    $state,
    formService,
    errorService,
    redemptionService,
    $uibModal,
    utilityService,
    notifier,
    authService
  ) {
    var vm = this,
      lookupModal;
    vm.clientLogoUrl = authService.authentication.clientLogoUrl;
    vm.userPermissions = redemptionService.userPermissions;
    vm.model = {};
    vm.submitForm = submitForm;
    vm.showLookupModal = showLookupModal;
    vm.isFromLookUp = redemptionService.isFromLookUp;
    vm.searchValue = redemptionService.searchValue;

    if (vm.isFromLookUp && vm.searchValue) {
      vm.showLookupModal();
    }

    $scope.$on('$destroy', function () {
      if (lookupModal && lookupModal.dismiss) {
        lookupModal.dismiss();
      }
    });

    function submitForm(form) {
      formService.makeFieldsDirty(form);
      if (form.$valid && !vm.isLoading) {
        var rewardCode = vm.model.rewardCode.toUpperCase();
        if (!utilityService.EMAIL_REGEXP.test(rewardCode)) {
          vm.errorsList = null;
          vm.isLoading = true;

          redemptionService
            .getRewardInfo(rewardCode)
            .then(
              function (response) {
                $log.debug(response);
                $state.go('redemption.details', { rewardCode: rewardCode });
              },
              function (response) {
                vm.errorsList = errorService.parseErrorsToList(
                  response && response.data
                );
                vm.errorsList = vm.errorsList.map(function (error) {
                  if (error && error.indexOf('Parameter name:') > -1) {
                    error = error.slice(0, error.indexOf('Parameter name:'));
                  }
                  return error;
                });
              }
            )
            .finally(function () {
              vm.isLoading = false;
            });
        } else {
          notifier.error('Invalid reward code: ' + rewardCode);
        }
      }
    }

    function showLookupModal() {
      lookupModal = $uibModal.open({
        templateUrl:
          'app/redemption/components/search/lookup-reward-modal.tmpl.html',
        controller: 'LookupRewardModalCtrl',
        controllerAs: 'search',
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      });

      lookupModal.result.then(function () {
        lookupModal = null;
      });

      return lookupModal;
    }
  }

  RedemptionSearchCtrl.$inject = [
    '$scope',
    '$log',
    '$state',
    'formService',
    'errorService',
    'redemptionService',
    '$uibModal',
    'utilityService',
    'notifier',
    'authService'
  ];

  function redemptionSearch() {
    return {
      restrict: 'AE',
      controller: RedemptionSearchCtrl,
      controllerAs: 'search',
      bindToController: true,
      templateUrl:
        'app/redemption/components/search/redemption-search.tmpl.html'
    };
  }

  angular.module('redemption').directive('redemptionSearch', redemptionSearch);
})(window.angular);
