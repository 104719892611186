(function (angular) {
	'use strict';

	function FinishInstanceModalService(defaults, $injector, confirmationModalService, $q) {
		var service = {},
			isShown = false;

		service.show = show;

		return service;

		function show(options) {
			options = angular.extend({}, defaults, options || {});

			if (isShown) {
				return $q.reject('This modal can be shown only one at the same time.');
			}

			isShown = true;

			return confirmationModalService
				.confirm(options)
				.result
				.then(function () {
					return options.success($injector);
				}).finally(function (result) {
					isShown = false;
					return result;
				});
		}
	}

	function finishInstanceModalProvider() {
		var that = this;

		that.defaultOptions = {
			message: 'Would you like to stop and return to the main menu?',
			okBtnText: 'End Now',
			cancelBtnText: 'Keep Going',
			okBtnClass: 'btn-danger',
			cancelBtnClass: 'btn-success',
			success: function () {
			}
		};

		that.$get = ['$injector', 'confirmationModalService', '$q', function ($injector, confirmationModalService, $q) {
			return new FinishInstanceModalService(that.defaultOptions, $injector, confirmationModalService, $q);
		}];
	}

	angular.module('common').provider('finishInstanceModalService', finishInstanceModalProvider);

})(window.angular);