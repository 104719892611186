(function (ng) {
	'use strict';

	var Service = function (apiResource, $log) {
		var service = this;

		service.redeemReward = redeemReward;
		service.getRewardCode = getRewardCode;
		service.getPOSCode = getPOSCode;

		return service;

		function redeemReward(rewardCode, ignoreDefaultErrorNotifier) {
			return apiResource.post('api/rewardlookup/self-redeem', null, {
				params: {
					rewardCode: rewardCode
				},
				ignoreDefaultErrorNotifier: !!ignoreDefaultErrorNotifier
			}).then(function (response) {
				$log.log('Reward has been redeemed', response);
				return response;
			});
		}

		function getRewardCode(reward) {
			reward = reward || service.rewardInfo || {combinedCode: {}};
			return reward.combinedCode.isOverridden ?
				{code: reward.combinedCode.auxCodeValue, type: reward.combinedCode.auxCodeType} :
				{code: reward.combinedCode.codeValue, type: reward.combinedCode.type};
		}

		function getPOSCode(reward) {
			reward = reward || service.rewardInfo || {combinedCode: {}};
			return reward.combinedCode.isOverridden ?
				{code: reward.combinedCode.codeValue, type: reward.combinedCode.type} :
				{code: reward.combinedCode.auxCodeValue, type: reward.combinedCode.auxCodeType};
		}
	};

	Service.$inject = [
		'apiResource',
		'$log'
	];

	ng.module('selfRedeem').service('selfRedeemRewardService', Service);

})(window.angular);