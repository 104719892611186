(function(angular) {
	'use strict';

	function ocThermometerCtrl($scope) {
		var vm = this;


		function computePercentComplete() {
			vm.percentComplete = (vm.value / vm.total) * 100;
			vm.percentComplete = vm.percentComplete >= 100 ? 98 : vm.percentComplete;
		}

		$scope.$watch('counter.value', function() {
			computePercentComplete();
		});

	}

	ocThermometerCtrl.$inject = ['$scope'];


	function ocThermometer() {
		return {
			restrict: 'AE',
			scope: {
				value: '=',
				total: '=',
				goalText: '@'
			},
			controller: ocThermometerCtrl,
			controllerAs: 'counter',
			bindToController: true,
			templateUrl: 'app/common/directives/oc-thermometer.tmpl.html'
		};
	}

	ocThermometer.$inject = [];


	angular.module('common')
	.directive('ocThermometer', ocThermometer);

})(window.angular);