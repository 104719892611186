(function (angular) {
	'use strict';

	function ocOnEnter() {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {

				element.bind('keydown keypress', function(event) {
					if(event.which === 13) {
						scope.$apply(function(){
							scope.$eval(attrs.ocOnEnter, {'event': event});
						});

						event.preventDefault();
					}
				});

			}
		};
	}

	ocOnEnter.$inject = [
		'$parse'
	];

	angular.module('common').directive('ocOnEnter', ocOnEnter);

})(window.angular);