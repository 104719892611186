(function (angular) {
	'use strict';

	function iosSafeAudioContextService($window, $log) {
		var service = this;

		service.createSafeAudioContext = createSafeAudioContext;

		function createSafeAudioContext() {
			if (!/(iPhone|iPad)/i.test($window.navigator.userAgent)) {
				return false;
			}

			try {
				var AudioCtor = $window.AudioContext || $window.webkitAudioContext;
				var desiredSampleRate = 44100;
				var context = new AudioCtor();
				var buffer = context.createBuffer(1, 1, desiredSampleRate);
				var bufferSource = context.createBufferSource();

				return {
					buffer: buffer,
					bufferSource: bufferSource
				};
			} catch (e) {
				return $log.error('iosSafeAudioContextService: Can not create AudioContext.');
			}
		}
	}

	iosSafeAudioContextService.$inject = [
		'$window',
		'$log'
	];

	angular.module('common').service('iosSafeAudioContextService', iosSafeAudioContextService);

})(window.angular);