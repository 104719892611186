(function (angular) {
	'use strict';

	function LobbyCtrl(lobbyService) {
		var vm = this;

		vm.dynamicLobbyConfiguration = lobbyService.dynamicLobbyConfiguration;

		// When back button is clicked from game or trivia to go back to lobby, the campaign state of the lobby is
		// already populated, so by checking this, we know to reload the lobby to initial state.
		if (lobbyService.campaignId !== null ||
			(lobbyService.campaigns !== null && lobbyService.campaigns.length > 0)) {
			lobbyService.onErrorRollbackToState('lobby.init');
		}

		vm.lobbyService = lobbyService;
		vm.uiConfiguration = lobbyService.config;
	}

	LobbyCtrl.$inject = [
		'lobbyService'
	];

	function lobby() {
		return {
			restrict: 'AE',
			transclude: true,
			controller: LobbyCtrl,
			controllerAs: 'lobby',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobby/lobby.tmpl.html',
			scope: {
				settings: '='
			}
		};
	}

	angular.module('lobby').directive('lobby', lobby);

})(window.angular);
