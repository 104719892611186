(function (angular) {
	'use strict';

	function ocCellsController($scope, $element, $window, utilityService) {
		var vm = this;
		var onWindowResize = utilityService.debounce(function () {
			onChange();
		}, 50);

		vm.init = init;
		vm.addCell = addCell;
		vm.removeCell = removeCell;
		vm.cells = [];

		$window.addEventListener('resize', onWindowResize, false);

		$scope.$on('$destroy', function () {
			$window.removeEventListener('resize', onWindowResize);
		});

		function init() {
			onChange();
		}

		function addCell(cell) {
			if (!~vm.cells.indexOf(cell)) {
				vm.cells.push(cell);
				onChange();
			}
		}

		function removeCell(cell) {
			if (~vm.cells.indexOf(cell)) {
				vm.cells.splice(vm.cells.indexOf(cell), 1);
				onChange();
			}
		}

		function onChange() {
			var parentHeight = $element.innerHeight();
			var cellsLength = vm.cells.length;
			var cellHeight = parentHeight / cellsLength;

			if (cellsLength > 0) {
				vm.cells.forEach(function (cell) {
					cell.setHeight(cellHeight);
				});
			}
		}
	}

	ocCellsController.$inject = [
		'$scope',
		'$element',
		'$window',
		'utilityService'
	];

	function ocCellsDirective($timeout) {
		return {
			restrict: 'E',
			controller: ocCellsController,
			require: 'ocCells',
			transclude: true,
			replace: true,
			template: '<div class="cells-group" ng-transclude></div>',
			scope: {},
			link: function (scope, element, attrs, ocCellsController) {
				$timeout(function () {
					ocCellsController.init();
				});
			}
		};
	}

	ocCellsDirective.$inject = [
		'$timeout'
	];

	angular.module('common').directive('ocCells', ocCellsDirective);

})(window.angular);

(function (angular) {
	'use strict';

	function ocCellDirective() {
		return {
			restrict: 'E',
			require: '^ocCells',
			transclude: true,
			replace: true,
			template: '<div class="cell" ng-transclude></div>',
			scope: {},
			link: function (scope, element, attrs, ocCellsController) {
				scope.setHeight = function (height) {
					element.css('height', height || '');
				};

				ocCellsController.addCell(scope);

				scope.$on('$destroy', function () {
					ocCellsController.removeCell(scope);
				});
			}
		};
	}

	angular.module('common').directive('ocCell', ocCellDirective);

})(window.angular);