(function (angular) {
	'use strict';

	function PasswordResetCtrl($state, errorService) {
		var vm = this;

        vm.messages = errorService.lastErrorMessage && errorService.lastErrorMessage.split('.');

		vm.goLogin = function() {
            $state.go('login');
		};

        vm.goForgotPassword = function() {
            $state.go('forgotPassword');
        };
	}

    PasswordResetCtrl.$inject = [
		'$state',
		'errorService'
	];

	function passwordReset() {
		return {
			restrict: 'AE',
			templateUrl: 'app/auth/components/passwordReset/password-reset.tmpl.html',
			controllerAs: 'passwordReset',
			controller: PasswordResetCtrl
		};
	}

	angular.module('auth').directive('passwordReset', passwordReset);

})(window.angular);