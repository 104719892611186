(function (angular) {
  'use strict';

  function PageLoaderCtrl(authService, $scope) {
    var vm = this;
    var interval;

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var infiniteLoading = urlParams.get('infinite_loading');
    var loaderCustomization = urlParams.get('loader_customization');
    vm.infinite = infiniteLoading ? Boolean(infiniteLoading) : false;

    $scope.$on('cosmopolitan', function () {
      vm.loaderType = 'cherry';
      vm.isLoaderVisible = true;
    });
    $scope.$on('solaire', function () {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      vm.isLoaderVisible = !urlParams.get('preloader');
      vm.loaderType = 'solaire';

      interval = setInterval(function () {
        var dots = document.getElementById('js-ofc-solaire-dots');

        switch (true) {
          case dots.classList.contains(
            'ofc-loader-solaire__content-dots--second'
          ):
            dots.classList.remove('ofc-loader-solaire__content-dots--second');
            dots.classList.remove('ofc-loader-solaire__content-dots--first');
            break;
          case dots.classList.contains(
            'ofc-loader-solaire__content-dots--first'
          ):
            dots.classList.add('ofc-loader-solaire__content-dots--second');
            break;
          case !dots.classList.contains(
            'ofc-loader-solaire__content-dots--first'
          ):
            dots.classList.add('ofc-loader-solaire__content-dots--first');
            break;
        }
      }, 3200 / 3);
    });

    $scope.$on('default', function () {
      vm.loaderType = 'default';
      vm.isLoaderVisible = true;

      var customization;

      if (loaderCustomization) {
        customization = JSON.parse(decodeURIComponent(loaderCustomization));

        if (customization.loaderImageUri['value']) {
          customization.loaderImageUri = customization.loaderImageUri.value;
        }
      } else {
        customization = JSON.parse(
          localStorage.getItem('loaderUiCustomization')
        );
      }
      vm.loaderUiCustomization = customization;
    });

    $scope.$on('$destroy', function () {
      clearInterval(interval);
    });
  }

  PageLoaderCtrl.$inject = ['authService', '$scope'];

  function pageLoader() {
    return {
      restrict: 'AE',
      scope: {},
      controller: PageLoaderCtrl,
      controllerAs: 'loader',
      replace: true,
      bindToController: true,
      templateUrl: 'app/common/directives/page-loader.tmpl.html'
    };
  }

  angular.module('auth').directive('pageLoader', pageLoader);
})(window.angular);
