(function (angular) {
	'use strict';

	function AcSuccessCtrl() {
		//var vm = this;
	}

	function acSuccess() {
		return {
			restrict: 'AE',
			controller: AcSuccessCtrl,
			controllerAs: 'error',
			bindToController: true,
			templateUrl: 'app/accessControl/components/interaction/success/success.tmpl.html'
		};
	}

	angular.module('accessControl').directive('acInteractionSuccess', acSuccess);

})(window.angular);