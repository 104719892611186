(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.aliases = [];
		vm.aliasChoices = [];
		vm.answerModel = [];
		vm.answerMap = [];
		//vm.grid = {"questionText":"Thanks for visiting Rhythm City! Please rate each of the following: ","columns":[{"text":"Excellent","isTextVisible":true,"image":""},{"text":"Good","isTextVisible":true,"image":""},{"text":"Fair","isTextVisible":true,"image":""},{"text":"Poor","isTextVisible":true,"image":""},{"text":"N/A","isTextVisible":true,"image":""}],"groups":[{"groupName":"Draft Day Sports Lounge","groupNameIsVisible":false,"groupImage":"https://prodocdata.blob.core.windows.net/images-8c95b39d5def/38e276ea3141407288d779720f2cc8a8-draftday-logo-02.png","rows":[{"text":"Service","isTextVisible":true,"image":""},{"text":"Food","isTextVisible":true,"image":""},{"text":"Menu","isTextVisible":true,"image":""},{"text":"Overall ","isTextVisible":true,"image":""}]},{"groupName":"Robert's Buffet","groupNameIsVisible":false,"groupImage":"https://prodocdata.blob.core.windows.net/images-8c95b39d5def/7c977061bf9e44d8be86d80e9dae8e7c-roberts-logo-02.png","rows":[{"text":"Service","isTextVisible":true,"image":""},{"text":"Food","isTextVisible":true,"image":""},{"text":"Menu","isTextVisible":true,"image":""},{"text":"Overall","isTextVisible":true,"image":""}]}]};
		vm.grid = vm.choices[0].content;


		/*
		function extractAliasChoices(choices) {
			var grid = {};
			var rows = [];
			grid.choices = choices.

			grid.rows = [];

			for (var i = 0; i < choices.length; i++) {
				var choice = choices[i];


				var ind = choice.value.split('|');

				var row = parseInt(ind[0]) - 1;
				var col = parseInt(ind[1]) - 1;

				if (!grid.rows[row]) {
					grid.rows[row] = angular.copy(choice);
					grid.rows[row].cols = [];
				}
				if(grid.rows[row].alias === ''
					&& grid.rows[row].aliasMediaUrl.value) {
					continue;
				}
				grid.rows[row].cols[col] = choice;

			}

			grid.headers = grid.rows[1].cols;

			return grid;
		}
		*/

		vm.init = function (ngModel, answers) {
			if (!ngModel || !answers) return;


			//vm.aliasChoices = extractAliasChoices(vm.choices);

			vm.getLabel = function (choice) {
				var l = answers.getLabel(choice);
				return l;
			};

			vm.onChange = function (group, row, col, i) {
				var answer = group.groupName + '|' + row.text + '|' + col.value;
				var exists = vm.answerMap.indexOf(group.groupName+row.text);
				if(~exists) {
					vm.answerMap.splice(exists, 1);
					vm.answerModel.splice(exists, 1);
				}
				vm.answerMap.push(group.groupName+row.text);
				vm.answerModel.push({value: answer});
				/*
				var model = [];
				for (var i = 0; i < vm.aliasChoices.length; i++) {
					var m = vm.aliasChoices[i].model;
					m && model.push(m);
				}
				*/

                vm.model = vm.answerModel;
                ngModel.$setViewValue(vm.model);

                var num = 0;
                vm.grid.groups.forEach(function (gr) {
                    num += gr.rows.length;
                });


                i = vm.grid.groups.indexOf(group);// group.rows.indexOf(row);

                if (vm.isRequired) {
                    vm.form[row.text + i].$setValidity('required', true);

                    if (vm.model.length === num) {
                        while (vm.form.$error.required && vm.form.$error.required.length > 0) {
                            vm.form.$error.required.forEach(function (req) {
                                req.$setValidity('required', true);
                            });
                        }
                    }
                }

			};

			ngModel.$render = function () {
				// Specify how UI should be updated
				vm.model = ngModel.$viewValue;
			};
		};
	}

	AnswerCtrl.$inject = [];

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-grid2.tmpl.html',
			require: ['answerGrid2', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
				answerBackgroundColor: '=',
				answerDisabled: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);

				scope.$watch('answer.choices', function (newObj, oldObj) {
					if (newObj !== oldObj) {
						ctrls[0].init(ctrls[1], ctrls[2]);
					}
				});
			}
		};
	}

	angular.module('answer').directive('answerGrid2', answer);

})(window.angular);