(function (angular) {
  'use strict';

  function DeliveryRewardModalCtrl(
    $rootScope,
    deliveryService,
    formService,
    errorService,
    utilityService
  ) {
    var vm = this,
      deliveryModel = vm.model,
      searchParams = utilityService.parseSearchQuery(),
      prefillDeliveryType = deliveryModel.deliveryType,
      prefillDeliveryValue =
        deliveryModel.deliveryAddress || searchParams.prefill;

    vm.OPTIONS = {
      EMAIL: 'Email',
      SMS: 'Sms',
      PRINT: 'Printer',
      SKIP: 'Skip',
      MAIL: 'MailingAddress'
    };

    vm.disableDeliveryForm = false;

    vm.reward = deliveryModel.reward;
    vm.pageConfig = deliveryModel.popupConfiguration;
    vm.deliveryOptions = deliveryModel.deliveryOptions || [];
    vm.country = deliveryModel.country;
    vm.displayCountryDropDown = deliveryModel.displayCountryDropDown;
    vm.showOptInMessage = deliveryModel.showOptInMessage;
    vm.optInMessage =
      deliveryModel.popupConfiguration.communicationsOptInMessage ||
      deliveryModel.communicationsOptInMessage ||
      deliveryModel.optInMessage;
    vm.rewardDeliveryTitle = deliveryModel.rewardDeliveryTitle;
    vm.rewardDeliveryPrivacyNotice = deliveryModel.rewardDeliveryPrivacyNotice;
    vm.isConsented = deliveryModel.isCheckedByDefault ? true : vm.isConsented;

    vm.submitDeliveryForm = submitDeliveryForm;
    vm.submitEmptyDeliveryForm = submitEmptyDeliveryForm;
    vm.isDeliveryTypePresented = isDeliveryTypePresented;
    vm.changeDeliveryType = changeDeliveryType;
    vm.onChangeDeliveryType = onChangeDeliveryType;
    vm.isDeliveryEmailTypePresented = isDeliveryTypePresented(vm.OPTIONS.EMAIL);
    vm.isDeliverySmsTypePresented = isDeliveryTypePresented(vm.OPTIONS.SMS);
    vm.isDeliveryPrintTypePresented = isDeliveryTypePresented(vm.OPTIONS.PRINT);
    vm.isDeliverySkipTypePresented = isDeliveryTypePresented(vm.OPTIONS.SKIP);
    vm.isDeliveryMailingAddressTypePresented = isDeliveryTypePresented(
      vm.OPTIONS.MAIL
    );
    vm.isEmailDeliveryTypeActive = isEmailDeliveryTypeActive;
    vm.isSmsDeliveryTypeActive = isSmsDeliveryTypeActive;
    vm.isMailDeliveryTypeActive = isMailDeliveryTypeActive;
    vm.isPrintDeliveryTypeActive = isPrintDeliveryTypeActive;
    vm.isSkipDeliveryTypeActive = isSkipDeliveryTypeActive;
    vm.applyEmail = applyEmail;
    vm.applyPhoneNumber = applyPhoneNumber;
    vm.applyPrint = applyPrint;
    vm.applySkip = applySkip;
    vm.applyMailAddress = applyMailAddress;
    vm.isPaperActive = false;
    vm.deliveryModel = {};
    vm.loading = {};
    vm.deliveryType = vm.deliveryOptions[0];

    $rootScope.$on('$stateChangeSuccess', function () {
      vm.$close();
    });

    prefillDeliveryModel(prefillDeliveryValue, prefillDeliveryType);

    function submitEmptyDeliveryForm(button) {
      return sendRewardDelivery([], vm.isConsented, button);
    }

    function submitDeliveryForm(deliveryType) {
      vm.disableDeliveryForm = true;
      var options = [],
        deliveryEmailModel = {
          DeliveryOption: vm.OPTIONS.EMAIL,
          Value: vm.deliveryModel.email
        },
        deliverySmslModel = {
          DeliveryOption: vm.OPTIONS.SMS,
          Value:
            vm.displayCountryDropDown && vm.deliveryModel.phone
              ? vm.country.phoneCode + vm.deliveryModel.phone
              : vm.deliveryModel.phone
        },
        deliveryMailingAddressModel = {
          DeliveryOption: vm.OPTIONS.MAIL,
          Value:
            vm.deliveryModel.mailingAddress &&
            vm.deliveryModel.mailingAddress.value
        };

      if (vm.isDeliveryEmailTypePresented) {
        options.push(deliveryEmailModel);
        if (deliveryType !== vm.OPTIONS.EMAIL) {
          deliveryEmailModel.Value = undefined;
        }
      }

      if (vm.isDeliverySmsTypePresented) {
        options.push(deliverySmslModel);
        if (deliveryType !== vm.OPTIONS.SMS) {
          deliverySmslModel.Value = undefined;
        }
      }

      if (vm.isDeliveryMailingAddressTypePresented) {
        options.push(deliveryMailingAddressModel);
        if (deliveryType !== vm.OPTIONS.MAIL) {
          deliveryMailingAddressModel.Value = undefined;
        }
      }

      return sendRewardDelivery(options, vm.isConsented, 'submit');
    }

    function applyEmail(form) {
      formService.makeFieldsPristine(form);

      formService.makeFieldsDirty(form.email);

      if ((form.email && form.email.$invalid) || vm.isLoading) {
        return false;
      }

      return submitDeliveryForm(vm.OPTIONS.EMAIL);
    }

    function applyPhoneNumber(form) {
      formService.makeFieldsPristine(form);

      formService.makeFieldsDirty(form.phone);

      if ((form.phone && form.phone.$invalid) || vm.isLoading) {
        return false;
      }

      return submitDeliveryForm(vm.OPTIONS.SMS);
    }

    function applyMailAddress(form) {
      formService.makeFieldsDirty(form);

      if ((form && form.$invalid) || vm.isLoading) {
        return false;
      }

      return submitDeliveryForm(vm.OPTIONS.MAIL);
    }

    function applyPrint() {
      if (vm.isLoading) {
        return false;
      }
      return submitEmptyDeliveryForm(vm.OPTIONS.PRINT);
    }

    function applySkip() {
      if (vm.isLoading) {
        return false;
      }
      return submitEmptyDeliveryForm(vm.OPTIONS.SKIP);
    }

    function sendRewardDelivery(deliveryOptions, isConsented, button) {
      vm.isLoading = vm.loading[button] = true;
      vm.errorsList = null;

      deliveryService
        .deliverReward({
          deliveryOptions: deliveryOptions,
          isConsented: isConsented
        })
        .then(function () {
          deliveryService.complete().finally(function () {
            vm.isLoading = vm.loading[button] = false;
            //vm.$close();
          });
        })
        .catch(function (response) {
          vm.errorsList = errorService.parseErrorsToList(response.data);
          vm.isLoading = false;
          vm.disableDeliveryForm = false;
        });
    }

    function changeDeliveryType(type) {
      vm.deliveryType = type;
    }

    function onChangeDeliveryType() {
      //vm.deliveryModel = {};
    }

    function isEmailDeliveryTypeActive() {
      return vm.deliveryType === vm.OPTIONS.EMAIL;
    }

    function isSmsDeliveryTypeActive() {
      return vm.deliveryType === vm.OPTIONS.SMS;
    }

    function isMailDeliveryTypeActive() {
      return vm.deliveryType === vm.OPTIONS.MAIL;
    }

    function isPrintDeliveryTypeActive() {
      return vm.deliveryType === vm.OPTIONS.PRINT;
    }

    function isSkipDeliveryTypeActive() {
      return vm.deliveryType === vm.OPTIONS.SKIP;
    }

    function isDeliveryTypePresented(type) {
      return vm.deliveryOptions.indexOf(type) !== -1;
    }

    /* Prefill delivery model by predefined value from url query */
    function prefillDeliveryModel(value, deliveryType) {
      if (value && ~vm.deliveryOptions.indexOf(deliveryType)) {
        if (deliveryType.toUpperCase() === vm.OPTIONS.SMS) {
          vm.deliveryModel = {
            phone: value
          };
          return (vm.deliveryType = deliveryType);
        }
        if (deliveryType.toUpperCase() === vm.OPTIONS.EMAIL) {
          vm.deliveryModel = {
            email: value
          };
          return (vm.deliveryType = deliveryType);
        }
      }

      if (value && utilityService.PHONE_REGEXP.test(value)) {
        // Phone is defined
        vm.deliveryModel = {
          phone: value
        };
        deliveryType = vm.deliveryOptions.filter(function (opt) {
          return opt.toUpperCase() === vm.OPTIONS.SMS;
        })[0];
      }

      if (value && utilityService.EMAIL_REGEXP.test(value)) {
        // Email is defined
        vm.deliveryModel = {
          email: value
        };
        deliveryType = vm.deliveryOptions.filter(function (opt) {
          return opt.toUpperCase() === vm.OPTIONS.EMAIL;
        })[0];
      }

      return (vm.deliveryType = deliveryType || vm.deliveryType);
    }
  }

  DeliveryRewardModalCtrl.$inject = [
    '$rootScope',
    'deliveryService',
    'formService',
    'errorService',
    'utilityService'
  ];

  function deliveryRewardModal() {
    return {
      restrict: 'AE',
      templateUrl:
        'app/delivery/components/deliveryRewardModal/delivery-reward-modal.tmpl.html',
      controller: DeliveryRewardModalCtrl,
      controllerAs: 'ctrl',
      bindToController: true,
      scope: {
        model: '=',
        $close: '&'
      }
    };
  }

  deliveryRewardModal.$inject = [];

  angular
    .module('delivery')
    .directive('deliveryRewardModal', deliveryRewardModal);
})(window.angular);
