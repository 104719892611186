(function (angular) {
	'use strict';

	function modelCustomFormatter($parse) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attr, ctrl) {
				var getter = $parse(attr.modelCustomFormatter);

				ctrl.$formatters.push(function (value) {
					return getter(scope, {$value: value});
				});
			}
		};
	}

	modelCustomFormatter.$inject = [
		'$parse'
	];

	angular.module('common').directive('modelCustomFormatter', modelCustomFormatter);

})(window.angular);