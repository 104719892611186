(function (angular) {
	'use strict';

	function imageUpload($timeout, blankImage) {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/image-upload.tmpl.html',
			scope: {
				file: '=',
				base64: '=',
				onChange: '&',
				templateType: '@',
				disableRemove: '=',
				preview: '='
			},
			link: function (scope, ele) {
				var inputFileWrapperEl = ele.find('.input-wrapper')[0];

				scope.blankImage = blankImage;

				scope.templateType = scope.templateType || 'link';

				scope.uploadImage = function () {
					$timeout(function () {
						ele.find('input')[0].click();
					}, 0);
				};

				scope.removeImage = function () {
					scope.file = null;
					scope.base64 = scope.base64 || {};
					scope.base64.size = 0;
					// brown magic to reset input-file field :)
					/* eslint-disable */
					inputFileWrapperEl.innerHTML = inputFileWrapperEl.innerHTML;
					/* eslint-enable */
					$timeout(function () {
						scope.onChange();
					});
				};
			}
		};
	}

	imageUpload.$inject = ['$timeout', 'blankImage'];

	angular.module('common').directive('imageUpload', imageUpload);

})(window.angular);