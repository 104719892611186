(function (angular) {
	'use strict';

	function campaignModeAcService($window, $rootScope, $state, apiResource, storageService, errorService, sessionService, authService) {
		var service = Object.create($window.EventEmitter.prototype);

		service.aToken = {};
		service.accessControlId = null;
		service.campaignId = null;
		service.accessRequestId = null;
		service.isPreviewMode = false;
		service.isTransitionToInfoStateProgrammed = false;
		service.getCampaignAccessControlIfoByAToken = getCampaignAccessControlIfoByAToken;
		service.startSession = startSession;
		service.isLoggedIn = isLoggedIn;

		return service;

		function getCampaignAccessControlIfoByAToken(aToken) {
			return apiResource.get('/api/campaigns/' + aToken).then(function (data) {
				service.aToken[aToken] = data;
				service.accessControlId = data.accessControlId;
				service.campaignId = data.campaignId;
				service.isPreviewMode = data.isPreviewMode;
				service.locale = data.locale;
				fireEvent('getAccessControlIfo', data);
				return data;
			});
		}

		function startSession(campaignId, scheduleId, accessRequestId) {
			campaignId = campaignId || service.campaignId;
			accessRequestId = accessRequestId || service.accessRequestId;
			scheduleId = scheduleId || service.scheduleId;

			if (sessionService.isResuming()) {
				return false;
			}

			return sessionService
				.startSessionInCampaignModeByCampaignId(campaignId, scheduleId, accessRequestId, service.isPreviewMode, true)
				.catch(function (error) {
					//Remove app data from storage in order to start from beginning next time
					storageService.clearAll();

					if (error.status !== 401) {
						service.isTransitionToInfoStateProgrammed = true;
						$state.go('info', {
							message: errorService.parseErrorsToList(error.data).join(' ')
						}).finally(function () {
							service.isTransitionToInfoStateProgrammed = false;
						});
					}
				});
		}

		function isLoggedIn() {
			return authService.authentication.isAuth
				&& authService.getTimeToExpirationToken() > 1
				&& sessionService.isSessionKeyExist();
		}

		function fireEvent(event, data) {
			service.trigger(event, (typeof data !== 'undefined') ? [data] : undefined);
			$rootScope.$applyAsync();
		}
	}

	campaignModeAcService.$inject = [
		'$window',
		'$rootScope',
		'$state',
		'apiResource',
		'storageService',
		'errorService',
		'sessionService',
		'authService'
	];

	angular.module('campaignModeAc').factory('campaignModeAcService', campaignModeAcService);

})(window.angular);