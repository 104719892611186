(function (angular) {
  'use strict';

  function RewardDetailsCtrl(
    $stateParams,
    $log,
    redemptionService,
    errorService,
    $state,
    notifier,
    $filter,
    confirmationModalService,
    authService
  ) {
    var vm = this,
      translate = $filter('translate');

    vm.model = redemptionService.rewardInfo;
    vm.isShownDescription = false;
    vm.isShownTerms = false;
    vm.isShownStartDate = vm.model.rewardState === 'NotStarted';
    vm.isRedeemable = vm.model.isRedeemable;
    vm.allowUserPrintOnRedemption =
      redemptionService.userPermissions.allowUserPrintOnRedemption;
    vm.allowUserReprintOnRedemption =
      redemptionService.userPermissions.allowUserReprintOnRedemption;
    vm.clientLogoUrl = authService.authentication.clientLogoUrl;
    vm.redeemCode = redeemCodeWrapper;
    vm.cancelBookingCode = cancelBookingCode;
    vm.onElapseCountdownTimer = onElapseCountdownTimer;
    vm.readDescription = readDescription;
    vm.readTerms = readTerms;
    vm.reprintReceipt = reprint;
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = authService.dateTimeFormat();

    vm.offerType = vm.model.offerType;
    vm.rewardStateExt = vm.model.rewardStateExt;

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[1]
            : date_components[0];
        var month =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[0]
            : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }

    if (vm.model.rewardState === 'Expired') {
      vm.isRedeemable =
        vm.isRedeemable &&
        redemptionService.userPermissions.enableUserRedeemExpired;
    }

    if (vm.model.isRedeemable === true) {
      vm.model.redeemAmount = '';
    }

    function readDescription() {
      vm.isShownDescription = true;
    }

    function readTerms() {
      vm.isShownTerms = true;
    }

    function redeemCodeWrapper(rewardCode, redemptionNote, redeemAmount) {
      if (vm.model.rewardState === 'Expired') {
        if (
          redemptionService.userPermissions &&
          redemptionService.userPermissions.allowUserRedeemExpired
        ) {
          confirmationModalService
            .confirm({
              message:
                'You are redeeming an expired code, do you want to continue?'
            })
            .result.then(function () {
              redeemCode(rewardCode, redemptionNote, redeemAmount);
            });
        } else {
          confirmationModalService
            .confirm({
              message:
                'You are not allowed to redeem an expired code, do you want to override user?',
              okBtnText: 'Override'
            })
            .result.then(function () {
              return $state.go('lobby.start', {
                rewardCode: rewardCode
              });
            });
        }
      } else {
        redeemCode(rewardCode, redemptionNote, redeemAmount);
      }
    }

    function redeemCode(rewardCode, redemptionNote, redeemAmount) {
      if (rewardCode) {
        redemptionService
          .redeemReward(rewardCode, redemptionNote, redeemAmount)
          .then(
            function (response) {
              $log.debug('reward code was marked as redeemed', response);
              if (response.allowUserPrintOnRedemption) {
                $state.go('redemption.print', {
                  rewardCode: vm.model.combinedCode.codeValue,
                  firstPrint: true
                });
              } else if (response.backToPinPad) {
                $state.go('lobby.start');
              } else {
                notifier.success(translate('The reward has been redeemed'));
                $state.go('redemption.search');
              }
            },
            function (response) {
              vm.errorsList = errorService.parseErrorsToList(
                response && response.data
              );
            }
          )
          .finally(function () {
            vm.isLoading = false;
          });

        vm.isLoading = true;
      }
    }

    function cancelBookingCode(rewardCode) {
      if (rewardCode) {
        redemptionService
          .cancelBookingReward(rewardCode)
          .then(
            function (response) {
              $log.debug('reward code was marked as canceled', response);
              if (response.backToPinPad) {
                $state.go('lobby.start');
              } else {
                notifier.success(
                  translate('The Booking reward has been canceled')
                );
                $state.go('redemption.search');
              }
            },
            function (response) {
              vm.errorsList = errorService.parseErrorsToList(
                response && response.data
              );
            }
          )
          .finally(function () {
            vm.isBookingLoading = false;
          });

        vm.isBookingLoading = true;
      }
    }

    function onElapseCountdownTimer() {
      redemptionService
        .getRewardInfo($stateParams.rewardCode)
        .then(function () {
          vm.model = redemptionService.rewardInfo;
        });
    }

    function reprint() {
      redemptionService.lastRedeemedReward.transactionId = undefined;
      $state.go('redemption.print', {
        rewardCode: vm.model.combinedCode.codeValue,
        firstPrint: false
      });
    }
  }

  RewardDetailsCtrl.$inject = [
    '$stateParams',
    '$log',
    'redemptionService',
    'errorService',
    '$state',
    'notifier',
    '$filter',
    'confirmationModalService',
    'authService'
  ];

  function rewardDetails() {
    return {
      restrict: 'AE',
      controller: RewardDetailsCtrl,
      controllerAs: 'reward',
      bindToController: true,
      templateUrl:
        'app/redemption/components/details/redemption-reward-details.tmpl.html'
    };
  }

  angular
    .module('redemption')
    .directive('redemptionRewardDetails', rewardDetails);
})(window.angular);
