(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.isTouchpoint = localStorage.getItem('isTouchpoint') === 'true';
		vm.init = function (ngModel, answers) {
			if (!ngModel || !answers) return;

			vm.getLabel = function (choice) {
				return answers.getLabel(choice);
			};

			vm.onChange = function () {
				ngModel.$setViewValue(vm.model);
				vm.onChangeModel({ $inputType: 'radio' });
			};

			ngModel.$render = function () {
				// Specify how UI should be updated
				vm.model = ngModel.$viewValue;
			};
		};
	}

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-true-or-false.tmpl.html',
			require: ['answerTrueOrFalse', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
				answerBackgroundColor: '=',
				answerDisabled: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);
			}
		};
	}

	angular.module('answer').directive('answerTrueOrFalse', answer);

})(window.angular);