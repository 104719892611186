(function (angular) {
	'use strict';

	function config($stateProvider) {
		$stateProvider
			.state('completed', {
				url: '/completed',
				templateUrl: 'app/completed/layout/completed.html'
			});
	}

	config.$inject = [
		'$stateProvider'
	];

	angular.module('completed').config(config);

})(window.angular);