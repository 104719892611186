(function (angular) {
	'use strict';

    angular.module('common').directive('fitFreeHeight', function () {
        return {
            restrict: 'A',
            link: function (scope, elem) {
                var parent = angular.element(elem).parent();
                var children = parent.children();
                var prevParentHeight = 0;
                var interval = setInterval(function(){
                    if (parent) {
                        var parentHeight = parent.height();
                        if (prevParentHeight != parentHeight) {
                            prevParentHeight = parentHeight;
                            var elementHeight = elem.height();
                            var childrenHeight = 0;
                            for (var i = 0; i < children.length; i++) {
                                childrenHeight += angular.element(children[i]).height();
                            }
                            var setHeight = parentHeight - (childrenHeight - elementHeight) - 20;
                            elem.css('height', setHeight + 'px');
                        }
                    }
                }, 1000);

                scope.$on('$destroy', function() {
                    clearInterval(interval);
                });
            }
        };
    });

})(window.angular);