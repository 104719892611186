(function (angular) {

	function FlipCounterDashboardBoxCtrl(){
		var vm = this;
        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };
	}

    FlipCounterDashboardBoxCtrl.$inject = [];

	function flipCounterDashboardBox() {
		return {
			controller: FlipCounterDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/flipCounter/flip-counter-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('flipCounterDashboardBox', flipCounterDashboardBox);

})(window.angular);