(function (angular) {
    'use strict';

    function htmlTemplateBindCompile($compile, $interpolate) {
        return {
            restrict: 'EA',
            link: function (scope, element, attrs) {

                scope.$watch(
                    function (scope) { return scope.$eval(attrs.htmlTemplateBindCompile); },
                    function (htmlTemplate) {
                        var comp = $interpolate(htmlTemplate)(scope);
                        element.html(comp);
                    });
            }
        };
    }

    htmlTemplateBindCompile.$inject = [
        '$compile', '$interpolate'
    ];

    angular.module('common').directive('htmlTemplateBindCompile', htmlTemplateBindCompile);

})(window.angular);