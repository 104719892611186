(function (angular) {
    'use strict';

    function searchCodeService(apiResource, sessionService) {
        var service = {};

        service.swapReward = swapReward;
        service.claimReward = claimReward;

        return service;

        function swapReward(swapRewardId) {
            var instanceId = sessionService.getSessionKey();
            return apiResource.post('api/rewardlookup/swap', {
                swapCampaignRewardItemId: swapRewardId,
                instanceId: instanceId
            });
        }

        function claimReward() {
            var instanceId = sessionService.getSessionKey();
            return apiResource.post('api/rewardlookup/claim', {
					instanceId: instanceId
            });
        }
    }

    searchCodeService.$inject = [
        'apiResource',
        'sessionService'
    ];

    angular.module('swapReward').factory('swapRewardService', searchCodeService);

})(window.angular);
