(function (angular, $) {
  'use strict';

  function ThankYouCtrl(
    $scope,
    $timeout,
    $log,
    $window,
    $state,
    $uibModal,
    utilityService,
    redeemService,
    inactivityConfirmationService,
    kioskService,
    $filter,
    authService,
    viewAnimationService,
    pickemService,
    notifier,
    swapRewardService,
    printerService
  ) {
    var vm = this,
      $translate = $filter('translate'),
      searchParams = utilityService.parseSearchQuery(),
      lobbyToken = searchParams.lobbytoken,
      lobbyConfig = kioskService.configuration,
      printButtonPromise,
      isShownRefreshButtonTimer,
      thankYouAutoRedirectTimer;

    vm.$timeout = $timeout;
    vm.printerService = printerService;
    vm.lobbyToken = lobbyToken;

    $log.debug('Model', redeemService.rewardData);

    vm.model = redeemService.rewardData;

    refreshRewardCodeInfo();

    vm.pageConfig = vm.model.dynamicConfiguration;
    vm.pageConfigLabelsHash = getLabelsHash(vm.pageConfig.labels);
    vm.complete = complete;
    vm.print = print;
    vm.redeem = redeem;
    vm.goToKiosk = goToKiosk;
    vm.refresh = refresh;
    vm.goToExternalUrl = goToExternalUrl;
    vm.claimReward = claimReward;
    vm.isDerivedFromKiosk = !!lobbyToken;

    vm.kioskDoneButtonText = $translate('Complete');
    vm.claimedLabel = $translate('Claimed');
    vm.expiresLabel = getLabel('expires', $translate('Expires'));

    vm.isShownRedeemButton =
      vm.model.redemptionOption === 'RedeemButton' &&
      !authService.isSystemUser();
    vm.isShownTermsLink = !vm.model.supressChanceOfWinning;
    vm.isShownRefreshButton = !!searchParams.loop;
    vm.isRefreshButtonDisabled = true;
    vm.onCompleteUrl = searchParams.oncompleteurl;
    vm.isTwoColumnsLayout = vm.model.isInstant || vm.model.containsAnswers;

    vm.model.isPrinter = vm.model.isPrinter || vm.model.displayPrintButton;
    vm.model.isReturnable = vm.model.isReturnable && !searchParams.atoken;
    vm.showTermsAndConditions = !vm.model.suppressTermsAndConditions;

    vm.openSwapsModal = openSwapsModal;
    vm.pickemInformUser = pickemInformUser;
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = authService.dateTimeFormat();

    if (vm.isDerivedFromKiosk && lobbyConfig) {
      vm.kioskDoneButtonText =
        lobbyConfig.uiConfiguration.doneText || vm.kioskDoneButtonText;
    }

    if (lobbyConfig) {
      vm.displayDoneButton = lobbyConfig.uiConfiguration.displayDoneButton;
      if (lobbyConfig.resetLobbyTimeout) {
        var resetLobbyTimeoutTimer = $timeout(function () {
          $state.go('lobby.init');
        }, lobbyConfig.resetLobbyTimeout * 1000);
      }
    }

    $scope.$on('$destroy', function () {
      if (printButtonPromise) {
        $timeout.cancel(printButtonPromise);
      }
      $timeout.cancel(isShownRefreshButtonTimer);
      $timeout.cancel(thankYouAutoRedirectTimer);
      $timeout.cancel(resetLobbyTimeoutTimer);
      inactivityConfirmationService.stop();
    });

    if (vm.isShownRefreshButton) {
      isShownRefreshButtonTimer = $timeout(function () {
        vm.isRefreshButtonDisabled = false;
      }, 5000);
    }

    if (vm.model.thankYouAutoRedirectEnabled) {
      thankYouAutoRedirectTimer = $timeout(function () {
        if (vm.model.thankYouAutoRedirectLinkUrl.indexOf('autoclose') === -1) {
          goToExternalUrl(vm.model.thankYouAutoRedirectLinkUrl);
        } else {
          window.location.href = '/closekiosk';
        }
      }, vm.model.thankYouAutoRedirectTimer * 1000);
    }

    function complete() {
      redeemService.complete();
    }

    function print() {
      if (vm.isDisabledPrintButton) {
        return false;
      }

      $window.print();

      if (~['GNFPT', 'AAwlAh'].indexOf(vm.lobbyToken)) {
        $timeout(function () {
          printerService.sendCut();
        }, 3000);
      }

      if (
        vm.isDerivedFromKiosk &&
        lobbyConfig &&
        lobbyConfig.uiConfiguration.disablePrintButton
      ) {
        vm.isDisabledPrintButton = true;
        if (lobbyConfig.uiConfiguration.disablePrintButtonTimeInterval > 0) {
          printButtonPromise = $timeout(function () {
            vm.isDisabledPrintButton = false;
          }, lobbyConfig.uiConfiguration.disablePrintButtonTimeInterval * 1000);
        }
      }
    }

    function refresh() {
      // I am checking here because oc-focus will not focus on a disabled
      // button.
      if (!vm.isRefreshButtonDisabled) {
        $window.location.reload();
      }
    }

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[1]
            : date_components[0];
        var month =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[0]
            : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }
    function redeem() {
      $state.go('lobby.start', {
        rewardCode: vm.rewardCode
      });
    }

    function goToKiosk() {
      return kioskService.goToKiosk(lobbyToken);
    }

    function goToExternalUrl(url) {
      return redeemService.goToExternalUrl(url);
    }

    function getLabelsHash(labels) {
      var hash = {};

      labels = labels || [];

      labels.forEach(function (label) {
        hash[label.key] = label;
      });

      return hash;
    }

    function getLabel(key, defaultLabel) {
      var hash = vm.pageConfigLabelsHash || {};
      return hash[key] && hash[key].isOverride
        ? hash[key].label
        : defaultLabel + ':';
    }

    function openSwapsModal() {
      return $uibModal
        .open({
          animation: true,
          templateUrl: 'app/redeem/components/swap/swap-reward-modal.tmpl.html',
          controller: 'SwapRewardModalCtrl',
          controllerAs: 'swap',
          resolve: {
            swapsList: function () {
              return vm.model.swapsModel.swaps;
            }
          }
        })
        .result.then(
          function (newReward) {
            angular.extend(vm.model, newReward);
            vm.model.swapsModel.isEnabled = false;
            vm.model.imageUrl = vm.model.iconUrl;
            vm.model.needToClaim = newReward.swapData.needToClaim;
            vm.model.claimedOn = newReward.swapData.claimedOn;
            vm.model.combinedCode = newReward.swapData.combinedCode;
            refreshRewardCodeInfo();
          },
          function () {
            //vm.cancelSelect();
          }
        );
    }

    function openPickemLinkModal() {
      return $uibModal
        .open({
          animation: true,
          templateUrl:
            'app/redeem/components/pickem/pickem-inform-modal.tmpl.html',
          controller: 'PickemModalCtrl',
          controllerAs: 'pickem',
          size: 'md',
          resolve: {}
        })
        .result.then(
          function () {},
          function () {}
        );
    }

    function pickemInformUser() {
      pickemService.pickemInformUser().then(
        function () {
          notifier.success('The link to your picks was sent to you.');
        },
        function () {
          notifier.error('There was a problem sending the link.');
        }
      );
    }

    if (vm.model.displayPickemInformLink) {
      openPickemLinkModal();
    }

    function claimReward() {
      if (vm.isLoading) {
        return;
      }

      vm.isLoading = true;

      swapRewardService
        .claimReward()
        .then(function (data) {
          vm.model.needToClaim = false;
          vm.model.swapsModel.isEnabled = false;
          vm.model.claimedOn = data.claimedOn;
          vm.model.combinedCode = data.combinedCode;
          refreshRewardCodeInfo();
          return data;
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    if (viewAnimationService.enterVideoUrl) {
      viewAnimationService
        .showVideoInstead('thank-you', viewAnimationService.enterVideoUrl)
        .then(function () {
          viewAnimationService.startEnterTransition($('.cim-transition'));
        });
    } else {
      viewAnimationService.startEnterTransition($('.cim-transition'));
    }
    function refreshRewardCodeInfo() {
      vm.rewardCode = vm.model.combinedCode.codeValue;
      vm.walletCode = vm.model.combinedCode.isOverridden
        ? vm.model.combinedCode.auxCodeValue
        : vm.model.combinedCode.codeValue;
      vm.rewardCodeType = vm.model.combinedCode.type;
      vm.isShownBarCodeImage =
        vm.model.isInstant &&
        vm.rewardCode &&
        vm.rewardCodeType != 'plaintext' &&
        vm.rewardCodeType != 'none';
      vm.isShownRewardCodeText = vm.model.isInstant && vm.rewardCode;
    }

    viewAnimationService.startEnterTransition($('.cim-transition'));
  }

  ThankYouCtrl.$inject = [
    '$scope',
    '$timeout',
    '$log',
    '$window',
    '$state',
    '$uibModal',
    'utilityService',
    'redeemService',
    'inactivityConfirmationService',
    'kioskService',
    '$filter',
    'authService',
    'viewAnimationService',
    'pickemService',
    'notifier',
    'swapRewardService',
    'printerService'
  ];

  function thankYou() {
    return {
      restrict: 'AE',
      controller: ThankYouCtrl,
      controllerAs: 'ctrl',
      bindToController: true,
      templateUrl: 'app/redeem/components/thankYou/thank-you.tmpl.html',
      scope: {},
      link: function (scope, attr, elem, ctrls) {
        if (ctrls.pageConfig.postHtml && window.Odometer) {
          //var count;
          //var current;
          var odometerValue;
          var odometerObj;
          var updateOdometer = function () {
            //current++;
            //if (current <= count) {
            odometerObj.update(odometerValue.toString());
            //if (current <= count + 1) {
            //setTimeout(updateOdometer, 4000);
            // }
            //  }
          };
          try {
            var postHtml = angular.element(ctrls.pageConfig.postHtml);
            var odometer = postHtml.filter('#odometer');
            if (odometer && odometer.length) {
              odometer = odometer[0];
              odometerValue = odometer.innerHTML
                .replace(/(\r\n|\n|\r)/gm, '')
                .replace(/ +/g, '')
                .split(',');
              // if (
              //   odometerValue &&
              //   odometerValue.length &&
              //   odometerValue.length > 1
              // ) {
              //count = odometerValue.length;
              //current = 0;
              setTimeout(function () {
                var odometerElement = document.querySelector('#odometer');
                odometerObj = new window.Odometer({
                  el: odometerElement,
                  value: odometerValue
                    .toString()

                    .replace(/[0-9]/g, '0'),
                  duration: 100,
                  format: '( ddd)',
                  theme: 'train-station' /* ,
                    animation: 'count' */
                });
              }, 1);
              setTimeout(updateOdometer, 1);
              // }
            }
          } catch (error) {
            0;
          }
        }
        if (~['GNFPT', 'AAwlAh'].indexOf(scope.ctrl.lobbyToken)) {
          var interval = setInterval(function () {
            if (window.externalPrintDone) {
              scope.ctrl.$timeout(function () {
                scope.ctrl.printerService.sendCut();
              }, 3000);
              delete window.externalPrintDone;
              clearInterval(interval);
            }
          }, 100);
        }
      }
    };
  }

  function redeem() {
    return {
      restrict: 'AE',
      controller: ThankYouCtrl,
      controllerAs: 'ctrl',
      bindToController: true,
      templateUrl: 'app/redeem/components/thankYou/redeem.tmpl.html',
      scope: {}
    };
  }

  angular
    .module('redeem')
    .directive('thankYou', thankYou)
    .directive('redeem', redeem);
})(window.angular, window.jQuery);
