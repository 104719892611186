(function (angular) {
    'use strict';

    function QuestionElementCtrl($uiViewScroll, $scope) {
        var vm = this;
        vm.isTouchpoint = localStorage.getItem('isTouchpoint') === 'true';

        if (!vm.country) {
            vm.country = {};
            vm.countryId && (vm.country.id = vm.countryId);
            vm.phoneCode && (vm.country.phoneCode = vm.phoneCode);
        }

        $scope.$watch(function () {
            return vm.country;
        }, function (newVal) {
            if (newVal) {
                newVal.phoneCode && (vm.phoneCode = newVal.phoneCode);
                newVal.id && (vm.countryId = newVal.id);
            }
        });

        if (!inIframe()) {
            var mainContainer = angular.element(document.querySelector('#main-container'));
            if (angular.isElement(mainContainer)) {
                $uiViewScroll(mainContainer);
            }
        }

        function inIframe() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }

    }

    QuestionElementCtrl.$inject = [
        '$uiViewScroll',
        '$scope'
    ];

    function questionElement() {
        return {
            restrict: 'AE',
            controller: QuestionElementCtrl,
            controllerAs: 'question',
            bindToController: true,
            templateUrl: 'app/question/components/questionCim/question-element.tmpl.html',
            scope: {
                model: '=question',
                onChangeAnswer: '&',
                form: '=',
                countryId: '=',
                phoneCode: '=',
                totalQuestions: '=',
                getCurrentQuestionNum: '&',
                showQuestionsProgress: '=',
                questionAnswerBackgroundColor: '=',
                onscreenKeyboard: '=',
                showNearEveryInput: '=',
                advanceAutomatically: '='
            }
        };
    }

    angular.module('question').directive('questionElement', questionElement);

})(window.angular);
