(function (angular) {
	'use strict';

	function deliveryService($log, $q, EventEmitter, apiSessionResource, sessionService, $uibModal, $rootScope, utilityService) {
		var service = Object.create(EventEmitter.prototype);

		service.rewardDeliveryModel = null;
		service.getReward = getReward;
		service.deliverReward = deliverReward;
		service.showDeliveryOptionsModal = showDeliveryOptionsModal;
		service.complete = complete;

		return service;

		function getReward(ignoreDefaultErrorNotifier) {
			var deferred = $q.defer();

			apiSessionResource
				.get('/rewardDelivery', {
					ignoreDefaultErrorNotifier: !!ignoreDefaultErrorNotifier
				})
				.then(function (data) {
					preLoadDeliveryConfigDataSafely(data).then(function() {
						$log.debug('get campaign\'s reward', data);
						service.rewardDeliveryModel = data;
						deferred.resolve(data);
						fireEvent('delivery.onGetModel', data);
					});
				}, function (result) {
					deferred.reject(result);
				}).finally(function () {
					fireEvent('delivery.onStopGettingModel', deferred);
				});

			fireEvent('delivery.onStartGettingModal', deferred);

			return deferred.promise;
		}

		function preLoadDeliveryConfigDataSafely(model) {

			function done() {
				return $q.when(model);
			}

			if (!model || !model.popupConfiguration || !model.popupConfiguration.backgroundImage) {
				return done();
			}

			return utilityService
				.loadImage(model.popupConfiguration.backgroundImage)
				.then(done, done);

		}

		function deliverReward(deliveryModel, ignoreDefaultErrorNotifier) {
			return apiSessionResource
				.post('/rewardDelivery', deliveryModel, {
					ignoreDefaultErrorNotifier: !!ignoreDefaultErrorNotifier
				})
				.then(function (data) {
					$log.debug('delivery reward', data);
					return data;
				});
		}

		function showDeliveryOptionsModal(rewardDelivery) {
			// Old Method
			return $uibModal.open({
				templateUrl: 'app/delivery/components/delivery/delivery-reward-modal.tmpl.html',
				controller: 'DeliveryRewardModalCtrl',
				controllerAs: 'ctrl',
				backdrop: 'static',
				keyboard: false,
				windowClass: 'modal-delivery-reward',
				resolve: {
					deliveryModel: function () {
						return rewardDelivery || {};
					}
				}
			}).result;
		}

		function complete() {
			return sessionService.getCurrentStep();
		}

		function fireEvent(event, data) {
			service.trigger(event, (typeof data !== 'undefined') ? [data] : undefined);
			$rootScope.$applyAsync();
		}
	}

	deliveryService.$inject = [
		'$log',
		'$q',
		'EventEmitter',
		'apiSessionResource',
		'sessionService',
		'$uibModal',
		'$rootScope',
		'utilityService'
	];

	angular.module('delivery').factory('deliveryService', deliveryService);

})(window.angular);