(function (angular) {
	'use strict';

	function run($rootScope, $state, browserService, authService) {
		$rootScope.$on('$stateChangeSuccess', function () {
			var clientName = authService.authentication.clientName;

			switch ($state.current.name) {
				case 'login':
					return browserService.setDocumentTitle('Login');
				case 'pin':
				case 'campaigns':
					return browserService.setDocumentTitle(clientName);
				default:
					return browserService.setDocumentTitle(clientName);
			}
		});
	}

	run.$inject = [
		'$rootScope',
		'$state',
		'browserService',
		'authService'
	];

	angular.module('browser').run(run);

})(window.angular);