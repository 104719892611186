(function (angular) {
	'use strict';

    function SubscriptionDetailsCtrl($uibModalInstance, model) {
        var vm = this;
        vm.model = model;
        vm.capitalize = function (word) {
            return word[0].toUpperCase() + word.substring(1);
        };

        vm.cancel = function () {
            $uibModalInstance.close(false);
        };
	}

    SubscriptionDetailsCtrl.$inject = [
        '$uibModalInstance',
        'model'
	];

    angular
        .module('email')
        .controller('SubscriptionDetailsCtrl', SubscriptionDetailsCtrl);

})(window.angular);