(function (angular) {
	'use strict';

	angular.module('common', [
		'ngSanitize',
		'ui.bootstrap',
		'ngAnimate',
		'pascalprecht.translate',
		'slick',
		'settings',
        'angular-intro'
	]);

})(window.angular);