(function (angular) {
	'use strict';

	function ocFocus($parse, $timeout) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var model = $parse(attrs.ocFocus);

				scope.$watch(model, function (value) {
					if (value === true) {
						$timeout(function () {
							element[0].focus();
						});
					}
				});
				element.bind('blur', function () {
                    if(model.assign) {
                        scope.$applyAsync(model.assign(scope, false));
                    } else {
                        attrs.ocFocus = false;
					}
                });
			}
		};
	}

	ocFocus.$inject = [
		'$parse',
		'$timeout'
	];

	angular.module('common').directive('ocFocus', ocFocus);

})(window.angular);