(function (angular) {
  'use strict';

  function lobbyService(
    $window,
    $rootScope,
    $state,
    apiResource,
    storageService,
    errorService,
    sessionService,
    authService,
    $timeout,
    $q,
    $filter,
    kioskService,
    redemptionService,
    apiSessionResource,
    utilityService
  ) {
    var service = Object.create($window.EventEmitter.prototype),
      translateFilter = $filter('translate');

    service.LOBBY_TYPES = {
      STANDARD: 'Standard',
      LANDING: 'LandingPage',
      CHALLENGE: 'Challenge'
    };
    service.LOBBY_MODES = {
      STANDARD: 'Standard',
      PICKEM: 'Pickem',
      COUPON: 'Coupon',
      CALENDAR: 'Calendar'
    };
    service.CHALLENGE_LOBBY_CAMPAIGN_STATUSES = {
      ACTIVE: 'Active',
      PENDING: 'Pending',
      ENDED: 'Ended'
    };
    service.campaignId = null;
    service.accessRequestId = null;
    service.isPreviewMode = false;
    service.requiresPinCode = false;
    service.isLandingPage = false;
    service.isChallengeLobby = false;
    service.allowRedeem = false;
    service.campaignsSlides = null;
    service.campaigns = null;
    service.config = null;
    service.isTransitionToInfoStateProgrammed = false;
    service.startCampaign = startCampaign;
    service.startSession = startSession;
    service.startCampaignWithPassThrough = startCampaignWithPassThrough;
    service.startSessionWithPassThrough = startSessionWithPassThrough;
    service.startCampaignViaExecute = startCampaignViaExecute;
    service.startSessionByPinCode = startSessionByPinCode;
    service.getLobbyConfig = getLobbyConfig;
    service.getCampaignsSlides = getCampaignsSlides;
    service.getDummyCampaignsSlides = getDummyCampaignsSlides;
    service.getCampaigns = getCampaigns;
    service.collectLobbyClicks = collectLobbyClicks;
    service.getLandingPageCampaigns = getLandingPageCampaigns;
    service.getCampaignsWithoutAC = getCampaignsWithoutAC;
    service.startCampaignWithoutAC = startCampaignWithoutAC;
    service.onErrorRollbackToState = onErrorRollbackToState;
    service.isStandardLobbyType = isStandardLobbyType;
    service.isLandingPageLobbyType = isLandingPageLobbyType;
    service.isChallengeLobbyType = isChallengeLobbyType;
    service.redirectoToInitialLobbyState = redirectoToInitialLobbyState;
    service.clearDynamicCouponBookConfigurations =
      clearDynamicCouponBookConfigurations;
    service.getDynamicLobbyConfiguration = getDynamicLobbyConfiguration;
    service.setIsAccessControlPage = setIsAccessControlPage;
    service.setCookedData = setCookedData;
    service.setOpenOnLoadToken = setOpenOnLoadToken;
    service.getCampaignScheduleRecords = getCampaignScheduleRecords;
    service.checkAvailability = checkAvailability;

    return service;

    function checkAvailability(campaignId) {
      var httpConfig = {
        ignoreDefaultErrorNotifier: true
      };
      return apiResource
        .get(
          '/api/lobby/' +
            service.aToken +
            '/campaigns/' +
            campaignId +
            '/couponAvailability',
          httpConfig
        )
        .then(function (data) {
          return data;
        });
    }

    function setCookedData(cooked) {
      service.cooked = cooked;
    }

    function getLobbyConfig(aToken) {
      var httpConfig = {
        ignoreDefaultErrorNotifier: true
      };

      if (aToken) {
        return authService
          .loginByAToken(aToken, 'lobby')
          .then(function () {
            return apiResource
              .get('/api/lobby/' + aToken, httpConfig)
              .then(function (data) {
                service.aToken = aToken;
                data.hasLobby = true;
                return onSuccessGetLobbyConfig(data);
              }, onErrorGetLobbyConfig);
          })
          .catch(onErrorGetLobbyConfig);
      }

      return apiResource
        .get('/api/lobby/default', httpConfig)
        .then(function (data) {
          service.aToken = data.aToken;
          service.campaignsSlides = extendSlidesData(data.slides) || {};
          if (!data.configuration) {
            data.configuration = { requiresPinCode: true };
          }
          data.configuration.hasLobby = data.hasLobby;
          return onSuccessGetLobbyConfig(data.configuration);
        }, onErrorGetLobbyConfig);
    }

    function onSuccessGetLobbyConfig(data) {
      service.lobbyId = data.lobbyId;
      service.hasAccessControl = data.hasAccessControl;
      service.accessControlId = data.accessControlId;
      service.isPreviewMode = data.isPreviewMode;
      service.requiresPinCode = data.requiresPinCode;
      service.allowRedeem = data.allowRedeem;
      service.isLandingPageLobby = isLandingPageLobbyType(data.lobbyType);

      var searchParams = utilityService.parseSearchQuery();
      service.schedulerId = searchParams.schedulerid;

      service.config = angular.extend(
        {
          mainImage: data.imageUri,
          screenSaverDelay: data.screenSaverDelay,
          hasLobby: data.hasLobby,
          isChallengeLobby: isChallengeLobbyType(data.lobbyType),
          challengeCampaignActiveStatus:
            service.CHALLENGE_LOBBY_CAMPAIGN_STATUSES.ACTIVE
        },
        data,
        data.uiConfiguration
      );

      // Share config with KioskService to support redeemDirective
      kioskService.configuration = service.config;

      fireEvent('onGetLobbyConfig', service.config);

      return service.config;
    }

    function onErrorGetLobbyConfig(error) {
      if (!authService.isUnauthorizedStatus(error.status)) {
        showErrorInSeparateState(error);
      }
      return $q.reject(error);
    }

    function getCampaignsSlides(aToken) {
      if (aToken) {
        return apiResource
          .get('/api/lobby/' + aToken + '/slideshow')
          .then(function (data) {
            return (service.campaignsSlides = extendSlidesData(data));
          });
      }
      return service.campaignsSlides;
    }

    function getDummyCampaignsSlides() {
      return (service.campaignsSlides = {});
    }

    function extendSlidesData(data) {
      if (data) {
        data.slides = data.mediaList || [];
        if (data.slides.length === 0) {
          data.slides = data.campaignImages || [];
        }
      }
      return data;
    }

    function getCampaigns(aToken, accessRequestId) {
      if (accessRequestId === null) {
        redirectoToInitialLobbyState();
        return;
      }
      return apiResource
        .get(
          '/api/lobby/' +
            (aToken || 'default') +
            '/campaigns/' +
            accessRequestId
        )
        .then(function (data) {
          service.campaignsMessage = data.message;
          service.campaigns = angular.isArray(data) ? data : [];
          service.campaigns = service.campaigns.map(function (campaign) {
            if (campaign.knownReward) {
              campaign.knownReward = redemptionService.extendRewardWithMessages(
                campaign.knownReward
              );
            }
            return campaign;
          });

          service.campaigns.forEach(function (campaign) {
            if (!$rootScope.currentCampaignLogoUri)
              $rootScope.currentCampaignLogoUri = {};
            $rootScope.currentCampaignLogoUri[campaign.campaignId] =
              campaign.clientLogoUri.value;
          });

          return service.campaigns;
        });
    }

    function getDynamicLobbyConfiguration(accessRequestId, aToken) {
      return apiResource
        .get(
          '/api/lobby/getDynamicLobbyConfiguration?accessRequestId=' +
            accessRequestId +
            '&lobbyToken=' +
            aToken
        )
        .then(function (dynamicLobbyConfiguration) {
          service.dynamicLobbyConfiguration =
            dynamicLobbyConfiguration || service.dynamicLobbyConfiguration;
          fireEvent('onGetCouponBookConfiguration');
          return service.dynamicLobbyConfiguration;
        });
    }

    function clearDynamicCouponBookConfigurations() {
      service.dynamicLobbyConfiguration = {};
      fireEvent('onClearCouponBookConfiguration');
    }

    function setIsAccessControlPage(isAC) {
      service.isAccessControlPage = isAC;
      fireEvent('onSetAccessControlPage');
    }

    function getLandingPageCampaigns(lobbyId, isPreview, pinCode) {
      var isPreviewBool =
        isPreview != undefined && isPreview != null
          ? isPreview.toLowerCase() === 'true'
          : false;
      return apiResource
        .post('/api/lobby/landingPage/' + lobbyId + '/campaigns', {
          isPreview: isPreviewBool,
          pinCode: pinCode
        })
        .then(function (data) {
          service.campaignsMessage = data.message;
          service.campaigns = angular.isArray(data) ? data : [];
          return service.campaigns;
        });
    }

    function collectLobbyClicks(campaign) {
      if (service.config.lobbyMode === 'Coupon' && !service.config.isPreview) {
        return apiResource
          .post('/api/instance/lobbyCampaignClick', {
            lobbyId: service.lobbyId,
            campaignId: campaign.campaignId,
            externalId: campaign.externalId,
            groups: campaign.groups
          })
          .then(function (data) {
            return data;
          });
      }
    }

    function getCampaignsWithoutAC() {
      return apiSessionResource.get('/campaigns').then(function (data) {
        return (service.campaigns = data);
      });
    }

    function startCampaignWithoutAC(campaignId) {
      return sessionService
        .startCampaign(campaignId)
        .then(function () {
          return sessionService.getCurrentStep();
        })
        .catch(onStartSessionError);
    }

    function startCampaign(campaignId, scheduleId, aToken, accessRequestId) {
      if (service.requiresPinCode) {
        return startSession(campaignId, scheduleId, accessRequestId, service.isPreviewMode);
      } else {
        return authService.loginByAToken(aToken, 'lobby').then(function () {
          return startSession(
            campaignId,
            scheduleId,
            accessRequestId,
            service.isPreviewMode
          );
        });
      }
    }

    function startCampaignWithPassThrough(
      campaignId,
      scheduleId,
      aToken,
      accessRequestId
    ) {
      if (service.requiresPinCode) {
        return startSessionWithPassThrough(
          campaignId,
          scheduleId,
          accessRequestId,
          service.isPreviewMode
        );
      } else {
        return authService.loginByAToken(aToken, 'lobby').then(function () {
          return startSessionWithPassThrough(
            campaignId,
            scheduleId,
            accessRequestId,
            service.isPreviewMode
          );
        });
      }
    }

    function startCampaignViaExecute() {
      if (service.requiresPinCode) {
        return startSessionViaExecute(service.isPreviewMode);
      } else {
        return authService
          .loginByAToken(sessionService.cooked.aToken, 'lobby')
          .then(function () {
            return startSessionViaExecute(
              service.isPreviewMode,
              sessionService.cooked.apiKey
            );
          });
      }
    }

    function getCampaignScheduleRecords(
      pageNumber,
      pageSize,
      campaignId,
      setNumber,
      scheduleId
    ) {
      return apiResource.get(
        '/api/lobby/' +
          service.aToken +
          '/campaigns/' +
          service.accessRequestId +
          '/' +
          campaignId +
          '/schedule?pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize +
          '&setNumber=' +
          setNumber +
          '&schedulerId=' +
          scheduleId
      );
    }

    function startSession(campaignId, scheduleId, accessRequestId, isPreviewMode) {
      campaignId = campaignId || service.campaignId;
      accessRequestId = accessRequestId || service.accessRequestId;
      scheduleId = scheduleId || service.scheduleId;
      isPreviewMode =
        typeof isPreviewMode != 'undefined'
          ? isPreviewMode
          : service.isPreviewMode;

      $rootScope.lastStartedCampaign = campaignId;

      return sessionService
        .startSessionInCampaignModeByCampaignId(
          campaignId,
          scheduleId,
          accessRequestId,
          isPreviewMode,
          true
        )
        .catch(onStartSessionError);
    }

    function startSessionWithPassThrough(
      campaignId,
      scheduleId,
      accessRequestId,
      isPreviewMode
    ) {
      campaignId = campaignId || service.campaignId;
      accessRequestId = accessRequestId || service.accessRequestId;
      scheduleId = scheduleId || service.scheduleId;
      isPreviewMode =
        typeof isPreviewMode != 'undefined'
          ? isPreviewMode
          : service.isPreviewMode;

      return sessionService
        .startSessionWithPassThrough(
          campaignId,
          scheduleId,
          accessRequestId,
          isPreviewMode,
          true
        )
        .catch(onStartSessionError);
    }

    function startSessionViaExecute(isPreview, apiKey) {
      return sessionService
        .startSessionViaExecute(isPreview, apiKey, service.schedulerId)
        .catch(onStartSessionError);
    }

    function onStartSessionError(error) {
      //Remove app data from storage in order to start from beginning next time
      storageService.clearAll();

      error.status = +error.status;

      if (!authService.isUnauthorizedStatus(error.status)) {
        showErrorInSeparateState(error);
      }
    }

    function showErrorInSeparateState(error) {
      $timeout(function () {
        var errorMessage =
          errorService.parseErrorsToList(error.data).join(' ') ||
          translateFilter('Unexpected error.');

        service.isTransitionToInfoStateProgrammed = true;

        return $state
          .go('info', {
            message: errorMessage,
            reload: true
          })
          .finally(function () {
            service.isTransitionToInfoStateProgrammed = false;
          });
      });
    }

    function startSessionByPinCode(code) {
      // Old method
      return sessionService.startSessionByPinCode(code);
    }

    function onErrorRollbackToState(stateName) {
      return function (error) {
        $timeout(function () {
          $state.go(stateName);
        });
        return $q.reject(error);
      };
    }

    function redirectoToInitialLobbyState() {
      $state.go('lobby.init');
    }

    function isStandardLobbyType(lobbyType) {
      return lobbyType === service.LOBBY_TYPES.STANDARD;
    }

    function isLandingPageLobbyType(lobbyType) {
      return lobbyType === service.LOBBY_TYPES.LANDING;
    }

    function isChallengeLobbyType(lobbyType) {
      return lobbyType === service.LOBBY_TYPES.CHALLENGE;
    }

    function fireEvent(event, data) {
      service.trigger(event, typeof data !== 'undefined' ? [data] : undefined);
      $rootScope.$applyAsync();
    }

    function setOpenOnLoadToken(campaignId) {
      service.campaignIdToLoad = campaignId;
    }
  }

  lobbyService.$inject = [
    '$window',
    '$rootScope',
    '$state',
    'apiResource',
    'storageService',
    'errorService',
    'sessionService',
    'authService',
    '$timeout',
    '$q',
    '$filter',
    'kioskService',
    'redemptionService',
    'apiSessionResource',
    'utilityService'
  ];

  angular.module('lobby').factory('lobbyService', lobbyService);
})(window.angular);
