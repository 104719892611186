(function (angular) {
	'use strict';

	function toggleSoundButton($window) {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/toggle-sound-button.tmpl.html',
			scope: {
				src: '='
			},
			link: function (scope) {
				var Howl = $window.Howl,
					//Howler = $window.Howler,
					backgroundMusic;

				scope.toggleMute = toggleMute;
				scope.muted = false;
				scope.isMuted = isMuted;

				if (scope.src) {
					backgroundMusic = new Howl({urls: [scope.src], autoplay: true, loop: true});

					if (/(iphone|ipod|ipad)/i.test($window.navigator.userAgent)) {
						toggleMute();
					}

					scope.$on('$destroy', function () {
						backgroundMusic.unload();
					});
				}

				function toggleMute() {
					if (scope.muted) {
						backgroundMusic.unmute();
						scope.muted = false;
					} else {
						backgroundMusic.mute();
						scope.muted = true;
					}

					scope.$applyAsync();
				}

				function isMuted() {
					return scope.muted;
				}
			}
		};
	}

	toggleSoundButton.$inject = [
		'$window'
	];

	angular.module('common').directive('toggleSoundButton', toggleSoundButton);

})(window.angular);