(function (angular) {
	'use strict';

	function AcCaptchaCtrl(vcRecaptchaService) {
		var vm = this;

		vm.response = null;
		vm.widgetId = null;

		vm.setResponse = function (response) {
			// Response available
			vm.response = response;
			//vm.submit({$value: response});
			vm.submit({
				$value: {
					value: response,
					isConsented: undefined,
					ruleId: vm.ruleId
				}
			});

		};

		vm.setWidgetId = function (widgetId) {
			vm.widgetId = widgetId;
		};

		vm.expire = function() {
			// Captcha expired. Resetting response object
			vcRecaptchaService.reload(vm.widgetId);
			vm.response = null;
		};
	}

	AcCaptchaCtrl.$inject = [
		'vcRecaptchaService'
	];

	function acInteractionCaptcha() {
		return {
			restrict: 'AE',
			controller: AcCaptchaCtrl,
			controllerAs: 'captcha',
			bindToController: true,
			templateUrl: 'app/accessControl/components/interaction/captcha/captcha.tmpl.html',
			scope: {
				key: '=',
				ruleId: '=',
				submit: '&'
			}
		};
	}

	angular.module('accessControl').directive('acInteractionCaptcha', acInteractionCaptcha);

})(window.angular);
