(function (angular) {
	'use strict';

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('embedded', {
				url: '/embedded',
				templateUrl: 'app/embedded/layout/embedded.html',
				resolve: {
					embedded: ['embeddedService', function (embeddedService) {
						return embeddedService.getEmbedded({
							showErrorInSeparateState: true
						});
					}]
				}
			});

		$urlRouterProvider.otherwise('/');
	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider'
	];

	angular.module('embedded').config(config);

})(window.angular);