(function (angular) {
	'use strict';

	function ocFocusIf($parse, $window) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var model = $parse(attrs.ocFocusIf);

				scope.$watch(model, function (value) {
					if (value === true) {
						$window.setTimeout(function () {
							element[0].focus();
						}, 50);
					}
				});
			}
		};
	}

	ocFocusIf.$inject = [
		'$parse',
		'$window'
	];

	angular.module('common').directive('ocFocusIf', ocFocusIf);

})(window.angular);