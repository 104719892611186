(function (angular) {
	'use strict';

	function ocLoadMoreButton($window, $timeout, utilityService) {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/oc-load-more-button.tmpl.html',
			scope: {
				numberOfItems: '=',
				totalItems: '='
			},
			link: function (scope) {
				scope.showButton = true;

				scope.$watch('totalItems', function() {
					scope.numberOfItems = $window.innerWidth < 992 ? 10 : scope.totalItems;
				}, true);

				scope.loadAll = function () {
					scope.showButton = false;
					scope.numberOfItems = scope.totalItems;
				};

				scope.onResize = function () {
					if (!scope.showButton)
						return;

					$timeout(function () {
						scope.numberOfItems = $window.innerWidth < 992 ? 10 : scope.totalItems;
					}, 0);
				};

				window.onresize = utilityService.debounce(function () {
					scope.onResize();
				}, 200);

				scope.onResize();
			}
		};
	}

	ocLoadMoreButton.$inject = ['$window', '$timeout', 'utilityService'];


	angular.module('common').directive('ocLoadMoreButton', ocLoadMoreButton);

})(window.angular);