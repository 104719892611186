(function (angular, $) {
	'use strict';

	function QuestionCimCtrl($scope, $element, $window, $timeout, questionService, formService, notifier, $filter, utilityService, confirmationModalService, kioskService, errorService, viewAnimationService) {
		var vm = this,
			showSubmitBtnTimer,
			translate = $filter('translate'),
			searchParams = utilityService.parseSearchQuery(),
			lobbyToken = searchParams.lobbytoken,
			derivedFromKiosk = !!lobbyToken,
			validationRegExpErrorMessage = translate('Your information does not match. Please try again.'),
			validationRequiredErrorMessage = translate('Please give an answer on the required question.'),
			validationNotMatchedErrorMessage = translate('Entered values do not match.'),
			backgroundMusic,
			Howl = $window.Howl;

		vm.isTouchpoint = localStorage.getItem('isTouchpoint') === 'true';
		vm.isShownSubmitButton = true;
		vm.isMuted = false;
		vm.questionCim = questionService.questionCim;
		vm.randomCount = vm.questionCim.randomCount | 0;
		vm.questionIndex = -1;
		vm.getCurrentQuestionNum = getCurrentQuestionNum;
		vm.onChangeAnswer = onChangeAnswer;
		vm.onSubmit = onSubmit;
		vm.isFormValid = isFormValid;

		function isFormValid(questionsForm) {
			return questionsForm && questionsForm.$valid && questionsForm.$dirty;
		}

		if (vm.randomCount === 0) {
			removeAnsweredInfoCards();
		}

		if (!vm.questionCim.groups || vm.questionCim.groups.length === 0) {
			completeStep();
		}

		vm.questionsToAsk = [];
		angular.copy(vm.questionCim.items.filter(function (item) {
			return item.questionType !== 'InfoCard';
		}), vm.questionsToAsk);

		vm.totalQuestions = vm.randomCount === 0 ? vm.questionsToAsk.length : vm.randomCount;

		vm.model = setModel(getFirstGroup()) || {};
		getLayoutMatrix();

		vm.uiCustomization = vm.questionCim.uiCustomization;
		if (vm.uiCustomization && vm.uiCustomization.backgroundMusic) {
			backgroundMusic = new Howl({
				urls: [vm.uiCustomization.backgroundMusic],
				autoplay: true,
				loop: true
			});

			$scope.$on('$destroy', function () {
				backgroundMusic.unload();
			});
		}

		if (vm.uiCustomization && vm.uiCustomization.showQuestionsProgress === null) {
			vm.uiCustomization.showQuestionsProgress = true;
		}

		if (viewAnimationService.enterVideoUrl) {
			viewAnimationService.showVideoInstead('.question-row', viewAnimationService.enterVideoUrl).then(function () {
				viewAnimationService.startEnterTransition($('.question-transition'));
			});
		} else {
			viewAnimationService.startEnterTransition($('.question-transition'));
		}

		function getCurrentQuestionNum(questionId) {
			if (vm.questionIndex > -1) {
				return vm.questionIndex;
			}

			var index = -1;

			if (vm.randomCount === 0) {
				var question = vm.questionsToAsk.filter(function (q) {
					return q.questionId === questionId.questionId;
				});
				if (question.length) {
					index = question[0].index;
				}
			} else {
				index = vm.totalQuestions - vm.questionsToAsk.filter(function (item) {
					return !item.isAnswered;
				}).length + 1;
			}

			vm.questionIndex = index;

			return index > -1 ? index : 1;
		}

		function removeAnsweredInfoCards() {
			if (!vm.questionCim.groups && vm.questionCim.groups.length === 0) {
				return;
			}

			var questions = vm.questionCim.items,
				answered = questions.filter(function (item) {
					return item.isAnswered;
				});

			if (answered.length === 0) {
				return;
			}

			var lastAnswered = answered[answered.length - 1],
				answeredInfoCards = [];

			for (var i = 0; i < questions.length; i++) {
				var question = questions[i];
				if (question.questionId === lastAnswered.questionId) {
					break;
				}
				if (question.questionType === 'InfoCard') {
					answeredInfoCards.push(question.questionId);
				}
			}

			if (answeredInfoCards.length === 0) {
				return;
			}

			var groups = vm.questionCim.groups,
				groupsToRemove = [],
				group;

			for (var j = 0; j < groups.length; j++) {
				group = groups[j];

				answered = group.items.filter(function (item) {
					return answeredInfoCards.indexOf(item.questionId) > -1;
				});

				if (answered.length === 1) {
					groupsToRemove.push(group);
				}
			}

			if (groupsToRemove.length === 0) {
				return;
			}

			for (var k = 0; k < groupsToRemove.length; k++) {
				group = groupsToRemove[k];

				var index = groups.indexOf(group);
				if (index > -1) {
					groups.splice(index, 1);
				}
			}
		}

		function onChangeAnswer($inputType, questionsForm) {
			if ($inputType === 'radio' && vm.isShownSubmitButton && vm.questionCim.isAdvanceAutomatically) {
				//It's radio button. Let's send answer automatically
				//Use timeout cause vm.model is not ready yet
				$timeout(function () {
					onSubmit(questionsForm);
				});
			}
		}

		function onSubmit(questionsForm) {
			var match = null;

			formService.makeFieldsDirty(questionsForm);
			questionsForm.$invalid = false;

			if (Object.keys(questionsForm.$error).length > 0) {
				if (questionsForm.$error.hasOwnProperty('pattern')) {
					questionsForm.$invalid = true;
					return notifier.warning(validationRegExpErrorMessage);
				}

				if (questionsForm.$error['required']) {
					questionsForm.$invalid = true;
					return notifier.warning(validationRequiredErrorMessage);
				}
			}

			if (Object.keys(questionsForm.$error).filter(function (e) { return e.indexOf('verif_') !== -1; }).length > 0) {
				questionsForm.$invalid = true;
				return notifier.warning(validationNotMatchedErrorMessage);
			}

			if (vm.isLoading || questionsForm.$invalid) {
				return;
			}

			var regexValidationFails = false;

			var signatureCheck = false;

			var savedValues = [];

			angular.forEach(vm.model.items, function (question) {
				if (question.validateUsingRegExp) {
					match = (question.answer && question.answer.value || '').match(new RegExp(question.validationRegExp));

					if (match) {
						for (var i = 1; i < match.length; i++) {
							if (match[i]) {
								match = match[i];
								break;
							}
						}
					}


					if (!match && question.isRequired) {

						questionsForm[question.questionId].$invalid = true;
						return regexValidationFails = true;
					}

				}
				if (question.answer && question.answer.contentType && (!question.answer.value || !question.answer.value.length) && question.isRequired) {
					signatureCheck = true;
				}
				if (question.answer && question.answer.value && question.answer.value.length && question.displayCountryDropDown && vm.questionCim.phoneCode) {
					savedValues.push(question.answer.value);
					question.answer.value = vm.questionCim.phoneCode + question.answer.value;
				}
			});

			if (signatureCheck) {
				vm.model.items = returnItemsValues(vm.model.items, savedValues);
				questionsForm.$invalid = true;
				return notifier.warning('Signature is empty');
			}

			if (regexValidationFails && derivedFromKiosk) {
				vm.model.items = returnItemsValues(vm.model.items, savedValues);
				questionsForm.$invalid = true;
				return showValidationPopup();
			}

			if (regexValidationFails) {
				vm.model.items = returnItemsValues(vm.model.items, savedValues);
				questionsForm.$invalid = true;
				return notifier.warning(validationRegExpErrorMessage);
			}

			vm.isLoading = true;
			questionsForm.$setPristine();

			function returnItemsValues(items, savedItems) {
				if (items && items.length && savedItems && savedItems.length) {
					return items.map(function (item, i) {
						return savedItems[i];
					});
				}
				return items;
			}

			return questionService.answerToQuestions(vm.model.items, true)
				.then(function () {
					vm.isLoading = false;

					var groups = vm.questionCim.groups;
					if (groups.length > 0) {
						groups.splice(0, 1);

						angular.forEach(vm.model.items, function (item) {
							var question = vm.questionsToAsk.filter(function (q) {
								return q.questionId === item.questionId;
							});
							if (question.length) {
								question[0].isAnswered = true;
							}
						});
					}

					if (groups.length === 0) {
						completeStep();
					} else {
						vm.questionIndex = -1;
						vm.model = setModel(getFirstGroup()) || {};
						getLayoutMatrix();
						formService.makeFieldsPristine(questionsForm);

						//this solution is needed only for multiSelectImage question type, because form has a strange error and wrong invalid state, did not find better solution
						var formErrors = Object.keys(questionsForm.$error);
						if (formErrors && formErrors.length === 1 && questionsForm.$error['required'] === false) {
							questionsForm.$invalid = false;
							questionsForm.$error = {};
						}
					}
				})
				.catch(function (response) {
					var errorMessage = errorService.parseErrorsToList(response).join('\n');

					vm.isLoading = false;

					if (derivedFromKiosk && response.status === 400) {
						return showErrorAndGoToKiosk(errorMessage);
					}

					return notifier.error(errorMessage);
				});
		}

		function showErrorAndGoToKiosk(errorMessage) {
			return confirmationModalService
				.confirm({
					message: errorMessage || validationRegExpErrorMessage,
					okBtnText: 'Ok',
					cancelBtnText: null
				})
				.result.then(function () {
					// Back to kiosk
					return kioskService.goToKiosk(lobbyToken);
				});
		}

		function showValidationPopup(errorMessage) {
			return confirmationModalService
				.confirm({
					message: errorMessage || validationRegExpErrorMessage,
					okBtnText: 'Ok',
					cancelBtnText: null
				});
		}

		function setModel(model) {
			vm.model = model;
			onChangeModel();
			return vm.model;
		}

		function onChangeModel() {
			checkTimerToShowSubmitButton();
		}

		function completeStep() {
			vm.isLoading = true;

			questionService.complete()
				.finally(function () {
					vm.isLoading = false;
				});
		}

		function checkTimerToShowSubmitButton() {
			var submissionDelay = vm.model.submissionDelay;
			if (!submissionDelay && vm.model.items.length === 1) {
				submissionDelay = vm.model.items[0].submissionDelaySec;
			}

			$timeout.cancel(showSubmitBtnTimer);

			if (submissionDelay > 0) {
				vm.isShownSubmitButton = false;
				showSubmitBtnTimer = $timeout(function () {
					vm.isShownSubmitButton = true;
				}, submissionDelay * 1000);
			} else {
				vm.isShownSubmitButton = true;
			}
		}

		function getLayoutMatrix() {
			if (vm.model.layout && vm.model.layout === 'horizontal') {
				createHorizontalLayout();
			} else {
				createVerticalLayout();
			}
		}

		function createHorizontalLayout() {
			vm.model.layoutMatrix = [];
			angular.forEach(vm.model.items, function (theQuestion) {
				var columns = [];
				columns.push({
					classSuffix: '12',
					question: theQuestion
				});
				vm.model.layoutMatrix.push(columns);
			});
		}

		function createVerticalLayout() {
			var classSuffix = '12';
			switch (vm.model.items.length) {
				case 1:
					break;
				case 2:
					classSuffix = '6';
					break;
				case 3:
					classSuffix = '4';
					break;
				default:
					classSuffix = '3';
			}

			vm.model.layoutMatrix = [];
			var columns = [];
			var n = 4;
			var i = 0;
			angular.forEach(vm.model.items, function (theQuestion) {
				if (i < n) {
					columns.push({
						classSuffix: classSuffix,
						question: theQuestion
					});
					i++;
				} else {
					i = 0;
					vm.model.layoutMatrix.push(columns);
					columns = [];
				}
			});
			if (i < n && i !== 0) {
				vm.model.layoutMatrix.push(columns);
			}
		}

		function getFirstGroup() {
			if (vm.questionCim.groups && vm.questionCim.groups.length > 0) {
				return vm.questionCim.groups[0];
			}
			return {};
		}
	}

	QuestionCimCtrl.$inject = [
		'$scope',
		'$element',
		'$window',
		'$timeout',
		'questionService',
		'formService',
		'notifier',
		'$filter',
		'utilityService',
		'confirmationModalService',
		'kioskService',
		'errorService',
		'viewAnimationService'
	];

	function questionCim() {
		return {
			restrict: 'AE',
			controller: QuestionCimCtrl,
			controllerAs: 'questions',
			bindToController: true,
			templateUrl: 'app/question/components/questionCim/question-cim.tmpl.html'
		};
	}

	angular.module('question').directive('questionCim', questionCim);

})(window.angular, window.jQuery);
