(function (angular) {

	function apiPickemSessionResource(apiResource) {


		Object.keys(apiResource).filter(function (key) {
			return (typeof apiResource[key] === 'function');
		}).forEach(function (key) {
			resource[key] = function (url) {
				var args = [].slice.call(arguments, 1);
				url = getURL(getBaseUrl(), url);
				args.unshift(url);
				return apiResource[key].apply(apiResource, args);
			};
		});

		function getBaseUrl() {
			return 'api/pickem/';
		}

		function getURL(url1, url2) {
			if (!/\/$/.test(url1) && !/^\//.test(url2)) {
				url2 = '/' + url2;
			}
			return (url1 + url2).replace(/(.)(\/+)/g, function (str, p1) {
				if (p1 === ':') return str;
				return p1 + '\/';
			});
		}

		function resource(config) {
			config.url = getURL(getBaseUrl(), config.url);
			return apiResource.apply(apiResource, arguments);
		}

		return resource;
	}

	apiPickemSessionResource.$inject = [
		'apiResource'
	];

	angular.module('session').factory('apiPickemSessionResource', apiPickemSessionResource);

})(window.angular);