(function (angular) {
  'use strict';

  function AnswerCtrl() {
    var vm = this;

    vm.isTouchpoint = localStorage.getItem('isTouchpoint') === 'true';
    vm.touchPointGrid = touchPointGrid();
    vm.init = function (ngModel, answers) {
      if (!ngModel || !answers) return;

      vm.getLabel = function (choice) {
        return answers.getLabel(choice);
      };

      vm.onChange = function (choice) {
        if (vm.model) {
          if (vm.model.includes(choice)) {
            return;
          } else {
            vm.model.push(choice);
          }
        } else {
          vm.model = [choice];
        }

        ngModel.$setViewValue(vm.model);
        vm.onChangeModel({ $inputType: 'checkbox' });
      };

      ngModel.$render = function () {
        // Specify how UI should be updated
        vm.model = ngModel.$viewValue;
      };
    };

    function touchPointGrid() {
      var itemsPerColumn = 4;

      var firstColumn = vm.choices.slice(0, itemsPerColumn);
      var secondColumn = vm.choices.slice(itemsPerColumn);

      return [firstColumn, secondColumn];
    }
  }

  function answer() {
    return {
      restrict: 'E',
      templateUrl:
        'app/answer/components/answer/answerItems/answer-multi-select.tmpl.html',
      require: ['answerMultiSelect', 'ngModel', '^answers'],
      controller: AnswerCtrl,
      controllerAs: 'answer',
      bindToController: true,
      scope: {
        choices: '=answerChoices',
        onChangeModel: '&answerOnChangeModel',
        isRequired: '=answerRequired',
        form: '=answerFormInstance',
        name: '=answerName',
        answerBackgroundColor: '=',
        answerDisabled: '='
      },
      link: function (scope, element, attr, ctrls) {
        ctrls[0].init(ctrls[1], ctrls[2]);
      }
    };
  }

  angular.module('answer').directive('answerMultiSelect', answer);
})(window.angular);
