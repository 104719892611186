(function (angular) {
	'use strict';

    function errorInterceptorService($q, notifier, errorService, $injector) {
		var service = {};

		service.responseError = responseError;

		return service;

		function responseError(error) {
			error = errorService.parseAndExtendHttpError(error);

			if (error
				&& !(error.config && error.config.ignoreDefaultErrorNotifier)
				&& error.status != 401) {

				var errorMessage = errorService.parseErrorsToList(error.data).join('\n');
                
                if (errorMessage.length) {
                    if (error.errorType && error.errorType === 'NotValid') {
                        var infoService = $injector.get('infoService');
                        infoService.catchErrorAndShowAlert(error);
                    } else {
                        notifier.error(errorMessage);
                    }
				}
			}

			return $q.reject(error);
		}
	}

	errorInterceptorService.$inject = [
		'$q',
		'notifier',
        'errorService',
        '$injector'
	];

	angular.module('common').factory('errorInterceptorService', errorInterceptorService);

})(window.angular);