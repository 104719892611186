(function (angular, $) {
  function ImageCarouselDashboardBoxCtrl($sce) {
    var vm = this;
    vm.slideIndex = 0;
    vm.imageCarouselUISettings = vm.model.imageCarouselUISettings;
    vm.images = vm.model.imageCarouselItems.map(function(image) {
      image.caption = $sce.trustAsHtml(image.caption);
  
      return image;
    });
    vm.setInitialValues = setInitialValues();
    vm.carouselInterval = getTimeout();
    vm.pause = getPauseValue();
    vm.navigateToHyperLink = navigateToHyperLink;
    vm.logLine;
    vm.isImagesInit = false;
    vm.isCaptionsAllowed = !!vm.images.find(function (image) {
      return image.caption;
    });

    function navigateToHyperLink(image) {
      if(!image.imageHyperlink) {
        return;
      }
      window.open(image.imageHyperlink, '_blank');
    }

    function setInitialValues() {
      if (!vm.imageCarouselUISettings.carouselFeatures) {
        vm.imageCarouselUISettings.carouselFeatures = {
          isAutomatic: false
        };
      }
    }

    $('.carousel-control').click(function (event) {
      event.preventDefault();
    });

    setTimeout(function () {
      if (!vm.imageCarouselUISettings.carouselFeatures.showArrows) {
        $('#' + vm.model.dashboardImageCarouselId + ' a').remove();
      } else {
        $('#' + vm.model.dashboardImageCarouselId + ' a').attr(
          'href',
          '#' + vm.model.dashboardImageCarouselId
        );
      }

      if (vm.imageCarouselUISettings.carouselFeatures.showButtons) {
        $('#' + vm.model.dashboardImageCarouselId)
          .carousel()
          .bind('slid.bs.carousel', function (e) {
            $('#' + vm.model.dashboardImageCarouselId)
              .find('#' + e.from)
              .css(
                'background-color',
                vm.imageCarouselUISettings.carouselFeatures.buttonCollor ||
                  'white'
              );
            $('#' + vm.model.dashboardImageCarouselId)
              .find('#' + e.to)
              .css(
                'background-color',
                vm.imageCarouselUISettings.carouselFeatures
                  .buttonHighlightColor || 'white'
              );
          });
        $(
          '#' +
            vm.model.dashboardImageCarouselId +
            ' ' +
            '.carousel-indicators li.active'
        ).css(
          'background-color',
          vm.imageCarouselUISettings.carouselFeatures.buttonHighlightColor ||
            'white'
        );
      } else {
        $('#' + vm.model.dashboardImageCarouselId).carousel();
      }

      if ($('#' + vm.model.dashboardImageCarouselId).length) {
        addSwiperSlide(vm.model.dashboardImageCarouselId);
      }
      
      
      $(window).on('resize orientationchange', function () {
        window.maxHeightImage = 0,
        window.imageHeights = [];
        
        heightCalculate(vm.model.dashboardImageCarouselId);
      });

      var gettingCaptionHeight = setInterval(function() {
        if (normalizeHeight(vm.model.dashboardImageCarouselId)) {
          clearInterval(gettingCaptionHeight);
        }
      }, 500);
    }, 0);

    function addSwiperSlide(id) {
      var touchstartX = 0;
      var touchendX = 0;
      var captionSwipe;

      function checkDirection() {
        if (vm.imageCarouselUISettings.carouselFeatures.blockCaptionSwipe && captionSwipe) return;

        if (touchendX < touchstartX) {
          $('#' + id).carousel('prev');
        }
        if (touchendX > touchstartX) {
          $('#' + id).carousel('next');
        }
      }
  
      document.getElementById(id).addEventListener('touchstart', function(e) {
        
        var path = e.path || (e.composedPath && e.composedPath());

        captionSwipe = path.some(function (x) {
          var elementClassList = x && x.classList ? Array.from(x.classList) : [];

          return elementClassList.includes('caption-block');
        });

        touchstartX = e.changedTouches[0].screenX;
      });
  
      document.getElementById(id).addEventListener('touchend', function(e) {
        touchendX = e.changedTouches[0].screenX;
        checkDirection();
      });
    }

    function getTimeout() {
      var res = vm.imageCarouselUISettings.carouselFeatures.isAutomatic
        ? vm.imageCarouselUISettings.carouselFeatures.timeBetweenSlidesSeconds *
          1000
        : false;
      return res;
    }

    function getPauseValue() {
      return vm.imageCarouselUISettings.carouselFeatures.isAutomatic
        ? 'hover'
        : true;
    }


  }

  ImageCarouselDashboardBoxCtrl.$inject = [
    '$sce'
  ];

  function imageCarouselDashboardBox() {
    return {
      controller: ImageCarouselDashboardBoxCtrl,
      controllerAs: 'box',
      bindToController: true,
      scope: {
        model: '='
      },
      templateUrl:
        'app/dashboards/components/image-carousel/image-carousel-dashboard-box.tmpl.html'
    };
  }

  function normalizeHeight(id) {
    window.imageHeights = [],
    window.maxHeightImage;
    
    return heightCalculate(id);
  }

  function heightCalculate(id) {
    var images = $('#' + id + ' .image');

    images.each(function() { 
        $(this).css('height', 'auto');
        window.imageHeights.push($(this).outerHeight());
    });
    window.maxHeightImage = Math.max.apply(null, window.imageHeights);

    images.each(function() {
      $(this).css('height', window.maxHeightImage + 'px');
    });

    $('#' + id + ' .image').each(function() {
        $(this).css('height', window.maxHeightImage + 'px');
    });

    $('#' + id + ' .carousel-control').each(function() {
        $(this).css('height', window.maxHeightImage + 'px');
    });

    $('#' + id + ' .carousel-indicators').css('top', (window.maxHeightImage + 15) + 'px');

    return (typeof(window.maxHeightImage) === Number && window.maxHeightImage > 0);
  }

  angular
    .module('dashboard')
    .directive('imageCarouselDashboardBox', imageCarouselDashboardBox);
})(window.angular, window.jQuery);
