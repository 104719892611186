(function (angular) {
	'use strict';

	function appModeService() {
		var self = this,
			COMMON_MODE = 'common',
			CTOKEN_MODE = 'ctoken',
			ATOKEN_MODE = 'atoken',
			PREVIEW_MODE = 'preview';

		self.mode = null;
		self.setCommonMode = getSetterModeFn(COMMON_MODE);
		self.setCTokenMode = getSetterModeFn(CTOKEN_MODE);
		self.setATokenMode = getSetterModeFn(ATOKEN_MODE);
		self.setPreviewMode = getSetterModeFn(PREVIEW_MODE);

		self.$get = [function () {
			return {
				isCommonMode: getComparisonModeFn(COMMON_MODE),
				isCTokenMode: getComparisonModeFn(CTOKEN_MODE),
				isATokenMode: getComparisonModeFn(ATOKEN_MODE),
				isPreviewMode: getComparisonModeFn(PREVIEW_MODE),
				getMode: getMode
			};
		}];

		function getSetterModeFn(modeName) {
			return function () {
				self.mode = modeName;
			};
		}

		function getComparisonModeFn(modeName) {
			return function () {
				return self.mode === modeName;
			};
		}

		function getMode() {
			return self.mode;
		}
	}

	angular.module('common').provider('appModeService', appModeService);

})(window.angular);