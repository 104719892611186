(function (angular) {
	'use strict';

	function NewsTickerCtrl($timeout) {
		var vm = this;

		vm.moving = false;
		vm.movingContinuous = false;
		vm.showPopOver = showPopOver;
		vm.hidePopOver = hidePopOver;
		vm.setStyles = setStyles;
		vm.pauseTicker = pauseTicker;
		vm.resumeTicker = resumeTicker;

		function pauseTicker() {
			vm.pause = true;
		}

		function resumeTicker() {
			vm.pause = false;
		}

		function showPopOver(event) {
			var offsetLeft = angular.element(event.currentTarget).offset().left;
			var elementWidth = angular.element(event.currentTarget).width();
			var bodyWidth = angular.element('body').width();
			if (offsetLeft < 0 || (bodyWidth - offsetLeft) < elementWidth) {
				angular.element('.news-ticker-arrow').css({'border-left-width': elementWidth/2});
				angular.element('.news-ticker-arrow').css({'border-right-width': elementWidth/2});
				vm.newsContent.html(angular.element(event.currentTarget).html());
				vm.newsContainer.css({'padding': '10px'});
				vm.newsContainer.css({'border': '1px solid rgba(0,0,0,.2)'});
				vm.newsContainer.css({'width': angular.element('body').width() - 120 + 'px'});
				vm.newsArrow.css({'display': 'block'});
				vm.newsContainer.position({'top': vm.ticker.position().top});
				if (vm.config.location === 'bottom') {
					vm.arrowClass= 'news-ticker-bottom-arrow';
					vm.newsContainer.css({'margin-top': -1 * vm.newsContent.height() - 20 + 'px'});
				} else {
					vm.arrowClass= 'news-ticker-top-arrow';
					vm.newsContainer.css({'margin-top': vm.ticker.height() + 20 + 'px'});
				}
				vm.newsArrow.addClass(vm.arrowClass);
				var arrowLeft = event.clientX - vm.newsContainer.offset().left;
				if(arrowLeft < 30) {
					arrowLeft = 30;
				}
				if( arrowLeft > vm.newsContainer.offset().left + vm.newsContainer.width() - 60) {
					arrowLeft = vm.newsContainer.offset().left + vm.newsContainer.width() - 60 - 15;
				}
				angular.element('<style>.news-ticker-item-full-container .news-ticker-item-full-arrow.'+ vm.arrowClass +'{left:'+ arrowLeft +'px;}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container .news-ticker-item-full-arrow.'+ vm.arrowClass +':after{left:'+ arrowLeft +'px;}</style>').appendTo('head');
			}
		}

		function hidePopOver() {
			vm.newsContent.html('');
			vm.newsContainer.css({'padding': '0'});
			vm.newsContainer.css({'border': 'none'});
			vm.newsArrow.css({'display': 'none'});
			angular.element('.news-ticker-arrow').css({'border-left-width': 0});
			angular.element('.news-ticker-arrow').css({'border-right-width': 0});
		}

		function setStyles() {
			angular.element('<style>.news-ticker-scroll .news-ticker-scroll-item{margin: 0 '+angular.element('body').width()/2+'px;}</style>').appendTo('head');
			if (vm.config.textColor) {
				angular.element('<style>.news-ticker-scroll .news-ticker-scroll-item{color:'+vm.config.textColor+';}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container{color:'+vm.config.textColor+';}</style>').appendTo('head');
			} else {
				angular.element('<style>.news-ticker-scroll .news-ticker-scroll-item{color:#212529;}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container{color:#212529;}</style>').appendTo('head');
			}
			if (vm.config.backgroundColor) {
				angular.element('<style>.news-ticker-scroll{background-color:'+vm.config.backgroundColor+';}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container{background-color:'+vm.config.backgroundColor+';}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container .news-ticker-item-full-arrow.news-ticker-top-arrow:after{border-bottom-color:'+vm.config.backgroundColor+';}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container .news-ticker-item-full-arrow.news-ticker-bottom-arrow:after{border-top-color:'+vm.config.backgroundColor+';}</style>').appendTo('head');
			} else {
				angular.element('<style>.news-ticker-scroll{background-color:rgba(255,255,255,0);}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container{background-color:rgba(255,255,255,0.15);}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container .news-ticker-item-full-arrow.news-ticker-top-arrow:after{border-bottom-color:rgba(255,255,255,0.15);}</style>').appendTo('head');
				angular.element('<style>.news-ticker-item-full-container .news-ticker-item-full-arrow.news-ticker-bottom-arrow:after{border-top-color:rgba(255,255,255,0.15);}</style>').appendTo('head');
			}
		}

		$timeout(function() {
			vm.ticker = angular.element('.news-ticker-scroll');
			vm.newsContainer = angular.element('.news-ticker-item-full-container');
			vm.newsContent = angular.element('.news-ticker-item-full-content');
			vm.newsArrow = angular.element('.news-ticker-item-full-arrow');
			setStyles();
			vm.ticker.show();
			vm.scroll = true;
			vm.duration = vm.config.speed;
			vm.pause = false;
		});
	}

	NewsTickerCtrl.$inject = [
		'$timeout',
		'$interval'
	];

	function newsTicker($timeout) {
		return {
			restrict: 'AE',
			controller: NewsTickerCtrl,
			controllerAs: 'ticker',
			bindToController: true,
			templateUrl: 'app/common/directives/oc-news-ticker.tmpl.html',
			scope: {
				config: '='
			},
			link: function (scope) {
				scope.$watch('ticker.config.speed', function (newSpeed) {
					if (!newSpeed) return;
					scope.ticker.duration = newSpeed;
					scope.ticker.scroll = false;
					$timeout(function(){
						scope.ticker.scroll = true;
					});
				});
				scope.$watch('ticker.config.textColor', function () {
					scope.ticker.setStyles();
				});
				scope.$watch('ticker.config.backgroundColor', function () {
					scope.ticker.setStyles();
				});

				scope.$watch('ticker.config.items', function (newItems, oldItems) {
					if (!newItems) return;
					var changed = false;
					if (oldItems.length !== newItems.length) {
						changed = true;
					} else {
						for (var i = 0; i < newItems.length; i++) {
							if (newItems[i].value !== oldItems[i].value) {
								changed = true;
								return;
							}
						}
					}
					if (changed) {
						scope.ticker.scroll = false;
						$timeout(function(){
							scope.ticker.scroll = true;
						});
					}
				});
			}
		};
	}

	newsTicker.$inject = [
		'$timeout'
	];

	angular.module('common').directive('newsTicker', newsTicker);

})(window.angular);