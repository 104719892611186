(function (angular) {
	'use strict';

    function LobbyAcCtrl(lobbyService, accessControlService, $state, inactivityConfirmationService, $uibModalStack) {
		var idleTimeout = lobbyService.config.idleTimeout * 1000,
			needMoreTimeDelay = lobbyService.config.needMoreTimeDelay * 1000;

		if (idleTimeout && inactivityConfirmationService.getListeners('elapsed').length) {
			inactivityConfirmationService.removeEvent('elapsed');
		}

        if (idleTimeout) {
			inactivityConfirmationService.extendOptions({
				confirmInactiveDuration: needMoreTimeDelay,
				stepInactiveDuration: idleTimeout
			});
			inactivityConfirmationService.start();
			inactivityConfirmationService.on('elapsed', function () {
                if (lobbyService.config.idleRedirectUrl && accessControlService.interactionsCount == 1) {
                    $uibModalStack.dismissAll();
                    return window.location = lobbyService.config.idleRedirectUrl;
				}
                if (lobbyService.aToken !== 'AA4fVr' || accessControlService.interactionsCount > 1) {
                    //Close all modals
                    $uibModalStack.dismissAll();
                    //Move to start of a lobby
                    return $state.go('lobby.init');
                } else {
                    $uibModalStack.dismissAll();
                    inactivityConfirmationService.start();
                }
			});
        }
	}

	LobbyAcCtrl.$inject = [
        'lobbyService',
        'accessControlService',
		'$state',
		'inactivityConfirmationService',
		'$uibModalStack'
	];

	function lobbyAc() {
		return {
			restrict: 'AE',
			controller: LobbyAcCtrl,
			controllerAs: 'lobbyAc',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyAc/lobby-ac.tmpl.html',
			scope: {
				settings: '='
			}
		};
	}

	angular.module('lobby').directive('lobbyAc', lobbyAc);

})(window.angular);