(function (angular) {

	function TwitterDashboardBoxCtrl() {
		var vm = this;
		vm.limit = 10;
        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };
	}

	TwitterDashboardBoxCtrl.$inject = [];

	function twitterDashboardBox() {
		return {
			controller: TwitterDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/twitter/twitter-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('twitterDashboardBox', twitterDashboardBox);

})(window.angular);