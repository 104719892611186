(function (angular) {
	'use strict';

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('game', {
				url: '/game',
				templateUrl: 'app/game/layout/game.html',
				resolve: {
					game: ['gameService', function (gameService) {
						return gameService.getGame({
							showErrorInSeparateState: true
						});
					}]
				}
			})

			.state('gameCollectModal', {
				resolve: {
					reward: ['gameService', function (gameService) {
						return gameService.showCollectModal();
					}]
				}
			});

		$urlRouterProvider.otherwise('/');
	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider'
	];

	angular.module('game').config(config);

})(window.angular);