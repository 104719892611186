(function (angular) {
	'use strict';

    function run($rootScope, $state, $translate, authService, sessionService, deliveryService, $q, overlayService, $filter, accessControlService, campaignModeAcService, utilityService, googleTagManagerService, facebookPixelService, gameService, infoService, behaviorEffectService, floodlightPixelService) {

		var translate = $filter('translate'),
			searchParams = utilityService.parseSearchQuery(),
			aToken = searchParams.atoken,
			loginPromise;

		$rootScope.authentication = authService.authentication;

		$translate.use(authService.authentication.locale);

		loginPromise = authService.loginByAToken(aToken);

		authService.on('auth.login', function () {
			$translate.use(authService.authentication.locale);
			googleTagManagerService.tryRun(authService.authentication.googleTagManagerKey);
            facebookPixelService.tryRun(authService.authentication.facebookPixelKey, authService.authentication.facebookPixelEvents);
            floodlightPixelService.tryRun();
		});

		campaignModeAcService.on('getAccessControlIfo', function (data) {
			if (data.locale) {
				$translate.use(data.locale);
			}
			window.multiLanguageSupport = data.multiLanguageSupport;
		});

		accessControlService.on('success', function (data) {
			campaignModeAcService.accessRequestId = data.accessRequestId;
			//authService.loginByAToken(aToken);
			loginPromise.then(function () {
				campaignModeAcService.startSession();
			});
		});

		accessControlService.on('resume', function (data) {
			var instanceId = data.instanceId;

			if (authService.authentication.isAuth) {
				return sessionService.resumeSessionById(instanceId);
			}

			authService.loginByAToken(aToken, 'campaign').then(function () {
				return sessionService.resumeSessionById(instanceId);
			});
		});

		(function () {
			var stateNum = 0,
				startedStates = [];

			$rootScope.$on('$stateChangeStart', function (event, toState) {
				stateNum++;

				//prevent login state
				if (toState.name === 'login') {
					event.preventDefault();
					return $state.go('info', {
						message: translate('The requested campaign is not available. Please check the link and try again later')
					});
				}

				//if we start from 'info' state then reset it and start from scratch
				if (stateNum === 1 && toState.name === 'info' && !campaignModeAcService.isTransitionToInfoStateProgrammed) {
					event.preventDefault();
					return $state.go('campaignModeAc');
				}

				//if we start from some step but is not logged in then reset it and start from scratch
				if (stateNum === 1 && toState.name !== 'campaignModeAc' && !campaignModeAcService.isLoggedIn()) {
					event.preventDefault();
					return $state.go('campaignModeAc');
				}

				var prevStartedState = startedStates.slice(-1)[0];

				startedStates = [prevStartedState, toState];

				if (!prevStartedState ||
					prevStartedState && prevStartedState.name !== toState.name) {

					// Bind deferred object to the new state
					var deferred = $q.defer();
					toState.__stateTransitionDeferred = deferred;

					// Show overlay
					overlayService.show(deferred.promise);
				}

				// Reject previous started state.
				if (prevStartedState && prevStartedState.__stateTransitionDeferred) {
					prevStartedState.__stateTransitionDeferred.resolve();
				}
			});

			$rootScope.$on('$stateChangeSuccess', function (event, toState) {
				if (toState.__stateTransitionDeferred) {
					//we need resolve/reject deferred object in order to hide overlay
					toState.__stateTransitionDeferred.resolve();
				}
				//close a cim effect if such exists
				behaviorEffectService.removeAll();
			});

			$rootScope.$on('$stateChangeError', function (event, toState) {
				if (toState.__stateTransitionDeferred) {
					//we need resolve/reject deferred object in order to hide overlay
					toState.__stateTransitionDeferred.reject();
				}
			});
		})();

		authService.on('authService.onStartLogin', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onStartSession', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onStartGettingCurrentStep', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onStartGettingCampaign', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onCampaignOver', function (data) {
			overlayService.show(data.promise, 'CampaignOver', data.message);
		});

		deliveryService.on('delivery.onStartGettingModal', function (deferred) {
			overlayService.show(deferred.promise);
		});

		// To implement CIM View Animation
		Array.prototype.push.apply(sessionService.STEP_STATE_NAMES, [
			'game',
			'pickem',
			'question',
			'embedded',
			'swapReward',
			'chooseReward',
			'deliveryModal',
			'delivery',
			'redeem'
		]);

		sessionService.on('session.onChangeCurrentStep', function onChangeCurrentStep(step) {
			var STEPS = sessionService.STEPS;

			var stateOptions = {
				reload: true,
				inherit: false,
				notify: true
			};

			if (step.prevStep && step.prevStep.stepType === STEPS.GAME
				&& step.stepType !== STEPS.DELIVERY
				&& step.stepType !== STEPS.GAME
				&& gameService.displayCollect) {

				return $state.go('gameCollectModal', null, {
					inherit: true,
					notify: false
				});
			}

			if (step.stepType === STEPS.GAME) {
				if ($state.current.name === 'game') {
					return $state.reload();
				}
				return $state.go('game', null, stateOptions);
			}

			if (step.stepType === STEPS.PICKEM) {
				if ($state.current.name === 'pickem') {
					return $state.reload();
				}
				return $state.go('pickem', null, stateOptions);
			}

			if (step.stepType === STEPS.QUESTION) {
				if ($state.current.name === 'question') {
					return $state.reload();
				}
				return $state.go('question', null, stateOptions);
			}

			if (step.stepType === STEPS.EMBEDDED) {
				if ($state.current.name === 'embedded') {
					return $state.reload();
				}
				return $state.go('embedded', null, stateOptions);
			}

			if (step.stepType === STEPS.SWAP_REWARD) {
				if ($state.current.name === 'swapReward') {
					return $state.reload();
				}
				return $state.go('swapReward', null, stateOptions);
			}

			if (step.stepType === STEPS.CHOOSE_REWARD) {
				if ($state.current.name === 'chooseReward') {
					return $state.reload();
				}
				return $state.go('chooseReward', null, stateOptions);
			}

			if (step.stepType === STEPS.DELIVERY) {
				/*if ($state.current.name === 'delivery') {
					return $state.reload();
				}
				return $state.go('delivery', null, stateOptions);*/
				return $state.go('deliveryModal', null, {
					notify: false
				});
			}

			if (step.stepType === STEPS.THANK_YOU) {
				if ($state.current.name === 'redeem') {
					return $state.reload();
				}
				return $state.go('redeem', null, stateOptions);
			}

			if (step.stepType === STEPS.COMPLETED) {
				return infoService.showMessageForCompletedStep();
			}

			throw 'Step for ' + step.stepType + ' was not found in CampaignAC mode';
		});

	}

	run.$inject = [
		'$rootScope',
		'$state',
		'$translate',
		'authService',
		'sessionService',
		'deliveryService',
		'$q',
		'overlayService',
		'$filter',
		'accessControlService',
		'campaignModeAcService',
		'utilityService',
		'googleTagManagerService',
		'facebookPixelService',
		'gameService',
		'infoService',
        'behaviorEffectService',
        'floodlightPixelService'
	];

	angular.module('campaignModeAc').run(run);

})(window.angular);