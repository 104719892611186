(function (angular) {
	'use strict';

	function fancyButtonDirective($timeout) {
		return {
			restrict: 'AE',
			transclude: true,
			replace: true,
			templateUrl: 'app/common/directives/fancy-button.tmpl.html',
			scope: {
				disabled: '=fancyButtonDisabled',
				loading: '=fancyButtonLoading',
				size: '@fancyButtonSize',
                style: '@fancyButtonStyle',
                title: '@fancyButtonTitle'
			},
			link: function (scope, element, attrs) {
				scope.style = scope.style || 'btn-primary';
				if (attrs.fancyButtonType && attrs.fancyButtonType.length) {
					element.attr('type', attrs.fancyButtonType);
                }

                //fix for ngTransclude orphan error
                $timeout(function () {
                    scope.__showBtnInner = true;
                });
			}
		};
	}

    angular.module('common').directive('fancyButton', ['$timeout', fancyButtonDirective]);

})(window.angular);