(function (angular) {
  'use strict';

  function previewOverlay(sessionService, $window) {
    return {
      restrict: 'AE',
      templateUrl:
        'app/session/components/previewOverlay/preview-overlay.tmpl.html',
      link: function (scope) {
        scope.model = {
          showPreviewOverlay: false,
          showPopOver: false,
          toggle: function () {
            this.showPopOver = !this.showPopOver;
          },
          hidePreview: function () {
            this.showPopOver = false;
            this.showPreviewOverlay = false;
          },
          restartPreview: function () {
            $window.location.reload();
          }
        };

        sessionService.on('session.onSetPreviewMode', updateVisibility);

        updateVisibility();

        function updateVisibility() {
          var isPreviewedMode = null;
          if (
            typeof sessionService.isPreviewedMode === 'string' &&
            sessionService.isPreviewedMode.toLowerCase() === 'true'
          ) {
            isPreviewedMode = true;
          }

          if (typeof sessionService.isPreviewedMode === 'boolean') {
            isPreviewedMode = sessionService.isPreviewedMode;
          }

          scope.model.showPreviewOverlay = isPreviewedMode;
        }
      }
    };
  }

  previewOverlay.$inject = ['sessionService', '$window'];

  angular.module('session').directive('previewOverlay', previewOverlay);
})(window.angular);
