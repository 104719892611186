(function (angular) {
	'use strict';

	function run($rootScope, sessionService, viewAnimationService, deliveryRewardModalService) {

		$rootScope.$on('$stateChangeStart', function (event, toState) {
            var currentStep = sessionService.currentStep;

			if ( sessionService.STEP_STATE_NAMES.indexOf(toState.name) === -1 ) {
				// Reset Current Step Data
				sessionService.currentStep = null;
			}

			applyViewAnimationData(currentStep);
		});

		// To Handle Delivery Modal Properly
		deliveryRewardModalService.on('delivery.onShowDeliveryModal', function () {
			var currentStep = sessionService.currentStep;

			applyViewAnimationData(currentStep);
		});

		function applyViewAnimationData(currentStep) {
			var leaveTransition = currentStep && currentStep.exitTransition,
				enterTransition = currentStep && currentStep.enterTransition,
				leaveDuration = currentStep && currentStep.exitTransitionDurationTime,
				enterDuration = currentStep && currentStep.enterTransitionDurationTime,
				cssOverride = currentStep && currentStep.cssOverride,
                enterVideoUrl = currentStep && currentStep.isEnableEnterVideo && currentStep.enterVideoUrl,
                exitVideoUrl = currentStep && currentStep.isEnableExitVideo && currentStep.exitVideoUrl;

			viewAnimationService.setLeaveAnimation(leaveTransition, leaveDuration);

			viewAnimationService.setEnterAnimation(enterTransition, enterDuration);

			viewAnimationService.setEnterVideo(enterVideoUrl);

            viewAnimationService.setLeaveVideo(exitVideoUrl);

			viewAnimationService.setCssOverride(cssOverride);
		}

	}

	run.$inject = [
		'$rootScope',
		'sessionService',
		'viewAnimationService',
		'deliveryRewardModalService'
	];

	angular.module('session').run(run);

})(window.angular);
