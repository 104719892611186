(function (angular) {
	'use strict';

	function authInterceptorService($q, $injector, httpBufferService, storageService) {
		var authInterceptorServiceFactory = {},
			authService,
			utilityService;

		authInterceptorServiceFactory.request = request;
		authInterceptorServiceFactory.responseError = responseError;

		return authInterceptorServiceFactory;

		function request(config) {
			authService = authService || $injector.get('authService');

			config.headers = config.headers || {};

			var authData = authService.getAuthenticationData();

            if (authData) {
				config.headers.Authorization = 'Bearer ' + authData.token;
			}

			if (config.headers.removeAuthorization) {
                delete config.headers.Authorization;
                delete config.headers.removeAuthorization;
			}

			return config;
		}

		function responseError(response) {
			var deferred = $q.defer();

			// error - was it 401 or something else?
			if (authService.isUnauthorizedStatus(response.status)
				&& !authService.isLoginInProcess()
				&& !response.config.ignoreDefaultErrorAuthInterceptor) {

				utilityService = utilityService || $injector.get('utilityService');

				var searchParams = utilityService.parseSearchQuery();

				if (searchParams.ctoken && !authService.isRefreshTokenPresented()) {
					storageService.clearAll();

					authService.loginByCToken(searchParams.ctoken);

					return $q.reject(response);
				}

				if (searchParams.atoken && !authService.isRefreshTokenPresented()) {
					storageService.clearAll();

					authService.loginByAToken(searchParams.atoken);

					return $q.reject(response);
				}

				httpBufferService.append(response.config, deferred);

				if (!authService.isRequestForTokenRefreshWasSent()) {
                    var authData = authService.getAuthenticationData();
                    // var state = $injector.get('$state').current.name;
                    // if (state.startsWith('lobby') || state.startsWith('redemption')) {
                    //     authData ? authService.logoutUser(authData) : authService.logOut();
                    // } else {
                        authService.refreshLogin(authData);
                    // }
				}

				return deferred.promise;
			}

			return $q.reject(response); // not a recoverable error
		}
	}

	authInterceptorService.$inject = [
		'$q',
		'$injector',
		'httpBufferService',
		'storageService'
	];

	angular.module('auth').factory('authInterceptorService', authInterceptorService);

})(window.angular);