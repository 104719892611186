(function (angular) {

	function DashboardCtrl($scope, $stateParams, dashboardService, $timeout, hintService) {
        var vm = this,
            timer;

        // Mock config for development.
        // vm.feed = {
        //     location: 'top',
        //     animationType: 'partialScroll',
        //     speed: 20000,
        //     backgroundColor: '#EBC159',
        //     textColor: '#000000',
        //     items: [
        //         {value: 'Sling TV founding CEO Roger Lynch is Pandora’s new president and CEO'},
        //         {value: 'Security researcher Marcus Hutchins pleads not guilty, returns to Twitter'},
        //         {value: 'Snapchat “Crowd Surf” stiches together everyone’s concert videos'},
        //         {value: 'Target acquires transportation company Grand Junction to expand same-day delivery services'}
        //     ]
        // };
        // End Mock.

        
        vm.logOut = function () {
            vm.isLoggingOut = true;
            dashboardService.logOut().finally(function () {
                vm.isLoggingOut = false;
            });
        };

		initModel(dashboardService.currentDashboard);

        if (dashboardService.currentDashboard.refreshPeriod > 0) {
            startTimer();
        }

        if (dashboardService.dashboardAuthorizationType !== 'None') {
            vm.isSecure = true;
        }

        hintService.introService.onAfterChange(function (target) {
            if (target) {
                var $ = window.jQuery;
                hintService.introService.refresh();

                var fixRefLayer = function (display) {
                    var t = $(target).offset();
                    var refLayer = $('.introjs-tooltipReferenceLayer');
                    refLayer.css('display', display ? 'block' : 'none');
                    refLayer.css('top', t.top - 5);
                    refLayer.css('left', t.left - 5);

                    var h = window.innerHeight
                        || document.documentElement.clientHeight
                        || document.body.clientHeight;
                    refLayer.css('max-height', h);
                };

                fixRefLayer();

                $('.dashboard-page').animate({
                    scrollTop: $('.dashboard-page').scrollTop() + $(target).offset().top - 150
                }, {
                    duration: 500,
                    complete: function () {
                        hintService.introService.refresh();
                        fixRefLayer(true);
                    }
                });
            }
        });

        $scope.$on('$destroy', stopTimer);

        function initModel(currentDashboard) {
            vm.model = currentDashboard;
            if (!currentDashboard.uiSettings) {
                return;
            }
			vm.model.uiSettings.loop = 0;
			vm.model.uiSettings.disablekb =  1;
			vm.model.uiSettings.fs =  0;
			vm.model.uiSettings.iv_load_policy = 3;
			vm.model.uiSettings.showinfo = 0;
			vm.model.uiSettings.background = 1;
			vm.dashboardTemplate = currentDashboard.dashboardTemplate;
            vm.dashboardBoxes = currentDashboard.dashboardBoxes;
            vm.model.lastUpdated = +new Date();
            var iteration = 0;
            
            vm.model.boxes = Object.values(vm.model.boxes).sort(function (a, b) {
                var hasRow = a.row != undefined && b.row != undefined;
                var condition = a.row - b.row;
                
                if(!hasRow || hasRow && condition === 0){
                    return a.col - b.col;
                }

                return condition;
            });

            var dashboardBoxesIds = vm.model.boxes.map(function(box) {
                return box.dashboardBoxId;
            });

            vm.model.dashboardBoxes = Object.values(vm.dashboardBoxes).sort(function(a, b) {
                return dashboardBoxesIds.indexOf(a.dashboardBoxId) - dashboardBoxesIds.indexOf(b.dashboardBoxId);
            });

            var isAnyHints = false;
            for (var box in vm.model.dashboardBoxes) {
                var dashBox = vm.model.dashboardBoxes[box];
                dashBox.lastUpdated = vm.model.lastUpdated;
                if (angular.isString(dashBox.hint) && dashBox.hint.length > 0) {
                    var hintObj = {
                        element: '#box' + box,
                        intro: dashBox.hint
                    };
                    hintService.addHints(hintObj);
                    if (!isAnyHints) {
                        isAnyHints = true;
                    }
                }
                iteration++;
            }

            isAnyHints && hintService.runTutorial('intro-' + vm.model.dashboardId);
        }

        function startTimer() {
            if (vm.model && vm.model.refreshPeriod) {
                timer = $timeout(getData, vm.model.refreshPeriod * 1000);
            }
        }

        function stopTimer() {
            $timeout.cancel(timer);
        }

        function getData() {
            dashboardService
                .getDashboard($stateParams.dToken)
                .then(function () {
                    initModel(dashboardService.currentDashboard);
                    startTimer();
                }, function () {
                    startTimer();
                });
        }
	}

	DashboardCtrl.$inject = [
		'$scope',
		'$stateParams',
		'dashboardService',
		'$timeout',
        'hintService'
	];

	function dashboard() {
		return {
			controller: DashboardCtrl,
			controllerAs: 'dashboard',
			bindToController: true,
			scope: {},
			templateUrl: 'app/dashboards/components/dashboard/dashboard.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('dashboard', dashboard);

})(window.angular);