(function (angular) {
	'use strict';

	function onScreenKeyboardDirective($timeout) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				function initKeyboard() {
					$timeout(function() {
						if(window.jsKeyboard) {
							var container = angular.element.find(attrs.onScreenKeyboard);
                            if (!attrs.onScreenKeyboardAllInputs) {
                                container = angular.element.find('.post-reward-block');
                                if (!container || container.length === 0) {
                                    container = angular.element.find('.question-row');
                                    var mainContainer = angular.element(angular.element.find('#main-container'));
                                    mainContainer.addClass('with-keyboard');
								}
							}
							if(container && container.length > 0) {
								var onScreenKeyboard = window.jsKeyboard;
								var firstInput = angular.element.find(attrs.onScreenKeyboard + ' input:text, ' + attrs.onScreenKeyboard + ' textarea');
								if(firstInput && firstInput.length > 0) {
									var keyboard = angular.element.find('#virtualKeyboard');
									if(!keyboard || keyboard.length === 0) {
										var div = angular.element('<div></div>');
										div.attr('id', 'virtualKeyboard');
										angular.element(container[container.length - 1]).after(div[0]);
										if (attrs.onScreenKeyboardAllInputs) {
											angular.element(container[0]).after(div[0]);
											//onScreenKeyboard.init('virtualKeyboard', null, attrs.onScreenKeyboard);
										} else {
											angular.element(container[container.length - 1]).after(div[0]);
											//onScreenKeyboard.init('virtualKeyboard');
										}
                                        $timeout(function() {
                                            var div = angular.element('<div></div>');
                                            div.attr('id', 'virtualKeyboardPlace');
                                            var keyboardInstance = angular.element.find('#keyboard');
                                            keyboardInstance = keyboardInstance && keyboardInstance.length && angular.element(keyboardInstance);
                                            if (keyboardInstance && !attrs.onScreenKeyboardAllInputs) {
                                                div.css({height: keyboardInstance.height() + 45 + 'px', width: keyboardInstance.width() + 15 + 'px'});
                                                keyboardInstance.after(div);
                                                keyboardInstance.css({position: 'fixed'});
                                            }
                                        });
									} else {
                                        angular.element(keyboard).css({position: 'fixed'});
									}
                                    if (attrs.onScreenKeyboardAllInputs) {
                                        onScreenKeyboard.init('virtualKeyboard', null, attrs.onScreenKeyboard);
                                    } else {
                                        onScreenKeyboard.init('virtualKeyboard');
                                    }
                                    onScreenKeyboard.currentElement = angular.element(firstInput[0]);
                                    onScreenKeyboard.currentElementCursorPosition = 0;
								}
							}
						}
					}, 100);
				}

				function removeOnScreenKeyboard() {
					$timeout(function() {
						var keyboard = angular.element.find('#virtualKeyboard');
						if(keyboard && keyboard[0]) {
							angular.element(keyboard[0]).remove();
						}
					});
				}

				scope.$watch('question.model', function(question) {

					var useOnscreenKeyboard = attrs.onScreenKeyboard && question;
					useOnscreenKeyboard = useOnscreenKeyboard || (Array.isArray(question) && question.some(function(quest) { return quest.uiCustomization.useOnScreenKeyboard === 1; }));

					if (useOnscreenKeyboard) {
						initKeyboard();
					} else {
						removeOnScreenKeyboard();
					}
				});
			}
		};
	}

	angular.module('common').directive('onScreenKeyboard', ['$timeout', onScreenKeyboardDirective]);

})(window.angular);