(function (angular) {
	'use strict';

	function RewardCtrl() {
		var vm = this;

		vm.isTermsShown = false;
		vm.toggleTerms = toggleTerms;

		function toggleTerms() {
			vm.isTermsShown = !vm.isTermsShown;
		}
	}

	RewardCtrl.$inject = [];

	function reward() {
		return {
			restrict: 'AE',
			controller: RewardCtrl,
			controllerAs: 'reward',
			bindToController: true,
			templateUrl: 'app/common/directives/reward.tmpl.html',
			scope: {
				model: '=',
				hideRewardName: '=',
				showExpirationDate: '=',
				showExpirationDateCountdown: '='
			}
		};
	}

	function mobileReward() {
		return {
			restrict: 'AE',
			controller: RewardCtrl,
			controllerAs: 'reward',
			bindToController: true,
			templateUrl: 'app/common/directives/mobile-reward.tmpl.html',
			scope: {
				model: '=',
				hideRewardName: '=',
				showExpirationDate: '=',
				showExpirationDateCountdown: '='
			}
		};
	}

	angular.module('common').directive('reward', reward)
		.directive('mobileReward', mobileReward);

})(window.angular);