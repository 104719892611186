(function (angular) {
	'use strict';

	var appDateFilter = function ($filter) {
		var dateFilter = $filter('date');
		return function (date, dateFormat) {
			dateFormat = dateFormat || 'MMMM dd, yyyy';
			return dateFilter(date, dateFormat);
		};
	};

	appDateFilter.$inject = [
		'$filter'
	];

	angular.module('common').filter('appDate', appDateFilter);

})(window.angular);