(function (angular) {
    'use strict';

    function RewardDiscountCtrl() {
        var vm = this,
            discount = vm.rewardData.discount;

        vm.composeParagraph = function () {
            var value = discount.discountType === 'Percent' ? discount.value + '%' : '$' + discount.value;
            var paragraph = 'Discount of ' + value + ' will be applied to qualifying purchases';

            if (discount.discountType === 'Percent' && discount.minThreshold && discount.minThreshold !== '0.00' && discount.maxThreshold && discount.maxThreshold !== '0.00') {
                paragraph = paragraph + ' of $' + discount.minThreshold + ' or more, with maximum savings of $' + discount.maxThreshold;
            }
            else if (discount.discountType === 'Percent' && discount.maxThreshold && discount.maxThreshold !== '0.00') {
                paragraph = paragraph + ', with maximum savings of $' + discount.maxThreshold;
            }
            else if (discount.minThreshold && discount.minThreshold !== '0.00') {
                paragraph = paragraph + ' of $' + discount.minThreshold + ' or more';
            }

            paragraph = paragraph + '.';

            return paragraph;
        };
    }

    RewardDiscountCtrl.$inject = [];

    function rewardDiscount() {
        return {
            restrict: 'AE',
            controller: RewardDiscountCtrl,
            controllerAs: 'ctrl',
            bindToController: true,
            templateUrl: 'app/common/directives/reward-discount.tmpl.html',
            scope: {
                rewardData: '='
            }
        };
    }

    angular.module('common').directive('rewardDiscount', rewardDiscount);

})(window.angular);