(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

        vm.aliases = [];
        vm.aliasChoices = [];
        //vm.choices = {"questionText":"Thanks for visiting Rhythm City! Please rate each of the following: ","columns":[{"text":"5","isTextVisible":true,"image":""},{"text":"4","isTextVisible":true,"image":""},{"text":"3","isTextVisible":true,"image":""},{"text":"2","isTextVisible":true,"image":""},{"text":"N/A","isTextVisible":true,"image":""}],"groups":[{"groupName":"Draft Day Sports Lounge","groupNameIsVisible":false,"groupImage":"https://prodocdata.blob.core.windows.net/images-8c95b39d5def/38e276ea3141407288d779720f2cc8a8-draftday-logo-02.png","rows":[{"text":"Service","isTextVisible":true,"image":""},{"text":"Food","isTextVisible":true,"image":""},{"text":"Menu","isTextVisible":true,"image":""},{"text":"Overall ","isTextVisible":true,"image":""}]},{"groupName":"Robert's Buffet","groupNameIsVisible":false,"groupImage":"https://prodocdata.blob.core.windows.net/images-8c95b39d5def/7c977061bf9e44d8be86d80e9dae8e7c-roberts-logo-02.png","rows":[{"text":"Service","isTextVisible":true,"image":""},{"text":"Food","isTextVisible":true,"image":""},{"text":"Menu","isTextVisible":true,"image":""},{"text":"Overall","isTextVisible":true,"image":""}]}]};

        function extractAliasChoices(choices) {
            var rows = [];

            for (var i = 0; i < choices.length; i++) {
                var choice = choices[i];

                var ind = choice.value.split('|');

                var row = parseInt(ind[0]) - 1;
                var col = parseInt(ind[1]) - 1;

                if (!rows[row]) {
                    rows[row] = angular.copy(choice);
                    rows[row].cols = [choice];
                } else {
                    rows[row].cols[col] = choice;
                }
            }

            return rows;
        }

        vm.init = function (ngModel, answers) {
            if (!ngModel || !answers) return;

            vm.aliasChoices = extractAliasChoices(vm.choices);

            vm.getLabel = function (choice) {
                return choice.label;
            };

            vm.onChange = function () {
                var model = [];
                for (var i = 0; i < vm.aliasChoices.length; i++) {
                    var m = vm.aliasChoices[i].model;
                    m && model.push(m);
                }
                vm.model = model;

                ngModel.$setViewValue(vm.model);
                //vm.onChangeModel({$inputType: 'radio'});
            };

            ngModel.$render = function () {
                // Specify how UI should be updated
                vm.model = ngModel.$viewValue;
            };
        };
	}

    AnswerCtrl.$inject = [];

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-grid.tmpl.html',
			require: ['answerGrid', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
				answerBackgroundColor: '=',
				answerDisabled: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);

				scope.$watch('answer.choices', function (newObj, oldObj) {
					if (newObj !== oldObj) {
						ctrls[0].init(ctrls[1], ctrls[2]);
					}
				});
			}
		};
	}

	angular.module('answer').directive('answerGrid', answer);

})(window.angular);