(function (angular) {
	'use strict';

	function DeliveryCtrl(deliveryService,authService) {
		var vm = this;

		vm.reward = deliveryService.reward;
		vm.displayRewardExpirationDate = deliveryService.displayRewardExpirationDate;
		vm.displayRewardExpirationDateCountdown = deliveryService.displayRewardExpirationDateCountdown;
		vm.isShownTermsLink = !deliveryService.supressChanceOfWinning;
		vm.showTermsAndConditions = !deliveryService.suppressTermsAndConditions;
		vm.clientLogoUrl = authService.authentication.clientLogoUrl;
	}

	DeliveryCtrl.$inject = [
		'deliveryService',
		'authService'
	];

	function delivery() {
		return {
			restrict: 'AE',
			controller: DeliveryCtrl,
			controllerAs: 'delivery',
			bindToController: true,
			templateUrl: 'app/delivery/components/delivery/delivery.tmpl.html',
			scope: {}
		};
	}

	angular.module('delivery').directive('delivery', delivery);

})(window.angular);