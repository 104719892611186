(function (angular) {
	'use strict';

	function ocStyleBlock() {
		return {
			restrict: 'AE',
			require: 'ngModel',
			template: '<style type="text/css"></style>',
			link: function(scope, $element, attrs, ngModel) {
				var $style = $element.find('style');

				ngModel.$render = function() {
					var styleStr = (ngModel.$viewValue || '').toString().trim();
					$style.html(styleStr);
				};
			}
		};
	}

	angular.module('common').directive('ocStyleBlock', ocStyleBlock);

})(window.angular);