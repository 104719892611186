(function (angular) {
	'use strict';

	function droppable() {
		return {
			restrict: 'A',
			scope: {
				droppableHoverClass: '@',
				droppableHandler: '='
			},
			link: function(scope, element) {
				element.droppable({
					hoverClass: scope.droppableHoverClass,
					drop: function(event, ui) {

						var dropped = ui.draggable;
						var droppedOn = this;

						if(angular.element(droppedOn).children().length > 0) {
							angular.element(droppedOn).children().detach().prependTo(angular.element(scope.$root.lastPlace));
						}

						angular.element(dropped).detach().css({
							top: 0,
							left: 0
						}).prependTo(angular.element(droppedOn));

						scope.droppableHandler();
					}
				});
			}
		};
	}

	angular.module('common').directive('droppable', droppable);

})(window.angular);