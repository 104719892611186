(function (angular) {

    'use strict';

    function printerService($timeout, $http, $log) {

        var service = {};
        service.cutUrl = 'http://localhost:31337/api/ReceiptPrinter/cut';
        service.sendCut = sendCut;

        return service;

        function sendCut() {
            $log.info('Cut sent');
            return $http.get(service.cutUrl, { ignoreDefaultErrorAuthInterceptor: true });
        }
    }

    printerService.$inject = [
        '$timeout',
        '$http',
        '$log'
    ];

    angular.module('common').factory('printerService', printerService);

})(window.angular);