(function (angular, $) {
	'use strict';

	function countryCodeDropdown(addressService) {
		return {
			restrict: 'AE',
			replace: true,
			require: 'ngModel',
			templateUrl: 'app/delivery/components/countryCodeDropdown/country-code-dropdown.tmpl.html',
			scope: {
				disabled: '='
			},
			link: function (scope, $element, attr, ngModel) {
				var $selectedValueEl = $element.find('.selected-value'),
					$dropdownList = $element.find('.dropdown-menu'),
					countryCodes = [],
					isLoadingCodes = false;

				if (attr['className']) {
					$element.addClass(attr['className']);
				}

				$element.addClass(attr['className'] || 'btn-group');

				scope.toggled = toggled;

				$element.on('click', '.dropdown-menu li', onClickCodeItem);
				//custom event for dropdown directive
				$element.on('onOpenDropdown', function () {
					scrollToCountryCode(ngModel.$viewValue.id);
				});

				ngModel.$render = function () {
					if (ngModel.$viewValue) {
						$selectedValueEl.html(ngModel.$viewValue.phoneCode);
					}
				};

				function toggled(isOpened) {
					if (isOpened && !countryCodes.length && !isLoadingCodes) {
						return loadCountryCodes().then(function () {
							scrollToCountryCode(ngModel.$viewValue.id);
						});
					}
				}

				function scrollToCountryCode(countryId) {
					var code = countryCodes.filter(function (code) {
							return code.id === countryId;
						})[0],
						index = countryCodes.indexOf(code),
						countryCodeItemEl = $dropdownList.children().eq(index)[0];

					if (~index && countryCodeItemEl) {
						countryCodeItemEl.scrollIntoView(true);
					}
				}

				function onClickCodeItem(event) {
					var index = $(this).index(),
						code = countryCodes[index];

					event && event.preventDefault();

					selectCode(code);
				}

				function selectCode(code) {
					var currentCountryCodeId = ngModel.$viewValue && ngModel.$viewValue.id;

					if (!code) {
						return false;
					}

					if (code.id !== currentCountryCodeId) {
						ngModel.$setViewValue(code);
					}

					$selectedValueEl.html(code.phoneCode);
				}

				function loadCountryCodes() {
					isLoadingCodes = true;

					return addressService.getCountryCodes().then(function (data) {
						countryCodes = data;
						renderDropdownList(countryCodes);
						return countryCodes;
					}).finally(function () {
						isLoadingCodes = false;
					});
				}

				function renderDropdownList(codes) {
					var html = (codes || []).map(function (code) {
						return '<li><a href="">' + code.nameWithPhoneCode + '</a></li>';
					});
					$dropdownList.html(html);
				}
			}
		};
	}

	countryCodeDropdown.$inject = [
		'addressService'
	];

	angular.module('delivery').directive('countryCodeDropdown', countryCodeDropdown);

})(window.angular, window.jQuery);