(function (angular) {
    'use strict';

    function SwapRewardModalCtrl($log, $uibModalInstance, swapsList, swapRewardService) {
        var vm = this;

        vm.swapsList = swapsList;
        vm.swapReward = swapReward;
        vm.close = close;
        vm.complete = complete;

        function complete(newReward) {
            return $uibModalInstance.close(newReward);
        }

        function swapReward() {
            if (vm.isLoading) {
                return;
            }

            vm.isLoading = true;

            swapRewardService.swapReward(vm.reward.id).then(function (data) {
                $log.debug(data);

                return data;
                
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function close() {
            return $uibModalInstance.dismiss();
        }
    }

    SwapRewardModalCtrl.$inject = [
        '$log',
        '$uibModalInstance',
        'swapsList',
        'swapRewardService',
        '$window'
    ];


    angular.module('redeem').controller('SwapRewardModalCtrl', SwapRewardModalCtrl);

})(window.angular);