(function (angular) {
	'use strict';

	function CountdownRewardCtrl() {
		//var vm = this;
	}

	function countdownReward() {
		return {
			restrict: 'AE',
			controller: CountdownRewardCtrl,
			controllerAs: 'countdown',
			bindToController: true,
			templateUrl: 'app/countdown/components/countdownReward/countdown-reward.tmpl.html',
			scope: {
				reward: '=currentReward',
				onElapse: '&'
			}
		};
	}

	angular.module('countdown').directive('countdownReward', countdownReward);

})(window.angular);