(function (angular) {
  'use strict';

  function config($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('redemption', {
        abstract: true,
        url: '/redemption',
        template: '<div ui-view style="height:100%"></div>'
      })
      .state('redemption.search', {
        url: '/',
        templateUrl: 'app/redemption/layout/search.html'
      })
      .state('redemption.print', {
        url: '/:rewardCode/print',
        templateUrl: 'app/redemption/layout/print.html',
        params: { firstPrint: false },
        resolve: {
          checkPinCode: [
            'redemptionService',
            '$q',
            '$state',
            '$timeout',
            function (redemptionService, $q, $state, $timeout) {
              if (redemptionService.getCurrentPinCodeForRedemption()) {
                return 'ok';
              }
              $timeout(function () {
                $state.go('lobby.start');
              });
              return $q.reject('Pin Code is needed');
            }
          ],
          model: [
            'redemptionService',
            '$stateParams',
            function (redemptionService, $stateParams) {
              return redemptionService.getRewardInfo(
                $stateParams.rewardCode,
                false
              );
            }
          ],
          isReprint: [
            'redemptionService',
            '$stateParams',
            function (redemptionService, $stateParams) {
              redemptionService.firstPrint = !!$stateParams.firstPrint;
            }
          ]
        }
      })
      .state('redemption.details', {
        url: '/:rewardCode/details',
        templateUrl: 'app/redemption/layout/details.html',
        resolve: {
          checkPinCode: [
            'redemptionService',
            '$q',
            '$state',
            '$timeout',
            function (redemptionService, $q, $state, $timeout) {
              if (redemptionService.getCurrentPinCodeForRedemption()) {
                return 'ok';
              }
              $timeout(function () {
                $state.go('lobby.start');
              });
              return $q.reject('Pin Code is needed');
            }
          ],
          model: [
            'redemptionService',
            '$stateParams',
            function (redemptionService, $stateParams) {
              // if (redemptionService.rewardInfo
              // 	&& redemptionService.rewardInfo.combinedCode.codeValue === $stateParams.rewardCode) {
              // 	return redemptionService.rewardInfo;
              // }
              return redemptionService.getRewardInfo(
                $stateParams.rewardCode,
                false
              );
            }
          ]
        }
      })
      .state('redemption.redeemedResult', {
        url: '/successResult',
        templateUrl: 'app/redemption/layout/redeemed-code-result.html'
      });

    $urlRouterProvider.otherwise('/');
  }

  config.$inject = ['$stateProvider', '$urlRouterProvider'];

  angular.module('redemption').config(config);
})(window.angular);
