(function (angular) {
	'use strict';

	function ocVideo($log) {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/oc-video.tmpl.html',
			scope: {
				src:  '=',
				attributes: '=',
				play: '=',
				onError: '&',
				onEnded: '&',
				onPlay: '&'
			},
			link: function(scope, $element) {
				var $video = $element.find('video');

				//I don't know why but ngSrc does not work with video tag

				scope.$watch('attributes', onChangeAttributes);

				scope.$watch('play', onChangePlay);

				$video.on('error', onError);
				$video.on('ended', onEnded);
				$video.on('playing', onPlay);

				function onChangeAttributes(attributes) {
					var defaultAttributes = { src: scope.src, preload: false, autoplay: false };
					if (attributes && attributes.length > 0) {
						attributes = angular.extend(defaultAttributes, JSON.parse(attributes));
					}
					else {
						attributes = defaultAttributes;
					}

					if ( Object.prototype.toString.call(attributes) === '[object Object]' ) {
						for (var attrName in attributes) {
							if (attributes.hasOwnProperty(attrName)) {
								$video.attr(attrName, attributes[attrName]);
								scope.error = null;
							}
						}
					}
				}

				function onError(error) {
					$log.warn('Video error: ', error);
					scope.error = error;
					scope.onError();
					scope.$apply();
				}

				function onEnded(event) {
					$log.log('Video ended: ', event);
					scope.onEnded();
				}

				function onPlay(event) {
					$log.log('Video onPlay: ', event);
					scope.onPlay();
				}

				function onChangePlay(play) {
					if (play) {
						$video[0].play();
					} else {
						$video[0].pause();
					}
				}
			}
		};
	}

	ocVideo.$inject = [
		'$log'
	];

	angular.module('common').directive('ocVideo', ocVideo);

})(window.angular);