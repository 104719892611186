(function (angular) {
	'use strict';

	function LogoutCtrl(authService) {
		this.logout = function () {
			authService.logout();
		};
	}

	LogoutCtrl.$inject = [
		'authService'
	];

	function logout() {
		return {
			restrict: 'AE',
			replace: false,
			controller: LogoutCtrl,
			controllerAs: 'logout',
			templateUrl: 'app/common/directives/logout.tmpl.html'
		};
	}

	angular.module('common').directive('logout', logout);

})(window.angular);