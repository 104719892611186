(function (angular) {
    'use strict';

    function googleTranslate($timeout, $interval) {
        return {
            restrict: 'AE',
            link: function(scope) {
                if(window && window.multiLanguageSupport && document) {
                    var translator = document.getElementById('google_translate_element');
                    if(!translator) {

                        document.cookie = 'googtrans' + '=; Max-Age=-99999999;';
                        document.cookie = 'googtransopt' + '=; Max-Age=-99999999;';

                        translator = angular.element('<div id="google_translate_element"></div>');
                        angular.element(document.body).prepend(translator);

                        scope.tryInitGoogleTranslate = function () {
                            var stop = $interval(function () {
                                if (window.google && window.google.translate && window.google.translate.TranslateElement) {
                                    new window.google.translate.TranslateElement({
                                        pageLanguage: 'en',
                                        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
                                    }, 'google_translate_element');
                                    $interval.cancel(stop);
                                    stop = undefined;
                                }
                            }, 1000, 20);
                        };

                        var type = 'text/javascript';
                        var url = '//translate.google.com/translate_a/element.js';
                        var script = document.querySelector('script[src*="' + url + '"]');

                        if (!script) {
                            var heads = document.getElementsByTagName('head');
                            if (heads && heads.length) {
                                var head = heads[0];
                                if (head) {
                                    script = document.createElement('script');
                                    script.onload = function () {
                                        $timeout(function () {
                                            scope.tryInitGoogleTranslate();
                                        }, 1000);
                                    };
                                    script.setAttribute('type', type);
                                    script.setAttribute('src', url);
                                    head.appendChild(script);
                                }
                            }
                        }

                    }
                }
            }
        };
    }

    googleTranslate.$inject = [
        '$timeout',
        '$interval'
    ];

    angular.module('common').directive('googleTranslate', googleTranslate);

})(window.angular);