(function (angular, $) {
	'use strict';

	function DeliveryRewardCtrl($log, deliveryService, formService, errorService, utilityService, $filter, $timeout) {
		var vm = this,
			translate = $filter('translate'),
			searchParams = utilityService.parseSearchQuery(),
			prefillDeliveryType = deliveryService.prefillDeliveryModel.type,
			prefillDeliveryValue = deliveryService.prefillDeliveryModel.value || searchParams.prefill;
			

		vm.reward = deliveryService.reward;
		vm.deliveryName = {
			'Email': translate('Email'),
			'Sms': translate('Text Message'),
			'Printer': translate('Printer'),
			'Skip': translate('Skip'),
			'MailingAddress': translate('Address')
		};
		vm.deliveryOptions = deliveryService.deliveryOptions;
		vm.selectableDeliveryOptions = vm.deliveryOptions.filter(function (option) {
			return option in vm.deliveryName;
		});
		vm.country = deliveryService.country;
		vm.displayCountryDropDown = deliveryService.displayCountryDropDown;
		vm.submitDeliveryForm = submitDeliveryForm;
		vm.deliverReward = deliverReward;
		vm.isDeliveryTypePresented = isDeliveryTypePresented;
		vm.onChangeDeliveryType = onChangeDeliveryType;
		vm.isDeliveryEmailTypePresented = isDeliveryTypePresented('Email');
		vm.isDeliverySmsTypePresented = isDeliveryTypePresented('Sms');
		vm.isDeliveryPaperTypePresented = isDeliveryTypePresented('PaperOnSite');
		vm.isDeliveryMailingAddressTypePresented = isDeliveryTypePresented('MailingAddress');
		vm.showOptInMessage = deliveryService.showOptInMessage;
		vm.optInMessage = deliveryService.optInMessage;
		vm.isConsented = deliveryService.isCheckedByDefault ? true : vm.isConsented;
		vm.isPaperActive = false;
		vm.deliveryModel = {};
		vm.deliveryType = vm.deliveryOptions[0];

		prefillDeliveryModel(prefillDeliveryValue, prefillDeliveryType);

		function submitDeliveryForm(form) {
			var options = [],
				deliveryEmailModel = {
					DeliveryOption: 'Email',
					Value: vm.deliveryModel.email
				},
				deliverySmslModel = {
					DeliveryOption: 'Sms',
					Value: vm.displayCountryDropDown && vm.deliveryModel.phone ? (vm.country.phoneCode + vm.deliveryModel.phone) : vm.deliveryModel.phone
				},
				deliveryMailingAddressModel = {
					DeliveryOption: 'MailingAddress',
					Value: vm.deliveryModel.mailingAddress && vm.deliveryModel.mailingAddress.value
				};

			formService.makeFieldsDirty(form);

			if (form.$valid && !vm.isLoading) {

				if (vm.isDeliveryEmailTypePresented) {
					options.push(deliveryEmailModel);
					if (vm.deliveryType !== 'Email') {
						deliveryEmailModel.Value = undefined;
					}
				}

				if (vm.isDeliverySmsTypePresented) {
					options.push(deliverySmslModel);
					if (vm.deliveryType !== 'Sms') {
						deliverySmslModel.Value = undefined;
					}
				}

				if (vm.isDeliveryMailingAddressTypePresented) {
					options.push(deliveryMailingAddressModel);
					if (vm.deliveryType !== 'MailingAddress') {
						deliveryMailingAddressModel.Value = undefined;
					}
				}

				vm.isLoading = true;
				vm.errorsList = null;

				deliveryService
					.deliverReward({
						deliveryOptions: options,
						isConsented: vm.isConsented
					}, true)
					.then(function () {
						deliveryService.complete()
							.finally(function () {
								vm.isLoading = false;
							});
					})
					.catch(function (response) {
						vm.errorsList = errorService.parseErrorsToList(response.data);
						vm.isLoading = false;
					});
			}
		}

		function deliverReward() {
			$log.debug('delivering');
			deliveryService.deliverReward([]).then(function () {
				deliveryService.complete();
			});
		}

		function onChangeDeliveryType() {
			//vm.deliveryModel = {};
			if (vm.deliveryType === 'Skip') {
				$timeout(function () {
					$('.skip-btn').click();
				});
			}
		}

		function isDeliveryTypePresented(type) {
			return vm.deliveryOptions.indexOf(type) !== -1;
		}

		/* Prefill delivery model by predefined value from url query */
		function prefillDeliveryModel(value, deliveryType) {

			if (value && ~vm.deliveryOptions.indexOf(deliveryType)) {
				if (deliveryType.toUpperCase() === 'SMS') {
					vm.deliveryModel = {
						phone: value
					};
					return (vm.deliveryType = deliveryType);
				}
				if (deliveryType.toUpperCase() === 'EMAIL') {
					vm.deliveryModel = {
						email: value
					};
					return (vm.deliveryType = deliveryType);
				}
			}

			if (value && utilityService.PHONE_REGEXP.test(value)) {
				// Phone is defined
				vm.deliveryModel = {
					phone: value
				};
				deliveryType = vm.deliveryOptions.filter(function (opt) {
					return opt.toUpperCase() === 'SMS';
				})[0];
			}

			if (value && utilityService.EMAIL_REGEXP.test(value)) {
				// Email is defined
				vm.deliveryModel = {
					email: value
				};
				deliveryType = vm.deliveryOptions.filter(function (opt) {
					return opt.toUpperCase() === 'EMAIL';
				})[0];
			}

			return (vm.deliveryType = deliveryType || vm.deliveryType);
		}
	}

	DeliveryRewardCtrl.$inject = [
		'$log',
		'deliveryService',
		'formService',
		'errorService',
		'utilityService',
		'$filter',
		'$timeout'
	];

	function deliveryReward() {
		return {
			restrict: 'AE',
			controller: DeliveryRewardCtrl,
			controllerAs: 'deliveryReward',
			bindToController: true,
			templateUrl: 'app/delivery/components/deliveryReward/delivery-reward.tmpl.html',
			scope: {}
		};
	}

	angular.module('delivery').directive('deliveryReward', deliveryReward);

})(window.angular, window.jQuery);