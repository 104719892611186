(function (angular, $) {
	'use strict';

	function questionService(EventEmitter, apiSessionResource, $log, sessionService, $timeout, utilityService, behaviorEffectService, $animate, viewAnimationService) {
		var service = Object.create(EventEmitter.prototype);

		service.questionCim = null;
		service.getQuestionCim = getQuestionCim;
		service.answerToQuestion = answerToQuestion;
		service.answerToQuestions = answerToQuestions;
		service.complete = complete;
		service.isImageMediaTypeActive = isImageMediaTypeActive;
		service.isVideoMediaTypeActive = isVideoMediaTypeActive;

		return service;

		function getQuestionCim(options) {
			return apiSessionResource.get('/questions', options || {})
				.then(function (data) {
					service.questionCim = data || {groups: []};
					service.questionCim.items = data.items;
					if (service.questionCim && service.questionCim.groups) {
						service.questionCim.groups.forEach(function(group) {
							if (group.questions) {
								group.questions.forEach(function(question){
									question.mediaConfiguration = utilityService.parseSafeJSON(question.mediaConfiguration) || {};
								});
                            }
						});
					}
					fireEvent('campaign.onChangeQuestionCim', service.questionCim);
					return service.questionCim;
				});
		}

		function answerToQuestion(question, choices, ignoreDefaultErrorNotifier) {
			var answers;

			if (question.questionType === 'Ranks' && !choices) {
				choices = angular.copy(question.choices);
			}

			choices = angular.isArray(choices) ? choices : [choices];

			answers = choices.map(function (choice) {
				return {
					answerValue: choice && (choice.value || choice.mediaUrl && choice.mediaUrl.value) || null,
					mediaType: choice && choice.mediaType || 'None'
				};
			});

			if (question.questionType === 'InfoCard') {
				answers = [];
			}

			return apiSessionResource.post('/questions/' + question.questionId + '/answers', {
				answers: answers
			}, {
				ignoreDefaultErrorNotifier: !!ignoreDefaultErrorNotifier
			}).then(function (response) {
				$log.debug('question answer', response);
				return response;
			});
		}

        function answerToQuestions(questions, ignoreDefaultErrorNotifier) {
            var answers = [];
            angular.forEach(questions, function (question) {
                var answer = {};
                answer.questionId = question.questionId;
                answer.questionType = question.questionType;
                answer.questionSubType = question.questionSubType;
                if (question.questionType === 'Ranks') {
                    question.answer = angular.copy(question.choices);
				}
                question.answer_send = angular.isArray(question.answer) ? question.answer : [question.answer];
                answer.answers = question.answer_send.map(function (choice) {
                    var answer = '';
                    var answerMediaType = 'None';
					if (choice && choice.contentType && choice.value && choice.fileName) {
                        answer = JSON.stringify(choice);
                        answerMediaType = 'Image';
                    } else {
                        answer = choice && (choice.value || choice.mediaUrl && choice.mediaUrl.value) || null;
					}
                    return {
                        answerValue: answer,
                        mediaType: answerMediaType
                    };
                });
                if (question.questionType === 'InfoCard') {
                    answer.answers = [];
                }
                answers.push(answer);
            });

            return apiSessionResource.post('/questions/answers', answers, {
                ignoreDefaultErrorNotifier: !!ignoreDefaultErrorNotifier
            }).then(function (response) {
                $log.debug('question answer', response);
                return response;
            });
        }

		function complete() {
			var behaviorData = sessionService.currentStep || {},
				reward = service.reward || {};

			//Show Effect if needed
			return behaviorEffectService
				.addEffect({
					effectDuration: behaviorData.effectDuration,
					effectSound: behaviorData.effectSound,
					effectType: behaviorData['effectType'],
					showDismissButton: behaviorData.showDismissButton,
					dismissImage: behaviorData.displayBehaviorDismissImage,
					dismissButtonText: behaviorData.displayBehaviorDismissText,
					dismissButtonColor: behaviorData.displayBehaviorDismissButtonColor,
					dismissButtonTextColor: behaviorData.displayBehaviorDismissTextColor,
					showReward: behaviorData.showReward,
					reward: reward
				}).then(function() {
					if (viewAnimationService.isLeaveAnimationPresent()) {
						return $animate.leave($('.question-transition')).done(function() {
                            if (viewAnimationService.leaveVideoUrl) {
                                viewAnimationService.showVideoInstead('.question-row', viewAnimationService.leaveVideoUrl).then(function () {
                                    return sessionService.getCurrentStep();
                                });
                            } else {
                                return sessionService.getCurrentStep();
							}
                        });
					}
				}).finally(function() {
					if (!viewAnimationService.leaveVideoUrl) {
                        return sessionService.getCurrentStep();
					}
				});
		}

		function isImageMediaTypeActive(model) {
			return model && model.mediaType && model.mediaType.toUpperCase() === 'IMAGE';
		}

		function isVideoMediaTypeActive(model) {
			return model && model.mediaType && model.mediaType.toUpperCase() === 'VIDEO';
		}

		function fireEvent(event, data) {
			$timeout(function () {
				service.trigger(event, data ? [data] : undefined);
			});
		}
	}

	questionService.$inject = [
		'EventEmitter',
		'apiSessionResource',
		'$log',
		'sessionService',
		'$timeout',
		'utilityService',
		'behaviorEffectService',
		'$animate',
		'viewAnimationService'
	];

	angular.module('question').factory('questionService', questionService);

})(window.angular, window.jQuery);
