(function (angular) {
	'use strict';

	function run(authService) {
		authService.fillAuthData();
	}

	run.$inject = [
		'authService'
	];

	angular.module('auth').run(run);

})(window.angular);