(function (angular) {
  'use strict';

  function AnswerCtrl() {
    var vm = this;

    vm.showAddressFields = true;
    vm.answerDisabled = true;
    vm.model = getEmptyModel();

    vm.init = function (ngModel) {
      if (!ngModel) return;
      var b = document.getElementById('usAddressAutocompleteDiv');
      b.addEventListener('keyup', function () {
        var pac = document.getElementsByClassName('pac-container')[0];
        b.append(pac);
      });
      setTimeout(function () {
        b.setAttribute('autocomplete', 'none');
      }, 200);

      vm.onChange = function () {
        var address = vm.model.address ? vm.model.address : 'N/A';
        var address2 = vm.model.address2 ? vm.model.address2 : 'N/A';
        var city = vm.model.city ? vm.model.city : 'N/A';
        var countryRegion = vm.model.countryRegion
          ? vm.model.countryRegion
          : 'N/A';
        var postalCode = vm.model.postalCode ? vm.model.postalCode : 'N/A';
        var country = vm.model.country ? vm.model.country : 'N/A';
        var fullAddress =
          address +
          ', ' +
          address2 +
          ', ' +
          city +
          ', ' +
          countryRegion +
          ', ' +
          postalCode +
          ', ' +
          country;
        vm.model.fullAddress = fullAddress
          .replaceAll('N/A,', '')
          .replace(/ +/g, ' ')
          .trim();
        setViewValue(vm.model);
        vm.onChangeModel({ $inputType: 'text' });
      };

      vm.modelParser = function (value) {
        if (!!vm.forceUppercase && value && value.toUpperCase) {
          value = value.toUpperCase();
        }
        return value;
      };

      ngModel.$render = function () {
        // Specify how UI should be updated when new question was applied
        vm.model = getEmptyModel();
        setViewValue(vm.model);
      };

      function setViewValue(model) {
        var json;

        try {
          json = JSON.stringify(model);
        } catch (e) {
          json = null;
        }

        ngModel.$setViewValue({
          value: json
        });
      }
    };

    function getEmptyModel() {
      return {
        address: '',
        address2: '',
        city: '',
        countryRegion: '',
        postalCode: '',
        country: '',
        fullAddress: ''
      };
    }
  }

  AnswerCtrl.$inject = ['authService'];

  function answer($window) {
    //function answer($window)
    var google = $window.google,
      $ = $window.jQuery;

    return {
      restrict: 'E',
      templateUrl:
        'app/answer/components/answer/answerItems/answer-autocomplete-address.tmpl.html',
      require: ['answerAutocompleteAddress', 'ngModel', '^answers'],
      controller: AnswerCtrl,
      controllerAs: 'answer',
      bindToController: true,
      scope: {
        onChangeModel: '&answerOnChangeModel',
        isRequired: '=answerRequired',
        form: '=answerFormInstance',
        enableAutoFocus: '=answerEnableAutoFocus',
        forceUppercase: '=answerForceUppercase',
        placeholderText: '=placeholderText'
      },
      link: function (scope, element, attr, ctrls) {
        ctrls[0].init(ctrls[1], ctrls[2]);

        var addressCtrl = ctrls[0];

        var componentForm = {
          street_number: 'short_name',
          route: 'long_name',
          locality: 'long_name',
          administrative_area_level_1: 'long_name',
          country: 'long_name',
          postal_code: 'short_name'
        };
        var mapping = {
          street_number: 'number',
          route: 'street',
          locality: 'city',
          administrative_area_level_1: 'state',
          country: 'country',
          postal_code: 'zip'
        };

        var options = {
          types: attr.googlePlace ? attr.googlePlace.split(',') : [],
          componentRestrictions: { country: [] }
        };

        //scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

        scope.gPlace = new google.maps.places.Autocomplete(
          $('#usAddressAutocomplete')[0],
          options
        );

        google.maps.event.addListener(
          scope.gPlace,
          'place_changed',
          function () {
            var place = scope.gPlace.getPlace();
            var details =
              place.geometry && place.geometry.location
                ? {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                  }
                : {};

            // Get each component of the address from the place details
            // and fill the corresponding field on the form.
            for (var i = 0; i < place.address_components.length; i++) {
              var addressType = place.address_components[i].types[0];
              if (componentForm[addressType]) {
                details[mapping[addressType]] =
                  place.address_components[i][componentForm[addressType]];
              }
            }
            details.formatted = place.formatted_address;
            details.placeId = place.place_id;

            scope.$apply(function () {
              var number = details['number'] ? details['number'] : '';
              var street = details['street'] ? details['street'] : '';
              addressCtrl.model.address = (number + ' ' + street).trim();
              addressCtrl.model.city = details['city'];
              addressCtrl.model.countryRegion = details['state'];
              addressCtrl.model.postalCode = details['zip'];
              addressCtrl.model.country = details['country'];
            });
            addressCtrl.onChange();
          }
        );
      }
    };
  }

  answer.$inject = ['$window'];

  angular.module('answer').directive('answerAutocompleteAddress', answer);
})(window.angular);
