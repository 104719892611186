(function (angular) {
  'use strict';

  function LobbyCtrl(
    $window,
    $scope,
    lobbyService,
    $uibModal,
    $filter,
    authService
  ) {
    var vm = this,
      isActiveCouponCampaignsClassName = 'coupon-campaigns-is-active';

    vm.tryToStartCampaign = tryToStartCampaign;
    vm.isShownBarCodeImage = isShownBarCodeImage;
    vm.showRewardModal = showRewardModal;
    vm.showCampaignScheduleModal = showCampaignScheduleModal;
    vm.campaignClick = campaignClick;
    vm.couponSort = couponSort;
    vm.isCampaignCancelledOrEnded = isCampaignCancelledOrEnded;
    vm.isAllCampaignsEnded = isAllCampaignsEnded;
    vm.isCampaignEnded = isCampaignEnded;
    vm.toggleRewardBarStatus = toggleRewardBarStatus;
    vm.onSelectPage = onSelectPage;

    vm.displayRewardStatusBar =
      vm.settings &&
      vm.settings.uiConfiguration &&
      vm.settings.uiConfiguration.displayRewardStatusBar;
    vm.couponsPerPage =
      vm.settings &&
      vm.settings.uiConfiguration &&
      vm.settings.uiConfiguration.couponsPerPage;
    vm.barStatuses = {
      Active: 0,
      Redeemed: 0,
      Expired: 0
    };
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = !vm.settings.uiConfiguration
      .shortFormatForCampaignStartEndDates
      ? authService.dateTimeFormat()
      : 'MMM dd, yyyy';

    vm.dateSort = authService.dateTimeFormat();

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateSort === 'MM/dd/yy' ? date_components[1] : date_components[0];
        var month =
          vm.dateSort === 'MM/dd/yy' ? date_components[0] : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }

    var $ = $window.jQuery;

    vm.campaigns = vm.campaigns.map(function (campaign) {
      if (
        campaign.schedule &&
        campaign.schedule.items &&
        campaign.schedule.items.length
      ) {
        campaign.schedule.items[0].campaignId = campaign.campaignId;
      }
      if (campaign.hasAnyCims && campaign.knownReward) {
        // mark campaign reward as Completed if campaign has any Cims
        campaign.knownReward.rewardState = 'Completed';
        campaign.knownReward.statusMessage = 'Completed';
      }
      vm.displayRewardStatusBar && selectRewardBarStatus(campaign);
      return campaign;
    });

    if (vm.displayRewardStatusBar) {
      vm.barStatusNames = Object.keys(vm.barStatuses);
      vm.activeRewardStatus = vm.barStatusNames.length && vm.barStatusNames[0];
      $window.document.body.classList.add('bar-statuses');
    }

    $window.document.body.classList.add(isActiveCouponCampaignsClassName);

    $scope.$on('$destroy', function () {
      $window.document.body.classList.remove(isActiveCouponCampaignsClassName);
    });

    if (vm.couponsPerPage) {
      vm.currentPage = 1;
      vm.paginationMaxSize = 5;

      onSelectPage();

      vm.search = function () {
        vm.currentPage = 1;
        onSelectPage();
      };

      vm.clearSearchTerm = function () {
        vm.searchTerm = '';
        vm.search();
      };
    } else {
      if (vm.displayRewardStatusBar) {
        vm.activeRewardStatus && toggleRewardBarStatus(vm.activeRewardStatus);
      } else {
        vm.filteredCampaigns = vm.campaigns;
      }
    }

    if (lobbyService.campaignIdToLoad) {
      var campaignsToOpen = vm.campaigns.filter(function (campaign) {
        return campaign.campaignId === lobbyService.campaignIdToLoad;
      });
      var campaignToOpen = null;
      var secondVariant = null;
      if (campaignsToOpen && campaignsToOpen.length) {
        for (var i = 0; i < campaignsToOpen.length; i++) {
          if (
            !campaignsToOpen[i].knownReward &&
            !campaignsToOpen[i].hasInteractions
          ) {
            campaignToOpen = campaignsToOpen[i];
            break;
          } else if (
            !secondVariant &&
            campaignsToOpen[i].knownReward &&
            campaignsToOpen[i].knownReward.rewardStates &&
            campaignsToOpen[i].knownReward.rewardStates.length === 1 &&
            campaignsToOpen[i].knownReward.rewardStates[0] === 'None'
          ) {
            secondVariant = campaignsToOpen[i];
          }
        }
        campaignClick(campaignToOpen || secondVariant || campaignsToOpen[0]);
      }
    }

    function timeout(duration) {
      var dfd = $.Deferred();
      setTimeout(function () {
        dfd.resolve();
      }, duration);
      return dfd.promise();
    }

    function processCampaigns(arr) {
      return arr.reduce(function (promise, item) {
        return promise.then(function () {
          return timeout(500).then(tryToStartCampaign(item, true));
        });
      }, $.Deferred().resolve().promise());
    }

    if (lobbyService.config && lobbyService.config.campaignsAutoProcessing) {
      var camps = vm.campaigns.filter(function (campaign) {
        return !isCampaignCancelledOrEnded(campaign) && !campaign.knownReward;
      });

      processCampaigns(camps);
    }

    function campaignClick(campaign) {
      delete lobbyService.campaignIdToLoad;
      if (campaign.schedule && campaign.schedule.items.length > 1) {
        vm.showCampaignScheduleModal(campaign).result.then(function (campaign) {
          if (campaign) {
            runCampaign(campaign);
          }
        });
      } else {
        runCampaign(campaign);
      }
      if (!campaign.isDisabled) {
        lobbyService.collectLobbyClicks(campaign);
      }
    }

    function runCampaign(campaign) {
      if (!isCampaignCancelledOrEnded(campaign)) {
        if (!lobbyService.config.campaignsAutoProcessing) {
          tryToStartCampaign(campaign);
        } else {
          vm.showRewardModal(campaign);
        }
      }
    }

    function tryToStartCampaign(campaign, silentMode) {
      vm.stopWiggle = true;

      if (!campaign || campaign._isStarting || campaign.knownReward) {
        if (
          !campaign._isStarting &&
          campaign.knownReward &&
          campaign.knownReward.rewardState != 'Completed'
        ) {
          !silentMode && vm.showRewardModal(campaign);
        }
        return false;
      }

      !silentMode && (lobbyService.campaignId = campaign.campaignId);

      campaign._isStarting = true;

      var scheduleId =
        campaign.scheduleId ||
        campaign.schedulerId ||
        (campaign.schedule && campaign.schedule.scheduleId) ||
        (campaign.schedule && campaign.schedule.schedulerId);

      return lobbyService
        .startCampaignWithPassThrough(
          campaign.campaignId,
          scheduleId,
          lobbyService.aToken,
          lobbyService.accessRequestId
        )
        .then(function (data) {
          if (data && angular.isObject(data.reward)) {
            campaign.knownReward = data.reward;
          }
        })
        .finally(function () {
          campaign._isStarting = false;
          // now just load the modal
          !silentMode && campaign.knownReward && vm.showRewardModal(campaign);

          if (campaign.status) {
            campaign.status = 'Completed';
          }
        });
    }

    function isShownBarCodeImage(campaign) {
      return (
        campaign &&
        campaign.knownReward &&
        campaign.knownReward.combinedCode.type != 'plaintext' &&
        campaign.knownReward.combinedCode.type != 'none'
      );
    }

    function showCampaignScheduleModal(campaign) {
      var modal = $uibModal.open({
        templateUrl:
          'app/lobby/components/lobbyCampaigns/coupon/campaign-schedule-modal.tmpl.html',
        controller: [
          'campaign',
          '$uibModalInstance',
          function (campaign, $uibModalInstance) {
            var vm = this;
            vm.campaign = campaign;
            vm.schedule = campaign.schedule;
            vm.schedule.pageNumber += 1;
            vm.schedule.items &&
              vm.schedule.items.length &&
              vm.schedule.items.forEach(function (item) {
                item.hideStartEndDates = vm.campaign.hideStartEndDates;
                item.campaignId = vm.campaign.campaignId;
              });
            vm.runCampaign = runCampaign;
            vm.onSelectPage = onSelectPage;
            vm.transform = transform;

            function transform(date) {
              return dateFormatTransform(date);
            }
            function runCampaign(campaign) {
              $uibModalInstance.close(campaign);
            }

            function onSelectPage(pageNumber, pageSize) {
              vm.scheduleLoading = true;
              lobbyService
                .getCampaignScheduleRecords(
                  pageNumber - 1,
                  pageSize,
                  vm.campaign.campaignId,
                  vm.schedule.setNumber,
                  vm.schedule.scheduleId || vm.schedule.schedulerId
                )
                .then(function (scheduleItems) {
                  scheduleItems.pageNumber += 1;
                  vm.schedule = scheduleItems;
                  vm.schedule.items &&
                    vm.schedule.items.length &&
                    vm.schedule.items.forEach(function (item) {
                      item.hideStartEndDates = vm.campaign.hideStartEndDates;
                      item.campaignId = vm.campaign.campaignId;
                    });
                  vm.scheduleLoading = false;
                });
            }
          }
        ],
        controllerAs: 'ctrl',
        resolve: {
          campaign: function () {
            return campaign;
          }
        }
      });

      return modal;
    }

    function showRewardModal(campaign) {
      var reward = campaign.knownReward;
      if (reward) var view;
      var modal = $uibModal.open({
        templateUrl:
          'app/lobby/components/lobbyCampaigns/coupon/reward-modal.tmpl.html',
        controller: [
          'reward',
          'settings',
          'checkAvailability',
          function (reward, settings, checkAvailability) {
            var vm = this;
            view = vm;
            vm.model = reward;
            vm.settings = settings;
            vm.walletCode = vm.model.combinedCode.isOverridden
              ? vm.model.combinedCode.auxCodeValue
              : vm.model.combinedCode.codeValue;
            vm.model.hideStartEndDates = campaign.hideStartEndDates;
            vm.model.hasCampaignStateTypeAsEnabled =
              checkAvailability.hasCampaignStateTypeAsEnabled;
            vm.model.isSatisfiedCampaignStateAsEnabled =
              checkAvailability.isSatisfiedCampaignStateAsEnabled;
            vm.transform = transform;

            function transform(date) {
              return dateFormatTransform(date);
            }
          }
        ],
        controllerAs: 'ctrl',
        backdrop: 'static',
        windowClass: 'reward-modal',
        keyboard: false,
        resolve: {
          reward: function () {
            return reward;
          },
          settings: function () {
            return vm.settings;
          },
          checkAvailability: function () {
            return lobbyService.checkAvailability(campaign.campaignId);
          }
        }
      });

      modal.rendered.then(function () {
        var e = document.querySelector('#truncated-terms');
        if (e && e.offsetWidth < e.scrollWidth) {
          view.isTruncated = true;
        }
      });

      return modal;
    }

    function couponSort(coupon) {
      if (vm.settings.orderType.toLowerCase() === 'ordered') {
        return;
      }
      if (!coupon.knownReward || coupon.knownReward.rewardStates.length < 2) {
        return -1;
      }
      switch (coupon.knownReward.rewardStates[1]) {
        case 'Redeemed':
          return 0;
        case 'Expired':
          return 1;
      }
    }

    function getKnownRewardValuableState(states) {
      var valuableStates =
        states &&
        states.length > 1 &&
        states.filter(function (state) {
          return state.toUpperCase() !== 'NONE';
        });
      if (valuableStates && valuableStates.length > 0) {
        return valuableStates[0];
      }
      return null;
    }

    function isCampaignCancelledOrEnded(coupon) {
      if (coupon && coupon.status) {
        if (!coupon.sequenceCampaign) {
          return isCouponBookCancelledOrEnded(coupon);
        } else {
          switch (coupon.status) {
            case 'Ended':
              if (!coupon.isAccessible) {
                if (
                  coupon.knownReward &&
                  coupon.knownReward.rewardStates &&
                  coupon.knownReward.rewardStates.length > 1
                ) {
                  return getKnownRewardValuableState(
                    coupon.knownReward.rewardStates
                  );
                } else {
                  return coupon.status;
                }
              }
              break;
            case 'Live':
            case 'Pending':
              if (!coupon.isAccessible) {
                if (
                  coupon.knownReward &&
                  coupon.knownReward.rewardStates &&
                  coupon.knownReward.rewardStates.length > 1
                ) {
                  var state = getKnownRewardValuableState(
                    coupon.knownReward.rewardStates
                  );
                  if (state === 'NotStarted' && coupon.knownReward.startDate) {
                    var date = new Date(coupon.knownReward.startDate);
                    return $filter('date')(date, 'MMM dd');
                  }
                }

                return GetDisabledStatus(coupon);
              } else {
                if (coupon.isDisabled) {
                  return GetDisabledStatus(coupon);
                }
              }
              break;
          }
        }
      }
      return null;
    }

    function isCampaignEnded(coupon) {
      if (coupon && coupon.status) {
        if (!coupon.sequenceCampaign) {
          return isCouponBookEnded(coupon);
        } else {
          if (coupon.status === 'Ended' && !coupon.isAccessible) {
            return true;
          }
        }
      }
      return false;
    }

    function isCouponBookCancelledOrEnded(coupon) {
      if (
        coupon &&
        (coupon.status === 'Cancelled' || coupon.status === 'Ended')
      ) {
        if (!coupon.knownReward) {
          return coupon.status;
        }
        if (
          coupon.knownReward.rewardStates &&
          coupon.knownReward.rewardStates.length === 1 &&
          coupon.knownReward.rewardStates[0] === 'None'
        ) {
          return null;
        }
        if (
          coupon.knownReward.rewardStates &&
          coupon.knownReward.rewardStates.length > 1
        ) {
          return coupon.status;
        }
      } else {
        if (
          (coupon.status === 'Live' || coupon.status === 'Pending') &&
          coupon.isDisabled
        ) {
          return GetDisabledStatus(coupon);
        }
      }
      return null;
    }

    function isCouponBookEnded(coupon) {
      if (coupon.status === 'Ended' || coupon.status === 'Cancelled') {
        if (
          coupon.knownReward &&
          coupon.knownReward.rewardStates &&
          coupon.knownReward.rewardStates.length === 1 &&
          coupon.knownReward.rewardStates[0] === 'None'
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    function isAllCampaignsEnded() {
      return vm.campaigns.every(function (campaign) {
        return isCampaignEnded(campaign);
      });
    }

    function GetDisabledStatus(coupon) {
      if (!coupon.isDisabled) return null;

      switch (coupon.isDisabledType) {
        case 'NotQualified':
          return 'Not Qualified';
        default:
          return 'Coming Soon';
      }
    }

    function incrementRewardBarStatus(rewardBarStatus) {
      if (!vm.barStatuses[rewardBarStatus]) {
        vm.barStatuses[rewardBarStatus] = 1;
      } else {
        vm.barStatuses[rewardBarStatus]++;
      }
    }

    function selectRewardBarStatus(campaign) {
      if (
        !campaign.knownReward ||
        (campaign.knownReward &&
          campaign.knownReward.rewardStates &&
          campaign.knownReward.rewardStates.length === 1 &&
          campaign.knownReward.rewardStates[0] === 'None')
      ) {
        campaign.barStatus = 'Active';
        incrementRewardBarStatus(campaign.barStatus);
      } else {
        var statuses = {};
        var status = 'Active';
        campaign.knownReward &&
          campaign.knownReward.rewardStates &&
          campaign.knownReward.rewardStates.length > 1 &&
          campaign.knownReward.rewardStates.forEach(function (rewardState) {
            if (rewardState !== 'None') {
              switch (rewardState) {
                case 'Redeemed':
                case 'Expired':
                  statuses[rewardState] = rewardState;
                  status = rewardState;
              }
            }
          });
        if (statuses['Redeemed'] && statuses['Expired']) {
          campaign.barStatus = 'Redeemed';
        } else {
          campaign.barStatus = status;
        }
        incrementRewardBarStatus(campaign.barStatus);
      }
    }

    function toggleRewardBarStatus(rewardBarStatus) {
      vm.activeRewardStatus = rewardBarStatus;

      if (vm.couponsPerPage) {
        vm.currentPage = 1;
        onSelectPage();
      } else {
        vm.filteredCampaigns = $filter('filter')(angular.copy(vm.campaigns), {
          barStatus: vm.activeRewardStatus
        });
      }
    }

    function onSelectPage() {
      vm.filteredBySearchTermCampaigns = $filter('filter')(
        angular.copy(vm.campaigns),
        {
          title: vm.searchTerm
        }
      );
      if (vm.displayRewardStatusBar) {
        vm.filteredBySearchTermCampaigns = $filter('filter')(
          angular.copy(vm.filteredBySearchTermCampaigns),
          {
            barStatus: vm.activeRewardStatus
          }
        );
      }
      vm.filteredCampaigns = angular
        .copy(vm.filteredBySearchTermCampaigns)
        .splice(
          vm.currentPage * vm.couponsPerPage - vm.couponsPerPage,
          vm.couponsPerPage
        );
    }
  }

  LobbyCtrl.$inject = [
    '$window',
    '$scope',
    'lobbyService',
    '$uibModal',
    '$filter',
    'authService'
  ];

  function lobby() {
    return {
      restrict: 'AE',
      controller: LobbyCtrl,
      controllerAs: 'lobbyCoupon',
      bindToController: true,
      templateUrl:
        'app/lobby/components/lobbyCampaigns/coupon/lobby-coupon-campaigns.tmpl.html',
      scope: {
        campaigns: '=campaigns',
        settings: '=lobbySettings',
        startCampaign: '&startCampaign',
        cancel: '&cancel'
      }
    };
  }

  angular.module('lobby').directive('lobbyCouponCampaigns', lobby);
})(window.angular);
