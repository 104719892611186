(function (angular) {
	'use strict';

    function UserLoginModalCtrl($uibModalInstance, $scope) {
        var vm = this;
        vm.loginCallback = loginCallback;

        $scope.$on('$locationChangeStart', function () {
            $uibModalInstance.close();
        });

        function loginCallback() {
            $uibModalInstance.close();
        }	
	}

    UserLoginModalCtrl.$inject = [
		'$uibModalInstance',
		'$scope'
	];

    angular.module('lobby').controller('userLoginModalCtrl', UserLoginModalCtrl);

})(window.angular);