(function (angular) {
	'use strict';

	function utilityService($injector) {
		var service = {},
            $window = $injector.get('$windowProvider').$get(),
			$q,
			Math = $window.Math,
            PHONE_REGEXP = /^(\d+-?)+\d+$/,
			EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;


		service.$get = ['$q', function ($qService) {
			$q = $qService;
			return service;
		}];

		service.isIE = isIE();
		service.PHONE_REGEXP = PHONE_REGEXP;
		service.EMAIL_REGEXP = EMAIL_REGEXP;
		service.parseSafeJSON = parseSafeJSON;
		service.parseSearchQuery = parseSearchQuery;
		service.debounce = debounce;
		service.throttle = throttle;
		service.clearSelection = clearSelection;
		service.isMobile = isMobile;
        service.isIOS = isIOS;
		service.isPreviewToken = isPreviewToken;
		service.shuffle = shuffle;
		service.getDaysInMonth = getDaysInMonth;
		service.getRange = getRange;
		service.convertToDecimalColor = convertToDecimalColor;
        service.loadImage = loadImage;
        service.isStringContains = isStringContains;
        service.downloadBlobFile = downloadBlobFile;
        service.convertDateToISOFormat = convertDateToISOFormat;

        function roundNumberToDigits(number) {
            return number > 9 ? number : '0' + number;
        }

        function convertDateToISOFormat(date) {
            date = new Date(date);

            return date.getFullYear()
                + '-'
                + roundNumberToDigits(date.getMonth() + 1)
                + '-'
                + roundNumberToDigits(date.getDate())
                + 'T'
                + roundNumberToDigits(date.getHours())
                + ':'
                + roundNumberToDigits(date.getMinutes())
                + ':'
                + roundNumberToDigits(date.getSeconds())
                + '.0Z';
        }

        function downloadBlobFile($http, url, fileName, method, options, postHeaders) {
            var $ = $window.jQuery;

            if (method == 'POST') {
                return $http({
                    method: 'POST',
                    url: url,
                    responseType: 'arraybuffer',
                    transformResponse: jsonBufferToObject,
                    data: options,
                    headers: postHeaders
                }).then(function resolve(result) {
                    process(result);
                });
            }
            else {
                return $http({
                    method: 'GET',
                    url: url,
                    responseType: 'arraybuffer',
                    transformResponse: jsonBufferToObject
                }).then(function resolve(result) {
                    process(result);
                });
            }

            function jsonBufferToObject(data, headersGetter) {
                var type = headersGetter('Content-Type');
                if (!type.startsWith('application/json')) {
                    return data;
                }
                var decoder = new TextDecoder('utf-8');
                var domString = decoder.decode(data);
                return JSON.parse(domString);
            }

            function process(result) {
                var objectURL,
                    dataURL,
                    data = result.data,
                    contentType = result.headers('Content-Type') || 'text/plain',
                    windowUrl = $window.URL || $window.webkitURL,
                    $anchor = $('<a/>');

                $('body').append($anchor);

                if ($window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(data, fileName);
                } else if (typeof windowUrl.createObjectURL === 'function') {
                    var blob = new Blob([data], { type: contentType });

                    objectURL = windowUrl.createObjectURL(blob);

                    $anchor.attr({
                        href: objectURL,
                        download: fileName
                    });

                    $anchor.get(0).click();

                    // Added timeout to make it working in FF
                    $window.setTimeout(function () {
                        windowUrl.revokeObjectURL(objectURL);
                    }, 100);
                }

                $window.setTimeout(function () {
                    $anchor.remove();
                }, 0);

                return {
                    fileName: fileName,
                    contentType: contentType,
                    objectURL: objectURL,
                    dataURL: dataURL
                };
            }
        }

		function parseSafeJSON(data) {
			try {
				return $window.JSON.parse(data);
			} catch (e) {
				return data;
			}
		}

		return service;

		function parseSearchQuery(searchStr) {
			var params = {},
				items;

			searchStr = (searchStr || $window.location.search || '').slice(1);

			searchStr = $window.decodeURIComponent(searchStr || '').trim();

			items = searchStr.split('&');

			if (items.length === 1 && items[0] === '') {
				return params;
			}

			for (var i = 0; i < items.length; i++) {
				var key = (items[i].split('=')[0] || '').toLowerCase();
				params[key] = items[i].split(/=(.+)/)[1];
				if (params[key]) {
					params[key] = params[key].trim();
				}
			}

			return params;
		}

		function debounce(func, wait, immediate) {
			var timeout;

			return function () {
				var context = this,
					args = arguments,
					later,
					callNow;

				later = function () {
					timeout = null;
					if (!immediate) {
						func.apply(context, args);
					}
				};

				callNow = immediate && !timeout;

				clearTimeout(timeout);

				timeout = setTimeout(later, wait);

				if (callNow) {
					func.apply(context, args);
				}
			};
		}

		function throttle(func, ms) {
			var isThrottled = false,
				savedArgs,
				savedThis;

			function wrapper() {

				if (isThrottled) { // (2)
					savedArgs = arguments;
					savedThis = this;
					return;
				}

				func.apply(this, arguments); // (1)

				isThrottled = true;

				setTimeout(function () {
					isThrottled = false; // (3)
					if (savedArgs) {
						wrapper.apply(savedThis, savedArgs);
						savedArgs = savedThis = null;
					}
				}, ms);
			}

			return wrapper;
		}

		function clearSelection() {
			if ($window.document.selection && $window.document.selection.empty) {
				$window.document.selection.empty();
			} else if ($window.getSelection) {
				var sel = $window.getSelection();
				sel.removeAllRanges();
			}
		}

		function isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test($window.navigator.userAgent);
		}

		function isIOS() {
			return /iPad|iPhone|iPod/.test($window.navigator.userAgent) && !$window.MSStream;
        }

		function isIE() {
			var navigator = $window.navigator;
			return navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/));
		}

		function isPreviewToken(token) {
			token = (token || '').toUpperCase();
			return token.indexOf('PREVIEW') > -1;
		}

		function shuffle(o) {
			for (var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x) {
				//
			}
			return o;
		}

		function getDaysInMonth(m, y) {
			return m === 2 ? y & 3 || !(y % 25) && y & 15 ? 28 : 29 : 30 + (m + (m >> 3) & 1);
		}

		function getRange(n, fn) {
			return (new Array(n)).join('.').split('.').map(function (el, index) {
				return fn ? fn(el, index) : index;
			});
		}

		function convertToDecimalColor(hex, opacity) {
			hex = hex.toString().replace('#', '');
			opacity = opacity || 1;

			var r = parseInt(hex.substring(0, 2), 16);
			var g = parseInt(hex.substring(2, 4), 16);
			var b = parseInt(hex.substring(4, 6), 16);

			return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
		}

		function loadImage(src) {
			var deferred = $q.defer(),
				img = new Image();

			img.onload = function() {
				deferred.resolve();
			};

			img.onerror = function(error) {
				deferred.reject(error);
			};

			img.src = src;

			return deferred.promise;
		}

        function isStringContains(value, prohibited) {
            for (var i = 0; i < prohibited.length; i++) {
                if (value.indexOf(prohibited[i]) > -1) {
                    return true;
                }
            }
            return false;
        }
	}

	utilityService.$inject = [
        '$injector'
	];

	angular.module('common').provider('utilityService', utilityService);

})(window.angular);