(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.init = function (ngModel, answers) {
			if (!ngModel || !answers) return;

			vm.getLabel = function (choice) {
				return answers.getLabel(choice);
			};

			vm.onChange = function (choice) {
				ngModel.$setViewValue(choice);
				vm.onChangeModel({$inputType: 'textarea'});
			};

			vm.modelParser = function (value) {
				if (!!vm.forceUppercase && value && value.toUpperCase) {
					value = value.toUpperCase();
				}
				return value;
			};

			ngModel.$render = function () {
				// Specify how UI should be updated when new question was applied
				vm.model = ngModel.$viewValue;
			};
		};
	}

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-free-form-multi-line.tmpl.html',
			require: ['answerFreeFormMultiLine', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
				enableAutoFocus: '=answerEnableAutoFocus',
				forceUppercase: '=answerForceUppercase',
				answerBackgroundColor: '=',
				placeholderText: '=',
				answerDisabled: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);
			}
		};
	}

	angular.module('answer').directive('answerFreeFormMultiLine', answer);

})(window.angular);