(function (angular) {
  'use strict';

  function LookupRewardModalCtrl(
    redemptionService,
    formService,
    authService,
    $filter,
    $uibModal
  ) {
    var vm = this,
      descriptionModal;
    vm.deliveryValue = null;
    vm.isFromLookUp = redemptionService.isFromLookUp;
    vm.searchValue = redemptionService.searchValue;
    vm.isInviteOffers = redemptionService.isInviteOffers;
    vm.externalIdValue = null;
    vm.rewards = null;
    vm.submit = submit;
    vm.sortBy = sortBy;
    vm.setIsFromLookUp = setIsFromLookUp;
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = authService.dateTimeFormat();
    vm.filterValue = '';
    vm.propertyName = '';
    vm.reverse = true;
    vm.getOffers = getOffers;
    vm.generateOffer = generateOffer;
    vm.updateOffers = updateOffers;
    vm.togglerChange = togglerChange;
    vm.showDescriptionModal = showDescriptionModal;
    vm.toggle = false;
    vm.isRWLVacc = isRWLVacc;
    vm.isButtonVisible = redemptionService.isButtonVisible;

    function isRWLVacc() {
      var authData = authService.getAuthenticationData();
      var accountHash =
        authData && authData.accountHash ? authData.accountHash : '';
      if (
        accountHash.toUpperCase() === '5d25ca9bbe2b'.toUpperCase() ||
        accountHash.toUpperCase() === '70f437e9d6d7'.toUpperCase()
      ) {
        vm.isInviteOffers = true;
        vm.isButtonVisible = true;
      } else {
        vm.isButtonVisible = false;
      }
    }

    function togglerChange() {
      vm.offers = [];
      vm.rewards = [];
    }

    function showDescriptionModal(offer) {
      descriptionModal = $uibModal.open({
        templateUrl: 'app/redemption/components/search/description-modal.html',
        controller: 'DescriptionModalCtrl',
        controllerAs: 'description',
        backdrop: 'static',
        keyboard: false,
        size: 'custom',
        resolve: {
          offer: function () {
            return offer;
          }
        }
      });
      descriptionModal.result.then(function () {
        descriptionModal = null;
      });

      return descriptionModal;
    }

    if (vm.isFromLookUp && vm.searchValue) {
      vm.deliveryValue = vm.searchValue;
      var formData = new FormData(document.querySelector('form'));
      vm.submit(formData);
    } else {
      vm.isRWLVacc();
    }

    function generateOffer(offer, index) {
      vm.isLoading = true;
      redemptionService
        .generateBookingReward(vm.deliveryValue, offer.rewardPosCode)
        .then(function (offer) {
          vm.updateOffers(index, offer);
          return offer;
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    function updateOffers(index, offer) {
      vm.offers[index].rewardCode = offer[0].rewardCode;
      return vm.offers;
    }

    function getOffers() {
      vm.isLoading = true;
      return redemptionService
        .searchBookingOffers(vm.deliveryValue)
        .then(function (offers) {
          if (offers.campaigns.length > 0) {
            var mapped_rewards = offers.campaigns.map(function (offer) {
              if (offer.knownReward) {
                return {
                  campaignTitle: offer.title,
                  issuedOn: offer.knownReward.startDateExt,
                  issuedOnUtc: offer.knownReward.startDateExt,
                  offerType: 'BookingReward',
                  rewardCode: offer.knownReward.rewardCode,
                  rewardPosCode: offer.knownReward.rewardPosCode,
                  rewardName: offer.knownReward.name,
                  status: offer.knownReward.rewardStateExt,
                  rewardRedemptionInfo: offer.knownReward.rewardRedemptionInfo,
                  termsAndConditions: offer.knownReward.termsAndConditions
                };
              }
            });
            vm.offers = mapped_rewards;
            return vm.offers;
          }
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[1]
            : date_components[0];
        var month =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[0]
            : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }

    function setIsFromLookUp() {
      redemptionService.isFromLookUp = true;
      redemptionService.isButtonVisible = vm.isButtonVisible;
      redemptionService.searchValue = vm.deliveryValue;
      redemptionService.isInviteOffers = vm.isInviteOffers;
    }

    function submit(formInstance) {
      redemptionService.isFromLookUp = false;
      redemptionService.searchValue = '';
      redemptionService.isInviteOffers = false;
      formService.makeFieldsDirty(formInstance);
      if (formInstance.$invalid) {
        return false;
      }
      if (vm.isInviteOffers) {
        return vm.getOffers();
      }
      vm.isLoading = true;
      return redemptionService
        .searchRewards(vm.deliveryValue, vm.externalIdValue)
        .then(function (rewards) {
          var mapped_rewards = rewards.map(function (reward) {
            if (reward.offerType === 'BookingReward') {
              reward.status = reward.rewardStateExt;
            } else {
              if (reward.rewardStates) {
                if (~reward.rewardStates.indexOf('Gifted')) {
                  reward.status = 'Gifted';
                } else if (~reward.rewardStates.indexOf('Redeemed')) {
                  reward.status = 'Redeemed';
                } else if (~reward.rewardStates.indexOf('Expired')) {
                  reward.status = 'Expired';
                } else if (~reward.rewardStates.indexOf('Voided')) {
                  reward.status = 'Voided';
                } else if (~reward.rewardStates.indexOf('NotStarted')) {
                  reward.status = 'NotStarted';
                }
              }
            }
            delete reward.rewardStateExt;
            delete reward.rewardStates;
            return reward;
          });

          vm.rewards = mapped_rewards;
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    function sortBy(propertyName) {
      vm.reverse = vm.propertyName === propertyName ? !vm.reverse : false;
      vm.propertyName = propertyName;
    }
  }

  LookupRewardModalCtrl.$inject = [
    'redemptionService',
    'formService',
    'authService',
    '$filter',
    '$uibModal'
  ];

  angular
    .module('redemption')
    .controller('LookupRewardModalCtrl', LookupRewardModalCtrl);
})(window.angular);
