(function (angular) {

	function SelectableRewardsDetailCtrl($uibModalInstance, reward) {
		var vm = this;

		vm.reward = reward;

		vm.close = function () {
			$uibModalInstance.close();
		};
	}

	SelectableRewardsDetailCtrl.$inject = [
		'$uibModalInstance',
		'reward'
	];

	angular.module('common').controller('SelectableRewardsDetailCtrl', SelectableRewardsDetailCtrl);

})(window.angular);