(function (angular) {
	'use strict';

	function loadResourceService($window, $q, $log) {
		var service = {};

		service.loadSyncScripts = loadSyncScripts;
		service.loadScript = loadScript;
		service.loadImage = loadImage;
		service.loadStyle = loadStyle;

		return service;

		function loadSyncScripts(sources) {
			sources = angular.isArray(sources) ? sources : [sources];

			var promises = [],
				fragment = $window.document.createDocumentFragment(),
				body = $window.document.body;

			sources.forEach(function (source) {
				var scriptObject = createScript(source, fragment, false);
				promises.push(scriptObject.promise);
			});

			body.appendChild(fragment);

			return $q.all(promises);
		}

		function loadScript(source, async) {
			var body = $window.document.body,
				scriptObject = createScript(source, null, async);

			body.appendChild(scriptObject.fragment);

			return scriptObject.promise;
		}

		function createScript(source, fragment, async) {
			var deferred = $q.defer(),
				script = document.createElement('script'),
				scriptInjected;

			fragment = fragment || $window.document.createDocumentFragment();

			scriptInjected = Array.prototype.filter.call(document.scripts, function (script) {
				return script.getAttribute('src') === source;
			})[0];

			if (scriptInjected) {
				script = scriptInjected;
			}

			script.addEventListener('load', function () {
				deferred.resolve();
			}, false);

			script.addEventListener('error', function () {
				deferred.reject();
			}, false);

			if (typeof async === 'boolean') {
				script.async = async;
			}

			if (!scriptInjected) {
				script.src = source;
				fragment.appendChild(script);
			}

			return {
				fragment: fragment,
				promise: deferred.promise
			};
		}

		function loadImage(src) {
			var deferred = $q.defer();

			var image = new Image();

			image.onload = function () {
				deferred.resolve(image);
			};

			image.onerror = function (err) {
				$log.error('loadResourceService can not load resource', err);
				deferred.reject(err);
			};

			image.src = src;

			return deferred.promise;
		}

		function loadStyle(src, customFontFamily) {
			var deferred = $q.defer(),
				link = $window.document.createElement('link');

			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.href = src;

			link.onload = function () {
				if (customFontFamily && customFontFamily.length) {
					//we need it to be sure that custom font will be loaded
					var _el = $window.document.createElement('span');
					_el.style.fontFamily = customFontFamily;
					_el.style.visibility = 'hidden';
					_el.innerText = 'custom font test';
					$window.document.body.appendChild(_el);
					$window.setTimeout(function () {
						deferred.resolve();
						$window.document.body.removeChild(_el);
					}, 100);
				} else {
					deferred.resolve();
				}
			};

			link.onerror = function (err) {
				$log.error('loadResourceService can not load resource', err);
				deferred.reject(err);
			};

			$window.document.getElementsByTagName('head')[0].appendChild(link);

			if (!('onload' in link)) {
				$log.debug('Tag link does not support onload event');
				deferred.resolve();
			}

			return deferred.promise;
		}
	}

	loadResourceService.$inject = [
		'$window',
		'$q',
		'$log'
	];

	angular.module('common').factory('loadResourceService', loadResourceService);

})(window.angular);