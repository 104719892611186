(function (angular) {

	function DynamicDashboardBoxCtrl() {
		var vm = this;
        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };
	}

    DynamicDashboardBoxCtrl.$inject = [];

	function dynamicDashboardBox() {
		return {
            controller: DynamicDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/dynamic/dynamic-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('dynamicDashboardBox', dynamicDashboardBox);

})(window.angular);