(function (angular) {
	'use strict';

	function browserService($document) {
		var service = {};

		service.setDocumentTitle = setDocumentTitle;

		return service;

		function setDocumentTitle(title) {
			$document[0].title = title || '';
		}
	}

	browserService.$inject = [
		'$document'
	];

	angular.module('browser').factory('browserService', browserService);

})(window.angular);