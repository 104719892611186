(function (angular) {
	'use strict';

	function TermsConditionsLinkCtrl(sessionService, apiSettings, appModeService, $translate) {
		var vm = this,
			userWebBaseUri = apiSettings.userWebBaseUri,
			instanceId = sessionService.getSessionKey(),
			// isCTokenMode = appModeService.isCTokenMode(),
			locale = $translate.use();

		vm.getTermsLinkUrl = getTermsLinkUrl;

		function getTermsLinkUrl() {
			return (userWebBaseUri.slice(-1) === '/' ? userWebBaseUri : userWebBaseUri + '/') + '?locale=' + locale + '#/instance/' + instanceId + '/terms';
		}

	}

	TermsConditionsLinkCtrl.$inject = [
		'sessionService',
		'apiSettings',
		'appModeService',
		'$translate'
	];

	function termsConditionsLink() {
		return {
			restrict: 'AE',
			controller: TermsConditionsLinkCtrl,
			controllerAs: 'termsLink',
			bindToController: true,
			templateUrl: 'app/session/components/termsConditionsLink/terms-conditions-link.tmpl.html'
		};
	}

	angular.module('session').directive('termsConditionsLink', termsConditionsLink);

})(window.angular);