(function (angular) {
	'use strict';

	function disableAnimate($animate) {
		return {
			restrict: 'A',
			link: function (scope, element) {
				$animate.enabled(element, false);
			}
		};
	}

	disableAnimate.$inject = ['$animate'];

	angular.module('common').directive('disableAnimate', disableAnimate);

})(window.angular);