(function (angular) {
	'use strict';

	function PinCodeCtrl($state, redemptionService, sessionService, $stateParams, authService) {
		var vm = this;

		vm.pinCode = '';
		vm.rewardCode = $stateParams.rewardCode;
		vm.lobbyEnabled = true;
        vm.clientLogoUrl = authService.authentication.clientLogoUrl;
		vm.onClickCampaigns = onClickCampaigns;
		vm.onClickRedeem = onClickRedeem;
		vm.onClickCancel = onClickCancel;

		function onClickCancel() {
			vm.rewardCode = null;

			return $state.go('pin', {
				rewardCode: vm.rewardCode
			});
		}

		function onClickCampaigns(code) {
			return sessionService.startSessionByPinCode(code || vm.pinCode);
		}

		function onClickRedeem(code) {
			return redemptionService
				.checkPinCodeForRedemption(code || vm.pinCode)
				.then(function () {
					if (vm.rewardCode) {
						return $state.go('redemption.details', {
							rewardCode: vm.rewardCode
						});
					} else {
						return $state.go('redemption.search');
					}
				});
		}
	}

	PinCodeCtrl.$inject = [
		'$state',
		'redemptionService',
		'sessionService',
		'$stateParams',
		'authService'
	];

	function pinCode() {
		return {
			restrict: 'E',
			controller: PinCodeCtrl,
			controllerAs: 'pin',
			templateUrl: 'app/pin/components/pinCode/pin-code.tmpl.html'
		};
	}

	angular.module('pin').directive('pinCode', pinCode);

})(window.angular);