(function (angular) {
	'use strict';

	function howlerTriviaToggleButton($window, $rootScope, $timeout) {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/toggle-trivia-sound-button.tmpl.html',
			link: function (scope) {
				var Howler = $window.Howler;

                scope.toggleMute = toggleMute;
                scope.toggleUserMute = toggleUserMute;
				scope.toggleFadeAndMute = toggleFadeAndMute;
				scope.isMuted = isMuted;
				scope.isThereSoundToPlay = isThereSoundToPlay;
				scope.muteButton = muteButton;
                scope.userMute = false;

				if (/(iphone|ipod|ipad)/i.test($window.navigator.userAgent) && !isMuted()) {
					toggleMute();
				}

				// If audio is played, fade out the campaign audio effects that are played by Howler.
				// If audio is paused or ended, fade in the campaign audio effects.
				$rootScope.$watch(
					function () { return document.getElementsByTagName('audio').length; },
					function () {
						var audioTags = document.getElementsByTagName('audio');
						for (var i = 0; i < audioTags.length; i++) {
							var audioTag = audioTags[i];
							var events = ['playing', 'pause'];
							for (var j = 0; j < events.length; j++) {
								audioTag.removeEventListener(events[j], function () {
									toggleFadeAndMute();
								});
								audioTag.addEventListener(events[j], function () {
									toggleFadeAndMute();
								});
							}
						}
					}
				);

				function toggleFadeAndMute() {
					if (isMuted()) {
						Howler.unmute();
						scope.$applyAsync();
						$rootScope.$applyAsync();

						Howler._howls.forEach(function (howl) {
							howl.fade(0.0, 1.0, 1000);
						});
					} else {
						Howler._howls.forEach(function (howl) {
							howl.fade(1.0, 0.0, 1000);
						});

						$timeout(function () {
							Howler.mute();
							scope.$applyAsync();
							$rootScope.$applyAsync();
						}, 1000);

						scope.$applyAsync();
						$rootScope.$applyAsync();
					}
				}

                function toggleUserMute() {
                    toggleMute();
                    scope.userMute = isMuted();
                }

				function toggleMute() {
					if (isMuted()) {
                        Howler._howls.forEach(function (howl) {
                            howl.volume(1);
                        });
						Howler.unmute();
					} else {
                        Howler._howls.forEach(function (howl) {
                            howl.volume(0);
                        });
						Howler.mute();
					}

					scope.$applyAsync();
					$rootScope.$applyAsync();
				}

				function isMuted() {
					return Howler._muted;
				}

				function muteButton() {
					if (isMuted()) {
						return scope.trivia.unmuteButton;
					} else {
						return scope.trivia.muteButton;
					}
				}

				function isThereSoundToPlay() {
					var howls = Howler._howls || [],
						areAllSilent;

					if (howls.length === 0) {
						return false;
					}

					areAllSilent = howls.map(function (howl) {
						return howl._src;
					}).every(function (src) {
						return !src || /oc-silent-point1sec\.mp3$/.test(src);
					});

					return !areAllSilent;
				}

                var videoPlaying = $rootScope.$on('videoplaying', function () {
                    if (!isMuted()) {
                        toggleFadeAndMute();
                    }
                });

                var videoStopped = $rootScope.$on('videostopped', function () {
                    if (isMuted() && !scope.userMute) {
                        toggleFadeAndMute();
                    }
                });

                scope.$on('$destroy', function () {
                    videoPlaying();
                    videoStopped();
                });
			}
		};
	}

	howlerTriviaToggleButton.$inject = [
		'$window',
		'$rootScope',
		'$timeout'
	];

	angular.module('common').directive('howlerTriviaToggleButton', howlerTriviaToggleButton);

})(window.angular);