(function (angular) {
	'use strict';

	function config($httpProvider, $stateProvider) {
		$httpProvider.interceptors.push('infoErrorInterceptorService');

		$stateProvider
			.state('info', {
				url: '/info?message',
				templateUrl: 'app/info/layout/info.html'
			});
	}

	config.$inject = [
		'$httpProvider',
		'$stateProvider'
	];

	angular.module('info').config(config);

})(window.angular);