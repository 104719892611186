(function (angular, $) {
	'use strict';

	var SVG_GRAYSCALE_FILTER = ''+
	'<svg xmlns="http://www.w3.org/2000/svg" id="svg-grayscale-filter" class="svg-grayscale-filter">\
		<defs>\
			<filter id="GrayScaleFilter">\
				<feComposite result="inputTo_38" in="SourceGraphic" in2="SourceGraphic" operator="arithmetic" k1="0" k2="1" k3="0" k4="0" />\
				<feColorMatrix id="filter_38" type="saturate" values="0" data-filterid="38" />\
			</filter>\
		</defs>\
	</svg>';

	function PickemQuestionCtrl() {
		var vm = this;

		vm.firstColumnTeam = vm.model.opponent;
		vm.secondColumnTeam = vm.model.partaker;
		vm.selectTeam = selectTeam;
		vm.isSelectedTeam = isSelectedTeam;
		vm.isUnselectedTeam = isUnselectedTeam;
		vm.isTeamSelected = isTeamSelected;
		vm.isFirstColumnSelected = isFirstColumnSelected;
		vm.isSecondColumnSelected = isSecondColumnSelected;
		vm.selectFirstColumn = selectFirstColumn;
		vm.selectSecondColumn = selectSecondColumn;
		vm.isActive = isActive;

		function selectTeam(teamId) {
			if (vm.model.chosenId !== teamId) {
				vm.model.chosenId = teamId;
				//vm.onChangeModel();
			}
		}

		function isActive() {
			return vm.model.isActive;
        }

		function isSelectedTeam(teamId) {
			return vm.model.chosenId === teamId;
		}

		function isUnselectedTeam(teamId) {
			return isTeamSelected() && vm.model.chosenId !== teamId;
		}

		function isTeamSelected() {
			return !!vm.model.chosenId;
		}

		function isFirstColumnSelected() {
			return isSelectedTeam(vm.firstColumnTeam.id);
		}

		function isSecondColumnSelected() {
			return isSelectedTeam(vm.secondColumnTeam.id);
		}

		function selectFirstColumn() {
			return selectTeam(vm.firstColumnTeam.id);
		}

		function selectSecondColumn() {
			return selectTeam(vm.secondColumnTeam.id);
		}
	}

	PickemQuestionCtrl.$inject = [
		'$filter'
	];

	function pickemQuestion($window, $swipe, RGBService) {
		return {
			restrict: 'AE',
			controller: PickemQuestionCtrl,
			controllerAs: 'pick',
			bindToController: true,
			templateUrl: 'app/pickem/components/pickemQuestion/pickem-question.tmpl.html',
			require: 'pickemQuestion',
			scope: {
				model: '=',
				form: '=',
				name: '=',
				onChangeModel: '&',
				displayPartakerClassifiers: '=',
				displayPartakers: '=',
				displayStandings: '=',
				displayTime: '=',
				questionBackgroundColor: '@'
			},
			link: function (scope, $element, attrs, ctrl) {
				var $pickemQuestion = $element.find('.pickem-question'),
					$elemToFade = $element.find('.fade-slide'),
					$row = $element.find('.pickem-question-row'),
					$col1 = $row.find('.col-1'),
					$col2 = $row.find('.col-2'),
					$teamName1 = $col1.find('.team-achievements'),
					$teamName2 = $col2.find('.team-achievements'),
					$teamNameImg1 = $col1.find('.team-name-img-wrapper .team-name-img'),
					$teamNameImg2 = $col2.find('.team-name-img-wrapper .team-name-img'),
					MAX_COL_WIDTH = 70,// Percent.
					THRESHOLD = 10,// Percent.
					RGB_BG_COLOR = new RGBService.RGB({r: 224, g: 224, b: 224}),// Background color for unselected area
					RGB_TEAM_COLOR = new RGBService.RGB({r: 85, g: 85, b: 85}, {r: 204, g: 204, b: 204}),// Team Name Color
					startPoint,
					isMoving = false,
					rowStartWidth,
					col1StartWidthPercent;

				if(!ctrl.isActive()){
					RGB_BG_COLOR = new RGBService.RGB({r: 215, g: 215, b: 215});
				}

				if (!$window.document.getElementById('svg-grayscale-filter')) {
					insertSVGGrayScaleFilter();
				}

				replaceImagesWithSVGGrayscaleFilter();

				if(ctrl.isActive()) {
					$swipe.bind($row, {
						start: onStart,
						move: onMove,
						end: onEnd,
						cancel: onCancel
					});
				}

				$col1.on('ClickWithoutMoving', function () {
					selectFirstColumn();
				});

				$col2.on('ClickWithoutMoving', function () {
					selectSecondColumn();
				});

				if (ctrl.isFirstColumnSelected()) {
					onStart({x:0, y:0});
					onEnd({x: 0, y:0});
					onCancel();
				}

				if (ctrl.isSecondColumnSelected()) {
					onStart({x:0, y:0});
					onEnd({x: 0, y:0});
					onCancel();
				}

				if(ctrl.isActive()){
					$col1.add($col2).on({
						mousedown: fixClickOnStart,
						touchstart: fixClickOnStart,
						mouseup: fixClickOnEnd,
						touchend: fixClickOnEnd
					});
				}

				function fixClickOnStart(e) {
					this._left = e.pageX;
					this._top = e.pageY;
				}

				function fixClickOnEnd(e) {
					var diffX = Math.abs(this._left - e.pageX),
						diffY = Math.abs(this._top - e.pageY);

					if (diffX < 5 && diffY < 5) {
						$(this).trigger('ClickWithoutMoving');
					}
				}

				function onStart(point) {
					removeCssAnimation();
					startPoint = point;
					rowStartWidth = $row.width();
					col1StartWidthPercent = 100 * $col1.width() / rowStartWidth;
				}

				function onMove(point) {
					var movePercent = 100 * (point.x - startPoint.x) / rowStartWidth;
					isMoving = true;
					swipeProgress(movePercent);
				}

				function onEnd(point) {
					var wasMoved = isMoving,
						diff = 100 * (point.x - startPoint.x) / rowStartWidth,
						isReachedThreshold = Math.abs(diff) > THRESHOLD;

					isMoving = false;

					if (!wasMoved) {
						return false;
					}

					addCssAnimation();

					if (!isReachedThreshold) {
						if (ctrl.isFirstColumnSelected()) {
							return selectFirstColumn();
						}
						if (ctrl.isSecondColumnSelected()) {
							return selectSecondColumn();
						}
						return reset();
					}

					if (diff > 0) {
						// From left to right
						return selectFirstColumn();
					}

					if (diff < 0) {
						// From right to left
						return selectSecondColumn();
					}
				}

				function onCancel() {
					addCssAnimation();

					if (ctrl.isFirstColumnSelected()) {
						return selectFirstColumn();
					}

					if (ctrl.isSecondColumnSelected()) {
						return selectSecondColumn();
					}

					reset();
				}

				function swipeProgress(value) {
					var newColWidth = col1StartWidthPercent + value,
						animProgress;

					newColWidth = newColWidth > MAX_COL_WIDTH ? MAX_COL_WIDTH :
						newColWidth < (100 - MAX_COL_WIDTH) ? (100 - MAX_COL_WIDTH) : newColWidth;

					animProgress = 100 * (newColWidth - 50) / (MAX_COL_WIDTH - 50);

					//setOpacityForElements(1 - Math.abs(animProgress) / 100);

					$col1.css('background-color', 'rgba(' + RGB_BG_COLOR.join() + ',' + (animProgress / -100) + ')');
					$col2.css('background-color', 'rgba(' + RGB_BG_COLOR.join() + ',' + (animProgress / 100) + ')');

					RGB_TEAM_COLOR.progress(-animProgress);
					$teamName1.css('color', RGB_TEAM_COLOR.toString());

					RGB_TEAM_COLOR.progress(animProgress);
					$teamName2.css('color', RGB_TEAM_COLOR.toString());

					$teamNameImg1.css({
						'transform': 'scale('+ Math.max(1, (1 + (animProgress / 100) * 0.1)) +')'
					});
					$teamNameImg2.css({
						'transform': 'scale('+ Math.max(1, (1 - (animProgress / 100) * 0.1)) +')'
					});

					setGrayScaleFilter($teamNameImg1, -animProgress);
					setGrayScaleFilter($teamNameImg2, animProgress);

					changeColWidth(newColWidth);
				}

				function reset() {
					setOpacityForElements(1);
					$col1.css({
						'width': '',
						'background-color': 'rgba(' + RGB_BG_COLOR.join() + ',0)'
					});
					$col2.css({
						'width': '',
						'background-color': 'rgba(' + RGB_BG_COLOR.join() + ',0)'
					});
					$teamNameImg1.css({
						'transform': 'scale(1)'
					});
					$teamNameImg2.css({
						'transform': 'scale(1)'
					});
					RGB_TEAM_COLOR.progress(0);
					$teamName1.css('color', RGB_TEAM_COLOR.toString());
					$teamName2.css('color', RGB_TEAM_COLOR.toString());
					setGrayScaleFilter($teamNameImg1, 0);
					setGrayScaleFilter($teamNameImg2, 0);
				}

				function setOpacityForElements(value) {
					$elemToFade.css({
						'opacity': value,
						'display': value <= 0 ? 'none' : ''
					});
				}

				function changeColWidth(value) {
					$col1[0].style.width = value + '%';
					$col2[0].style.width = (100 - value) + '%';
				}

				function addCssAnimation() {
					$pickemQuestion.addClass('animation-enabled');
				}

				function removeCssAnimation() {
					$pickemQuestion.removeClass('animation-enabled');
				}

				function selectFirstColumn() {
					addCssAnimation();
					swipeProgress(100);
					ctrl.selectFirstColumn();
					/*
					scope.$apply(function () {
						ctrl.selectFirstColumn();
					});
					*/
				}

				function selectSecondColumn() {
					addCssAnimation();
					swipeProgress(-100);
					ctrl.selectSecondColumn();
					/*
					scope.$apply(function () {
						ctrl.selectSecondColumn();
					});
					*/
				}

				function replaceImagesWithSVGGrayscaleFilter() {
					$teamNameImg1[0].onload = function() {
						var svgElement = replaceImageWithSVGGrayscaleFilter.call(this);
						$teamNameImg1 = $(svgElement);
					};

					$teamNameImg2[0].onload = function() {
						var svgElement = replaceImageWithSVGGrayscaleFilter.call(this);
						$teamNameImg2 = $(svgElement);
					};
				}

				function replaceImageWithSVGGrayscaleFilter() {
					var imageWidth = this.naturalWidth;
					var imageHeight = this.naturalHeight;

					var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
					svg.setAttributeNS(null, 'width', imageWidth);
					svg.setAttributeNS(null, 'height', imageHeight);
					svg.setAttributeNS(null, 'viewBox', '0 0 ' + imageWidth + ' ' + imageHeight);
					svg.setAttributeNS(null, 'class', this.getAttribute('class'));

					var image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
					image.setAttributeNS(null, 'class', 'original-image');
					image.setAttributeNS(null, 'width', imageWidth);
					image.setAttributeNS(null, 'height', imageHeight);
					image.setAttributeNS(null, 'x', 0);
					image.setAttributeNS(null, 'y', 0);
					image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', this.src);

					var imageGrayscale = image.cloneNode();
					imageGrayscale.setAttributeNS(null, 'class', 'gray-image');
					imageGrayscale.setAttributeNS(null, 'filter', 'url(#GrayScaleFilter)');
					imageGrayscale.setAttributeNS(null, 'style', 'opacity: 0');

					svg.appendChild(image);
					svg.appendChild(imageGrayscale);

					this.parentNode.replaceChild(svg, this);

					return svg;
				}

				function setGrayScaleFilter($svg, value) {
					if (!$svg.data('gray-image')) {
						$svg.data('gray-image', $svg.find('.gray-image'));
					}
					if (!$svg.data('original-image')) {
						$svg.data('original-image', $svg.find('.original-image'));
					}

					$svg.data('gray-image').css('opacity', value / 100);

					$svg.data('original-image').css('opacity', value < 99 ? 1 : 0);
				}

				function insertSVGGrayScaleFilter() {
					var div = $window.document.createElement('div');
					div.innerHTML = SVG_GRAYSCALE_FILTER;
					$window.document.body.appendChild(div.firstElementChild);
				}
			}
		};
	}

	pickemQuestion.$inject = [
		'$window',
		'$swipe',
		'RGBService'
	];

	angular.module('pickem').directive('pickemQuestion', pickemQuestion);

})(window.angular, window.jQuery);