(function (angular) {
	'use strict';

	function countdownFlipNumber($log) {
		return {
			restrict: 'AE',
			templateUrl: 'app/countdown/components/countdownFlipNumber/countdown-flip-number.tmpl.html',
			scope: {
				value: '='
			},
			link: function (scope, element) {
				var $thousands = element.find('.thousands'),
					$hundreds = element.find('.hundreds'),
					$tens = element.find('.tens'),
					$units = element.find('.units');

				scope.$watch('value', function(value) {
					value = Number(value);
					if(!isNaN(value)){
						Math.floor(value);
						var partsArray = String(value).split('');
						if(partsArray[partsArray.length - 1]){
                            scope.units = Number(partsArray[partsArray.length - 1]);
                            move($units,scope.units);
						}
						if(partsArray[partsArray.length - 2]){
                            scope.tens = Number(partsArray[partsArray.length - 2]);
                            move($tens,scope.tens);
						}
						if(partsArray[partsArray.length - 3]) {
                            scope.hundreds = Number(partsArray[partsArray.length - 3]);
                            move($hundreds,scope.hundreds);
                        }
                        if(partsArray[partsArray.length - 4]){
                            scope.thousands = Number(partsArray[partsArray.length - 4]);
                            move($thousands,scope.thousands);
						}
					} else {
                        scope.units = 0;
                        move($units,scope.units);
						$log.error('Type error: countdown value must be a number');
					}
				});


				function move($ul, nextValue) {
					if ($ul.data('flipTimerValue') === nextValue) {
						return;
					}
                    if(nextValue === 0){
                        return setNumber(0,$ul);
                    }
                    var increment = 0;
                    var interval = setInterval(function () {
                        if(increment >= nextValue){
                            clearInterval(interval);
                        }
                        setNumber(increment,$ul);
                        increment++;
                    },500);
				}

				function setNumber(number,element) {
                    var $items = element.children(),
                        $current = $items.filter('.active'),
                        $next = $items.not('.active');

                    $next.find('.inn').text(number);

                    $items.removeClass('active').removeClass('before');

                    $current.addClass('before').removeClass('active');

                    $next.addClass('active');

                    element.addClass('play');

                    element.data('flipTimerValue', number);
                }
			}
		};
	}

	angular.module('countdown').directive('countdownFlipNumber',['$log',countdownFlipNumber]);

})(window.angular);