(function (angular) {
  'use strict';

  function gamePreview(
    $window,
    gameService,
    utilityService,
    viewAnimationService,
    $animateCss
  ) {
    return {
      restrict: 'AE',
      templateUrl: 'app/game/components/gamePreview/game-preview.tmpl.html',
      scope: {
        game: '=model',
        onCompleteGame: '&'
      },
      link: function ($scope, $element) {
        var iframe = $element.find('iframe')[0],
          gameWindow = iframe.contentWindow,
          gamePreviewLoader = $element.find('.game-preview-pre-load')[0],
          model = angular.copy($scope.game),
          timer,
          timerForHeight,
          EMPTY_PAGE = 'about:blank',
          GAME_ORIGIN = '*',
          GAME_SETTINGS_EVENT = 'OC_GAME_SETTINGS',
          GAME_LOADED_EVENT = 'OC_GAME_LOADED',
          GAME_COMPLETE_EVENT = 'OC_GAME_COMPLETE',
          GET_REWARD_ON_DEMAND_EVENT = 'OC_GET_REWARD_ON_DEMAND',
          ON_GET_REWARD_ON_DEMAND_EVENT = 'OC_ON_GET_REWARD_ON_DEMAND',
          GAME_FORCE_RESIZE_EVENT = 'OC_GAME_FORCE_RESIZE',
          ON_CATCH_USER_ACTION_EVENT = 'OC_ON_CATCH_USER_ACTION';

        //To Fix iOS issue http://stackoverflow.com/questions/23083462/how-to-get-an-iframe-to-be-responsive-in-ios-safari
        if (utilityService.isMobile()) {
          iframe.setAttribute('scrolling', 'no');
        }

        iframe.src = gameService.getGameResourceURL(model.gameType);
        iframe.onload = startGame;

        onResizeWindow();

        $window.addEventListener('resize', onResizeWindow, false);
        $window.addEventListener('orientationchange', onResizeWindow, false);
        $window.addEventListener('message', onReceiveMessage, false);

        $scope.$on('$destroy', destroy);

        function startGame() {
          var inputParams;

          if (~iframe.src.indexOf(EMPTY_PAGE)) {
            //If we reload game and applied IE fix
            return false;
          }

          inputParams = gameService.getGameInputParams(model);

          sendEventToGame(GAME_SETTINGS_EVENT, inputParams);

          onResizeWindow();
        }

        function sendEventToGame(eventName, data) {
          gameWindow.postMessage(
            {
              eventName: eventName,
              message: data
            },
            GAME_ORIGIN
          );
        }

        function onReceiveMessage(event) {
          var origin = event.origin || event.originalEvent.origin,
            eventName = event.data.eventName,
            data = event.data.message;

          if (!/.+/.test(origin)) {
            return false;
          }

          switch (eventName) {
            case GAME_LOADED_EVENT:
              onLoadedGame(data);
              break;
            case GET_REWARD_ON_DEMAND_EVENT:
              getRewardOnDemand(data);
              break;
            case GAME_COMPLETE_EVENT:
              onCompleteGame(data);
              break;
            case ON_CATCH_USER_ACTION_EVENT:
              onCatchUserActionInGameWindow(data);
              break;
          }
        }

        function onLoadedGame() {
          gamePreviewLoader.hidden = true;

          $animateCss($element, {
            addClass: 'loaded'
          })
            .start()
            .then(function () {
              if (viewAnimationService.enterVideoUrl) {
                viewAnimationService
                  .showVideoInstead('game', viewAnimationService.enterVideoUrl)
                  .then(function () {
                    viewAnimationService.startEnterTransition($element);
                  });
              } else {
                viewAnimationService.startEnterTransition($element);
              }
            });
        }

        function getRewardOnDemand(data) {
          gameService.getReward(model.cimGameId, data).then(function (reward) {
            sendEventToGame(ON_GET_REWARD_ON_DEMAND_EVENT, reward);
          });
        }

        function onCompleteGame() {
          $scope.onCompleteGame();
        }

        function onCatchUserActionInGameWindow(gameWindowEvent) {
          //Just trigger event in app window
          var event;

          if (document.createEvent) {
            event = $window.document.createEvent('Event');
            event.initEvent(gameWindowEvent.type, false, false);
            parent.postMessage('GameAction', '*');
          } else {
            event = new $window.Event(gameWindowEvent.type);
          }

          $window.document.documentElement.dispatchEvent(event, {
            bubbles: false,
            cancelable: false
          });
        }

        function fixIframeIssue() {
          //To fix iframe issue in IE
          if (utilityService.isIE) {
            iframe.src = EMPTY_PAGE;
          }
        }

        function onResizeWindow() {
          setElementSize();

          $window.scrollTo(0, getPossiblePageScrollX());

          startTimerForHeight();

          $window.clearTimeout(timer);

          timer = $window.setTimeout(function () {
            stopTimerForHeight();
          }, 2000);

          $window.setTimeout(function () {
            sendEventToGame(GAME_FORCE_RESIZE_EVENT);
          }, 0);
        }

        function setElementSize() {
          $element[0].style.width = '';
          $element[0].style.height = '';
          $element[0].style.width = $window.innerWidth + 'px';
          $element[0].style.height = $window.innerHeight + 'px';
        }

        function startTimerForHeight() {
          stopTimerForHeight();
          timerForHeight = $window.setInterval(setElementSize, 250);
        }

        function stopTimerForHeight() {
          $window.clearInterval(timerForHeight);
        }

        function destroy() {
          $window.removeEventListener('resize', onResizeWindow, false);
          $window.removeEventListener(
            'orientationchange',
            onResizeWindow,
            false
          );
          $window.removeEventListener('message', onReceiveMessage, false);
          fixIframeIssue();
          stopTimerForHeight();
        }

        function getPossiblePageScrollX() {
          return $window.document.body.scrollHeight - $window.innerHeight;
        }
      }
    };
  }

  gamePreview.$inject = [
    '$window',
    'gameService',
    'utilityService',
    'viewAnimationService',
    '$animateCss'
  ];

  angular.module('game').directive('gamePreview', gamePreview);
})(window.angular);
