(function (angular) {
    'use strict';

    function disableAutoFill(/*$timeout*/) {
        return {
            restrict: 'A',
            link: function(scope, element) {
                if (disableAutoFill && element && element[0]) {
                    var tagName = element[0] && element[0].tagName;

                    switch (tagName) {
                        case 'INPUT':
                            var form = findClosestForm(element[0]);
                            if (form) {
                                form = angular.element(form);
                                form.attr('autocomplete', 'off');
                                element.attr('autocomplete', 'off');
                            }
                            break;
                        case 'FORM':
                            element.attr('autocomplete', 'off');
                    }
                }

                function findClosestForm(elem) {
                    for( ; elem && elem !== document; elem = elem.parentNode ) {
                        if (elem.matches('form')) return elem;
                    }
                    return null;
                }
            }
        };
    }

    disableAutoFill.$inject = [
        '$timeout'
    ];

    angular.module('common').directive('disableautofill', disableAutoFill);

})(window.angular);