(function (angular) {

	function ThreeFieldLeaderboardCtrl() {

	}

	ThreeFieldLeaderboardCtrl.$inject = [];

	function threeFieldLeaderboard() {
		return {
			controller: ThreeFieldLeaderboardCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '=',
				countdownOptions: '=',
				countdownModelId: '=countdownId',
				onElapse: '&',
				isCountdownShown: '&'
			},
			templateUrl: 'app/dashboards/components/leaderboard/three-field-leaderboard.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('threeFieldLeaderboard', threeFieldLeaderboard);

})(window.angular);