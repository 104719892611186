(function (angular) {
	'use strict';

	function BehaviorEffectService($document, $rootScope, $compile, $q, apiResource, utilityService, defaultOptions) {
		var service = {},
			$body = $document.find('body'),
			stack = [];

		service.addEffect = show;
		service.removeEffect = remove;
		service.removeAll = removeAll;

		return service;

		function show(options) {
			var deferred = $q.defer(),
				opts = angular.extend({}, defaultOptions, options),
				html,
				element,
				scope,
				instance;


			if (!opts.effectType || opts.effectType === 'None') {
				deferred.resolve();
				return deferred.promise;
			}

			scope = $rootScope.$new(true);

			scope.options = opts;

			scope.$close = function() {
				remove(instance);
			};

			if(opts.effectType == 'Leaderboard') {
				getDashboard(opts.leaderboardId).then(function(dashboard) {
					scope.options.dashboard = dashboard;
					html = angular.element('<behavior-effect options="options" $close="$close()"></behavior-effect>');
					element = $compile(html)(scope);

					$body.append(element);

					instance = {
						element: element,
						scope: scope,
						deferred: deferred
					};
					stack.push(instance);
				}, function() {
					removeAll();
					deferred.resolve();
				});
			} else {
				html = angular.element('<behavior-effect options="options" $close="$close()"></behavior-effect>');
				element = $compile(html)(scope);

				$body.append(element);

				instance = {
					element: element,
					scope: scope,
					deferred: deferred
				};

				stack.push(instance);
			}


			return deferred.promise;
		}

		function remove(instance) {
			var index = stack.indexOf(instance);

			if (index === -1) return;

			stack.splice(index, index);

			instance.element.remove();

			instance.scope.$destroy();

			instance.deferred.resolve();
		}

		function removeAll() {
			for (var i = stack.length - 1; i >= 0; i--) {
				remove(stack[i]);
			}
		}

		function getDashboard(token) {
			return apiResource
			.get('api/dashboard/' + token, {
				params: {
					userName: null,
					password: null
				}
			})
			.then(function (dashboard) {

			dashboard.dToken = token;

			dashboard.feed = utilityService.parseSafeJSON(dashboard.feed) || service.feedTemplate;
			dashboard.uiSettings = utilityService.parseSafeJSON(dashboard.uiSettings);

			dashboard.boxes.forEach(function (box) {
				if (box.uiSettings) {
					box.uiSettings = utilityService.parseSafeJSON(box.uiSettings);
				}
				if (box.data) {
					box.data = utilityService.parseSafeJSON(box.data);
				}
				if (box.values) {
					box.values.forEach(function (value) {
						if (typeof value.name == 'string') {
							value.name = value.name.trim().replace(/\s+/, ' ');
						}
					});
				}
			});

			//setCurrentDashboard(dashboard);

			return dashboard;
		});
		}
	}

	function provider() {
		var that = this;

		that.defaultOptions = {
			effectDuration: 0,
			effectSound: null,
			effectType: 'None',
			showDismissButton: true,
			showReward: false,
			showScore: false,
			cssOverride: '',
			reward: {}
		};

		that.$get = ['$document', '$rootScope', '$compile', '$q', 'apiResource', 'utilityService', function ($document, $rootScope, $compile, $q, apiResource, utilityService) {
			return new BehaviorEffectService($document, $rootScope, $compile, $q, apiResource, utilityService, that.defaultOptions);
		}];
	}

	angular.module('behavior').provider('behaviorEffectService', provider);

})(window.angular);
