(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.init = function (ngModel, answers) {
			if (!ngModel || !answers) return;

			vm.inputPattern && (vm.regexp = new RegExp(vm.inputPattern));

			vm.placeholderText = vm.placeholderText || 'Your answer';

			vm.type = 'text';
			if(answers.subtype === 'Email'){
				vm.inputPattern = vm.inputPattern ||  '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$';
				vm.regexp = new RegExp(vm.inputPattern) || '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$';
				vm.type = 'email';
			}

			if(answers.subtype === 'PhoneNumber'){
				vm.inputPattern = vm.inputPattern || (vm.displayCountryCodeDropdown ? '[0-9]{9,10}' :'[\+][0-9 /(/)\s-]{10,16}');
				vm.regexp = new RegExp(vm.inputPattern) || (vm.displayCountryCodeDropdown ? /[0-9]{9,10}$/ : /^\+[0-9 /(/)\s-]{10,16}$/);
				vm.type = 'tel';
			}

			vm.getLabel = function (choice) {
				return answers.getLabel(choice);
			};

			vm.onChange = function (value) {
				ngModel.$setViewValue(value);
				vm.onChangeModel({ $inputType: 'text' });
				if(vm.regexp) {
					var correct = true;
					if (vm.isPattern) {
						correct = (value.value || '').match(vm.regexp);
					}
					if (!correct || (vm.isRequired)) {
                        vm.form.$error[vm.name] = true;
                    }else{
						delete vm.form.$error[vm.name];
					}
				}

                vm.useVerification === 1 && vm.onChangeVerify(vm.verify);
			};

            vm.onChangeVerify = function (value) {
                if (value !== vm.model.value) {
                    vm.form.$error['verif_' + vm.name] = true;
                } else {
                    delete vm.form.$error['verif_' + vm.name];
                }
            };

			vm.modelParser = function (value) {
				if (!!vm.forceUppercase && value && value.toUpperCase) {
					value = value.toUpperCase();
				}
				return value;
			};

			ngModel.$render = function () {
				// Specify how UI should be updated when new question was applied
				vm.model = ngModel.$viewValue;
			};
		};
	}

    AnswerCtrl.$inject = [];

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-free-form-single-line.tmpl.html',
			require: ['answerFreeFormSingleLine', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
				inputPattern: '=',
				type: '=',
				enableAutoFocus: '=answerEnableAutoFocus',
				forceUppercase: '=answerForceUppercase',
				answerBackgroundColor: '=',
                displayCountryCodeDropdown: '=',
				country: '=',
				placeholderText: '=',
                answerDisabled: '=',
                useVerification: '=',
				isPattern: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);
			}
		};
	}

	angular.module('answer').directive('answerFreeFormSingleLine', answer);

})(window.angular);