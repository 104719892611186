(function (angular) {
  'use strict';

  function ReceiptCtrl(authService, $filter) {
    var vm = this;
    vm.clientLogoUrl = authService.authentication.clientLogoUrl;
    vm.isTermsShown = false;
    vm.toggleTerms = toggleTerms;
    vm.posLoaded =
      !vm.model.combinedCode.codeValue ||
      vm.model.combinedCode.type === 'plaintext';
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = authService.dateTimeFormat();
    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[1]
            : date_components[0];
        var month =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[0]
            : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }

    function toggleTerms() {
      vm.isTermsShown = !vm.isTermsShown;
    }
  }

  ReceiptCtrl.$inject = ['authService', '$filter'];

  function receipt($timeout) {
    return {
      restrict: 'AE',
      controller: ReceiptCtrl,
      controllerAs: 'reward',
      bindToController: true,
      templateUrl: 'app/common/directives/receipt.tmpl.html',
      replace: false,
      scope: {
        model: '=',
        hideRewardName: '=',
        posLoaded: '='
      },
      link: function (scope, ele) {
        var $descriptionTextEl = angular.element(
          ele[0].querySelector('.description-text')
        );

        $timeout(function () {
          resizeText($descriptionTextEl);
          ele.children().addClass('visible-print');
          ele.addClass('visible');
        });

        function resizeText($elem) {
          var fontsize,
            fontstep = 0.5;

          if (
            $elem.height() > $elem.parent().height() ||
            $elem.width() > $elem.parent().width()
          ) {
            fontsize = +$elem.css('font-size').replace(/px/, '');
            $elem.css('font-size', fontsize - fontstep + 'px');
            resizeText($elem);
          }
        }
      }
    };
  }

  receipt.$inject = ['$timeout'];

  angular.module('common').directive('receipt', receipt);
})(window.angular);
