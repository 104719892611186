(function (angular) {
	'use strict';

	function RedeemService($window, sessionService, apiSessionResource, utilityService, $q) {
		var service = {};

		service.rewardData = null;
		service.getRedeemCode = getRedeemCode;
		service.goToExternalUrl = goToExternalUrl;
		service.complete = complete;
		service.preloadConfigDataSafely = preloadConfigDataSafely;

		return service;

		function getRedeemCode(options) {
			return apiSessionResource.get('redeem', options)
				.then(function (response) {
					if (response.dynamicConfiguration) {
						response.dynamicConfiguration.labels = utilityService.parseSafeJSON(response.dynamicConfiguration.labels);
					}
					window.parent.postMessage(JSON.stringify(response), '*');
					return service.rewardData = response;
				});
		}

		function preloadConfigDataSafely(model) {
			var bg = model.dynamicConfiguration.backgroundImage,
				headerBG = model.dynamicConfiguration.headerBackgroundImage,
				headerImage = model.dynamicConfiguration.headerImage,
				promises = [];

			function cb(data) {
				return data;
			}

			if (bg) {
				promises.push( utilityService.loadImage(bg).then(cb, cb) );
			}

			if (headerImage) {
				promises.push( utilityService.loadImage(headerImage).then(cb, cb) );
			}

			if (headerBG) {
				promises.push( utilityService.loadImage(headerBG).then(cb, cb) );
			}

			if (promises.length) {
				return $q.all(promises);
			}

			return $q.when(promises);
		}

		function goToExternalUrl(url) {
			return $window.location.href = url;
		}


		function complete() {
			return sessionService.finishCampaign();
		}
	}

	RedeemService.$inject = [
		'$window',
		'sessionService',
		'apiSessionResource',
		'utilityService',
		'$q'
	];

	angular.module('redeem').factory('redeemService', RedeemService);

})(window.angular);