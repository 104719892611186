(function (angular) {

    'use strict';

    function hintService($log, localStorageService, ngIntroService, $filter, $timeout) {

        var service = {},
            $translate = $filter('translate'),
            options = {
                steps:[],
                showStepNumbers: false,
                tooltipPosition: 'auto',
                showBullets: false,
                scrollToElement: false,
                scrollTo: 'tooltip',
                exitOnEsc: false,
                exitOnOverlayClick: true,
                disableInteraction: false,
                nextLabel: $translate('Next'),
                prevLabel: $translate('Go Back'),
                skipLabel: $translate('Skip'),
                doneLabel: $translate('Got it!')
            };

        service.addHints = addHints;
        service.runTutorial = runTutorial;
        service.clearHints = clearHints;
        service.introService = ngIntroService;
        
        return service;

        function isValidStep(steps,newStep) {
            for(var i = 0; i<steps.length; i++) {
                if(steps[i].element === newStep.element){
                    return false;
                }
                if(i === steps.length - 1){
                    return true;
                }
            }
        }

        function addHints(steps) {
            if (angular.isObject(steps)) {
                if(!options.steps.length){
                    options.steps.push(steps);
                } else {
                    if(isValidStep(options.steps,steps)){
                        options.steps.push(steps);
                    }

                }
            } else if (angular.isArray(steps)) {
                options.steps = steps;
            } else {
                return $log.error('Syntax error: tutorial step options must be an object or an array');
            }
        }

        function clearHints() {
            options.steps = [];
        }

        function runTutorial(indicator) {
            $timeout(function () {
                if (options.steps.length) {
                    if (indicator) {
                        if (localStorageService.get(indicator) === null) {
                            ngIntroService.setOptions(options);
                            ngIntroService.start();

                            localStorageService.set(indicator, true);
                        }
                    } else {
                        ngIntroService.setOptions(options);
                        ngIntroService.start();
                    }
                }
            }, 1000);
        }

    }

    hintService.$inject = [
        '$log',
        'localStorageService',
        'ngIntroService',
        '$filter',
        '$timeout'
    ];

    angular.module('common').factory('hintService', hintService);

})(window.angular);