(function (angular) {
    'use strict';

    function LobbyCtrl($uibModal, lobbyService, overlayService) {
        var vm = this;

        vm.settings.lobbyMode = vm.settings.lobbyMode ? vm.settings.lobbyMode : 'Standard';
        vm.settings.showCancelButton = vm.settings.showCancelButton === undefined ? true : vm.settings.showCancelButton;
        vm.showCampaigns = false;

        vm.showRewardModal = function (campaign) {

            if (campaign) {

                var view;

                var rewardModal = function (reward) {
                    var modal = $uibModal
                        .open({
                            templateUrl: 'app/lobby/components/lobbyCampaigns/coupon/reward-modal.tmpl.html',
                            controller: ['reward', 'settings', function (reward, settings) {
                                var vm = this;
                                view = vm;
                                vm.model = reward;
                                vm.settings = settings;


                            }],
                            controllerAs: 'ctrl',
                            backdrop: 'static',
                            windowClass: 'reward-modal',
                            keyboard: false,
                            resolve: {
                                reward: function () {
                                    return reward;
                                },
                                settings: function () {
                                    return vm.settings;
                                }
                            }
                        });

                    modal.rendered.then(function () {
                        var e = document.querySelector('#truncated-terms');
                        if (e && (e.offsetWidth < e.scrollWidth)) {
                            view.isTruncated = true;
                        }
                    });

                    return modal.result;
                };

                if (!campaign.knownReward) {
                    var scheduleId = campaign.scheduleId || (campaign.schedule && campaign.schedule.scheduleId);

                    var req = lobbyService
                        .startCampaignWithPassThrough(campaign.campaignId, scheduleId, lobbyService.aToken, lobbyService.accessRequestId);
                    var promise = req
                        .then(function (data) {

                            if (data) {
                                var reward;
                                if (data && angular.isObject(data.reward)) {
                                    campaign.knownReward = data.reward;
                                    reward = campaign.knownReward;
                                }

                                if (data.stepType === 'RewardDelivery') {
                                    reward = null;
                                }

                                if (reward) {
                                    return rewardModal(reward);
                                }
                            } else {
                                return rewardModal(campaign.knownReward);
                            }

                            return null;
                        });

                    overlayService.show(req);
                } else {
                    return rewardModal(campaign.knownReward);
                }

                return promise;
            }

            return null;
        };
    }

    LobbyCtrl.$inject = ['$uibModal', 'lobbyService', 'overlayService'];

    function lobby() {
        return {
            restrict: 'AE',
            controller: LobbyCtrl,
            controllerAs: 'lobby',
            bindToController: true,
            templateUrl: 'app/lobby/components/lobbyCampaigns/lobby-campaigns.tmpl.html',
            scope: {
                campaigns: '=lobbyCampaignsList',
                settings: '=lobbyCampaignsSettings',
                startCampaign: '&lobbyCampaignsStartCampaign',
                cancel: '&lobbyCampaignsCancel'
            },
            link: function (scope) {
                if (scope.lobby.settings.lobbyMode === 'Coupon' &&
                    scope.lobby.campaigns &&
                    scope.lobby.campaigns.length === 1 &&
                    scope.lobby.settings.displaySingleCampaignAsCoupon && scope.lobby.settings.openSingleCouponAutomatically) {
                    scope.lobby.modalResult = scope.lobby.showRewardModal(scope.lobby.campaigns[0]);
                    scope.lobby.modalResult && scope.lobby.modalResult.then(function () {
                        scope.lobby.showCampaigns = true;
                    }, function () {
                        scope.lobby.showCampaigns = true;
                    });

                } else {
                    scope.lobby.showCampaigns = true;
                }
            }
        };
    }

    angular.module('lobby').directive('lobbyCampaigns', lobby);

})(window.angular);