(function (angular) {
	'use strict';

	function config($httpProvider) {
		$httpProvider.interceptors.push('errorSessionInterceptorService');
	}

	config.$inject = [
		'$httpProvider'
	];

	angular.module('session').config(config);

})(window.angular);