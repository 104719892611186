(function (angular) {
    'use strict';

    function LobbyAccountActivationCtrl($sce, authService, apiSettings) {
        var vm = this;

        var authData = authService.getAuthenticationData();
        vm.accountActivationUrl = $sce.trustAsResourceUrl(apiSettings.playerManagementUri + authData.refreshToken + '?destination=covert/account-activation)');

    }

    LobbyAccountActivationCtrl.$inject = ['$sce', 'authService', 'apiSettings'];

    function lobbyAccountActivation() {
        return {
            restrict: 'AE',
            controller: LobbyAccountActivationCtrl,
            controllerAs: 'lobby',
            bindToController: true,
            templateUrl: 'app/lobby/components/lobbyPersonLookup/lobby-account-activation.tmpl.html'
        };
    }

    angular.module('lobby').directive('lobbyAccountActivation', lobbyAccountActivation);

})(window.angular);