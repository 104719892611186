(function (angular) {
	'use strict';

	function subscriptionService($q, $log, apiResource) {
		var service = {};

        service.getSubscription = getSubscription;
        service.previewTemplate = previewTemplate;
        service.resendEmail = resendEmail;

        service.userPermissions = null;

		return service;

        function getSubscription(email, redeemToken) {
            return apiResource.get('api/subscription/lookup', {
                params: {
                    email: email,
                    redeemUserToken: redeemToken || service.userPermissions.redeemUserToken
                }
            }).then(function (resp) {
                return resp;
            });
        }

        function previewTemplate(subscriptionRecipientId) {
            return apiResource.get('api/subscription/previewTemplate', {
                params: {
                    subscriptionRecipientId: subscriptionRecipientId
                }
            }).then(function (resp) {
                return resp;
            });
        }

        function resendEmail(subscriptionRecipientId) {
            return apiResource.get('api/subscription/reSendMailChimpNotification', {
                params: {
                    subscriptionRecipientId: subscriptionRecipientId
                }
            }).then(function (resp) {
                return resp;
            });
        }
	}

    subscriptionService.$inject = [
		'$q',
		'$log',
		'apiResource'
	];

    angular.module('email').factory('subscriptionService', subscriptionService);

})(window.angular);
