(function (angular) {
  'use strict';

  function LobbyCtrl($filter, authService) {
    var vm = this;

    vm.showRewards = showRewards;
    vm.hideRewards = hideRewards;
    vm.showAbout = showAbout;
    vm.hideAbout = hideAbout;
    vm.showTerms = showTerms;
    vm.hideTerms = hideTerms;
    vm.isLobbyItemDisabled = isLobbyItemDisabled;
    vm.isLobbyItemPaused = isLobbyItemPaused;
    vm.formatDate = formatDate;

    function formatDate(date) {
      var dateOut = new Date(date);
      return dateOut;
    }

    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = !vm.settings.uiConfiguration
      .shortFormatForCampaignStartEndDates
      ? authService.dateTimeFormat()
      : 'MMM dd, yyyy';
    vm.dateSort = authService.dateTimeFormat();

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateSort === 'MM/dd/yy' ? date_components[1] : date_components[0];
        var month =
          vm.dateSort === 'MM/dd/yy' ? date_components[0] : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }

    function showRewards(campaign) {
      campaign.rewardsIsShown = true;
      hideAbout(campaign);
      hideTerms(campaign);
    }

    function hideRewards(campaign) {
      campaign.rewardsIsShown = false;
    }

    function showAbout(campaign) {
      campaign.aboutIsShown = true;
      hideTerms(campaign);
      hideRewards(campaign);
    }

    function hideAbout(campaign) {
      campaign.aboutIsShown = false;
    }

    function showTerms(campaign) {
      campaign.termsIsShown = true;
      hideRewards(campaign);
      hideAbout(campaign);
    }

    function hideTerms(campaign) {
      campaign.termsIsShown = false;
    }

    function isLobbyItemDisabled(campaign) {
      return (
        vm.settings.isChallengeLobby &&
        campaign.challengeStatus !== vm.settings.challengeCampaignActiveStatus
      );
    }

    function isLobbyItemPaused(campaign) {
      return (
        (campaign.status === 'Live' || campaign.status === 'Pending') &&
        GetDisabledStatus(campaign)
      );
    }

    function GetDisabledStatus(campaign) {
      if (!campaign.isDisabled) return null;

      switch (campaign.isDisabledType) {
        case 'NotQualified':
          return 'Not Qualified';
        default:
          return 'Coming Soon';
      }
    }
  }

  LobbyCtrl.$inject = ['$filter', 'authService'];

  function lobby() {
    return {
      restrict: 'AE',
      controller: LobbyCtrl,
      controllerAs: 'lobbyStandard',
      bindToController: true,
      templateUrl:
        'app/lobby/components/lobbyCampaigns/standard/lobby-standard-campaigns.tmpl.html',
      scope: {
        campaigns: '=campaigns',
        settings: '=lobbySettings',
        startCampaign: '&startCampaign',
        cancel: '&cancel'
      }
    };
  }

  angular.module('lobby').directive('lobbyStandardCampaigns', lobby);
})(window.angular);
