(function (angular) {
	'use strict';

	function config($stateProvider,
					$urlRouterProvider,
					utilityServiceProvider,
					localStorageServiceProvider,
					appModeServiceProvider,
					$translateProvider) {

		var searchParams = utilityServiceProvider.parseSearchQuery(),
			aToken = searchParams.atoken,
			isPreview = utilityServiceProvider.isPreviewToken(aToken);

		//load campaign mode using aToken
		appModeServiceProvider.setATokenMode();

		localStorageServiceProvider.setPrefix(aToken + (searchParams.externalid ? '_' + searchParams.externalid : ''));

		//Translation settings
		$translateProvider.useStaticFilesLoader({
			prefix: 'locales/locale_',
			suffix: '.json'
		});
		$translateProvider.preferredLanguage('en-US');

		$stateProvider
			.state('campaignModeAc', {
				url: '/',
				controller: ['$state', 'sessionService', 'campaignModeAcService', 'authService', '$interval', function ($state, sessionService, campaignModeAcService, authService, $interval) {
					if (!campaignModeAcService.isLoggedIn()) {
						// Start Access Control
                        var authentication = authService.getAuthenticationData();
                        if (!authentication || !authentication.token) {
                            var i = $interval(function() {
                                authentication = authService.getAuthenticationData();
                                if (authentication && authentication.token) {
                                    $interval.cancel(i);
									$state.go('campaignModeAcInteraction');
								}
                            }, 10);
                        } else {
                            $state.go('campaignModeAcInteraction');
						}
					} else if (sessionService.isSessionKeyExist()) {
						// Get Current Step
						$state.go('campaignModeStart');
					} else {
						sessionService.setPreviewFlag(isPreview);
					}
				}],
				resolve: {
					accessControlData: ['campaignModeAcService', function (campaignModeAcService) {
						return campaignModeAcService.getCampaignAccessControlIfoByAToken(aToken);
					}]
				}
			})
			.state('campaignModeAcInteraction', {
				templateUrl: 'app/modeCampaignAc/layout/campaign-mode-ac-interaction.html',
				resolve: {
					interaction: ['accessControlService', 'campaignModeAcService', function (accessControlService, campaignModeAcService) {
						return accessControlService.startAccessControlForCampaign(campaignModeAcService.accessControlId, campaignModeAcService.isPreviewMode);
					}]
				}
			})

			.state('campaignModeStart', {
				resolve: {
					getSessionStep: ['sessionService', 'authService', function (sessionService, authService) {
						getSessionStep(sessionService, authService);
					}]
				}
			});

		$urlRouterProvider.otherwise('/');

		function getSessionStep(sessionService, authService) {
			if (!authService.isLoginInProcess() &&
				sessionService.isSessionKeyExist() && !sessionService.isSessionStarting()) {

				sessionService.getCurrentStep();
			}
		}

	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider',
		'utilityServiceProvider',
		'localStorageServiceProvider',
		'appModeServiceProvider',
		'$translateProvider'
	];

	angular.module('campaignModeAc').config(config);

})(window.angular);