(function (angular) {
    'use strict';

    function SwapRewardCtrl($uibModal, notifier, swapRewardService) {
        var vm = this;


        vm.showSwapRewardModal = showSwapRewardModal;
        vm.selectedReward = null;
        vm.select = select;
        vm.isSelectedReward = isSelectedReward;
        vm.leftArrow = false;
        vm.rightArrow = false;
        vm.popoverPlacement = 'left';
        vm.choose =  choose;


        function select(reward) {
            vm.list.forEach(function (reward) {
                reward.isOpen = false;
            });
            vm.selectedReward = reward;
        }

        function choose() {
            if (vm.isLoading) {
                return;
            }

            vm.isLoading = true;
            swapRewardService.swapReward(vm.selectedReward.id).then(function (data) {
					vm.selectedReward.swapData = data;
                vm.selectedReward.combinedCode = {codeValue: data.rewardCode};
                var $rewardTitle = angular.element(document.querySelector('.reward-title'));
                $rewardTitle.text(vm.selectedReward.name);
            }).finally(function () {
                vm.isLoading = false;
                vm.onSwapComplete({newReward: vm.selectedReward});
            });
        }


        function showSwapRewardModal() {
            if (!vm.selectedReward) {
                return notifier.warning('Please select your reward.');
            }

            return $uibModal.open({
                animation: true,
                templateUrl: 'app/swapReward/components/swap/swap-reward-modal.tmpl.html',
                controller: 'SwapRewardModalCtrl',
                controllerAs: 'swap',
                resolve: {
                    reward: function () {
                        return vm.selectedReward;
                    }
                }
            }).result.then(function () {
                }, function () {
                    vm.cancelSelect();
                });
        }

        function isSelectedReward(reward) {
            return vm.selectedReward && vm.selectedReward.id === reward.id;
        }
    }

    SwapRewardCtrl.$inject = [
        '$uibModal',
        'notifier',
        'swapRewardService',
        '$window'
    ];

    function swapReward($timeout) {
        return {
            restrict: 'AE',
            controller: SwapRewardCtrl,
            controllerAs: 'swap',
            bindToController: true,
            templateUrl: 'app/redeem/components/swap/swap-reward.tmpl.html',
            scope: {
                list: '=swapRewardList',
                onSwapComplete: '&'

            },
            link: function (scope, element, attrs, ctrl) {
                /*
                var $swapList = $('.swap-reward-list');

                $swapList.on("scroll", utilityService.debounce(displayArrows, 250));
                */
    
                $timeout(function () {
                    /*
                    if ($swapList.width() < $swapList[0].scrollWidth) {
                        ctrl.rightArrow = true;
                    }
                    */
                    if (window.innerWidth < 768) {
                        ctrl.popoverPlacement = 'top';
                    }
                });

            }
        };
    }

    swapReward.$inject = [
        '$timeout'
    ];

    angular.module('redeem').directive('swapReward', swapReward);

})(window.angular);