(function (angular) {
	'use strict';

	function MediaResourceCtrl() {
		var vm = this;

		vm.isImageMediaTypeActive = isImageMediaTypeActive;
		vm.isVideoMediaTypeActive = isVideoMediaTypeActive;

        vm.mediaTypeProp = vm.mediaTypeField || 'mediaType';
        vm.mediaUrlProp = vm.mediaUrlField || 'mediaUrl';

        function isImageMediaTypeActive(model) {
            return model && model[vm.mediaTypeProp] && model[vm.mediaTypeProp].toUpperCase() === 'IMAGE';
		}

		function isVideoMediaTypeActive(model) {
            return model && model[vm.mediaTypeProp] && model[vm.mediaTypeProp].toUpperCase() === 'VIDEO';
		}
	}

	function mediaResource() {
		return {
			restrict: 'AE',
			controller: MediaResourceCtrl,
			controllerAs: 'media',
			bindToController: true,
			templateUrl: 'app/answer/components/mediaResource/media-resource.tmpl.html',
			scope: {
                model: '=',
                mediaTypeField: '@', //can be null
                mediaUrlField: '@', //can be null
				type: '@',
				onPlay: '&',
				onEnded: '&'
			}
		};
	}

	angular.module('answer').directive('mediaResource', mediaResource);

})(window.angular);