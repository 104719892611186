(function (angular) {
	'use strict';

	function ForgotPasswordCtrl(authService, $state, formService, errorService, $uibModal, $stateParams) {
		var vm = this;
		vm.email = $stateParams.email || null;
		vm.resetPassword = sendPasswordResetRequest;
		vm.comebackState = $stateParams.comebackState;
		vm.back = back;

		function back() {
			return $state.go(vm.comebackState);
        }

		function sendPasswordResetRequest(formInstance) {
			formService.makeFieldsDirty(formInstance);

			if (formInstance.$valid && !vm.isLoading) {
				vm.isLoading = true;
				vm.error = null;

				authService.sendResetPasswordRequest(vm.email, 'forgotForm')
					.then(function (data) {
						vm.error = null;
						if(data && data.directReset) {
							$uibModal.open({
								templateUrl: 'app/auth/components/resetPasswordById/reset-password-by-id.tmpl.html',
								controller: 'resetUserPasswordById',
								controllerAs: 'resetPass',
								backdrop: 'static',
								keyboard: false,
								resolve: {
									userName: function () {
										return vm.email;
									}
								}
							}).result.then(function () {
								$state.go('login');
							});
						} else {
                            errorService.lastErrorMessage = data && data.message && data.message.message;
							$state.go('passwordReset');
						}
					}, function (error) {
						vm.error = errorService.parseErrorsToList(error);
					}).finally(function () {
						vm.isLoading = false;
					});
			}
		}
	}

	ForgotPasswordCtrl.$inject = [
		'authService',
		'$state',
		'formService',
		'errorService',
		'$uibModal',
		'$stateParams'
	];

	function forgotUserPassword() {
		return {
			restrict: 'AE',
			templateUrl: 'app/auth/components/forgotPassword/forgot-password.tmpl.html',
			controllerAs: 'forgotPass',
			controller: ForgotPasswordCtrl
		};
	}

	angular.module('auth').directive('forgotUserPassword', forgotUserPassword);

})(window.angular);