(function (angular) {
    'use strict';

    function PickemModalCtrl($uibModalInstance, pickemService, notifier) {
        var vm = this;
        vm.pickemInformUser = pickemInformUser;

        function pickemInformUser() {
            pickemService.pickemInformUser().then(function() { 
                notifier.success('The link to your picks was sent to you.');
                complete();
            }, function() {
                notifier.error('There was a problem sending the link.');
            });
        }

        vm.close = close;
        vm.complete = complete;

        function complete() {
            return $uibModalInstance.close();
        }

        function close() {
            return $uibModalInstance.dismiss();
        }
    }

    PickemModalCtrl.$inject = [
        '$uibModalInstance',
        'pickemService',
        'notifier'
    ];


    angular.module('redeem').controller('PickemModalCtrl', PickemModalCtrl);

})(window.angular);