(function (angular) {
	'use strict';

	function RGBService($window) {

		function RGB(start, end) {
			this.start = start || {r: 0, g: 0, b: 0};
			this.end = end || {r: 0, g: 0, b: 0};
			this.r = start.r;
			this.g = start.g;
			this.b = start.b;
		}

		RGB.prototype.join = function () {
			return [this.r, this.g, this.b].join(',');
		};

		RGB.prototype.toString = function () {
			return 'rgb(' + this.join() + ')';
		};

		RGB.prototype.progress = function (progress) {
			this.r = this.lerp(this.start.r, this.end.r, progress);
			this.g = this.lerp(this.start.g, this.end.g, progress);
			this.b = this.lerp(this.start.b, this.end.b, progress);
		};

		RGB.prototype.lerp = function (start, end, progress) {
			progress = progress / 100;
			return $window.parseInt((1 - progress) * start + progress * end);
		};

		return {
			RGB: RGB
		};
	}

	RGBService.$inject = [
		'$window'
	];

	angular.module('common').factory('RGBService', RGBService);

})(window.angular);