(function (angular) {
	'use strict';

	function imageStamp($window, utilityService) {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/image-stamp.tmpl.html',
			scope: {
				src: '=',
				message: '='
			},
			link: function ($scope, $element) {
				var $imgEl = $element.find('.img'),
					$messageEl = $element.find('.message'),
					$messageWrapperEl = $element.find('.message-wrapper'),
					onResizeWindow;

				onResizeWindow = utilityService.debounce(function () {
					calcMessageScale();
				}, 10);

				$scope.$watch('src', onChangeSrc);

				$scope.$watch('message', onChangeMessage);

				$imgEl.on('load', onLoadImage);

				$window.addEventListener('resize', onResizeWindow);

				$scope.$on('$destroy', function () {
					$window.removeEventListener('resize', onResizeWindow);
				});

				function onChangeSrc(src) {
					setImageSrc(src);
					//hide message until an image will be loaded
					$messageWrapperEl.css('visibility', 'hidden');
				}

				function onChangeMessage(message) {
					setMessage(message);
					calcMessageScale();
				}

				function setMessage(message) {
					$messageEl.text(message);
					if (!message || !message.length) {
						$messageWrapperEl.hide();
					} else {
						$messageWrapperEl.show();
					}
				}

				function setImageSrc(src) {
					$imgEl.attr('src', src);
				}

				function onLoadImage() {
					calcMessageScale();
					$messageWrapperEl.css('visibility', 'visible');
				}

				function calcMessageScale() {
					var imageWidth = $imgEl.width(),
						messageElWidth = $messageEl.outerWidth(),
						scale = imageWidth / messageElWidth;

					$messageEl.css({
						'transform': 'rotate(-45deg) scale(' + scale + ')',
						'transform-origin': '50% 50%'
					});
				}
			}
		};
	}

	imageStamp.$inject = [
		'$window',
		'utilityService'
	];

	angular.module('common').directive('imageStamp', imageStamp);

})(window.angular);