(function (angular) {
	'use strict';

	function CampaignsListCtrl(campaignService, $location) {
		var vm = this;

		vm.campaigns = campaignService.campaigns;
		vm.startCampaign = startCampaign;
		vm.cancel = cancel;
		vm.lobbySettings = angular.extend({
			mainImage: campaignService.facilitatedLobby.configuration.imageUri
		}, campaignService.facilitatedLobby.configuration.uiConfiguration, {
			showDetailedCampaignInfo: true
		});

		function startCampaign(campaign) {
			campaign.isStarting = true;

			campaignService.startCampaign(campaign.campaignId)
				.then(function () {
					campaignService.complete()
						.catch(cancel)
						.finally(function () {
							campaign.isStarting = false;
						});
				}, cancel);
		}

		function cancel() {
			//TODO Cancel Instance
			$location.path('/');
		}
	}

	CampaignsListCtrl.$inject = [
		'campaignService',
		'$location'
	];

	function campaignsList() {
		return {
			restrict: 'AE',
			controller: CampaignsListCtrl,
			controllerAs: 'campaignsList',
			templateUrl: 'app/campaigns/components/campaignsList/campaignsList.tmpl.html'
		};
	}

	angular.module('campaigns').directive('campaignsList', campaignsList);

})(window.angular);