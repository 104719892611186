(function (angular) {
  'use strict';

  function TriviaGameAnswersCtrl(
    gameService,
    $timeout,
    $window,
    utilityService
  ) {
    var vm = this;

    vm.getLabel = getLabel;
    vm.modelParser = modelParser;
    vm.isSelectedChoice = isSelectedChoice;
    vm.getColorName = getColorName;
    vm.selectAnswer = selectAnswer;
    vm.setAnswers = setAnswers;
    vm.setZoom = setZoom;
    vm.isChoiceCorrect = isChoiceCorrect;
    vm.isChoiceIncorrect = isChoiceIncorrect;
    vm.isIE = utilityService.isIE;

    vm.$timeout = $timeout;
    vm.$window = $window;

    function getLabel(choice) {
      return choice.value || choice.label;
    }

    function modelParser(value, choice) {
      if (choice) {
        choice.value = value;
      }
      return value && value.length ? choice : null;
    }

    function isSelectedChoice(choice) {
      if (vm.oldAnswers && vm.oldAnswers.length) {
        for (var i = 0; i < vm.oldAnswers.length; i++) {
          if (choice.choiceValueId === vm.oldAnswers[i].value) {
            break;
          }
        }
        return !!vm.oldAnswers[i];
      }

      if (angular.isArray(vm.model)) {
        return !!~vm.model.indexOf(choice);
      }
      return vm.model === choice;
    }

    function isChoiceCorrect(choice) {
      if (vm.oldAnswers && vm.oldAnswers.length) {
        for (var i = 0; i < vm.oldAnswers.length; i++) {
          if (choice.choiceValueId === vm.oldAnswers[i].value) {
            break;
          }
        }
        return vm.oldAnswers[i] && vm.oldAnswers[i].isAnswerCorrect;
      } else {
        return false;
      }
    }

    function isChoiceIncorrect(choice) {
      if (vm.oldAnswers && vm.oldAnswers.length) {
        for (var i = 0; i < vm.oldAnswers.length; i++) {
          if (choice.choiceValueId === vm.oldAnswers[i].value) {
            break;
          }
        }
        return vm.oldAnswers[i] && !vm.oldAnswers[i].isAnswerCorrect;
      } else {
        return false;
      }
    }

    function getColorName(index) {
      return gameService.getColorNameForTriviaChoiceByIndex(index);
    }

    function selectAnswer(choice) {
      vm.model = choice;
      vm.onChangeModel();
    }

    function setAnswers() {
      vm.isLoading = true;
      $timeout(function () {
        vm.model = [];
        var answers = [];
        angular.forEach(angular.element('.matches>div'), function (answer) {
          answers.push(answer.id);
        });
        for (var i = 0; i < vm.choices.length; i++) {
          var matchobject = undefined;
          angular.forEach(vm.matches, function (match) {
            if (match.choiceValueId === answers[i]) {
              return (matchobject = match);
            }
          });
          vm.model.push({ choice: vm.choices[i], match: matchobject });
        }
        vm.isLoading = false;
      });
    }

    function showZoom() {
      vm.zoomWillShow = true;
      angular.element('#preview').remove();
      angular.element('.zoomContainer').remove();
      var div = document.createElement('div');
      div.setAttribute('id', 'preview');
      div.style.width = 0.5 * angular.element('body').width() + 'px';
      var screenWidth = angular.element('body').width();
      var screenHalfHeight = window.screen.height / 2;

      div.style.width =
        screenWidth <= 414
          ? 0.8 * screenWidth + 'px'
          : 0.5 * screenWidth + 'px';
      div.style.left = screenWidth <= 414 ? '10%' : '25%';

      if (vm.src) {
        var newImg = document.createElement('img');
        newImg.onload = function (event) {
          var target = angular.element(event.target);
          var parent = target.parent();

          var w = target[0].naturalWidth;
          var h = target[0].naturalHeight;

          target.css(
            'width',
            w && w < parent.width() - 20 ? w + 'px' : parent.width() - 20 + 'px'
          );

          var ch = target[0].clientHeight;
          var cw = target[0].clientWidth;
          parent.css('top', screenHalfHeight - ch + 'px');

          if (ch > screenHalfHeight) {
            target.css('width', 'unset');
            target.css('max-width', cw + 'px');
            target.css('max-height', screenHalfHeight + 'px');
            parent.css('top', screenHalfHeight / 4 + 'px');
          }
          if (target.height() < h || target.width() < w) {
            target.data('zoom-image', vm.src);
            target.elevateZoom({
              zoomType: 'lens',
              containLensZoom: false
            });
          }
        };
        newImg.setAttribute('src', vm.src);
        div.appendChild(newImg);
        if (vm.text) {
          newImg.style.float = 'left';
        }
      }
      if (vm.text) {
        if (!div.style.top) {
          div.style.top = window.screen.height / 3 + 'px';
        }
        var span = document.createElement('span');
        span.innerText = vm.text;
        div.appendChild(span);
      }
      document.body.appendChild(div);
    }

    function zoomTouchStart() {
      if (vm.zoomTouchDuration) {
        if (!vm.zoomTimer) {
          vm.zoomTimer = $timeout(function () {
            $timeout.cancel(vm.zoomTimer);
            vm.zoomTimer = null;
          }, vm.zoomTouchDuration);
        } else {
          $timeout.cancel(vm.zoomTimer);
          vm.zoomTimer = null;
          showZoom();
        }
      }
    }

    function setZoomValues(target) {
      var elem = angular.element(target);
      var span = elem.find('span.answer-value span');
      var img = elem.find('img')[0];
      vm.text = (span && span.text()) || undefined;
      vm.src = (img && img.src) || undefined;
    }

    function setZoom() {
      vm.isLoading = true;
      $timeout(function () {
        vm.zoomTouchDuration = 600;
        angular.element(document).bind('mousedown', function () {
          vm.zoomWillShow = false;
          angular.element('#preview').remove();
          angular.element('.zoomContainer').remove();
        });
        angular.element('.match').bind('touchstart', function (event) {
          setZoomValues(event.currentTarget);
          zoomTouchStart(event);
        });
        angular.element('.match').bind('dblclick', function (event) {
          setZoomValues(event.currentTarget);
          showZoom();
        });
        angular.element('.match').bind('touchend', function (event) {
          if (vm.zoomWillShow) {
            event.preventDefault();
          }
        });
        angular.forEach(angular.element('.match'), function (item) {
          var elem = angular.element(item);
          var span = elem.find('span.answer-value span');
          if (span) {
            var parent = span.parent()[0];
            if (
              parent.offsetHeight !== parent.scrollHeight ||
              parent.offsetWidth !== parent.scrollWidth
            ) {
              elem.append('<div>&raquo;</div>');
            }
          }
        });
        vm.isLoading = false;
      });
    }
  }

  TriviaGameAnswersCtrl.$inject = [
    'gameService',
    '$timeout',
    '$window',
    'utilityService'
  ];

  function triviaGameAnswers(hintService) {
    return {
      restrict: 'AE',
      controller: TriviaGameAnswersCtrl,
      controllerAs: 'answer',
      bindToController: true,
      templateUrl:
        'app/game/components/triviaGame/trivia-game-answers.tmpl.html',
      scope: {
        type: '=',
        choices: '=',
        matches: '=',
        oldAnswers: '=',
        model: '=',
        isRequired: '=',
        form: '=',
        name: '=',
        onChangeModel: '&',
        isLoading: '=',
        disabled: '=',
        toggleInterval: '&',
        mediaType: '=',
        mediaUrl: '='
      },
      link: function (scope) {
        scope.$watch('answer.matches', function (newMatches) {
          if (!newMatches) return;
          scope.answer.setAnswers();
          scope.answer.setZoom();

          if (scope.answer.type === 'MatchColumns') {
            if (scope.answer.hintWas === undefined) {
              hintService.introService.onBeforeChange(function (target) {
                if (target) {
                  var $ = window.jQuery;
                  $('body').animate(
                    {
                      scrollTop: $('.trivia-game-header').height()
                    },
                    { duration: 500 }
                  );
                }
              });

              hintService.introService.onExit(function () {
                scope.answer.toggleInterval();
              });

              hintService.addHints({
                element: '.hint',
                intro: 'Drag & drop the answers to match the questions',
                position: 'top'
              });
              scope.answer.hintWas = true; //turn of if the hint should be not once per game but for every question
              hintService.runTutorial();
            } else {
              scope.answer.toggleInterval();
            }
          }
        });

        scope.$watch('answer.choices', function (newChoices) {
          if (!newChoices) return;
          function resetHeights() {
            var answers = document.querySelectorAll(
              '.trivia-game-content .trivia-game-answer:not(.match-match) .answer-value'
            );
            var height = 0;
            angular.forEach(answers, function (answer) {
              var answerElement = angular.element(answer);
              height =
                answerElement.height() > height
                  ? answerElement.height()
                  : height;
              height = height < 70 ? 70 : height;
            });
            angular.forEach(answers, function (answer) {
              var answerElement = angular.element(answer);
              answerElement.css({ 'min-height': height, height: '1px' });
            });
          }
          window.onresize = resetHeights;
          scope.answer.$timeout(function () {
            resetHeights();
          }, 100);
        });
      }
    };
  }

  angular
    .module('game')
    .directive('triviaGameAnswers', ['hintService', triviaGameAnswers]);
})(window.angular);
