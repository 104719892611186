(function (angular) {
	'use strict';

	function InteractionCtrl($log, accessControlService, authService, $q, sessionService) {
		var vm = this;

		if (sessionService.cooked) {
            startCookedCampaign(sessionService.cooked.accessRequestId);
            return;
		}

		vm.auth = authService.authentication;

		vm.model = accessControlService.interactions;
		vm.pageConfig = accessControlService.pageConfigurations;
		vm.lobbyUiConfiguration = accessControlService.lobbyUiConfiguration || {};

		vm.type = null;
		vm.question = {};
		vm.submit = submit;

		if (vm.context !== 'campaign' && vm.context !== 'lobby') {
			$log.error('Access control context is not defined.');
		}

		onChangeInteraction(vm.model);

		function submit(answer) {

			vm.isLoading = true;

			var deferred = $q.defer();

			accessControlService.submitInteraction(vm.context, answer)
				.then(function (data) {
					deferred.resolve(onChangeInteraction(data.interactions));
				})
				.finally(function () {
					vm.isLoading = false;
				});

			return deferred.promise;
		}

        function onChangeInteraction(interactions) {
            //if (accessControlService.isInit === undefined) {
            //    (accessControlService.isInit = true);
            //} else {
            //    accessControlService.isInit === true && (accessControlService.isInit = false);
            //}
            
            accessControlService.interactionsCount++;

			var interactionType = accessControlService.getInteractionType(interactions[0]);

			vm.model = interactions;

			if (interactionType === 'resumeExisting') {

				vm.onResume({
					$instanceId: vm.model[0].continuationId
				});

				if(!angular.isDefined(vm.lobbyLogoNeed)) {
					vm.lobbyLogoNeed = true;
				}

				accessControlService.fireEvent('resume', {
					instanceId: vm.model[0].continuationId,
					accessRequestId: accessControlService.accessRequestId,
					isChallengeLobby: vm.model[0].isChallengeLobby
				});
			}

			if (interactionType === 'success') {
				vm.onSuccess({
					$accessRequestId: accessControlService.accessRequestId
				});
				accessControlService.fireEvent('success', {
					accessRequestId: accessControlService.accessRequestId
				});
			}

			if (interactionType === 'error') {
				accessControlService.fireEvent('error', vm);
			}

			if (interactionType === 'retry') {
				if (vm.questions && vm.questions instanceof Array) {
					vm.questions.forEach(function (quest) {
						if (vm.model && vm.model instanceof Array) {
							vm.model.forEach(function (err) {
								if (quest.ruleId === err.ruleId) {
									quest.errorMessage = err.text;
								}
							});
						}

					});
				}
			}

			if (interactionType === 'captcha') {
				vm.question = angular.copy(vm.model[0]);
			}

			if (interactionType === 'question') {
				vm.questions = angular.copy(vm.model);
			}

			if (interactionType === 'signature') {
				vm.questions = angular.copy(vm.model);
			}

			vm.type = interactionType === 'retry' ? 'question' : interactionType;

			return vm.questions || vm.question;
		}

		function startCookedCampaign(accessRequestId) {
            vm.onSuccess({
                $accessRequestId: accessRequestId
            });
            accessControlService.fireEvent('success', {
                accessRequestId: accessRequestId
            });
		}
	}

	InteractionCtrl.$inject = [
		'$log',
		'accessControlService',
		'authService',
		'$q',
		'sessionService'
	];

	function acInteraction() {
		return {
			restrict: 'AE',
			controller: InteractionCtrl,
			controllerAs: 'interaction',
			bindToController: true,
			templateUrl: 'app/accessControl/components/interaction/interaction.tmpl.html',
			scope: {
				context: '@',
				onResume: '&',
				onSuccess: '&',
				onError: '&'
			},
			link: function(scope) {

				/*Fixes for double logo in lobby in case of lobby logo and access control header images presents*/
				function getParentLobbyScope(parent) {
					if (!parent) return;
					var resParent = parent;
					if (resParent.lobbyPage) {
						return resParent;
					} else {
						resParent = getParentLobbyScope(resParent.$parent);
					}
					return resParent;
				}
				var ia = scope.interaction;
				if (ia && ia.type !== 'success') {
					var parentLobbyScope = getParentLobbyScope(scope.$parent);
					if (parentLobbyScope && parentLobbyScope.lobbyPage && parentLobbyScope.lobbyPage.logoNeed) {
						ia.lobbyLogoNeed = false;
						parentLobbyScope.lobbyPage && (parentLobbyScope.lobbyPage.logoNeed = ia.lobbyLogoNeed);
					}
				}
			}
		};
	}

	angular.module('accessControl').directive('acInteraction', acInteraction);

})(window.angular);