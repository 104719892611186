(function (angular) {
	'use strict';

	function RedeemRewardCtrl($uibModalInstance, reward, selfRedeemRewardService, errorService) {
		var vm = this;

		vm.posCode = null;
		vm.reward = reward;
		vm.countdownTimeRange = '00:00:00:' + reward.selfRedeemDismissTimer;
		vm.redeemReward = redeemReward;
		vm.onElapseCountdown = onElapseCountdown;
		vm.cancel = cancel;
		vm.close = close;

		function redeemReward() {
			var rewardCode = selfRedeemRewardService.getRewardCode(reward);

			if (vm.isLoading) {
				return false;
			}

			selfRedeemRewardService.redeemReward(rewardCode.code, true)
				.then(function () {
					if(vm.reward.selfRedeemDismissTimer) {
						vm.posCode = selfRedeemRewardService.getPOSCode(reward) || rewardCode;
					} else {
						vm.onElapseCountdown();
					}
				}, function (response) {
					vm.errors = errorService.parseErrorsToList(response.data);
					if (!vm.errors.length) {
						vm.errors = ['Internal Server Error'];
					}
				})
				.finally(function () {
					vm.isLoading = false;
				});

			vm.isLoading = true;
			vm.errors = [];
		}

		function onElapseCountdown() {
			return close();
		}

		function cancel() {
			return $uibModalInstance.dismiss();
		}

		function close() {
			return $uibModalInstance.close();
		}
	}

	RedeemRewardCtrl.$inject = [
		'$uibModalInstance',
		'reward',
		'selfRedeemRewardService',
		'errorService'
	];

	angular.module('selfRedeem').controller('SelfRedeemRewardCtrl', RedeemRewardCtrl);

})(window.angular);