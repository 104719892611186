(function (angular) {
	'use strict';

	function appStorageProvider() {
		var self = this,
			defaultAppKey = 'defaultAppKey',
			uniqueKey = defaultAppKey,
			localStorageFlag = true; //default value

		self.setUniqueKey = function (key) {
			uniqueKey = key || defaultAppKey;
		};

		self.getUniqueKey = function () {
			return uniqueKey;
		};

		self.useLocalStorage = function (flag) {
			localStorageFlag = !!flag;
		};

		self.$get = ['localStorageService', 'storageService', function appStorageService(localStorageService, storageService) {
			var service = {};

			service.set = set;
			service.get = get;
			service.remove = remove;
			service.removeAppData = removeAppData;
			service.transferDataToTempStorage = transferDataToTempStorage;

			return service;

			function set(key, data) {
				var appData = getStorage().get(uniqueKey) || {};
				appData[key] = data;
				getStorage().set(uniqueKey, appData);
			}

			function get(key) {
				var appData = getStorage().get(uniqueKey);
				return appData && appData[key];
			}

			function remove(key) {
				return set(key, undefined);
			}

			function removeAppData() {
				return getStorage().remove(uniqueKey);
			}

			function getStorage() {
				if (isUsedLocalStorage()) {
					return localStorageService;
				}
				return storageService;
			}

			function isUsedLocalStorage() {
				return !!localStorageFlag;
			}

			function transferDataToTempStorage() {
				if (isUsedLocalStorage()) {
					var appData = localStorageService.get(uniqueKey);

					storageService.set(uniqueKey, appData);

					localStorageService.remove(uniqueKey);

					self.useLocalStorage(false);

					return true;
				}

				return false;
			}
		}];
	}

	angular.module('appStorage').provider('appStorageService', appStorageProvider);

})(window.angular);