(function (angular) {
    'use strict';

    function config($stateProvider, $urlRouterProvider, $translateProvider) {
        $stateProvider
            .state('dashboard-login', {
                url: '/login/:dToken?aType&token&u&p',
                templateUrl: 'app/dashboards/layout/login.html',
                resolve: {
                    clientInfo: ['dashboardService', '$stateParams', function (dashboardService, $stateParams) {
                        //return ('locale' in dashboardService) || dashboardService.getDashboard($stateParams.dToken);


                        if (dashboardService.loginPageConfig) {
                            return dashboardService.loginPageConfig;
                        }

                        return dashboardService.loginCustomUser($stateParams.dToken);
                    }]
                }
            })
            .state('dashboard-delivery', {
                url: '/delivery?dToken&userId',
                templateUrl: 'app/dashboards/layout/delivery.html',
                resolve: {
                    deliveryTypes: ['dashboardService', function (dashboardService) {
                        return dashboardService.getNotificationDeliveryTypes();
                    }]
                }
            })
            .state('dashboard', {
                url: '/dashboards/:dToken?u&p',
                templateUrl: 'app/dashboards/layout/dashboard.html',
                resolve: {
                    dashboard: ['dashboardService', '$stateParams', '$state', 'apiSettings', function (dashboardService, $stateParams) {
                        if (dashboardService.currentDashboard.dToken === $stateParams.dToken) {
                            return dashboardService.currentDashboard;
                        }

                        return dashboardService.getSecureDashboard($stateParams);
                    }]
                }
            })
            .state('integration-login', {
                url: '/integration-login/:dToken',
                template: '<integration-login></integration-login>',
                resolve: {
                    loginPageConfig: ['dashboardService', '$stateParams', 'utilityService', 'sessionStorageService', function (dashboardService, $stateParams, utilityService, sessionStorageService) {
                        if (!dashboardService.loginPageConfig) {
                            dashboardService.loginPageConfig = utilityService.parseSafeJSON(sessionStorageService.get('integrationLoginPageConfig'));
                        }
                        if (dashboardService.loginPageConfig) {
                            return dashboardService.loginPageConfig;
                        }

                        return dashboardService.getSecureDashboard($stateParams);
                    }]
                }
            })
            .state('custom-dashboard', {
                url: '/custom-dashboard/:dToken',
                template: '<custom-dashboard></custom-dashboard>',
                resolve: {
                    dashboard: ['dashboardService', '$stateParams', function (dashboardService, $stateParams) {
                        if (dashboardService.currentDashboard && dashboardService.currentDashboard.dToken === $stateParams.dToken) {
                            return dashboardService.currentDashboard;
                        }
                        return dashboardService.getCustomDashboard($stateParams.dToken);
                    }]
                }
            });

        $urlRouterProvider.otherwise('/');

        //Translation settings
        $translateProvider.useStaticFilesLoader({
            prefix: 'locales/locale_',
            suffix: '.json'
        });

        $translateProvider.preferredLanguage('en-US');

    }

    config.$inject = [
        '$stateProvider',
        '$urlRouterProvider',
        '$translateProvider'
    ];

    angular.module('dashboard').config(config);

})(window.angular);