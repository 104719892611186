(function (angular) {

	function CounterDashboardBoxCtrl(){
		var vm = this;
        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };
	}


	CounterDashboardBoxCtrl.$inject = [
	];

	function counterDashboardBox() {
		return {
			controller: CounterDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/counter/counter-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('counterDashboardBox', counterDashboardBox);

})(window.angular);