(function (angular) {
	'use strict';

	angular.module('campaignMode', [
		'ui.router',
		'ngTouch',
		'settings',
		'common',
		'info',
		'auth',
		'session',
		'chooseReward',
		'delivery',
		'question',
		'embedded',
		'game',
		'redeem',
		'redemption',
		'browser',
		'completed',
		'swapReward',
		'accessControl',
		'countdown'
	]);

})(window.angular);