(function (angular) {
	'use strict';

	function addressService($q, apiResource) {
		var service = {},
			countryRegionsHashByCountryId = {};

		service.countryCodes = [];
		service.getCountryCodes = getCountryCodes;
		service.getCountryRegions = getCountryRegions;

		return service;

		function getCountryCodes() {
			if (service.countryCodes && service.countryCodes.length) {
				return $q.when(service.countryCodes);
			}
			return apiResource.get('/api/info/countryCodes')
				.then(function (data) {
					service.countryCodes = data;

					service.countryCodes.forEach(function (countryCode) {
						if (!countryCode.nameWithPhoneCode) {
							countryCode.nameWithPhoneCode = countryCode.name + ' (' + countryCode.phoneCode + ')';
						}
					});
					return service.countryCodes = data;
				});
		}

		function getCountryRegions(countryId) {
			if (countryId === undefined) countryId = 232;

			if (countryId in countryRegionsHashByCountryId) {
				return $q.when(countryRegionsHashByCountryId[countryId]);
			}
			return apiResource.get('/api/info/' + countryId + '/countryRegions')
				.then(function (data) {
					return countryRegionsHashByCountryId[countryId] = data;
				});
		}
	}

	addressService.$inject = [
		'$q',
		'apiResource'
	];

	angular.module('answer').factory('addressService', addressService);

})(window.angular);