(function (angular) {
	'use strict';

	function errorInterceptorService($log, $q, $injector) {
		var service = {},
			errorService,
			sessionService,
			$location,
			$timeout,
			InstanceIdIsUndefinedError = 'InstanceId is undefined',
			CampaignOverError = 'CampaignOver',
            MultiGetSetReward = 'MultiGetSetReward';

		service.request = request;
		service.responseError = responseError;

		return service;

		function request(config) {
			if (/api\/instance\/null/.test(config.url)) {
				return $q.reject({
					config: {
						ignoreDefaultErrorNotifier: true
					},
					data: InstanceIdIsUndefinedError
				});
			}
			return config;
		}

		function responseError(error) {
			errorService = errorService || $injector.get('errorService');
			$location = $location || $injector.get('$location');
			$timeout = $timeout || $injector.get('$timeout');

			error = errorService.parseAndExtendHttpError(error) || {};

			if (error.data === InstanceIdIsUndefinedError) {

				// User tries to get step without InstanceId and will be redirected
				$timeout(function(){
					$location.path('/');
				});

			} else if (error.status === 403
				&& error.data && error.data.indexOf && ~error.data.indexOf('ExceptionStep.')) {

				error.data = error.data.replace('ExceptionStep.', '');

				// Need to be silent
				error.config.showErrorInSeparateState = false;
				error.config.ignoreDefaultErrorNotifier = true;

				$log.debug('User tries to get unavailable step and will be redirected', error.data);

				getCurrentStep();
			} else if (~[MultiGetSetReward, CampaignOverError].indexOf(error.errorType)) {
				// Need to be silent
				error.config.showErrorInSeparateState = false;
				error.config.ignoreDefaultErrorNotifier = true;

				// Need to go to state to display a message
				if (error.errorType === CampaignOverError) {
                    gotoCampaignOverMessage(error.data.message);
                } else {
                    $log.debug('MultiGetSetReward', error.data);
				}

			}

			return $q.reject(error);
		}

		function getCurrentStep() {
			sessionService = sessionService || $injector.get('sessionService');
			return sessionService.getCurrentStep();
		}

		function gotoCampaignOverMessage(message) {
			sessionService = sessionService || $injector.get('sessionService');
			return sessionService.gotoCampaignOverMessage(message);
		}
	}

	errorInterceptorService.$inject = [
		'$log',
		'$q',
		'$injector'
	];

	angular.module('common').factory('errorSessionInterceptorService', errorInterceptorService);

})(window.angular);