(function (angular) {
	'use strict';

	function ExternalIdCtrl() {
		//var vm = this;
	}

	function externalId() {
		return {
			restrict: 'AE',
			controller: ExternalIdCtrl,
			controllerAs: 'external',
			bindToController: true,
			templateUrl: 'app/redemption/components/externalId/external-id.tmpl.html',
			scope: {
				id: '='
			}
		};
	}

	angular.module('redemption').directive('externalId', externalId);

})(window.angular);