(function (angular) {
	'use strict';

	function RedeemRewardButtonCtrl($window, $uibModal) {
		var vm = this;

		vm.onClickRedeem = onClickRedeem;

		function onClickRedeem() {
			return $uibModal
				.open({
					templateUrl: 'app/selfRedeem/components/selfRedeemReward/self-redeem-reward-modal.tmpl.html',
					controller: 'SelfRedeemRewardCtrl',
					controllerAs: 'redeem',
					backdrop: 'static',
					keyboard: false,
					resolve: {
						reward: function () {
							return vm.reward;
						}
					}
				})
				.result
				.then(function (/*data*/) {
					vm.reward.rewardState = 'Redeemed';
					vm.reward.rewardStates.push('Redeemed');
					vm.reward.statusMessage = 'Redeemed';
					vm.reward.isRedeemable = false;
				});
		}
	}

	RedeemRewardButtonCtrl.$inject = [
		'$window',
		'$uibModal'
	];

	function redeemRewardButton() {
		return {
			restrict: 'AE',
			controller: RedeemRewardButtonCtrl,
			controllerAs: 'redeem',
			bindToController: true,
			templateUrl: 'app/selfRedeem/components/selfRedeemRewardButton/self-redeem-reward-button.tmpl.html',
			scope: {
                reward: '=rewardModel',
                disabled: '='
			}
		};
	}

	angular.module('selfRedeem').directive('selfRedeemRewardButton', redeemRewardButton);

})(window.angular);