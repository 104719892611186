(function (angular) {
  'use strict';

  function AnswersCtrl() {
    var vm = this;

    vm.getLabel = function (choice) {
      return choice.label || choice.value;
    };
  }

  AnswersCtrl.$inject = [];

  function answers() {
    return {
      restrict: 'AE',
      templateUrl: 'app/answer/components/answer/answers.tmpl.html',
      controller: AnswersCtrl,
      controllerAs: 'answers',
      bindToController: true,
      scope: {
        type: '=',
        subtype: '=',
        choices: '=',
        model: '=',
        onChangeModel: '&',
        isRequired: '=',
        form: '=',
        name: '=',
        enableAutoFocus: '=',
        forceUppercase: '=',
        answerBackgroundColor: '=',
        layout: '=',
        placeholderText: '=',
        displayCountryCodeDropdown: '=',
        country: '=',
        isUseTodayDate: '=',
        isUseDateRange: '=',
        dateRange: '=',
        dateFormat: '=',
        advanceAutomatically: '=',
        answerCartMediaUrl: '=',
        answerPattern: '=',
        isPattern: '=',
        answerCartMediaType: '=',
        answerDisabled: '=',
        useVerification: '=',
        uniqueId: '=',
        renderMode: '=',
        startYear: '=',
        endYear: '=',
        automaticYear: '='
      }
    };
  }

  angular.module('answer').directive('answers', answers);
})(window.angular);
