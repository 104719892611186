(function (angular) {
    'use strict';

    function LobbyPersonLookupCtrl($uibModal, lobbyService, overlayService, personProfileService, $state, notifier, confirmationModalService, $scope) {
        var vm = this;

        vm.externalId = '';
        vm.profiles = [];

        vm.isLoading = false;
        vm.isSending = false;

        $scope.$on('$destroy', function () {
            vm.confirmModal && vm.confirmModal.dismiss();
        });

        vm.cancel = function () {
            $state.go('lobby.init');
        };

        vm.getProfiles = function () {
            vm.isLoading = true;
            personProfileService.getPersons(vm.externalId).finally(function () {
                vm.profiles = personProfileService.profiles;
                vm.isLoading = false;
            });
        };

        vm.search = function () {
            vm.getProfiles();
        };

        vm.edit = function (person) {
            var modal = $uibModal
                .open({
                    templateUrl: 'app/lobby/components/lobbyPersonLookup/edit-modal.tmpl.html',
                    controller: ['person', function (person) {
                        var _vm = this;
                        _vm.person = Object.assign({}, person);

                        _vm.save = function (p) {
                            personProfileService.updatePerson(p).then(function () {
                                vm.getProfiles();
                                modal.close();
                                notifier.success('Email updated!');
                            });
                        };
                    }],
                    controllerAs: 'ctrl',
                    backdrop: 'static',
                    keyboard: false,
                    size: 'sm',
                    resolve: {
                        person: function () {
                            return person;
                        }
                    }
                });
        };

        vm.sendLink = function (person) {
            vm.confirmModal = confirmationModalService.confirm({ message: 'Send sign up link to ' + person.email + '?' });
            vm.confirmModal.result.then(function () {
                person.isSending = true;
                personProfileService.sendSignUpLink(person).then(function () {
                    notifier.success('Successfully sent!');
                }).finally(function () {
                    person.isSending = false;
                });
            });
        };
    }

    LobbyPersonLookupCtrl.$inject = ['$uibModal', 'lobbyService', 'overlayService', 'personProfileService', '$state', 'notifier', 'confirmationModalService', '$scope'];

    function lobbyPersonLookup() {
        return {
            restrict: 'AE',
            controller: LobbyPersonLookupCtrl,
            controllerAs: 'lobby',
            bindToController: true,
            templateUrl: 'app/lobby/components/lobbyPersonLookup/lobby-person-lookup.tmpl.html'
        };
    }

    angular.module('lobby').directive('lobbyPersonLookup', lobbyPersonLookup);

})(window.angular);