(function (angular) {
	'use strict';

	function LobbyPageCtrl($scope, $window, lobbyService) {
		var vm = this,
			Howl = $window.Howl,
			backgroundMusic;


		vm.settings.backgroundMusic = vm.settings.backgroundMusic || {};

        vm.backgroundImage = vm.settings && vm.settings.backgroundImage && vm.settings.backgroundImage.value;

        lobbyService.on('onGetCouponBookConfiguration', function() {
            vm.dynamicSettings = lobbyService.dynamicLobbyConfiguration;
            if (vm.dynamicSettings && vm.dynamicSettings.backgroundImage && vm.dynamicSettings.backgroundImage.size && vm.dynamicSettings.backgroundImage.value) {
                vm.backgroundImage = vm.dynamicSettings.backgroundImage.value;
			}
		});

        lobbyService.on('onClearCouponBookConfiguration', function() {
            vm.dynamicSettings = {};
            vm.backgroundImage = vm.settings && vm.settings.backgroundImage && vm.settings.backgroundImage.value;
		});
		
		lobbyService.on('onSetAccessControlPage', function() {
			vm.isAccessControlPage = lobbyService.isAccessControlPage;
        });

        if (vm.settings.backgroundMusic.value) {
			backgroundMusic = new Howl({urls: [vm.settings.backgroundMusic.value], autoplay: true, loop: true});

			$scope.$on('$destroy', function () {
				backgroundMusic.unload();
			});
		}
	}

	LobbyPageCtrl.$inject = [
		'$scope',
		'$window',
		'lobbyService'
	];

	function lobbyPage() {
		return {
			restrict: 'AE',
			transclude: true,
			controller: LobbyPageCtrl,
			controllerAs: 'lobbyPage',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyPage/lobby-page.tmpl.html',
			scope: {
				settings: '=lobbyPageSettings',
				dynamicSettings: '=dynamicLobbyConfiguration'
			}
		};
	}

	angular.module('lobby').directive('lobbyPage', lobbyPage);

})(window.angular);