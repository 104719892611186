(function (angular) {
	'use strict';

	function trustedResourceFilter($sce) {
		return function (src) {
			return $sce.trustAsResourceUrl(src || '');
		};
	}

	trustedResourceFilter.$inject = [
		'$sce'
	];

	angular.module('common').filter('trustedResource', trustedResourceFilter);

})(window.angular);