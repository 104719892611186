(function (angular) {
  'use strict';

  function AnswerCtrl($http) {
    var vm = this;

    vm.init = function (ngModel) {
      if (!ngModel) return;

      vm.type = 'text';

      vm.onChange = function (value) {
        vm.model.value = value;
        ngModel.$setViewValue(vm.model);
        vm.onChangeModel({ $inputType: 'text' });
      };

      ngModel.$render = function () {
        // Specify how UI should be updated when new question was applied
        vm.model = {};
        vm.model.value = ngModel.$viewValue;
      };

      var zip = '0000';

      function showPosition(position) {
        var lat = position.coords.latitude;
        var long = position.coords.longitude;
        var url =
          'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
          lat +
          ',' +
          long +
          '&key=AIzaSyCUsCg1ClMAYwgw_h1-7VjUA9-r-zkqSQg';
        $http({
          method: 'GET',
          url: url,
          headers: { removeAuthorization: true }
        })
          .then(function (msg) {
            var results = msg.data.results;
            if (
              results &&
              results.length &&
              results[0].address_components &&
              results[0].address_components.length
            ) {
              results[0].address_components.forEach(function (
                address_component
              ) {
                if (
                  address_component.types &&
                  address_component.types.length &&
                  address_component.types.some(function (type) {
                    return type === 'postal_code';
                  })
                ) {
                  zip = address_component.long_name;
                }
              });
            }

            vm.onChange(zip);
          })
          .catch(function () {
            vm.onChange('ERROR');
          });
      }

      navigator.geolocation.getCurrentPosition(showPosition, function (error) {
        vm.onChange((error && error.message) || 'ERROR');
      });
    };
  }

  AnswerCtrl.$inject = ['$http'];

  function answer() {
    return {
      restrict: 'E',
      templateUrl:
        'app/answer/components/answer/answerItems/answer-location-number.tmpl.html',
      require: ['answerLocationNumber', 'ngModel', '^answers'],
      controller: AnswerCtrl,
      controllerAs: 'answer',
      bindToController: true,
      scope: {
        choices: '=answerChoices',
        onChangeModel: '&answerOnChangeModel',
        isRequired: '=answerRequired',
        form: '=answerFormInstance',
        name: '=answerName',
        type: '='
      },
      link: function (scope, element, attr, ctrls) {
        ctrls[0].init(ctrls[1], ctrls[2]);
      }
    };
  }

  angular.module('answer').directive('answerLocationNumber', answer);
})(window.angular);
