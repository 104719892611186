(function (angular, $) {
	'use strict';

	function viewAnimationService($animateCss, $q) {
		var service = {};

		service.TRANSITIONS_NAMES = {
			'FadeIn': 'fadeIn',        // The key = what we get from a server. The value = animation name from animation.css
			'FadeOut': 'fadeOut',
			'FadeInUp': 'fadeInUp',
			'FadeInDown': 'fadeInDown',
			'FadeInLeft': 'fadeInLeft',
			'FadeInRight': 'fadeInRight',
			'FadeOutUp': 'fadeOutUp',
			'FadeOutDown': 'fadeOutDown',
			'FadeOutLeft': 'fadeOutLeft',
			'FadeOutRight': 'fadeOutRight',
			'SlideInUp': 'slideInUp',
			'SlideInDown': 'slideInDown',
			'SlideInLeft': 'slideInLeft',
			'SlideInRight': 'slideInRight',
			'SlideOutUp': 'slideOutUp',
			'SlideOutDown': 'slideOutDown',
			'SlideOutLeft': 'slideOutLeft',
			'SlideOutRight': 'slideOutRight',
			'LightSpeedIn': 'lightSpeedIn',
			'LightSpeedOut': 'lightSpeedOut',
			'ZoomIn': 'zoomIn',
			'ZoomOut': 'zoomOut'
		};

		service._enterAnimation = null;
		service._leaveAnimation = null;
		service._enterAnimationDuration = 0;  // Seconds
		service._leaveAnimationDuration = 0;  // Seconds
		service.getCurrentEnterAnimation = getCurrentEnterAnimation;
		service.getCurrentLeaveAnimation = getCurrentLeaveAnimation;
		service.getCurrentEnterAnimationDuration = getCurrentEnterAnimationDuration;
		service.getCurrentLeaveAnimationDuration = getCurrentLeaveAnimationDuration;
		service.isLeaveAnimationPresent = isLeaveAnimationPresent;
		service.setEnterAnimation = setEnterAnimation;
		service.setLeaveAnimation = setLeaveAnimation;
		service.startEnterTransition = startEnterTransition;
		service.startLeaveTransition = startLeaveTransition;
		service.setCssOverride = setCssOverride;
        service.setEnterVideo = setEnterVideo;
		service.setLeaveVideo = setLeaveVideo;
		service.showVideoInstead = showVideoInstead;

		return service;

		function getCurrentEnterAnimation() {
			return service.TRANSITIONS_NAMES[service._enterAnimation];
		}

		function getCurrentLeaveAnimation() {
			return service.TRANSITIONS_NAMES[service._leaveAnimation];
		}

		function getCurrentEnterAnimationDuration() {
			return service._enterAnimationDuration;
		}

		function getCurrentLeaveAnimationDuration() {
			return service._leaveAnimationDuration;
		}

		function setEnterAnimation(anim, duration) {
			service._enterAnimation = (anim && anim !== 'None') ? anim : null;
			service._enterAnimationDuration = (duration && duration != 0) ? duration : 0;
		}

		function setLeaveAnimation(anim, duration) {
			service._leaveAnimation = (anim && anim !== 'None') ? anim : null;
			service._leaveAnimationDuration = (duration && duration != 0) ? duration : 0;
		}

		function isLeaveAnimationPresent() {
			return (!!getCurrentLeaveAnimation() && !!getCurrentLeaveAnimationDuration());
		}

		function startEnterTransition(element) {
			var animationName = service.TRANSITIONS_NAMES[service._enterAnimation],
			animationDuration = service._enterAnimationDuration;
			service._element = element;

			if (service._cssOverride) {
                $('<style type=\'text/css\'>' + service._cssOverride + '</style>').appendTo('head');
            }

			if (animationName && animationDuration) {
				return $animateCss(element, {
					event: 'enter',
					keyframeStyle: animationName + ' ' + animationDuration + 's',
					structural: true
				}).start();
			}
		}

		function startLeaveTransition(element) {
			var animationName = service.TRANSITIONS_NAMES['FadeOut'],
			animationDuration = service._leaveAnimationDuration;
			element = element || service._element;

            if (service._cssOverride) {
                $('<style type=\'text/css\'>' + service._cssOverride + '</style>').detach();
            }

			if (animationName && animationDuration) {
				return $animateCss(element, {
					event: 'leave',
					keyframeStyle: animationName + ' ' + animationDuration + 's',
					structural: true,
					cleanupStyles: false
				}).start();
			}
		}
		
		function setCssOverride(css) {
            service._cssOverride = css ? css : null;
        }

        function setEnterVideo(videoUrl) {
			service.enterVideoUrl = videoUrl;
        }

        function setLeaveVideo(videoUrl) {
            service.leaveVideoUrl = videoUrl;
		}

		function showVideoInstead(selector, videoUrl) {

			var deferred = $q.defer();

            var instead = angular.element(selector);
            var videoElement = angular.element('<video src="' + videoUrl +'" autoplay muted controls playsinline></video>');

            videoElement.css('background', 'black');
            videoElement.css('width', '100%');
            videoElement.css('height', '100%');
            videoElement.css('display', 'none');
            instead.css('display', 'none');

            videoElement.ready(function() {
                videoElement.css('display', 'block');
            });
            videoElement.on('ended', function() {
                videoElement.remove();
                instead.css('display', 'block');

                deferred.resolve();
            });
            angular.element('#main-container').append(videoElement);

            return deferred.promise;
		}
	}

	viewAnimationService.$inject = [
		'$animateCss',
		'$q'
	];

	angular.module('common').factory('viewAnimationService', viewAnimationService);


	/***************** .view-animation **********************/

	function viewAnimation(viewAnimationService, $animateCss) {
		return {
			enter: function (element, done) {
				var enterAnimationName = viewAnimationService.getCurrentEnterAnimation(),
					enterAnimationDuration = viewAnimationService.getCurrentEnterAnimationDuration();

				if (!enterAnimationName || !enterAnimationDuration) {
					done();
					return;
				}

				return $animateCss(element, {
					event: 'enter',
					keyframeStyle: enterAnimationName + ' ' + enterAnimationDuration + 's',
					structural: true
				});
			},
			leave: function (element) {
				var leaveAnimationName = viewAnimationService.getCurrentLeaveAnimation(),
					leaveAnimationDuration = viewAnimationService.getCurrentLeaveAnimationDuration();

				return $animateCss(element, {
					event: 'leave',
					keyframeStyle: leaveAnimationName + ' ' + leaveAnimationDuration + 's',
					structural: true
				});
			}
		};
	}

	viewAnimation.$inject = [
		'viewAnimationService',
		'$animateCss'
	];

	function questionAnimation(viewAnimationService, $animateCss) {
		return {
			enter: function (element, done) {
				var enterAnimationName = viewAnimationService.getCurrentEnterAnimation(),
					enterAnimationDuration = viewAnimationService.getCurrentEnterAnimationDuration();

				if (!enterAnimationName || !enterAnimationDuration) {
					done();
					return;
				}

				return $animateCss(element, {
					event: 'enter',
					keyframeStyle: enterAnimationName + ' ' + enterAnimationDuration + 's',
					structural: true
				});
			},
			leave: function (element) {
				var leaveAnimationName = viewAnimationService.getCurrentLeaveAnimation(),
					leaveAnimationDuration = viewAnimationService.getCurrentLeaveAnimationDuration();

				return $animateCss(element, {
					event: 'leave',
					keyframeStyle: leaveAnimationName + ' ' + leaveAnimationDuration + 's',
					structural: true
				});
			}
		};
	}

	questionAnimation.$inject = [
		'viewAnimationService',
		'$animateCss'
	];
    
	angular.module('common').animation('.cim-transition', viewAnimation);
	angular.module('common').animation('.question-transition', questionAnimation);

})(window.angular, window.jQuery);
