(function (angular, $) {
	'use strict';

	function pickemService(EventEmitter, apiSessionResource, apiPickemSessionResource, sessionService, $rootScope, utilityService, behaviorEffectService, $animate, viewAnimationService) {
		var service = Object.create(EventEmitter.prototype);

		service.pickemCim = null;
		service.getPickemCim = getPickemCim;
		service.getExistingPickemCim = getExistingPickemCim;
		service.answerToQuestion = answerToQuestion;
		service.complete = complete;
		service.pickemInformUser = pickemInformUser;
		service.pickemExists = pickemExists;
		var isExistingPickem;
		var existingPickemInstanceId;

		return service;

		function getPickemCim(options) {
			var searchParams = utilityService.parseSearchQuery();
			if(searchParams.pickemid) {
				service.existingPickemInstanceId = searchParams.pickemid;
				isExistingPickem = true;
				return getExistingPickemCim(options);
			}
			return getNewPickemCim(options);
		}

		function getNewPickemCim(options) {
			return apiSessionResource.get('/pickem', options || {})
			.then(function (data) {
				service.pickemCim = data || {items: []};
				service.pickemCim.uiCustomization = utilityService.parseSafeJSON(service.pickemCim.uiCustomization);
				fireEvent('campaign.onChangePickemCim', service.pickemCim);
				return service.pickemCim;
			});
		}

		function getExistingPickemCim(pickemId) {
			return apiPickemSessionResource.get(pickemId, {})
			.then(function (data) {
				isExistingPickem = true;
				existingPickemInstanceId = pickemId;
				service.pickemCim = data || {items: []};
				service.pickemCim.uiCustomization = utilityService.parseSafeJSON(service.pickemCim.uiCustomization);
				fireEvent('campaign.onChangePickemCim', service.pickemCim);
				return service.pickemCim;
			});
		}

		function pickemExists() {
			return isExistingPickem;
		}


		function answerToQuestion(questions) {
			var answers = (questions || []).map(function (question) {
				return {
					pickemQuestionId: question.pickemQuestionId,
					chosenId: question.chosenId
				};
			});

			if(isExistingPickem) {
				return apiPickemSessionResource.post(existingPickemInstanceId, {
					answers: answers
				}).then(function(response) {
					return (response);
				});
			}
			return apiSessionResource.post('/pickem', {
				answers: answers
			}).then(function (response) {
				return response;
			});
		}

		function pickemInformUser() {
			return apiPickemSessionResource.post(sessionService.getSessionKey()+'/informUser')
			.then(function (response) {
				return response;
			});
		}

		function complete() {
			var behaviorData = sessionService.currentStep || {},
				reward = service.reward || {};

			//Show Effect if needed
			return behaviorEffectService
				.addEffect({
					effectDuration: behaviorData.effectDuration,
					effectSound: behaviorData.effectSound,
					effectType: behaviorData['effectType'],
					showDismissButton: behaviorData.showDismissButton,
					dismissImage: behaviorData.displayBehaviorDismissImage,
					dismissButtonText: behaviorData.displayBehaviorDismissText,
					dismissButtonColor: behaviorData.displayBehaviorDismissButtonColor,
					dismissButtonTextColor: behaviorData.displayBehaviorDismissTextColor,
					showReward: behaviorData.showReward,
					reward: reward
				}).then(function() {
					if(viewAnimationService.isLeaveAnimationPresent()) {
						return $animate.leave($('.cim-transition'));
					}
				}).finally(function() {
					return sessionService.getCurrentStep();
				});
		}

		function fireEvent(event, data) {
			service.trigger(event, data ? [data] : undefined);
			$rootScope.$applyAsync();
		}
	}

	pickemService.$inject = [
		'EventEmitter',
		'apiSessionResource',
		'apiPickemSessionResource',
		'sessionService',
		'$rootScope',
		'utilityService',
		'behaviorEffectService',
		'$animate',
		'viewAnimationService'
	];

	angular.module('pickem').factory('pickemService', pickemService);

})(window.angular, window.jQuery);