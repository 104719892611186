(function (angular) {
    'use strict';

    function smartTooltip($window) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                if(scope.enabled !== false && scope.text && scope.text.length){

                    var defaultOptions = {
                        arrow: true,
                        performance: true,
                        trigger: ''
                    };

                    scope.options.content = document.createElement('span');
                    scope.options.content.innerHTML = scope.text;
                    scope.options.content.style.fontSize = '12px';

                    defaultOptions = angular.extend(defaultOptions, scope.options);
                    var instance = $window.tippy.one(element[0], defaultOptions);
                    element.bind('click',function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        if(instance.state.isMounted){
                            instance.hide();
                        } else {
                            instance.show();
                        }
                    });
                }
            },
            scope: {
                text: '=tooltipText',
                enabled: '=tooltipEnabled',
                options: '=tooltipOptions'
            }
        };
    }

    angular.module('common').directive('smartTooltip', ['$window', smartTooltip]);

})(window.angular);