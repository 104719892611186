(function (angular) {
	'use strict';

	function ocResponsive($window, utilityService) {
		return {
			restrict: 'AE',
			transclude: true,
			replace: true,
			templateUrl: 'app/common/directives/oc-responsive.tmpl.html',
			scope: {
				maxWidth: '@ocMaxWidth',
				minWidth: '@ocMinWidth'
			},
			link: function (scope) {
				var maxWidth = parseInt(scope.maxWidth),
					minWidth = parseInt(scope.minWidth),
					isMaxWidthAvailable = !$window.isNaN(maxWidth),
					isMinWidthAvailable = !$window.isNaN(minWidth),
					_onResize = utilityService.debounce(onResize, 50);

				if (!isMaxWidthAvailable && !isMinWidthAvailable) {
					return false;
				}

				scope.isShown = false;

				$window.addEventListener('resize', _onResize, false);

				scope.$on('$destroy', destroy);

				_onResize();

				function onResize() {
					var windowWidth = $window.innerWidth,
						isShown = false;

					if (isMaxWidthAvailable && maxWidth > windowWidth) {
						isShown = true;
					}

					if (isMinWidthAvailable && minWidth <= windowWidth) {
						isShown = true;
					}

					if (scope.isShown !== isShown) {
						scope.isShown = isShown;
						if (scope.$root.$$phase !== '$apply' && scope.$root.$$phase !== '$digest') {
							scope.$apply();
						}
					}
				}

				function destroy() {
					$window.removeEventListener('resize', _onResize, false);
				}
			}
		};
	}

	ocResponsive.$inject = [
		'$window',
		'utilityService'
	];

	angular.module('common').directive('ocResponsive', ocResponsive);

})(window.angular);