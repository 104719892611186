(function (angular) {
	'use strict';

    angular.module('common').directive('fullParentHeight', function () {
        return {
            restrict: 'A',
            transclude: true,
            template: '<div ng-transclude style="padding: 15px 0; height: 100%;"></div>',
            link: function (scope, elem) {
                function setFullHeifhtToParent(element) {
                    var parent = element && element.parent();
                    angular.element(element).css('height', '100%');
                    angular.element(element).css('overflow', 'hidden');
                    angular.element(element).css('margin', '0 auto');
                    angular.element(element).css('padding', '0');
                    if (parent && parent[0] && parent[0].tagName !== 'BODY') {
                        return setFullHeifhtToParent(parent);
                    } else {
                        return parent;
                    }
                }
                setFullHeifhtToParent(elem);
            }
        };
    });

})(window.angular);