(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.choices = vm.choices.map(function(choice) {return choice.value;});

		vm.init = function (ngModel, answers) {
			if (!ngModel || !answers) return;

			vm.placeholderText = vm.placeholderText || 'Your answer';

			vm.type = 'text';

			vm.getLabel = function (choice) {
				return answers.getLabel(choice);
			};

			vm.onChange = function (value) {
				ngModel.$setViewValue(value);
				vm.onChangeModel({ $inputType: 'text' });
			};

			vm.modelParser = function (value) {
				if (!!vm.forceUppercase && value && value.toUpperCase) {
					value = value.toUpperCase();
				}
				return value;
			};

			ngModel.$render = function () {
				// Specify how UI should be updated when new question was applied
				vm.model = ngModel.$viewValue;
			};
		};
	}

    AnswerCtrl.$inject = [];

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-free-form-single-line-autocomplete.tmpl.html',
			require: ['answerFreeFormSingleLineAutocomplete', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
                inputPattern: '=',
				type: '=',
				enableAutoFocus: '=answerEnableAutoFocus',
				forceUppercase: '=answerForceUppercase',
				answerBackgroundColor: '=',
                displayCountryCodeDropdown: '=',
				country: '=',
				placeholderText: '=',
                answerDisabled: '=',
                useVerification: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);
				if (window.autoComplete) {
					setTimeout(function() {
                        var autoCompleteConfig = {
                            data: {src: scope.answer.choices},
                            trigger: {
                                event: ['input', 'focusin'],
                                condition: function (query) {
                                    return !!query.replace(/ /g, '').length;
                                }
                            },
                            placeHolder: scope.answer.placeholderText,
                            selector: '#autoComplete',
                            debounce: 0,
                            searchEngine: 'strict',
                            highlight: true,
                            maxResults: 5,
                            resultsList: {
                                render: true,
                                container: function (source) {
                                    source.setAttribute('id', 'autoComplete_list');
                                },
                                element: 'ul',
                                destination: document.querySelector('#autoComplete'),
                                position: 'afterend'
                            },
                            resultItem: {
                                content: function (data, source) {
                                    source.innerHTML = data.match;
                                    source.setAttribute('class', 'list-group-item');
                                },
                                element: 'li'
                            },
                            noResults: function () {
                                var result = document.createElement('li');
                                result.setAttribute('class', 'no_result');
                                result.setAttribute('tabindex', '1');
                                result.innerHTML = 'No Results';
                                document.querySelector('#autoComplete_list').appendChild(result);
                            }, onSelection: function (feedback) {
                                document.querySelector('#autoComplete').blur();
                                if (feedback.selection) {
                                    document.querySelector('#autoComplete').value = feedback.selection.value;
                                    scope.answer.onChange({ value: feedback.selection.value });
								}
                            }
                        };
                        new window.autoComplete(autoCompleteConfig);
                    },  100);

				}
			}
		};
	}

	angular.module('answer').directive('answerFreeFormSingleLineAutocomplete', answer);

})(window.angular);