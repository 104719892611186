(function (angular) {
  'use strict';

  function RedemptionPrintCtrl(
    $stateParams,
    $window,
    $log,
    $timeout,
    redemptionService,
    errorService,
    $state,
    notifier,
    $filter,
    confirmationModalService,
    authService
  ) {
    var vm = this,
      translate = $filter('translate');

    vm.model = redemptionService.rewardInfo;
    vm.model.transactionId = redemptionService.lastRedeemedReward.transactionId;
    vm.isShownDescription = false;
    vm.isShownTerms = false;
    vm.redeemCode = redeemCodeWrapper;
    vm.onElapseCountdownTimer = onElapseCountdownTimer;
    vm.readDescription = readDescription;
    vm.readTerms = readTerms;
    vm.isRedeemable = vm.model.isRedeemable;
    vm.printReceipt = printReceipt;
    vm.reprintReceipt = reprintReceipt;
    vm.cssOverride =
      redemptionService.userPermissions.allowPrintOnRedemptionCssOverride;
    vm.isReprint = !redemptionService.firstPrint;
    vm.userPermissions = redemptionService.userPermissions;
    vm.cleanTransactionId = cleanTransactionId;
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = authService.dateTimeFormat();

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[1]
            : date_components[0];
        var month =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[0]
            : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }

    //console.log("the print", redemptionService.userPermissions);
    if (!vm.isReprint) {
      if ($window.matchMedia) {
        var mediaQueryList = $window.matchMedia('print');
        mediaQueryList.addListener(function (mql) {
          if (!mql.matches) {
            afterPrint();
          }
        });
      } else {
        $window.onafterprint = afterPrint;
      }
    }

    function readDescription() {
      vm.isShownDescription = true;
    }

    function readTerms() {
      vm.isShownTerms = true;
    }

    function afterPrint() {
      if (vm.userPermissions.backToPinPad) {
        $state.go('lobby.start');
      } else {
        $state.go('redemption.search');
      }
      if ($window.matchMedia) {
        mediaQueryList.removeListener(function () {});
      } else {
        $window.removeEventListener('onafterprint');
      }
    }

    function printReceipt() {
      $window.print();
    }

    function reprintReceipt() {
      vm.isReprint = true;
      $timeout(function () {
        printReceipt();
      }, 0);
    }

    function redeemCodeWrapper(rewardCode, redemptionNote, redeemAmount) {
      if (vm.model.rewardState === 'Expired') {
        confirmationModalService
          .confirm({
            message:
              'You are redeeming an expired code, do you want to continue?'
          })
          .result.then(function () {
            redeemCode(rewardCode, redemptionNote, redeemAmount);
          });
      } else {
        redeemCode(rewardCode, redemptionNote, redeemAmount);
      }
    }

    function redeemCode(rewardCode, redemptionNote, redeemAmount) {
      if (rewardCode) {
        redemptionService
          .redeemReward(rewardCode, redemptionNote, redeemAmount)
          .then(
            function (response) {
              $log.debug('reward code was marked as redeemed', response);
              notifier.success(translate('The reward has been redeemed'));
              if (response.allowUserPrintOnRedemption) {
                $state.go('redemption.print');
              } else if (vm.userPermissions.backToPinPad) {
                $state.go('lobby.start');
              } else {
                $state.go('redemption.search');
              }
            },
            function (response) {
              vm.errorsList = errorService.parseErrorsToList(
                response && response.data
              );
            }
          )
          .finally(function () {
            vm.isLoading = false;
          });

        vm.isLoading = true;
      }
    }

    function onElapseCountdownTimer() {
      redemptionService
        .getRewardInfo($stateParams.rewardCode)
        .then(function () {
          vm.model = redemptionService.rewardInfo;
        });
    }

    $timeout(function () {
      printReceipt();
    }, 0);

    function cleanTransactionId() {
      vm.model.transactionId = undefined;
      redemptionService.lastRedeemedReward.transactionId = undefined;
    }
  }

  RedemptionPrintCtrl.$inject = [
    '$stateParams',
    '$window',
    '$log',
    '$timeout',
    'redemptionService',
    'errorService',
    '$state',
    'notifier',
    '$filter',
    'confirmationModalService',
    'authService'
  ];

  function redemptionPrint() {
    return {
      restrict: 'AE',
      controller: RedemptionPrintCtrl,
      controllerAs: 'reward',
      bindToController: true,
      templateUrl: 'app/redemption/components/print/redemption-print.tmpl.html'
    };
  }

  angular.module('redemption').directive('redemptionPrint', redemptionPrint);
})(window.angular);
