(function (angular) {
	'use strict';

	function ResetPasswordCtrl(authService, $state, $stateParams, formService, errorService, $filter) {
		var vm = this,
			translate = $filter('translate');

		vm.newPassword = null;
		vm.confirmNewPassword = null;
		vm.saveNewPassword = saveNewPassword;

		function saveNewPassword(formInstance) {
			var resetCode = $stateParams.resetcode;

			formService.makeFieldsDirty(formInstance);

			if (formInstance.$valid && !vm.isLoading) {
				if (vm.newPassword !== vm.confirmNewPassword) {
					return vm.error = translate('Passwords are not the same. Check them again');
				}

				vm.isLoading = true;
				vm.error = null;

				var requestModel = {
					resetToken: resetCode,
					newPassword: vm.newPassword
				};

				authService.updatePasswordFromResetToken(requestModel)
					.then(function () {
						vm.error = null;
						$state.go('login');
					}, function (error) {
						vm.error = errorService.parseErrorsToList(error);
					}).finally(function () {
						vm.isLoading = false;
					});
			}
		}
	}

	ResetPasswordCtrl.$inject = [
		'authService',
		'$state',
		'$stateParams',
		'formService',
		'errorService',
		'$filter'
	];

	function resetUserPassword() {
		return {
			restrict: 'AE',
			templateUrl: 'app/auth/components/resetPassword/reset-password.tmpl.html',
			controllerAs: 'resetPass',
			controller: ResetPasswordCtrl
		};
	}

	angular.module('auth').directive('resetUserPassword', resetUserPassword);

})(window.angular);