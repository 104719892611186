(function (angular) {
	'use strict';

	angular.module('swapReward', [
		'common',
		'session',
		'ui.router',
		'appStorage'
	]);

})(window.angular);