(function (angular) {
	'use strict';

	function draggable() {
		return {
			restrict: 'A',
			scope: {
				draggableSnapClass: '@'
			},
			link: function(scope, element) {
				element.draggable({
					revert: 'invalid',
					revertDuration: 800,
					snap: scope.snapClass,
					snapMode: 'inner',
					snapTolerance: 0,
					zIndex: 10,
					axis: 'y',
					start: function() {
						scope.scrollTopBefore = angular.element('body').scrollTop();
						scope.scrollTop = angular.element('body').scrollTop();
						scope.$root.lastPlace = angular.element(this).parent();
					},
					drag: function(event, ui) {
						var scrollTop = angular.element('body').scrollTop();

						if(scrollTop !== scope.scrollTopBefore) {
							if(scrollTop > scope.scrollTopBefore) {
								if(scope.scrollUp === undefined) {
									scope.scrollDownWasFirst = true;
								}
								scope.scrollUp = false;
							} else {
								if(scope.scrollUp === undefined) {
									scope.scrollDownWasFirst = false;
								}
								scope.scrollUp = true;
							}
							scope.scrollTopBefore = scrollTop;
							if(scope.scrollWas === undefined) {
								scope.scrollWas = true;
								scope.scrollTop = scrollTop;
							}
						}
						if(scope.scrollWas !== undefined) {
							if(scope.scrollUp !== undefined && scope.scrollWas) {
								if (scope.scrollUp) {
									ui.position.top -= scope.scrollTop;
								} else {
									ui.position.top += scrollTop;
									if (scope.scrollDownWasFirst !== undefined && scope.scrollDownWasFirst) {
										ui.position.top -= scope.scrollTop;
									} else {
										ui.position.top -= scrollTop;
									}
								}

							}
						}
					},
					stop: function () {
						scope.scrollWas = undefined;
						scope.scrollTop = angular.element('body').scrollTop();
						scope.scrollTopBefore = angular.element('body').scrollTop();
						scope.scrollUp = undefined;
						scope.scrollDownWasFirst = undefined;
					}
				});
			}
		};
	}

	angular.module('common').directive('draggable', draggable);

})(window.angular);