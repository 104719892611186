(function (angular) {
	'use strict';

	function imageSignature($timeout) {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/image-signature.tmpl.html',
			scope: {
				base64: '=',
                uniqueId: '='
			},
			link: function (scope) {
                $timeout(function() {
                    var canvas = document.getElementById(scope.uniqueId);
                    if (window.SignaturePad && canvas) {
                        scope.signaturePad = new window.SignaturePad(canvas, {
                            backgroundColor: 'rgba(255, 255, 255, 0)',
                            penColor: 'rgb(0, 0, 0)'
                        });

                        window.addEventListener('resize', resizeCanvas);
                        resizeCanvas(canvas);

                        canvas.onmousedown = function() {
                            startSignature();
                        };

                        canvas.ontouchstart = function() {
                            startSignature();
                        };

                        document.addEventListener('mouseup', updateBase64InScope);
                        document.addEventListener('touchend', updateBase64InScope);

                        scope.clearSignature = function () {
                            $timeout(function() {
                                scope.base64 = undefined;
                                scope.signaturePad.clear();
                            });
                        };

                        scope.clearSignature();
                    }
                });

                function startSignature() {
                    scope.startSignature = true;
                }

                function resizeCanvas(canvas) {
                    $timeout(function() {
                        if (canvas && canvas.getContext) {
                            var ratio = Math.max(window.devicePixelRatio || 1, 1);
                            canvas.width = canvas.offsetWidth * ratio;
                            canvas.height = canvas.offsetHeight * ratio;
                            canvas.getContext('2d').scale(ratio, ratio);
                            scope.signaturePad.clear();
                        }
                    });
                }

                function updateBase64InScope() {
                    $timeout(function() {
                        if (scope.startSignature) {
                            scope.base64 = scope.signaturePad.toDataURL('image/png');
                            scope.startSignature = false;
                        }
                    });
                }

                scope.$on(
                    '$destroy', function() {
                        $timeout(function() {
                            window.removeEventListener('resize', resizeCanvas);
                            document.removeEventListener('mouseup', updateBase64InScope);
                            document.removeEventListener('touchend', updateBase64InScope);
                        });
                    }
                );
			}
		};
	}

	imageSignature.$inject = ['$timeout'];

	angular.module('common').directive('imageSignature', imageSignature);

})(window.angular);