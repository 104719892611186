(function (angular) {
	'use strict';

	function infoService($injector) {
		var service = {},
			$q,
			$timeout,
			$state,
			errorService,
			$filter;

		service.catchErrorAndShowAlert = catchErrorAndShowAlert;
		service.showCampaignIsNotConfiguredProperly = showCampaignIsNotConfiguredProperly;
		service.showMessageForCompletedStep = showMessageForCompletedStep;
		service.showMessage = showMessage;
        service.errorData = {};

		return service;

		function showMessage(message) {
            $state = $state || $injector.get('$state');

            service.errorData = {
                message: message
            };

			return $state.go('info');
		}

		function catchErrorAndShowAlert(error) {
			$q = $q || $injector.get('$q');
			$timeout = $timeout || $injector.get('$timeout');
			$state = $state || $injector.get('$state');
			errorService = errorService || $injector.get('errorService');

            service.errorData = {
                message: errorService.parseErrorsToList(error).join('\n'),
                cssOverrides: (error && error.data) ? error.data.cssOverrides : null 
            };

			$timeout(function () {
				$state.go('info');
			});

			return $q.reject(error);
		}

		function showCampaignIsNotConfiguredProperly() {
			var message;

			$q = $q || $injector.get('$q');
			$timeout = $timeout || $injector.get('$timeout');
			$state = $state || $injector.get('$state');
			$filter = $filter || $injector.get('$filter');

			message = $filter('translate')('The campaign is not configured properly.');

            service.errorData = {
                message: message
            };

			$timeout(function () {
				$state.go('info');
			});

			return $q.reject(message);
		}

		function showMessageForCompletedStep() {
			var message;

			$state = $state || $injector.get('$state');
			$filter = $filter || $injector.get('$filter');

			message = $filter('translate')('Your reward has been redeemed.');

            service.errorData = {
                message: message
            };

			return $state.go('info');
		}
	}

	infoService.$inject = [
		'$injector'
	];

	angular.module('info').factory('infoService', infoService);

})(window.angular);