(function (angular) {
	'use strict';

	function SurveyCtrl() {
		//var vm = this;
	}

	function surveyResponses() {
		return {
			restrict: 'AE',
			controller: SurveyCtrl,
			controllerAs: 'survey',
			bindToController: true,
			templateUrl: 'app/redemption/components/surveyResponses/survey-responses.tmpl.html',
			scope: {
				responses: '='
			}
		};
	}

	angular.module('redemption').directive('surveyResponses', surveyResponses);

})(window.angular);