(function (angular) {
	'use strict';

	function AnswerExplanationCtrl($sce) {
		//var vm = this;
		this.model.text = $sce.trustAsHtml(this.model.text);

	}

	AnswerExplanationCtrl.$inject = ['$sce'];

	function answerExplanation() {
		return {
			restrict: 'AE',
			controller: AnswerExplanationCtrl,
			controllerAs: 'explanation',
			bindToController: true,
			templateUrl: 'app/game/components/triviaGame/answer-explanation.tmpl.html',
			scope: {
				model: '=',
				isCorrect: '='
			}
		};
	}

	angular.module('game').directive('answerExplanation', answerExplanation);

})(window.angular);