(function (angular) {
	'use strict';

	angular.module('accessControl', [
		'common',
		'answer',
		'vcRecaptcha',
		'omr.angularFileDnD'
	]);

})(window.angular);