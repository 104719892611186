(function (angular) {
	'use strict';

	function facebookPixelService($window, EventEmitter, $rootScope, $location) {
		var service = Object.create(EventEmitter.prototype),
			cache = {};

		service.tryRun = tryRun;

		return service;

		function tryRun(facebookPixelKey,facebookPixelEvents) {
            if (!Array.isArray(facebookPixelEvents) && (typeof facebookPixelEvents === 'string' || facebookPixelEvents instanceof String)) {
                facebookPixelEvents = [facebookPixelEvents];
            }

			if (!facebookPixelKey || cache[facebookPixelKey]) {
				return false;
			}

			!function(f,b,e,v,n,t,s) {
				if(f.fbq)return;

				n=f.fbq=function() {
					n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments);
				};

				if(!f._fbq) f._fbq=n;

				n.push=n;
				n.loaded=!0;
				n.version='2.0';
				n.queue=[];
				t=b.createElement(e);
				t.async=!0;
				t.src=v;
				s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s);
			}($window,$window.document,'script','https://connect.facebook.net/en_US/fbevents.js');

			$window.fbq('init', facebookPixelKey); // Insert your pixel ID here.
			facebookPixelEvents.forEach(function(fbEvent) {
				$window.fbq('track', fbEvent);
			});

			cache[facebookPixelKey] = true;


			if (!cache['bindToRouteChanges']) {
				bindToRouteChanges();
				cache['bindToRouteChanges'] = true;
			}
		}

		function bindToRouteChanges() {
			// Safely instantiate dataLayer
			var dataLayer = $window.dataLayer || [];

			$rootScope.$on('$stateChangeSuccess', function(event, toState) {
				dataLayer.push({
					event: 'uiRouteChange',
					attributes: {
						route: $location.path(),
						stateName: toState.name
					}
				});
			});
		}
	}

	facebookPixelService.$inject = [
		'$window',
		'EventEmitter',
		'$rootScope',
		'$location'
	];

	angular.module('common').factory('facebookPixelService', facebookPixelService);

})(window.angular);