(function (angular) {
  'use strict';

  function SelectableRewardsListCtrl($window, $uibModal, authService, $filter) {
    var vm = this,
      $ = $window.jQuery;

    vm.currentRewardIndex = 0;
    vm.dateFormatTransform = dateFormatTransform;
    vm.dateFormat = authService.dateTimeFormat();

    function dateFormatTransform(date) {
      var locale = authService.authentication.locale;
      if (locale === 'es-MX' && !date.includes('/')) {
        var spToEnglishMonths = {
          ene: 'January',
          feb: 'February',
          mar: 'March',
          abr: 'April',
          may: 'May',
          jun: 'June',
          jul: 'July',
          ago: 'August',
          sep: 'September',
          oct: 'October',
          nov: 'November',
          dic: 'December'
        };
        var spDate = date.replace(/[.,]/g, '');
        var spMonth = spDate.split(' ').find(function (element) {
          return isNaN(element);
        });
        var englishMonth = spToEnglishMonths[spMonth];
        var englishDate = spDate.replace(spMonth, englishMonth);
        date = new Date(englishDate);
        return $filter('date')(date, vm.dateFormat);
      }

      if (date.includes('/')) {
        var date_components = date.split('/');
        var day =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[1]
            : date_components[0];
        var month =
          vm.dateFormat === 'MM/dd/yy'
            ? date_components[0]
            : date_components[1];
        var year = date_components[2];
        date = new Date(month + '/' + day + '/' + year);
        return $filter('date')(date, vm.dateFormat);
      }

      var f = new Date(date);
      return $filter('date')(f, vm.dateFormat);
    }
    vm.carouselResponsiveSettings = [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ];

    vm.onChangeCurrentIndex = function (currentIndex) {
      vm.currentRewardIndex = currentIndex;
      selectReward(vm.rewardsList[currentIndex]);
    };

    vm.toggleRewardDescription = function (e) {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.parentElement.parentElement.classList.toggle('hover');
    };

    vm.showMobileRewardDetail = function (currentIndex) {
      vm.currentRewardIndex = currentIndex;

      selectReward(vm.rewardsList[currentIndex]);

      $('.mobile-reward-front')
        .removeClass('slideInLeft')
        .addClass('slideOutLeft');

      $('.mobile-reward-back')
        .removeClass('hidden')
        .removeClass('slideOutRight')
        .addClass('slideInRight');
    };

    vm.hideMobileRewardDetail = function () {
      $('.mobile-reward-back')
        .removeClass('slideInRight')
        .addClass('slideOutRight');

      $('.mobile-reward-front')
        .addClass('slideInLeft')
        .removeClass('slideOutLeft');
    };

    vm.showRewardDetails = function (reward, event) {
      event && event.preventDefault && event.preventDefault();
      event && event.stopPropagation && event.stopPropagation();
      $uibModal.open({
        templateUrl: 'app/common/directives/selectable-reward-detail.tmpl.html',
        controller: 'SelectableRewardsDetailCtrl',
        controllerAs: 'swapDetail',
        keyboard: true,
        backdrop: 'static',
        resolve: {
          reward: function () {
            return reward;
          }
        }
      });
    };

    vm.onChangeCurrentIndex(vm.currentRewardIndex);

    function selectReward(reward) {
      vm.selectedReward = reward;
    }
  }

  SelectableRewardsListCtrl.$inject = [
    '$window',
    '$uibModal',
    'authService',
    '$filter'
  ];

  function selectableRewardsList() {
    return {
      restrict: 'AE',
      controller: SelectableRewardsListCtrl,
      controllerAs: 'rewards',
      bindToController: true,
      templateUrl: 'app/common/directives/selectable-rewards-list.tmpl.html',
      scope: {
        rewardsList: '=',
        selectedReward: '=',
        isLoading: '=',
        onChooseReward: '&'
      }
    };
  }

  angular
    .module('common')
    .directive('selectableRewardsList', selectableRewardsList);
})(window.angular);
