(function (angular) {
	'use strict';

    function WeatherService($http, $window) {
		var service = $window.Object.create($window.EventEmitter.prototype);

        service.getWeatherForecast = function (apiKey, zip) {
            
            var apiBaseUrl = 'https://api.openweathermap.org/data/2.5/weather';

            return $http.get(apiBaseUrl,
                {
                    params: {
                        APPID: apiKey,
                        mode: 'json',
                        units: 'imperial',
                        lang: 'en',
                        zip: zip
                    }
                });
        };

        return service;
	}

    WeatherService.$inject = [
        '$http',
		'$window'
	];

    angular.module('dashboard').factory('weatherService', WeatherService);
})(window.angular);