(function (angular) {
	'use strict';

    function run(dashboardService, googleTagManagerService, facebookPixelService) {

        dashboardService.on('onSetCurrentDashboard', function (data) {
			window.multiLanguageSupport = data && data.uiSettings && data.uiSettings.multiLanguageSupport && data.uiSettings.multiLanguageSupport === 1;
            var localSnapshotGoogleTagManagerKey = data && data.uiSettings && data.uiSettings.googleTagManagerKey;
			googleTagManagerService.tryRun(localSnapshotGoogleTagManagerKey || data.googleTagManagerKey);
            facebookPixelService.tryRun(data.facebookPixelKey, data.facebookPixelEvents);
		});
	}

	run.$inject = [
        'dashboardService',
        'googleTagManagerService',
        'facebookPixelService'
	];

	angular.module('dashboard').run(run);

})(window.angular);