(function (angular) {
	'use strict';

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('delivery', {
				url: '/delivery',
				templateUrl: 'app/delivery/layout/delivery.html',
				resolve: {
					reward: ['deliveryService', function (deliveryService) {
						return deliveryService.getReward();
					}]
				}
			})

			.state('deliveryModal', {
				url: '/deliveryModal',
				resolve: {
					model: ['deliveryService', 'deliveryRewardModalService', function (deliveryService, deliveryRewardModalService) {
						return deliveryService.getReward().then(function(model) {
							deliveryRewardModalService.closeAllDeliveryOptionsModals();
							return deliveryRewardModalService.showDeliveryOptionsModal(model);
						});
					}]
				}
			});

		$urlRouterProvider.otherwise('/');
	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider'
	];

	angular.module('delivery').config(config);

})(window.angular);