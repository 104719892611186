(function (angular) {
	'use strict';

	function InactivityConfirmationService(defaults, $rootScope, EventEmitter, confirmationModalService, $q, $timeout, $document, utilityService) {

		var service = Object.create(EventEmitter.prototype),
			options = angular.extend({}, defaults),
			elapsedEvent = options.elapsedEvent,
			documentEventsToCatch = ['click', 'touchstart', 'touchmove', 'keypress'],
			stepTimer = null,
			confirmTimer = null,
			isStarted = false,
			isConfirmShown = false,
			confirmationModalPopup,
			$stateChangeSuccessUnbind,
			onTouchDocumentWithThrottle = utilityService.throttle(onTouchDocument, 500);

		service.start = start;
		service.stop = stop;
		service.isActive = isActive;
		service.showConfirm = showConfirm;
		service.closeConfirm = closeConfirm;
		service.extendOptions = extendOptions;
		service.stopAndFireEvent = stopAndFireEvent;

		return service;

		function start() {
			if (isStarted) {
				return;
			}

			isStarted = true;

			runStepTimer();

			$stateChangeSuccessUnbind = $rootScope.$on('$stateChangeSuccess', onChangeRouteState);
		}

		function stop() {
			isStarted = false;

			stopStepTimer();

			stopConfirmTimer();

			closeConfirm();

			if (typeof $stateChangeSuccessUnbind === 'function') {
				$stateChangeSuccessUnbind();
			}
		}

		function stopAndFireEvent() {
			stop();
			fireEvent(elapsedEvent);
		}

		function isActive() {
			return isStarted;
		}

		function onChangeRouteState() {
			if (isConfirmShown) {
				return;
			}
			stopStepTimer();
			runStepTimer();
		}

		function bindToDocumentClick() {
			documentEventsToCatch.forEach(function (eventName) {
				$document[0].addEventListener(eventName, onTouchDocumentWithThrottle, true);
			});
		}

		function unbindToDocumentClick() {
			documentEventsToCatch.forEach(function (eventName) {
				$document[0].removeEventListener(eventName, onTouchDocumentWithThrottle, true);
			});
		}

		function onTouchDocument() {
			stopStepTimer();
			runStepTimer();
		}

		function showConfirm() {
			if (!options.isConfirmModalEnabled) {
				return $q.reject('Confirmation modal is disabled.');
			}

			if (isConfirmShown) {
				return $q.reject('This modal can be shown only one at the same time.');
			}

			if (!isStarted) {
				return $q.reject('Use method "start" to start service.');
			}

			stopStepTimer();

			runConfirmTimer();

			isConfirmShown = true;

			confirmationModalPopup = confirmationModalService.confirm(options);

			return confirmationModalPopup
				.result
				.then(function () {
					//If needed more time
					stopConfirmTimer();
					runStepTimer();
				}, function () {
					//Stop service
					stopAndFireEvent();
				})
				.finally(function (result) {
					isConfirmShown = false;
					return result;
				});
		}

		function closeConfirm() {
			if (isConfirmShown && confirmationModalPopup) {
				confirmationModalPopup.dismiss();
			}
		}

		function runStepTimer() {
			if (stepTimer) {
				stopStepTimer();
			}
			stepTimer = $timeout(onStepTimerElapsed, options.stepInactiveDuration);
			bindToDocumentClick();
		}

		function runConfirmTimer() {
			if (confirmTimer) {
				stopConfirmTimer();
			}
			confirmTimer = $timeout(onConfirmTimerElapsed, options.confirmInactiveDuration);
		}

		function stopStepTimer() {
			if (stepTimer) {
				$timeout.cancel(stepTimer);
				stepTimer = null;
			}
			unbindToDocumentClick();
		}

		function stopConfirmTimer() {
			if (confirmTimer) {
				$timeout.cancel(confirmTimer);
				confirmTimer = null;
			}
		}

		function onStepTimerElapsed() {
			if (options.isConfirmModalEnabled) {
				return showConfirm();
			}
			return fireEvent(elapsedEvent);
		}

		function onConfirmTimerElapsed() {
			fireEvent(elapsedEvent);
		}

		function extendOptions(newOptions) {
			options = angular.extend(options, newOptions || {});
		}

		function fireEvent(event, data) {
			$timeout(function () {
				service.trigger(event, (typeof data !== 'undefined') ? [data] : undefined);
			});
		}
	}

	function inactivityConfirmationProvider() {
		var that = this;

		this.defaultOptions = {
			isConfirmModalEnabled: true,
			message: 'Your session is about to timeout. Do you want to end?',
			stepInactiveDuration: 40000, //milliseconds
			confirmInactiveDuration: 20000, //milliseconds
			elapsedEvent: 'elapsed',
			okBtnText: 'No, I want to continue',
			cancelBtnText: 'Ok, end my session',
			okBtnClass: 'btn-success',
			cancelBtnClass: 'btn-danger'
		};

		this.$get = ['$rootScope', 'EventEmitter', 'confirmationModalService', '$q', '$timeout', '$document', 'utilityService', function ($rootScope, EventEmitter, confirmationModalService, $q, $timeout, $document, utilityService) {
			return new InactivityConfirmationService(that.defaultOptions, $rootScope, EventEmitter, confirmationModalService, $q, $timeout, $document, utilityService);
		}];
	}

	angular.module('common').provider('inactivityConfirmationService', inactivityConfirmationProvider);

})(window.angular);