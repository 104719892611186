(function (angular) {
	'use strict';

	function overlayService($window, $rootScope, $compile) {
		var service = {},
			promises = [],
			active = false,
			dateStamp,
			overlayEl,
			CAMPAIGN_OVER = 'CampaignOver',
			overlayTemplate = '<page-loader></page-loader>',
			campaignOverTemplate = '<div class="campaign-over-message"> \
									<div class="error-block">\
									<div class="alert alert-warning" ng-bind-html="message">\
									</div></div></div></div>';


		service.show = show;
		service.hide = hide;
		service.isActive = isActive;
		service.clear = clear;

        return service;

		function show(promise, type, message) {
			if (!promise) {
				throw 'promise is required to show overlay';
			}

			if(type == CAMPAIGN_OVER) {
				showCampaignOverEl(message);
			} else {

				showOverlayEl(type);

				addPromise(promise);
			}
		}

		function addPromise(promise) {
			if (!promise || ~promises.indexOf(promise)) {
				return false;
			}

			promises.push(promise);

			promise.finally(function () {
				//use timeout to prevent overlay blinking when after first overlay follows second
				var elapsed = Date.now() - dateStamp;

				if (elapsed < 250) {
					$window.setTimeout(function () {
						removePromiseFromList(promise);
					}, 250 - elapsed);
				} else {
					removePromiseFromList(promise);
				}
			});
		}

		function removePromiseFromList(promise) {
			if (promises.indexOf(promise) > -1) {
				promises.splice(promises.indexOf(promise), 1);
				if (promises.length === 0) {
					hide();
				}
			}
		}

		function showCampaignOverEl(message) {
			hide();
			var campaignOverEl = createCampaignOverEl(message);

			campaignOverEl.style.display = 'block';

			active = true;
		}

		function showOverlayEl() {
			if (!overlayEl) {
				overlayEl = createOverlayEl(overlayTemplate);
			}

			if (isActive()) {
				return false;
			}

			angular.element(overlayEl).css('display', 'block');

			active = true;

		}

		function hide() {
			if (!overlayEl) {
				throw 'Overlay has not been created yet';
			}

			if (!isActive()) {
				return false;
			}

            angular.element(overlayEl).css('display', 'none');

			active = false;
		}

		function isActive() {
			return active;
		}

		function clear() {
			promises = [];
			hide();
		}

		function createOverlayEl() {
			var wrapperEl = $window.document.createElement('div'),
				overlay;

            var scope = $rootScope.$new(true);

            wrapperEl.innerHTML = overlayTemplate;

            overlay = wrapperEl.children[0];

            var html = angular.element(overlayTemplate);

            var compiledHtml = $compile(html)(scope);


            $window.jQuery('body').append(compiledHtml);

			// Prevent scrolling
			overlay.addEventListener('touchmove', function (event) {
				event.preventDefault();
			}, false);

			return compiledHtml;
		}

		function createCampaignOverEl(message) {
			var wrapperEl = $window.document.createElement('div'),
				overlay, scope, html, compiledHtml;

			scope = $rootScope.$new(true);

			scope.message = message;

			wrapperEl.innerHTML = campaignOverTemplate;

			overlay = wrapperEl.children[0];

			html = angular.element(campaignOverTemplate);

			compiledHtml = $compile(html)(scope);


			$window.jQuery('.panel-body').append(compiledHtml);

			// Prevent scrolling
			overlay.addEventListener('touchmove', function (event) {
				event.preventDefault();
			}, false);

			return overlay;
		}

	}

	overlayService.$inject = [
		'$window',
		'$rootScope',
		'$compile'
	];

	angular.module('common').factory('overlayService', overlayService);

})(window.angular);