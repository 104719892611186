(function (angular) {
	'use strict';

	function LobbyCtrl() {
		//var vm = this;
	}

	LobbyCtrl.$inject = [];

	function lobby() {
		return {
			restrict: 'AE',
			controller: LobbyCtrl,
			controllerAs: 'lobbyPickem',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyCampaigns/pickem/lobby-pickem-campaigns.tmpl.html',
			scope: {
				campaigns: '=campaigns',
				settings: '=lobbySettings',
				startCampaign: '&startCampaign',
				cancel: '&cancel'
			}
		};
	}

	angular.module('lobby').directive('lobbyPickemCampaigns', lobby);

})(window.angular);