(function (angular) {
	'use strict';

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('question', {
				url: '/question',
				templateUrl: 'app/question/layout/question.html',
				controller: 'QuestionController',
				controllerAs: '$ctrl',
				resolve: {
					question: ['questionService', function (questionService) {
						return questionService.getQuestionCim({
							showErrorInSeparateState: true
						});
					}]
				}
			});

		$urlRouterProvider.otherwise('/');
	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider'
	];

	angular.module('question').config(config);

	function QuestionController(question) {
		var $ctrl = this;

		$ctrl.question = question;
		$ctrl.isTouchpoint = localStorage.getItem('isTouchpoint') === 'true';
	}

	QuestionController.$inject = ['question'];

	angular.module('question')
		.controller('QuestionController', QuestionController);

})(window.angular);
