(function (angular, $) {
	'use strict';

	function campaignEndDirective(finishInstanceModalService, appModeService, $timeout) {
		return {
			restrict: 'AE',
			replace: true,
			template: '<div class="campaign-end hidden-print" ng-if="isCommonMode()"><span class="glyphicon glyphicon-minus"></span></div>',
			scope: {},
			link: function (scope) {
				scope.campaignMenu = function () {
					finishInstanceModalService.show();
				};

				scope.isCommonMode = function () {
					return appModeService.isCommonMode();
				};

				var campaignTimeout;

				$timeout(function () {
					$('.campaign-end').on('mousedown touchstart', function () {
						campaignTimeout = setTimeout(scope.campaignMenu, 2000);
						return false;
					}).on('mouseup mouseleave touchend touchcancel', function () {
						clearTimeout(campaignTimeout);
					});
				});
			}
		};
	}

	campaignEndDirective.$inject = [
		'finishInstanceModalService',
		'appModeService',
		'$timeout'
	];

	angular.module('common').directive('campaignEnd', campaignEndDirective);

})(window.angular, window.jQuery);