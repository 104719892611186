(function (angular) {
	'use strict';

	function pinService($q, sessionService, EventEmitter, $timeout) {
		var service = Object.create(EventEmitter.prototype);

		service.startSessionByPinCode = startSessionByPinCode;

		return service;

		function startSessionByPinCode(code) {
			return sessionService
				.startSessionByPinCode(code)
				.then(
				onStartSessionByPinCodeSuccessful,
				onPinCodeError
			);
		}

		function onStartSessionByPinCodeSuccessful(sessionId) {
			fireEvent('pincode.onSuccess', sessionId);
			return sessionId;
		}

		function onPinCodeError(error) {
			fireEvent('pincode.onError', error);
			return $q.reject(error);
		}

		function fireEvent(event, data) {
			$timeout(function () {
				service.trigger(event, (typeof data !== 'undefined') ? [data] : undefined);
			});
		}
	}

	pinService.$inject = [
		'$q',
		'sessionService',
		'EventEmitter',
		'$timeout'
	];

	angular.module('pin').factory('pinService', pinService);

})(window.angular);