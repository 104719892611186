(function (angular) {
  'use strict';

  function AnswerCtrl(utilityService) {
    var vm = this;
    vm.year = null;

    if (
      vm.automaticYear ||
      vm.automaticYear === undefined ||
      !vm.endYear ||
      !vm.startYear
    ) {
      vm.years = utilityService.getRange(100, function (el, index) {
        var today = new Date();
        today.setFullYear(today.getFullYear());
        return today.getFullYear() - index;
      });
    } else {
      vm.years = utilityService.getRange(
        vm.endYear - vm.startYear + 1,
        function (el, index) {
          var today = vm.endYear;
          return today - index;
        }
      );
    }
    vm.init = function (ngModel) {
      if (!ngModel) return;

      vm.onChange = function () {
        ngModel.$setViewValue({
          value: vm.year
        });
        vm.onChangeModel({ $inputType: 'DateMonthYear' });
      };

      ngModel.$render = function () {
        // Specify how UI should be updated when new question was applied
        vm.year = null;
      };

      if (vm.isUseTodayDate) {
        var date = new Date();
        vm.year = date.getFullYear();
        vm.onChange();
      }
    };
  }

  AnswerCtrl.$inject = ['utilityService'];

  function answer() {
    return {
      restrict: 'E',
      templateUrl:
        'app/answer/components/answer/answerItems/answer-date-year.tmpl.html',
      require: ['answerDateYear', 'ngModel', '^answers'],
      controller: AnswerCtrl,
      controllerAs: 'answer',
      bindToController: true,
      scope: {
        onChangeModel: '&answerOnChangeModel',
        isRequired: '=answerRequired',
        form: '=answerFormInstance',
        isUseTodayDate: '=',
        answerDisabled: '=',
        answerName: '=',
        startYear: '=',
        endYear: '=',
        automaticYear: '='
      },
      link: function (scope, element, attr, ctrls) {
        ctrls[0].init(ctrls[1], ctrls[2]);
      }
    };
  }

  angular.module('answer').directive('answerDateYear', answer);
})(window.angular);
