(function (angular) {
	'use strict';

	function GameCtrl(gameService, $q) {
		var vm = this;

		vm.model = gameService.game;
		vm.isTriviaGame = gameService.game.gameType === 'Trivia';
		vm.confirmGame = confirmGame;
		vm.showGame = false;

		function confirmGame() {
			var deferred = $q.defer(),
				promise = deferred.promise;

			vm.isLoading = true;

			if (vm.model.gameKind === 'ChanceBased') {
				promise = promise.then(function() {
					return gameService.confirmGame();
				});
			}

			promise = promise.then(function () {
				return gameService.complete();
			});

			promise.finally(function () {
				vm.isLoading = false;
			});

			deferred.resolve();

			return promise;

			/*gameService.confirmGame()
				.then(function () {
					gameService.complete()
						.finally(function () {
							vm.isLoading = false;
						});
				})
				.catch(function () {
					vm.isLoading = false;
				});*/
		}
	}

	GameCtrl.$inject = [
		'gameService',
		'$q'
	];

	function game() {
		return {
			restrict: 'AE',
			controller: GameCtrl,
			controllerAs: 'game',
			bindToController: true,
			templateUrl: 'app/game/components/game/game.tmpl.html'
		};
	}

	angular.module('game').directive('game', game);

})(window.angular);
