(function (angular) {

	function ImageDashboardBoxCtrl() {
		var vm = this;
        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };
	}

	ImageDashboardBoxCtrl.$inject = [];

	function imageDashboardBox() {
		return {
			controller: ImageDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/image/image-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('imageDashboardBox', imageDashboardBox);

})(window.angular);