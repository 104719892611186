(function (angular) {

    function GridDashboardCtrl(dashboardService, utilityService, $scope, $timeout/*, $document*/) {
        var vm = this;
        vm.options  = {
            columns: dashboardService.currentDashboard.boxesLayout.columns || 6,
            maxRows: 10000, //dashboardService.currentDashboard.boxesLayout.maxRows,
            rowHeight: 10,
            minRows: 1,
            minColumns: 1,
            defaultSizeX:1,
            floating: true,
            resizable:{
                enabled: false
            },
            draggable: {
                enabled: false
            }
        };

        vm.isActiveBox = isActiveBox;
        vm.convertToDecimalColor = convertToDecimalColor;

        vm.getMinHeight = getMinHeight;
        vm.getMaxHeight = getMaxHeight;

        vm.isMobileMode = false;

        function isActiveBox(box, boxType) {
            return (box.subtype || box.type) === boxType;
        }

        function convertToDecimalColor(hex) {
            return hex ? utilityService.convertToDecimalColor(hex, 0.85) : null;
        }

        function getMinHeight(box) {
            var height = box.uiSettings.height;
            return height > 0 ? height + 'px' : 'auto';
        }

        function getMaxHeight(box) {
            var height = box.uiSettings.height;
            var maxHeight = box.uiSettings.maxMobileHeight || vm.parentUiSettings.maxMobileHeight;
            var h = height > 0 ? (height + 'px') : ((vm.isMobileMode && maxHeight) ? (maxHeight + 'px') : 'auto');

            return h;
        }

        $scope.$on('gridster-mobile-changed', function (gridster, config) {
            vm.isMobileMode = config.isMobile; 
        });

        $scope.$on('toggle_expand', function (event, model) {
            if (model) {
                var box = vm.boxes[model.index];
                if (box) {
                    var exCols = model.uiSettings.expandColumns || 0;
                    var exRows = model.uiSettings.expandRows || 0;

                    $timeout(function () {
                        if (model.isExpanded) {
                            box.sizeX += exCols;
                            box.sizeY += exRows;
                        } else {
                            box.sizeX += -exCols;
                            box.sizeY += -exRows;
                        }
                    });
                    
                }
            }
        });
    }

    GridDashboardCtrl.$inject = [
        'dashboardService',
        'utilityService',
        '$scope',
        '$timeout'
    ];

    function gridDashboard() {
        return {
            controller: GridDashboardCtrl,
            controllerAs: 'grid',
            bindToController: true,
            scope: {
                boxes: '=',
                options: '=',
                tokensData: '=?',
                parentUiSettings: '='
            },
            templateUrl: 'app/dashboards/components/grid/grid-dashboard-box.tmpl.html'
        };
    }

    angular
        .module('dashboard')
        .directive('gridDashboard', gridDashboard);

})(window.angular);