(function (angular) {
	'use strict';

	function trustedHtmlFilter($sce) {
		return function (html) {
			return $sce.trustAsHtml(html || '');
		};
	}

	trustedHtmlFilter.$inject = [
		'$sce'
	];

	angular.module('common').filter('trustedHtml', trustedHtmlFilter);

})(window.angular);