(function (angular) {
	'use strict';

	function PosCodeService(apiSettings) {
		var service = {},
			baseUrl = apiSettings.apiServiceBaseUri + 'api/info/barcode/';//'http://api-bwipjs.rhcloud.com/';

		service.getPosCodeImageUrl = getPosCodeImageUrl;

		return service;

		function getPosCodeImageUrl(type, code, scale) {
			var params = getUrlParamsFromObject({
				posCodeType: type,
				value: code,
				scale: scale
			});
			return baseUrl + params;
		}

		function getUrlParamsFromObject(obj) {
			var params = '';

			for (var key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key) && typeof obj[key] !== 'undefined') {
					params += params.length ? '&' : '?';
					params += key + '=' + obj[key];
				}
			}
			return params;
		}
	}

	PosCodeService.$inject = [
		'apiSettings'
	];

	angular.module('common').service('posCodeService', PosCodeService);

})(window.angular);