(function (angular) {

	function apiSessionResource(storageService, apiResource) {

		Object.keys(apiResource).filter(function (key) {
			return (typeof apiResource[key] === 'function');
		}).forEach(function (key) {
			resource[key] = function (url) {
				var args = [].slice.call(arguments, 1);
				url = getURL(getBaseUrl(), url) + getChallengeLobbyFlag() ;
				args.unshift(url);
				return apiResource[key].apply(apiResource, args);
			};
		});

		function getSessionId() {
			return storageService.get('sessionId') || null;
		}

		function getBaseUrl() {
			return 'api/instance/' + getSessionId();
		}

		function getChallengeLobbyFlag() {
			return storageService.get('challengeLobby') | 0 > 0 ? '?challengeLobby=1' : '';
        }

		function getURL(url1, url2) {
			if (!/\/$/.test(url1) && !/^\//.test(url2)) {
				url2 = '/' + url2;
			}
			return (url1 + url2).replace(/(.)(\/+)/g, function (str, p1) {
				if (p1 === ':') return str;
				return p1 + '\/';
			});
		}

		function resource(config) {
			config.url = getURL(getBaseUrl(), config.url);
			return apiResource.apply(apiResource, arguments);
		}

		return resource;
	}

	apiSessionResource.$inject = [
		'storageService',
		'apiResource'
	];

	angular.module('session').factory('apiSessionResource', apiSessionResource);

})(window.angular);