(function (angular) {
	'use strict';

	function LobbyAcInteractionCtrl(lobbyService, $state) {
		var vm = this;

		vm.onSuccess = onSuccess;
		vm.onError = onError;

		function onSuccess(accessRequestId) {
			lobbyService.accessRequestId = accessRequestId;
			$state.go('lobby.ac.campaigns');
		}

		function onError() {
			$state.go('lobby.init');
		}
	}

	LobbyAcInteractionCtrl.$inject = [
		'lobbyService',
		'$state'
	];

	function lobbyAcInteraction() {
		return {
			restrict: 'AE',
			controller: LobbyAcInteractionCtrl,
			controllerAs: 'lobbyInteraction',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyAcInteraction/lobby-ac-interaction.tmpl.html'
		};
	}

	angular.module('lobby').directive('lobbyAcInteraction', lobbyAcInteraction);

})(window.angular);