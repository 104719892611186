(function (angular) {
  'use strict';

  function toBoolean(value) {
    if (angular.isString(value)) {
      return value === 'true' ? true : false;
    }
    return Boolean(value);
  }

  function authService(
    $log,
    $window,
    $injector,
    $q,
    $timeout,
    EventEmitter,
    apiSettings,
    utilityService,
    localStorageService,
    storageService,
    notifier,
    $rootScope,
    userActivityService
  ) {
    var service = Object.create(EventEmitter.prototype);

    apiSettings = apiSettings.ngAuthSettings;

    var isRequestForTokenRefreshSent = false;
    var loginInProcess = false;
    var authTokenTimer = {};
    var localStorageAuthKey = 'authorizationData';
    var authentication = {
      loginType: null,
      isAuth: false,
      isAdmin: false,
      isSysAdmin: false,
      userName: '',
      displayName: '',
      locale: 'en-US',
      phoneCode: '+1'
    };

    service.login = loginByUserName;
    service.loginByCToken = loginByCToken;
    service.loginByAToken = loginByAToken;
    service.logout = logOut;
    service.logoutUser = logoutUser;

    service.isLoginInProcess = isLoginInProcess;
    service.refreshLogin = refreshLogin;
    service.fillAuthData = fillAuthData;
    service.getAuthenticationData = getAuthenticationData;
    service.setAuthenticationData = setAuthenticationData;
    service.getFullAuthenticationData = getFullAuthenticationData;

    service.extendAuthenticationData = extendAuthenticationData;
    service.cleanAuthData = cleanAuthData;
    service.isRefreshTokenPresented = isRefreshTokenPresented;
    service.isCTokenPresented = isCTokenPresented;
    service.authentication = authentication;
    service.saveRegistration = saveRegistration;
    service.sendResetPasswordRequest = sendResetPasswordRequest;
    service.updatePasswordFromResetToken = updatePasswordFromResetToken;
    service.getTimeToExpirationToken = getTimeToExpirationToken;
    service.isRequestForTokenRefreshWasSent = isRequestForTokenRefreshWasSent;
    service.setSentRefreshTokenFlag = setSentRefreshTokenFlag;
    service.isUnauthorizedStatus = isUnauthorizedStatus;
    service.isSystemUser = isSystemUser;
    service.resetPinAndPassword = resetPinAndPassword;

    service.setActiveUser = setActiveUser;
    service.dateTimeFormat = dateTimeFormat;

    var authData = getFullAuthenticationData();
    if (authData.length > 0) {
      var user =
        authData.filter(function (ad) {
          return ad.isActive === true;
        })[0] || authData[0];
      if (user) {
        setActiveUser(user, true);
      }
    }

    userActivityService.init(authentication.idleLogoutIn);

    $rootScope.$on(
      'UserActivityService.isUserActive',
      function (scope, isActive) {
        !isActive && !service.isSystemUser() && logOut();
      }
    );

    return service;

    function saveRegistration(registration) {
      logOut();
      return $injector
        .get('$http')
        .post(
          apiSettings.apiServiceBaseUri + 'api/account/register',
          registration
        );
    }

    function _logOut(authData) {
      //return $injector.get('$http').post(apiSettings.apiServiceBaseUri + 'api/users/logout?clientId=' + apiSettings.clientId);

      userActivityService.suspend();

      var data = 'client_id=' + apiSettings.clientId;
      data += '&token_type_hint=refresh_token';
      data += '&token=' + ((authData && authData.refreshToken) || '');

      return $injector
        .get('$http')
        .post(apiSettings.apiServiceBaseUri + 'revoke/?logout=1', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } /*,
                ignoreDefaultErrorNotifier: true*/
        });
    }

    function logOut(path, disableRedirect) {
      var authData = getAuthenticationData();
      _logOut(authData).success(function () {
        cleanAuthData();
        $log.debug('Logging out...');

        !disableRedirect &&
          $timeout(function () {
            $injector.get('$state').go('login', {
              path: path
            });
          });

        setSentRefreshTokenFlag(false);
        fireEvent('auth.logout');
      });
    }

    function logoutUser(user) {
      var authData = getAuthenticationData();
      _logOut(authData).success(function () {
        var isActive = user.isActive;
        cleanAuthData(user);
        $log.debug('User ' + user.userName + ' has logged out');

        var users = getFullAuthenticationData();
        if (isActive && users.length > 0) {
          setActiveUser(users[0], true);
          $log.debug('Redirecting to pinpad...');
          $injector.get('$state').go('lobby.start');
        }

        users.length === 0 && logOut();
        setSentRefreshTokenFlag(false);
        fireEvent('auth.logout');
      });
    }

    function setLoginInProgress(value) {
      loginInProcess = !!value;
    }

    function loginByUserName(loginData, isFacilitated) {
      var authData = getFullAuthenticationData(true).filter(function (u) {
        return u.userName === loginData.userName;
      })[0];

      if (
        loginData.password &&
        loginData.password.length &&
        ~loginData.password.indexOf('+')
      ) {
        loginData.password = loginData.password.replace('+', '%2B');
      }

      var data =
        'grant_type=password&username=' +
        encodeURIComponent(loginData.userName) +
        '&password=' +
        loginData.password +
        '&client_id=' +
        apiSettings.clientId +
        '&twoFactorToken=' +
        (loginData.twoFactorToken || '') +
        '&rememberBrowserToken=' +
        ((authData &&
          authData.rememberBrowserToken &&
          $window.encodeURIComponent(authData.rememberBrowserToken)) ||
          '');

      if (isFacilitated) {
        data += '&facilitated=true';
      }

      return login(data);
    }

    function loginByCToken(cToken) {
      return login(
        'grant_type=campaign_token&ctoken=' +
          cToken +
          '&client_id=' +
          apiSettings.clientId
      );
    }

    function loginByAToken(aToken, context) {
      var data;

      context = context || 'campaign';

      switch (context) {
        case 'campaign':
          data =
            'grant_type=access_token&atoken=' +
            aToken +
            '&client_id=' +
            apiSettings.clientId;
          break;
        case 'lobby':
          data =
            'grant_type=lobby_token&ltoken=' +
            aToken +
            '&client_id=' +
            apiSettings.clientId;
          break;
        default:
          data =
            'grant_type=access_token&atoken=' +
            aToken +
            '&client_id=' +
            apiSettings.clientId;
      }

      return login(data);
    }

    function login(data) {
      var deferred = $q.defer(),
        grantType = data.match(/grant_type=(\w+)&/),
        browserToken = data.match(/rememberBrowserToken=([^&]*)/);

      grantType = grantType && grantType[1];

      setLoginInProgress(true);

      $injector
        .get('$http')
        .post(apiSettings.apiServiceBaseUri + 'token', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          ignoreDefaultErrorNotifier: true
        })
        .then(function (response) {
          localStorage.setItem(
            'loaderUiCustomization',
            response.data.loaderUiCustomization
          );

          if (
            response.data.accountHash === 'D2936FE29960' ||
            response.data.accountHash === '01289f2a2271'
          ) {
            $rootScope.$broadcast('cosmopolitan');
          } else if (
            response.data.accountHash === '1893e4991f7c' ||
            response.data.accountHash === '2af0a71e2e3e'
          ) {
            $rootScope.$broadcast('solaire');
          } else {
            $rootScope.$broadcast('default');
          }

          var btoken =
            response.data.rememberBrowserToken ||
            (browserToken && decodeURIComponent(browserToken[1]));
          btoken && (response.data.rememberBrowserToken = btoken);

          applyNewAuthData(response.data, grantType, true);
          userActivityService.init(authentication.idleLogoutIn);

          deferred.resolve(response.data);
          fireEvent('auth.login');
        })
        .catch(function (err) {
          if (err.data.error === 'verification_required') {
            err.isVerificationRequired = true;
          }
          deferred.reject(err);
        })
        .finally(function () {
          setLoginInProgress(false);
        });

      fireEvent('authService.onStartLogin', deferred);

      return deferred.promise;
    }

    function refreshLogin(authData) {
      $log.debug(
        'Refreshing login for ' + authData
          ? authData.userName
          : 'none' + ' is active'
      );

      //var authData = getAuthenticationData();
      var httpBufferService = $injector.get('httpBufferService');
      var deferred = $q.defer(); // defer until we can re-request a new token

      setSentRefreshTokenFlag(true);

      if (authData && authData.refreshToken) {
        var data =
          'grant_type=refresh_token&refresh_token=' +
          authData.refreshToken +
          '&client_id=' +
          apiSettings.clientId;

        // Get a new token... (cannot inject $http directly as will cause a circular ref)
        $injector
          .get('$http')
          .post(apiSettings.apiServiceBaseUri + 'token', data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            ignoreDefaultErrorNotifier: true
          })
          .then(
            function (loginResponse) {
              if (loginResponse.data) {
                applyNewAuthData(loginResponse.data, authData.grantType, false);

                if (!httpBufferService.isEmpty()) {
                  httpBufferService.retryAll();
                }

                deferred.resolve(loginResponse.data);
              } else {
                deferred.reject({
                  data: {
                    message: "login.json didn't give us data"
                  }
                });
              }
            },
            function (rejection) {
              deferred.reject(rejection); // token retry failed, redirect so user can login again
            }
          )
          .finally(function () {
            setSentRefreshTokenFlag(false);
          });
      } else {
        deferred.reject({
          status: 401,
          data: {
            message: 'Authorization has been denied. Please login.'
          }
        });
      }

      deferred.promise.catch(function (rejection) {
        if (!httpBufferService.isEmpty()) {
          httpBufferService.rejectAll(rejection);
        }

        setSentRefreshTokenFlag(false);

        //notifier.error('Authorization has been denied. Please login.');

        if (rejection && +rejection.status === 0) {
          // if it is network issue

          startAuthTokenTimer(10000, authData); //try to refresh token after some milliseconds
        } else {
          rejection.status = 401;

          $timeout(function () {
            authData ? logoutUser(authData) : logOut(); // token retry failed, redirect so user can login again
          });
        }

        return $q.reject(rejection);
      });

      return deferred.promise; // return the deferred promise
    }

    function setSentRefreshTokenFlag(value) {
      isRequestForTokenRefreshSent = value;
    }

    function isRequestForTokenRefreshWasSent() {
      return isRequestForTokenRefreshSent;
    }

    function isLoginInProcess() {
      return loginInProcess;
    }

    function cleanAuthData(user) {
      clearAuthTokenTimer(user);
      logoutInternal(user);
      authentication = {};
      service.authentication = authentication;
    }

    function logoutInternal(user) {
      var auth =
        localStorageService.get(localStorageAuthKey) ||
        storageService.get(localStorageAuthKey);
      if (auth) {
        if (auth && Array.isArray(auth)) {
          for (var i = 0; i < auth.length; i++) {
            if (auth[i].userName === (user || authentication).userName) {
              if (auth[i].rememberBrowserToken) {
                auth[i] = {
                  rememberBrowserToken: auth[i].rememberBrowserToken,
                  userName: auth[i].userName
                };
              } else {
                auth.splice(i, 1);
              }
              break;
            }
          }
        }
        var data = getAuthenticationData();
        if (data.grantType === 'password') {
          localStorageService.set(localStorageAuthKey, auth);
        } else {
          storageService.set(localStorageAuthKey, auth);
        }
      } else {
        storageService.remove(localStorageAuthKey);
        localStorageService.remove(localStorageAuthKey);
      }
    }

    function applyNewAuthData(data, grantType, login) {
      data.receivedTokenTime = Date.now();
      var auth = {
        token: data.access_token,
        refreshToken: data.refresh_token,
        userName: data.userName,
        accountHash: data.accountHash,
        displayName: data.displayName,
        datetimeFormat: data.datetimeFormat,
        clientName: data.clientName,
        clientLogoUrl: data.clientLogoUrl,
        locale: data.locale,
        googleTagManagerKey: data.googleTagManagerKey,
        facebookPixelKey: data.facebookPixelKey,
        facebookPixelEvents: data.facebookPixelEvents.split(','),
        floodlightPixel: data.floodlightPixel,
        grantType: grantType,
        expires: data['.expires'],
        phoneCode: data.PhoneCode,
        collectLocalIp: toBoolean(data.collectLocalIp),
        countryId: data.countryId,
        rememberBrowserToken: data.rememberBrowserToken,
        useAppleWalletIntegration: toBoolean(data.useAppleWalletIntegration),
        idleLogoutIn: parseInt(data.idleLogoutIn)
      };

      setAuthenticationData(auth);
      setActiveUser(auth, login);
      //startAuthTokenTimer(null, auth);
    }

    function startAuthTokenTimer(specialTimeDiff, authUserData) {
      var timeDiff = specialTimeDiff || getTimeToExpirationToken(authUserData);

      if (timeDiff < 1) {
        return;
      }

      var authData = authUserData || getAuthenticationData();
      clearAuthTokenTimer(authUserData);

      var timer = function ($injector, authData) {
        authTokenTimer[authData.userName] = $window.setTimeout(function () {
          $log.debug('Login for ' + authData.userName + ' has expired');
          var searchParams = utilityService.parseSearchQuery();

          if (searchParams.ctoken && !authData.refreshToken) {
            loginByCToken(searchParams.ctoken);
          } else if (!isRequestForTokenRefreshWasSent()) {
            // var state = $injector.get('$state').current.name;
            // if (state.startsWith('lobby') || state.startsWith('redemption')) {
            //     setSentRefreshTokenFlag(true);
            //     $log.debug('Logging out ' + authData.userName);
            //     authData ? logoutUser(authData) : logOut();
            // } else {
            refreshLogin(authData);
            // }
          }
        }, timeDiff);
      };

      timer($injector, authData);
    }

    function clearAuthTokenTimer(authData) {
      if (authData) {
        $window.clearTimeout(authTokenTimer[authData.userName]);
        delete authTokenTimer[authData.userName];
      } else {
        Object.keys(authTokenTimer).forEach(function (k) {
          $window.clearTimeout(authTokenTimer[k]);
        });
      }
    }

    function getTimeToExpirationToken(auth) {
      var authData = auth || getAuthenticationData();
      var refreshTimerMargin = 10000;

      if (!authData || !authData.expires) {
        return 0;
      }

      return new Date(authData.expires) - new Date() - refreshTimerMargin;
    }

    //function checkActiveUsers(auth) {
    //    if (auth) {
    //        !Array.isArray(auth) && (auth = [auth]);
    //        auth = auth.map(function (u) {
    //            u.isActive = u.userName === authentication.userName;
    //            return u;
    //        });
    //    }
    //}

    function getFullAuthenticationData(includeTwoFactored) {
      var auth;
      if (
        !authentication.isAuth ||
        (authentication.isAuth && authentication.grantType === 'password')
      ) {
        auth = localStorageService.get(localStorageAuthKey);
      } else {
        auth = storageService.get(localStorageAuthKey);
      }

      //checkActiveUsers(auth);

      return includeTwoFactored
        ? auth || []
        : (auth || []).filter(function (a) {
            return !!a.token;
          });
    }

    function getAuthenticationData(isFull) {
      var auth = getFullAuthenticationData(isFull);

      if (Array.isArray(auth)) {
        for (var i = 0; i < auth.length; i++) {
          if (auth[i].userName === authentication.userName) {
            auth = auth[i];
            break;
          }
        }

        if (Array.isArray(auth)) {
          auth = auth[0];
        }
      }

      return auth || authentication;
    }

    function isRefreshTokenPresented() {
      var authData = getAuthenticationData();
      return (
        authData && authData.refreshToken && !!authData.refreshToken.length
      );
    }

    function isCTokenPresented() {
      var searchParams = utilityService.parseSearchQuery(
        $window.location.search
      );
      return searchParams.ctoken && !!searchParams.ctoken.length;
    }

    function setAuthenticationData(data) {
      var auth = localStorageService.get(localStorageAuthKey);
      if (auth && Array.isArray(auth)) {
        var ifAuthDataFound = false;
        for (var i = 0; i < auth.length; i++) {
          if (auth[i].userName === data.userName) {
            var userInfo = angular.copy(auth[i].userInfo);
            auth[i] = data;
            auth[i].userInfo = auth[i].userInfo || userInfo;
            ifAuthDataFound = true;
            break;
          }
        }

        !ifAuthDataFound && auth.push(data);
      } else {
        auth = [data];
      }

      if (data && data.grantType === 'password') {
        localStorageService.set(localStorageAuthKey, auth);
      } else {
        storageService.set(localStorageAuthKey, auth);
      }

      fillAuthData(data);
      fireEvent('auth.onChangeAuthData');
    }

    function fillAuthData(data) {
      var authData = data || getAuthenticationData();

      if (
        authData &&
        (!authentication.userName ||
          authentication.userName === authData.userName)
      ) {
        authentication.isAuth = true;
        //authentication.isActive = true;
        authentication.grantType = authData.grantType;
        authentication.accountHash = authData.accountHash;
        authentication.userName = authData.userName;
        authentication.displayName = authData.displayName;
        authentication.clientName = authData.clientName;
        authentication.clientLogoUrl = authData.clientLogoUrl;
        authentication.locale = authData.locale;
        authentication.googleTagManagerKey = authData.googleTagManagerKey;
        authentication.facebookPixelKey = authData.facebookPixelKey;
        authentication.facebookPixelEvents = authData.facebookPixelEvents;
        authentication.floodlightPixel = authData.floodlightPixel;
        authentication.phoneCode = authData.phoneCode;
        authentication.collectLocalIp = authData.collectLocalIp;
        authentication.countryId = authData.countryId;
        authentication.useAppleWalletIntegration =
          authData.useAppleWalletIntegration;
        authentication.rememberBrowserToken = authData.rememberBrowserToken;
        authentication.idleLogoutIn = authData.idleLogoutIn;

        service.authentication = authentication;
      }
    }

    function setActiveUser(user, login) {
      if (login) {
        authentication = user;
        authentication.isActive = true;
      }

      getFullAuthenticationData().forEach(function (auth) {
        var e = login
          ? extendAuthenticationData(
              {
                isActive: auth.userName === user.userName,
                loggingOut:
                  auth.userName === user.userName
                    ? user.loggingOut
                    : auth.loggingOut
              },
              auth
            )
          : extendAuthenticationData(
              {
                isActive: auth.userName === authentication.userName,
                loggingOut:
                  auth.userName === authentication.userName
                    ? authentication.loggingOut
                    : auth.loggingOut
              },
              auth
            );

        if (e.isActive) {
          $log.debug('User ' + e.userName + ' is active');
        }

        startAuthTokenTimer(null, e);
      });

      //startAuthTokenTimer(null, user);
      //fireEvent('auth.onChangeAuthData');
    }

    function extendAuthenticationData(data, source) {
      var currentData = source || getAuthenticationData() || {};
      var ext = angular.extend(currentData, data);
      setAuthenticationData(ext);

      return ext;
    }

    function isSystemUser() {
      var authData = getAuthenticationData();
      return authData.displayName === 'System User';
    }

    function dateTimeFormat() {
      var authData = getAuthenticationData();
      return authData.datetimeFormat;
    }

    function fireEvent(event, data) {
      $timeout(function () {
        service.trigger(event, data ? [data] : undefined);
      });
    }

    function sendResetPasswordRequest(emailValue, sourceForm) {
      var deferred = $q.defer();

      $injector
        .get('$http')
        .post(
          apiSettings.apiServiceBaseUri + 'api/Users/reset-password',
          {
            email: emailValue,
            sourceForm: sourceForm,
            requestSiteName: 'CobraSiteUrl'
          },
          {
            ignoreDefaultErrorNotifier: true
          }
        )
        .success(function (response) {
          deferred.resolve(response);
        })
        .error(function (err, status) {
          $log.debug('rejecting', err, status);
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function updatePasswordFromResetToken(model) {
      var deferred = $q.defer();

      $injector
        .get('$http')
        .post(
          apiSettings.apiServiceBaseUri + 'api/Users/update-password',
          {
            resetToken: model.resetToken,
            newPassword: model.newPassword
          },
          {
            ignoreDefaultErrorNotifier: true
          }
        )
        .success(function (response) {
          deferred.resolve(response);
        })
        .error(function (err, status) {
          $log.debug('rejecting', err, status);
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function isUnauthorizedStatus(status) {
      return status == 401 || status == 0; // status 0 for IE10 (IE10 bug)
    }

    function resetPinAndPassword(model) {
      var deferred = $q.defer();

      $injector
        .get('$http')
        .post(
          apiSettings.apiServiceBaseUri + 'api/Users/update-password-direct',
          model
        )
        .then(
          function (data) {
            notifier.success('Password was successfully reset');
            deferred.resolve(data);
          },
          function (error) {
            deferred.reject(error);
          }
        );

      return deferred.promise;
    }
  }

  authService.$inject = [
    '$log',
    '$window',
    '$injector',
    '$q',
    '$timeout',
    'EventEmitter',
    'apiSettings',
    'utilityService',
    'localStorageService',
    'storageService',
    'notifier',
    '$rootScope',
    'userActivityService'
  ];

  angular.module('auth').factory('authService', authService);
})(window.angular);
