(function (angular) {
  'use strict';

  function accessControlService(
    apiResource,
    $window,
    $rootScope,
    $q,
    sessionService
  ) {
    var service = Object.create($window.EventEmitter.prototype),
      search = $window.location.search;

    service.accessControlId = null;
    service.accessRequestId = null;
    service.startAccessControlForLobby = startAccessControlForLobby;
    service.startAccessControlForCampaign = startAccessControlForCampaign;
    service.submitInteractionForLobby = submitInteractionForLobby;
    service.submitInteractionForCampaign = submitInteractionForCampaign;
    service.submitInteraction = submitInteraction;
    service.getInteractionType = getInteractionType;
    service.fireEvent = fireEvent;
    service.pageConfigurations = null;
    service.lobbyUiConfiguration = null;
    service.isInit = undefined;
    service.interactionsCount = 0;

    return service;

    function startAccessControlForLobby(
      accessControlId,
      isPreviewMode,
      pinCode,
      lobbyConfig
    ) {
      return startAccessControl(
        'lobby',
        accessControlId,
        isPreviewMode,
        pinCode,
        lobbyConfig
      );
    }

    function startAccessControlForCampaign(
      accessControlId,
      isPreviewMode,
      pinCode
    ) {
      sessionService.isPreviewedMode = isPreviewMode;
      return startAccessControl(
        'campaign',
        accessControlId,
        isPreviewMode,
        pinCode
      );
    }

    function startAccessControl(
      context,
      accessControlId,
      isPreviewMode,
      pinCode,
      lobbyConfig
    ) {
      accessControlId = accessControlId || service.accessControlId;

      isPreviewMode = isPreviewMode || false;

      var query =
        search + (search ? '&' : '?') + 'isPreviewMode=' + isPreviewMode;

      return apiResource
        .post('/api/access/' + context + '/start/' + accessControlId + query, {
          pinCode: pinCode
        })
        .then(function (data) {
          return apiResource
            .get('/api/access/' + accessControlId + '/pageConfigurations')
            .then(function (resp) {
              service.accessControlId = accessControlId;
              service.pageConfigurations = resp;
              service.lobbyUiConfiguration =
                lobbyConfig && lobbyConfig.uiConfiguration
                  ? lobbyConfig.uiConfiguration
                  : null;
              return onSuccessInteraction(data);
            });
        });
    }

    function submitInteractionForLobby(answer, accessRequestId) {
      return submitInteraction('lobby', answer, accessRequestId);
    }

    function submitInteractionForCampaign(answer, accessRequestId) {
      return submitInteraction('campaign', answer, accessRequestId);
    }

    function submitInteraction(context, answers, accessRequestId) {
      accessRequestId = accessRequestId || service.accessRequestId;

      var accessPayloadModels = [];

      if (!Array.isArray(answers)) answers = [answers];
      answers.forEach(function (answ) {
        accessPayloadModels.push({
          ruleId: answ.ruleId,
          payload: answ.value,
          isConsented: answ.isConsented
        });
      });

      var deferred = $q.defer();

      apiResource
        .post(
          '/api/access/' + context + '/continue/' + accessRequestId + search,
          accessPayloadModels
        )
        .then(function (data) {
          deferred.resolve(onSuccessInteraction(data));
        });
      /*.catch(function(error) {
						if (context === 'campaign' && error && error.status === 400 && error.data) {
							var syntheticErrorInteraction = {};
                            syntheticErrorInteraction.interactions = [];
                            syntheticErrorInteraction.interactions.push({ruleType: 'Error', continuationId: accessRequestId, text: error.data.message});
                            deferred.resolve(syntheticErrorInteraction);
						}
					})*/

      return deferred.promise;
    }

    function onSuccessInteraction(data) {
      if (data instanceof Array) {
        var interactionObject = {};
        interactionObject.interactions = [];
        data.forEach(function (interaction) {
          interactionObject.accessRequestId =
            interactionObject.accessRequestId || interaction.accessRequestId;
          interactionObject.requireAnswer =
            interactionObject.requireAnswer != undefined
              ? interactionObject.requireAnswer
              : interaction.requireAnswer;
          interactionObject.uiState =
            interactionObject.uiState || parseJSON(interaction.uiState);

          var interactionItem = parseJSON(interaction.interactionJson);
          interactionItem.uiState =
            interactionItem.uiState && parseJSON(interactionItem.uiState);
          interactionItem.forceUppercase =
            interactionItem.uiState && interactionItem.uiState.forceUppercase;
          interactionItem.enableAutoFocus =
            interactionItem.uiState && interactionItem.uiState.enableAutoFocus;
          interactionItem.uiCustomization =
            interactionItem.uiState && interactionItem.uiState.uiCustomization;
          interactionItem.title =
            interactionItem.uiState && interactionItem.uiState.title;
          interactionObject.interactions.push(interactionItem);
        });
        service.accessControl = interactionObject;
        service.accessRequestId = interactionObject.accessRequestId;
        service.interactions = interactionObject.interactions;
        fireEvent('onSuccessInteraction', interactionObject);
        return interactionObject;
      } else {
        //Old scenario
        data.interaction = parseJSON(data.interactionJson);
        data.uiState = parseJSON(data.uiState);
        angular.extend(data.interaction, data.uiState || {});
        service.accessControl = data;
        service.interaction = data.interaction;
        service.accessRequestId = data.accessRequestId;
        fireEvent('onSuccessInteraction', data);
        return data;
      }
    }

    function getInteractionType(interaction) {
      if (interaction.ruleType === 'Success') {
        return 'success';
      }

      if (
        interaction.ruleType === 'Error' ||
        interaction.ruleType === 'Autogenerated'
      ) {
        return 'error';
      }

      if (
        interaction.ruleType === 'ResumeExisting' &&
        interaction.isShowErrorMessage
      ) {
        return 'error';
      }

      if (interaction.ruleType === 'Retry') {
        return 'retry';
      }

      if (interaction.ruleType === 'ResumeExisting') {
        return 'resumeExisting';
      }

      if (interaction.ruleType === 'CAPTCHA') {
        return 'captcha';
      }

      if (interaction.questionType && interaction.questionType.length) {
        return 'question';
      }

      if (interaction.ruleType === 'Redirect') {
        return 'redirect';
      }

      if (interaction.ruleType === 'ZipCode') {
        return 'zip';
      }
    }

    function parseJSON(json) {
      try {
        return $window.JSON.parse(json);
      } catch (e) {
        return null;
      }
    }

    function fireEvent(event, data) {
      service.trigger(event, typeof data !== 'undefined' ? [data] : undefined);
      $rootScope.$applyAsync();
    }
  }

  accessControlService.$inject = [
    'apiResource',
    '$window',
    '$rootScope',
    '$q',
    'sessionService'
  ];

  angular
    .module('accessControl')
    .factory('accessControlService', accessControlService);
})(window.angular);
