(function(angular) {
	'use strict';

	angular.module('pickem', [
		'common',
		'session',
		'ui.router',
		'ngTouch'
	]);

})(window.angular);
