(function (angular) {
	'use strict';

	function PickemCimCtrl($uibModal, pickemService, formService, notifier, $filter, utilityService) {
		var vm = this,
			translate = $filter('translate'),
			validationRequiredErrorMessage = translate('Please give an answer on the required question.');

		vm.pickemCim = pickemService.pickemCim;
		vm.questions = vm.pickemCim.items;
		vm.questionsSortedByFormattedDate = [];
		vm.uiCustomization = vm.pickemCim.uiCustomization;
		vm.uiCustomization.elementBackgroundColor = vm.uiCustomization.elementBackgroundColor || 'FFFFFF';
		vm.questionBackgroundColor = utilityService.convertToDecimalColor(vm.uiCustomization.elementBackgroundColor, vm.uiCustomization.elementBackgroundOpacity);
		vm.accept = accept;

		(function(arr){
			$filter('orderBy')(vm.questions, 'gameDate').forEach(function(i) {
				if (arr[arr.length-1] && arr[arr.length-1][0].formattedDate === i.formattedDate) {
					arr[arr.length-1].push(i);
				} else {
					arr.push([i]);
				}
			});
		})(vm.questionsSortedByFormattedDate);

		function accept(questionsForm) {
			formService.makeFieldsDirty(questionsForm);

			if (questionsForm.$error && questionsForm.$error.required) {
				return notifier.warning(validationRequiredErrorMessage);
			}

			if (vm.isLoading || questionsForm.$invalid) {
				return;
			}

			vm.isLoading = true;

			return pickemService.answerToQuestion(vm.questions)
				.then(function () {
					if(pickemService.pickemExists()) {
						return $uibModal.open({
							templateUrl: 'app/pickem/components/pickemConfirmationModal/pickem-confirmation.tmpl.html',
							controller: ['$uibModalInstance', function($uibModalInstance) {
								this.close = function() {
									$uibModalInstance.dismiss();
								};
							}],
							size: 'sm',
							controllerAs: 'vm',
							keyboard: true,
							backdrop: 'static'
						});
					}
					return pickemService
						.complete()
						.finally(function () {
							vm.isLoading = false;
							if(pickemService.pickemExists()) {
								notifier.success('Your picks have been saved.');
							}
						});
				})
				.finally(function () {
					vm.isLoading = false;
				});
		}
	}

	PickemCimCtrl.$inject = [
		'$uibModal',
		'pickemService',
		'formService',
		'notifier',
		'$filter',
		'utilityService'
	];

	function pickemCim() {
		return {
			restrict: 'AE',
			controller: PickemCimCtrl,
			controllerAs: 'pickem',
			bindToController: true,
			templateUrl: 'app/pickem/components/pickemCim/pickem-cim.tmpl.html'
		};
	}

	angular.module('pickem').directive('pickemCim', pickemCim);

})(window.angular);