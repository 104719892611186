(function (angular) {
	'use strict';

	function KioskService(apiResource, $window) {
		var service = {},
			location = $window.location;

		service.getCampaigns = getCampaigns;
		service.goToKiosk = goToKiosk;
		service.startCampaign = startCampaign;
		service.getConfiguration = getConfiguration;
		service.getConfigurationByAToken = getConfigurationByAToken;
		service.getCampaignsSlides = getCampaignsSlides;
		service.getCampaignsByCampaignTag = getCampaignsByCampaignTag;
		service.getCampaignsSlidesByCampaignTag = getCampaignsSlidesByCampaignTag;
		service.getKioskConfigByAToken = getKioskConfigByAToken;
		service.getCampaignsByAToken = getCampaignsByAToken;
		service.getCampaignsSlidesByAToken = getCampaignsSlidesByAToken;
		service.goToKioskByAToken = goToKioskByAToken;

		return service;

		function getKioskConfigByAToken(aToken) {
			return apiResource.get('/api/kiosk/a/' + aToken).then(function (data) {
				service.lobby = data;
				service.accessControlId = data.accessControlId;
				service.isPreviewMode = data.isPreviewMode;
				return service.lobby;
			});
		}

		function getCampaignsByAToken(aToken, accessRequestId) {
			return apiResource.get('/api/kiosk/a/campaigns/' + aToken + '/' + accessRequestId).then(function (data) {
				service.lobbyCampaigns = data;
				return service.lobbyCampaigns;
			});
		}

		function getCampaignsSlidesByAToken(aToken) {
			return apiResource.get('/api/kiosk/a/' + aToken + '/slideshow').then(onCampaignsSlidesSuccess);
		}

		function goToKioskByAToken(aToken) {
			var url = location.protocol + '\/\/' + location.hostname + ':' + location.port + '/kiosk.html#/ac/' + aToken;
			return location.assign(url);
		}

		function getCampaigns(lobbyToken) {
			return apiResource.get('/api/kiosk/' + lobbyToken).then(function (data) {
				service.lobby = data;
				service.lobbyToken = lobbyToken;
				return service.lobby;
			});
		}

		function getCampaignsByCampaignTag(accountHash, campaignTag) {
			return apiResource.get('/api/kiosk/' + accountHash + '/' + campaignTag).then(function (data) {
				service.lobby = data;
				service.lobbyToken = data.lobbyId;
				return service.lobby;
			});
		}

		function getConfiguration(lobbyToken) {
			return apiResource.get('/api/kiosk/' + lobbyToken + '/configuration').then(function (data) {
				service.lobbyToken = lobbyToken;
				service.configuration = data;
				return service.configuration;
			});
		}

		function getConfigurationByAToken(lobbyToken) {
			return apiResource.get('/api/kiosk/a/' + lobbyToken + '/configuration').then(function (data) {
				service.lobbyToken = lobbyToken;
				service.configuration = data;
				return service.configuration;
			});
		}

		function goToKiosk(lobbyToken) {
			var url = location.protocol + '\/\/' + location.hostname + ':' + location.port + '/kiosk.html#/' + lobbyToken;
			return location.assign(url);
		}

		function startCampaign(cToken, lobbyToken) {
			var url = location.protocol + '\/\/' + location.hostname + ':' + location.port + '/?cToken=' + cToken + '&lobbyToken=' + lobbyToken;
			return location.assign(url);
		}

		function getCampaignsSlides(lobbyToken) {
			return apiResource.get('/api/kiosk/' + lobbyToken + '/slideshow').then(onCampaignsSlidesSuccess);
		}

		function getCampaignsSlidesByCampaignTag(accountHash, campaignTag) {
			return apiResource.get('/api/kiosk/' + accountHash + '/' + campaignTag + '/slideshow').then(onCampaignsSlidesSuccess);
		}

		function onCampaignsSlidesSuccess(data) {
			service.campaignsSlides = data;
			service.campaignsSlides.slides = service.campaignsSlides.mediaList || [];
			if (service.campaignsSlides.slides.length === 0) {
				service.campaignsSlides.slides = service.campaignsSlides.campaignImages || [];
			}
			return data;
		}
	}

	KioskService.$inject = [
		'apiResource',
		'$window'
	];

	angular.module('common').factory('kioskService', KioskService);

})(window.angular);