(function (angular) {
	'use strict';

	function run($rootScope, $q, $state, $translate, authService, sessionService, deliveryService, overlayService, lobbyService, accessControlService, utilityService, googleTagManagerService, facebookPixelService, gameService, kioskService, infoService, behaviorEffectService) {

		var lobbyAToken = utilityService.parseSearchQuery().lobbytoken;

		$rootScope.authentication = authService.authentication;

		(function (states, startedStates) {
			$rootScope.$on('$stateChangeStart', function (event, toState) {
				if (!authService.authentication.isAuth
					&& !$state.includes('lobby.**')
					&& !~['login', 'resetPassword', 'forgotPassword', 'info', 'lobby.init'].indexOf(toState.name)) {
					event.preventDefault();
					return $state.go('lobby.init');
				}

				var prevStartedState = startedStates.slice(-1)[0];

				startedStates = [prevStartedState, toState];

				if (!prevStartedState ||
					prevStartedState && prevStartedState.name !== toState.name) {

					// Bind deferred object to the new state
					var deferred = $q.defer();
					toState.__stateTransitionDeferred = deferred;

					// Show overlay
					overlayService.show(deferred.promise);
				}

				// Reject previous started state.
				if (prevStartedState && prevStartedState.__stateTransitionDeferred) {
					prevStartedState.__stateTransitionDeferred.resolve();
				}
			});

			$rootScope.$on('$stateChangeSuccess', function (event, toState) {
				if (toState.__stateTransitionDeferred) {
					//we need resolve/reject deferred object in order to hide overlay
					toState.__stateTransitionDeferred.resolve();
				}
				//store the new state to be able to prevent going on the one step back
				states.push(toState);
				//track only the last two states
				states = states.slice(-2);
				//close a cim effect if such exists
				behaviorEffectService.removeAll();
			});

			$rootScope.$on('$stateChangeError', function (event, toState) {
				if (toState.__stateTransitionDeferred) {
					//we need resolve/reject deferred object in order to hide overlay
					toState.__stateTransitionDeferred.reject();
				}
			});
		})([], []);

		//$translate.use(authService.authentication.locale);

		// Override goToKioskMethod
		kioskService.goToKiosk = function() {
			return $state.go('lobby.init');
		};

		accessControlService.on('success', function (data) {
			lobbyService.accessRequestId = data.accessRequestId;
		});

		lobbyService.on('onGetLobbyConfig', function (lobbyConfig) {
			if(lobbyConfig && lobbyConfig.uiConfiguration && lobbyConfig.uiConfiguration.multiLanguageSupport) {
				window.multiLanguageSupport = lobbyConfig.uiConfiguration.multiLanguageSupport;
			}
			$translate.use(lobbyConfig.locale);
			googleTagManagerService.tryRun(lobbyConfig.googleTagManagerKey);
            facebookPixelService.tryRun(lobbyConfig.facebookPixelKey, lobbyConfig.facebookPixelEvents);
		});

		accessControlService.on('resume', function (data) {
			var instanceId = data.instanceId,
				isChallengeLobby = data.isChallengeLobby;

			if (isChallengeLobby) {
                sessionService.setChallengeLobbyState(data.accessRequestId);
			}

			if (authService.authentication.isAuth) {
				return sessionService.resumeSessionById(instanceId);
			}

			authService.loginByAToken(lobbyAToken, 'lobby').then(function () {
				return sessionService.resumeSessionById(instanceId);
			});
		});

		authService.on('auth.login', function () {
			//$translate.use(authService.authentication.locale);
			googleTagManagerService.tryRun(authService.authentication.googleTagManagerKey);
		});

		authService.on('authService.onStartLogin', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onStartSession', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onStartGettingCurrentStep', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onStartGettingCampaign', function (deferred) {
			overlayService.show(deferred.promise);
		});

		deliveryService.on('delivery.onStartGettingModal', function (deferred) {
			overlayService.show(deferred.promise);
		});

		// To implement CIM View Animation
		Array.prototype.push.apply(sessionService.STEP_STATE_NAMES, [
			'lobby.campaigns',
			'game',
			'pickem',
			'question',
			'embedded',
			'swapReward',
			'chooseReward',
			'deliveryModal',
			'delivery',
			'redeem'
		]);

		sessionService.on('session.onChangeCurrentStep', function onChangeCurrentStep(step) {
			var STEPS = sessionService.STEPS;

			var stateOptions = {
				reload: true,
				inherit: false,
				notify: true
			};

			if (step.prevStep && step.prevStep.stepType === STEPS.GAME
				&& step.stepType !== STEPS.DELIVERY
				&& step.stepType !== STEPS.GAME
				&& gameService.displayCollect) {

				return $state.go('gameCollectModal', null, {
					inherit: true,
					notify: false
				});
			}

			if (step.stepType === STEPS.SELECT_CAMPAIGN) {
				if ($state.current.name === 'lobby.campaigns') {
					return $state.reload();
				}
				return $state.go('lobby.campaigns', null, {
					reload: false,
					inherit: false,
					notify: true
				});
			}

			if (step.stepType === STEPS.GAME) {
				if ($state.current.name === 'game') {
					return $state.reload();
				}
				return $state.go('game', null, stateOptions);
			}

			if (step.stepType === STEPS.PICKEM) {
				if ($state.current.name === 'pickem') {
					return $state.reload();
				}
				return $state.go('pickem', null, stateOptions);
			}

			if (step.stepType === STEPS.QUESTION) {
				if ($state.current.name === 'question') {
					return $state.reload();
				}
				return $state.go('question', null, stateOptions);
			}

			if (step.stepType === STEPS.EMBEDDED) {
				if ($state.current.name === 'embedded') {
					return $state.reload();
				}
				return $state.go('embedded', null, stateOptions);
			}

			if (step.stepType === STEPS.SWAP_REWARD) {
				if ($state.current.name === 'swapReward') {
					return $state.reload();
				}
				return $state.go('swapReward', null, stateOptions);
			}

			if (step.stepType === STEPS.CHOOSE_REWARD) {
				if ($state.current.name === 'chooseReward') {
					return $state.reload();
				}
				return $state.go('chooseReward', null, stateOptions);
			}

			if (step.stepType === STEPS.DELIVERY) {
				/*if ($state.current.name === 'delivery') {
				 return $state.reload();
				 }
				 return $state.go('delivery', null, stateOptions);*/

				return $state.go('deliveryModal', null, {
					notify: false
				});
			}

			if (step.stepType === STEPS.THANK_YOU) {
				if ($state.current.name === 'redeem') {
					return $state.reload();
				}
				return $state.go('redeem', null, stateOptions);
			}

			if (step.stepType === STEPS.COMPLETED) {
				return infoService.showMessageForCompletedStep();
			}

            if (step.stepType === STEPS.LOBBYHOME) {
				lobbyService.accessRequestId = step.accessRequestId;
				return $state.go('lobby.ac.campaigns');
            }

			//appropriate state was not found
			throw 'Appropriate step was not found for common mode';
		});

		sessionService.on('session.onComplete', function onComplete() {
			$state.go('lobby.init');
		});

	}

	run.$inject = [
		'$rootScope',
		'$q',
		'$state',
		'$translate',
		'authService',
		'sessionService',
		'deliveryService',
		'overlayService',
		'lobbyService',
		'accessControlService',
		'utilityService',
		'googleTagManagerService',
        'facebookPixelService',
		'gameService',
		'kioskService',
		'infoService',
		'behaviorEffectService'
	];

	angular.module('commonMode').run(run);

})(window.angular);