(function (angular) {
	'use strict';

	function ErrorService($window) {
		var service = {};

		service.errorTypes = {
			USE_BLOCKED: 'UseBlocked'
		};
		service.parseErrorsToList = parseErrorsToList;
		service.createErrorModel = createErrorModel;
		service.parseAndExtendHttpError = parseAndExtendHttpError;
        service.lastErrorMessage = null;

		return service;

		function parseErrorsToList(error) {
			var list = [];

            error = error && error.data || error || {};

            if (error.exceptionMessage) {
                list.push(error.exceptionMessage);
            } else if (error.errors && error.errors.length) {
                list = error.errors.map(function (nestedError) {
					return nestedError.message;
                });
            } else if (error.modelState && error.modelState.errors) {
                [].push.apply(list, error.modelState.errors);
            } else if (error.message) {
                list.push(error.message);
            } else if (error.error_description) {
                list.push(error.error_description);
            } else {
                list.push('Connection lost. Please refresh the page.');
            }
            
			return list;
		}

		function createErrorModel(message) {
			return {
				message: message
			};
		}

		function parseAndExtendHttpError(error) {
			var parsedSpecialErrorObject;

			if (error && error.data && error.data.message && error.data.message.length > 0) {
				try {
					parsedSpecialErrorObject = $window.JSON.parse(error.data.message);
                    error.data.message = parsedSpecialErrorObject.alternativeMessage || parsedSpecialErrorObject.message;
                    error.data.cssOverrides = parsedSpecialErrorObject.cssOverrides;
					error.errorType = parsedSpecialErrorObject.type;
				} catch (e) {
					//
				}
			}

			return error;
		}
	}

	ErrorService.$inject = [
		'$window'
	];

	angular.module('common').factory('errorService', ErrorService);

})(window.angular);