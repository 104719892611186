(function (angular) {
  'use strict';

    function localIpService(/*notifier*/) {
    var service = {};

    service.getAddresses = getAddresses;

    return service;

    function getAddresses(callback) {
      var addresses = [];
      /*
       * NOTE:  If this should be a cross-browser feature we will need Adapter.js:
       *        https://github.com/webrtc/adapter/
       */
      var WebRTCPeerConnection = window.RTCPeerConnection
        || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;

        if (WebRTCPeerConnection) {
            var connection = new WebRTCPeerConnection({ iceServers: [] });
            if (connection.createDataChannel) {
                connection.createDataChannel('');
                connection.onicecandidate = function (e) {
                    if (!e.candidate) {
                        connection.close();
                        callback(addresses);
                        return;
                    }

                    var ip = /^candidate:.+ (\S+) \d+ typ/.exec(e.candidate.candidate)[1];
                    if (addresses.indexOf(ip) == -1) {
                        addresses.push(ip);
                    }
                };

                connection.createOffer(function (obj) {
                    connection.setLocalDescription(obj);
                }, function onerror() { });
            } else {
                connection.close();
                tryUseORTC(callback);
            }
        } else {
            tryUseORTC(callback);
        }
      }

      function tryUseORTC(callback) {
          if (window.RTCIceGatherer) {
              var ipAddresses = [];

              var oRTCIceGatherer = new window.RTCIceGatherer({ 'gatherPolicy': 'all', 'iceServers': [] });
              oRTCIceGatherer.onlocalcandidate = function (oEvent) {
                  if (oEvent.candidate.type == 'host') {
                      ipAddresses.push(oEvent.candidate.ip);
                  }

                  if (!oEvent.candidate.type) {
                      callback(ipAddresses);
                  }
              };
          } else {
              //notifier.error('Sorry, this feature is not supported by your browser, please switch to Chrome, Firefox or Edge.');
              callback([]);
          }
      }
  }

  localIpService.$inject = ['notifier'];

  angular.module('common').factory('localIpService', localIpService);

})(window.angular);
