(function (angular) {
	'use strict';

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('email', {
				abstract: true,
				url: '/email',
                template: '<div ui-view style="height:100%"></div>',
                resolve: {
                    checkClient: ['$q', '$state', '$timeout', 'authService', function ($q, $state, $timeout, authService) {
                        var auth = authService.getAuthenticationData();
                        if (auth && auth.userInfo && auth.userInfo.uiCustomization && (auth.userInfo.uiCustomization.isShowEmailButton.toLowerCase() === 'true')) {
                            return 'ok';
                        }
                        $timeout(function () {
                            $state.go('lobby.start');
                        });
                        return $q.reject();
                    }]
                }
			})
			.state('email.search', {
				url: '/',
				templateUrl: 'app/email/layout/search.html'
			})
			//.state('redemption.print', {
			//	url: '/:rewardCode/print',
			//	templateUrl: 'app/redemption/layout/print.html',
			//	params: {firstPrint: false},
			//	resolve: {
			//		checkPinCode: ['redemptionService', '$q', '$state', '$timeout', function (redemptionService, $q, $state, $timeout) {
			//			if (redemptionService.getCurrentPinCodeForRedemption()) {
			//				return 'ok';
			//			}
			//			$timeout(function () {
			//				$state.go('lobby.start');
			//			});
			//			return $q.reject('Pin Code is needed');
			//		}],
			//		model: ['redemptionService', '$stateParams', function (redemptionService, $stateParams) {
			//			return redemptionService.getRewardInfo($stateParams.rewardCode,false);
			//		}],
			//		isReprint: ['redemptionService', '$stateParams', function (redemptionService, $stateParams) {
			//			redemptionService.firstPrint = !!$stateParams.firstPrint;
			//		}]
			//	}
			//})
			.state('email.details', {
				url: '/:email/details',
				templateUrl: 'app/email/layout/details.html',
				resolve: {
					checkPinCode: ['redemptionService', '$q', '$state', '$timeout', function (redemptionService, $q, $state, $timeout) {
						if (redemptionService.getCurrentPinCodeForRedemption()) {
							return 'ok';
						}
						$timeout(function () {
							$state.go('lobby.start');
						});
						return $q.reject('Pin Code is needed');
					}],
					model: ['redemptionService', '$stateParams', function (redemptionService, $stateParams) {
						if (redemptionService.rewardInfo
							&& redemptionService.rewardInfo.combinedCode.codeValue === $stateParams.rewardCode) {
							return redemptionService.rewardInfo;
						}
						return redemptionService.getRewardInfo($stateParams.rewardCode,false);
					}]
				}
			});

		$urlRouterProvider.otherwise('/');
	}

	config.$inject = [
		'$stateProvider',
        '$urlRouterProvider'
	];

	angular.module('email').config(config);

})(window.angular);