(function (angular) {
	'use strict';

	function config($stateProvider) {
		$stateProvider
			.state('pin', {
				url: '/pin?rewardCode',
				templateUrl: 'app/pin/layout/pin.html',
				resolve: {
					templateCacheFix: ['$q', '$timeout', function ($q, $timeout) {
						var deferred = $q.defer();
						$timeout(function () {
							deferred.resolve();
						});
						return deferred.promise;
					}],
					authChecking: ['$q', 'authService', function ($q, authService) {
						var expTime = authService.getTimeToExpirationToken();

						if (authService.authentication.isAuth && expTime > 0) {
							return {};
						}

						if (authService.authentication.isAuth && expTime < 1) {
							return authService.refreshLogin();
						}

						authService.logout();

						return $q.reject();
					}]
				}
			});
	}

	config.$inject = [
		'$stateProvider'
	];

	angular.module('pin').config(config);

})(window.angular);