(function (angular) {
    'use strict';

    function googleButton() {

        return {
            restrict: 'E',
            scope:{
                jwt: '='
            },
            template: '<div id="googleContainer"></div>',
            link: function (scope) {
                window.gapi.savetoandroidpay.render('googleContainer',{
                    'jwt': scope.jwt,
                    'onsuccess': 'successHandler',
                    'onfailure': 'failureHandler'
                });

                window.successHandler = function () {
                };

                window.failureHandler = function () {
                };
            }
        };
    }

    angular.module('common').directive('googleButton', googleButton);

})(window.angular);