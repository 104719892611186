(function (angular) {

	function ThreeLeadersAndListLeaderboardCtrl() {
		/*var vm = this;

		vm.getTopLeaderWidth = function(index) {
			var scale = vm.model.uiSettings.zoom || 1;

			if (index === 0) {
				return Math.min(33, 33 * scale) + '%';
			}
			if (index === 1) {
				return Math.min(25 * scale) + '%';
			}
			if (index >= 2) {
				return Math.min(20 * scale) + '%';
			}
		};*/
	}

	ThreeLeadersAndListLeaderboardCtrl.$inject = [];

	function threeLeadersAndListLeaderboard() {
		return {
			controller: ThreeLeadersAndListLeaderboardCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '=',
				countdownOptions: '=',
				countdownModelId: '=countdownId',
				onElapse: '&',
				isCountdownShown: '&'
			},
			templateUrl: 'app/dashboards/components/leaderboard/three-leaders-and-list-leaderboard.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('threeLeadersAndListLeaderboard', threeLeadersAndListLeaderboard);

})(window.angular);