(function (angular) {
	'use strict';

    function LookupSubscriptionsModalCtrl(subscriptionService, formService, list, $uibModal) {
		var vm = this;

        vm.list = list;
        vm.preview = preview;
        vm.resend = resend;
        vm.view = view;

        vm.capitalize = function (word) {
            return word[0].toUpperCase() + word.substring(1);
        };

        vm.getColumns = function (recipients, colsNum) {

            var cols = [];
            var colsObj = [];

            (recipients && recipients.length > 0) && recipients.forEach(function (l) {

                for (var key in l.context) {
                    if (l.context.hasOwnProperty(key)) {
                        var col = vm.capitalize(key);
                        if (cols.indexOf(col) === -1) {
                            cols.push(col);
                            colsObj.push({ header: col, fieldName: key });
                        }
                    }
                }
            });

            return !colsNum ? colsObj : colsObj.slice(0, colsNum);
        };

        vm.columns = vm.getColumns(vm.list, 5);

        function resend(sub) {
            if (window.confirm('Resend email to ' + sub.email + '?')) {
                sub.resending = true;
                subscriptionService.resendEmail(sub.subscriptionRecipientId).finally(function () {
                    sub.resending = false;
                    vm.refresh && vm.refresh();
                });
            }
        }

        function preview(sub) {
            sub.isPreviewLoading = true;
            $uibModal.open({
                templateUrl: 'app/email/components/details/preview-emails-modal.tmpl.html',
                controller: 'PreviewEmailsModalCtrl',
                controllerAs: 'modal',
                size: 'lg',
                resolve: {
                    template: function () {
                        return subscriptionService.previewTemplate(sub.subscriptionRecipientId).finally(function () { sub.isPreviewLoading = false; });
                    }
                }
            });
        }

        function view(sub) {
            $uibModal.open({
                templateUrl: 'app/email/components/details/subscription-details.tmpl.html',
                controller: 'SubscriptionDetailsCtrl',
                controllerAs: 'modal',
                size: 'lg',
                resolve: {
                    model: function () {
                        return sub;
                    }
                }
            });
        }
	}

    LookupSubscriptionsModalCtrl.$inject = [
		'subscriptionService',
        'formService',
        'list',
        '$uibModal'
	];

    angular.module('email').controller('LookupSubscriptionsModalCtrl', LookupSubscriptionsModalCtrl);

})(window.angular);