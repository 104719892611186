(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.isTouchpoint = localStorage.getItem('isTouchpoint') === 'true';
		vm.touchPointGrid = touchPointGrid();
		vm.init = function (ngModel, answers) {
			if (!ngModel || !answers) return;

			vm.answerRenderMode = vm.answerRenderMode || 'RadioButton';
			vm.placeholderText = answers.placeholderText;

			vm.getLabel = function (choice) {
				return answers.getLabel(choice);
			};

			vm.onChange = function () {
				ngModel.$setViewValue(vm.model);
				vm.onChangeModel({ $inputType: vm.answerRenderMode === 'RadioButton' ? 'radio' : 'dropdown' });
			};

			vm.selectAnswer = function (choice) {
				vm.model = choice;
				vm.onChange();
			};

			ngModel.$render = function () {
				// Specify how UI should be updated
				vm.model = ngModel.$viewValue;
			};
		};

		function touchPointGrid() {
			var itemsPerColumn = 4;

			var firstColumn = vm.choices.slice(0, itemsPerColumn);
			var secondColumn = vm.choices.slice(itemsPerColumn);

			return [firstColumn, secondColumn];
		}
	}

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-multiple-choice.tmpl.html',
			require: ['answerMultipleChoice', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
				answerBackgroundColor: '=',
				layout: '=',
				answerDisabled: '=',
				answerRenderMode: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);
			}
		};
	}

	angular.module('answer').directive('answerMultipleChoice', answer);

})(window.angular);