(function (angular) {
	'use strict';

	function deliveryRewardModalService($rootScope, $document, $compile, $q, $animate, EventEmitter) {
		var service = Object.create(EventEmitter.prototype),
			$body = $document.find('body'),
			stack = [];

		service.showDeliveryOptionsModal = show;
		service.closeDeliveryOptionsModal = remove;
		service.closeAllDeliveryOptionsModals = removeAll;

		return service;

		function show(model) {
			var deferred = $q.defer(),
				html,
				element,
				scope,
				instance;

			scope = $rootScope.$new(true);

			scope.model = model;

			scope.$close = function () {
				remove(instance);
			};

			html = angular.element('<delivery-reward-modal class=\"view-animation\" model=\"model\" $close=\"$close()\"></delivery-reward-modal>');

			element = $compile(html)(scope);

			// We must fire event before insertion in DOM to make working VIEW-ANIMATION on DeliveryModal
			fireEvent('delivery.onShowDeliveryModal');

			$animate.enter(element, $body);

			instance = {
				element: element,
				scope: scope,
				deferred: deferred
			};

			stack.push(instance);

			return deferred.promise;
		}

		function remove(instance) {
			var index = stack.indexOf(instance);

			if (index === -1) return;

			stack.splice(index, index);

			$animate.leave(instance.element);

			instance.scope.$destroy();

			instance.deferred.resolve();

			fireEvent('delivery.onCloseDeliveryModal');
		}

		function removeAll() {
			for (var i = stack.length - 1; i >= 0; i--) {
				remove(stack[i]);
			}
		}

		function fireEvent(event, data) {
			service.trigger(event, (typeof data !== 'undefined') ? [data] : undefined);
			$rootScope.$applyAsync();
		}
	}

	deliveryRewardModalService.$inject = [
		'$rootScope',
		'$document',
		'$compile',
		'$q',
		'$animate',
		'EventEmitter'
	];

	angular.module('delivery').factory('deliveryRewardModalService', deliveryRewardModalService);

})(window.angular);