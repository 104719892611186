(function(angular) {
	'use strict';

	angular.module('question', [
		'answer',
		'common',
		'session',
		'ui.router'
	]);

})(window.angular);
