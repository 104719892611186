(function (angular) {
	'use strict';

	function EffectCtrl($scope, $window, $timeout, $log) {
		var vm = this,
			Howl = $window.Howl,
			effectDuration = +vm.options.effectDuration * 1000,
			backgroundMusic,
			timer;

		vm.closeEffect = closeEffect;

		backgroundMusic = new Howl({
			urls: [vm.options.effectSound || ''],
			loop: false,
			autoplay: true
		});

		$scope.$on('$destroy', function () {
			backgroundMusic.unload();
			$timeout.cancel(timer);
		});

        switch(vm.options.effectType){
            // case 'billsRain':
            //     var maxBills = 100;
            //     var i = 0;
            //     var interval = setInterval(function () {
            //         if (i === maxBills) {
            //             clearInterval(interval);
            //         }
            //         i++;
            //         var width = window.innerWidth - 200;
            //
            //         var randomPosition = Math.floor(width * Math.random());
            //
            //         var randomDelay = Math.random();
            //         var randomDuration = Math.random() + 10;
            //
            //
            //         var bill = angular.element('<span class='dollar'>')
            //             .css({
            //                 left: randomPosition,
            //                 top: '0',
            //                 '-webkit-animation-delay': randomDelay + 's',
            //                 '-webkit-animation-duration': randomDuration + 's'
            //             });
            //
            //         bill.prepend('<img src='images/bill.svg' alt='bill_img'>');
            //
            //         var body = angular.element(document).find('body');
            //
            //         body.append(bill);
            //     },500);
            //     break;
            case  'CoinsRain':
                var element = document.querySelector('.behavior-effect-box');
                var canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d'),
                    focused = false,
                    newCoins = true,
                    coin = new Image();

                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
                canvas.id = 'coinRain';

                coin.src = 'images/coin.png'; // 440 wide, 40 high, 10 states
                coin.onload = function () {
                    element.appendChild(canvas);
                    focused = true;

                    timer = $timeout(function () {
                        newCoins = false;
                        closeEffect();
                    }, effectDuration);

                    var coins = [];

                    (function drawloop() {
                        if (focused) {
                            requestAnimFrame(drawloop);
                        }

                        ctx.clearRect(0, 0, canvas.width, canvas.height);

                        if (Math.random() < .3 && newCoins) {
                            coins.push({
                                x: Math.random() * canvas.width | 0,
                                y: -50,
                                dy: 3,
                                s: 0.5 + Math.random(),
                                state: Math.random() * 10 | 0
                            });
                        }

                        var i = coins.length;
                        while (i--) {
                            var x = coins[i].x;
                            var y = coins[i].y;
                            var s = coins[i].s;
                            var state = coins[i].state;
                            coins[i].state = (state > 9) ? 0 : state + 0.1;
                            coins[i].dy += 0.3;
                            coins[i].y += coins[i].dy;

                            ctx.drawImage(coin, 44 * Math.floor(state), 0, 44, 40, x, y, 44 * s, 40 * s);

                            if (y > canvas.height) {
                                coins.splice(i, 1);
                            }
                        }
                    })();
                };
                break;
            case 'ConfettiRain':
                confettiRain();
                break;
            default:
                if (effectDuration > 0) {
                    timer = $timeout(closeEffect, effectDuration);
                }
                break;
        }


        function closeEffect() {
            return vm.$close();
        }

        function requestAnimFrame(callback) {
            return window.requestAnimationFrame(callback) ||
                window.webkitRequestAnimationFrame(callback) ||
                window.mozRequestAnimationFrame(callback) ||
                window.oRequestAnimationFrame(callback) ||
                window.msRequestAnimationFrame(callback) ||
                function (callback) {
                    if (callback) {
                        return window.setTimeout(callback, 1000 / 60);
                    } else {
                        return $log.error('Function requestAnimFrame(): callback is undefined');
                    }
                };
        }


        function confettiRain() {
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d'),
                mp = 150,
                particles = [],
                angle = 0,
                tiltAngle = 0,
                confettiActive = true,
                animationComplete = false,
                animationHandler,
                particleColors = {
                    colorOptions: ['DodgerBlue', 'OliveDrab', 'Gold', 'pink', 'SlateBlue', 'lightblue', 'Violet', 'PaleGreen', 'SteelBlue', 'SandyBrown', 'Chocolate', 'Crimson'],
                    colorIndex: 0,
                    colorIncrementer: 0,
                    colorThreshold: 10,
                    getColor: function () {
                        if (this.colorIncrementer >= 10) {
                            this.colorIncrementer = 0;
                            this.colorIndex++;
                            if (this.colorIndex >= this.colorOptions.length) {
                                this.colorIndex = 0;
                            }
                        }
                        this.colorIncrementer++;
                        return this.colorOptions[this.colorIndex];
                    }
                };

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            canvas.id = 'confettiRain';

            document.querySelector('.behavior-effect-box').appendChild(canvas);

            for (var i = 0; i < mp; i++) {
                var particleColor = particleColors.getColor();
                particles.push(new confettiParticle(particleColor));
            }

            (function animloop() {
                if (animationComplete) return null;
                animationHandler = requestAnimFrame(animloop);
                return Draw();
            })();

            timer = $timeout(function () {
                confettiActive = false;
                clearTimeout(animationHandler);
                closeEffect();
            },effectDuration);//Deactivate confetti

            function confettiParticle(color) {
                this.x = Math.random() * window.innerWidth; // x-coordinate
                this.y = (Math.random() * window.innerHeight) - window.innerHeight; //y-coordinate
                this.r = Math.floor(Math.random() * (30 - 10 + 1) + 10); //radius;
                this.d = (Math.random() * mp) + 10; //density;
                this.color = color;
                this.tilt = Math.floor(Math.random() * 10) - 10;
                this.tiltAngleIncremental = (Math.random() * 0.07) + .05;
                this.tiltAngle = 0;

                this.draw = function () {
                    ctx.beginPath();
                    ctx.lineWidth = this.r / 2;
                    ctx.strokeStyle = this.color;
                    ctx.moveTo(this.x + this.tilt + (this.r / 4), this.y);
                    ctx.lineTo(this.x + this.tilt, this.y + this.tilt + (this.r / 4));
                    return ctx.stroke();
                };
            }
            function Draw() {
                ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
                var results = [];
                for (var j = 0; j < mp; j++) {
                    results.push(particles[j].draw());
                }
                var remainingFlakes = 0;
                var particle;
                angle += 0.01;
                tiltAngle += 0.1;

                for (var i = 0; i < mp; i++) {
                    particle = particles[i];
                    if (animationComplete) return;

                    if (!confettiActive && particle.y < -15) {
                        particle.y = window.innerHeight + 100;
                        continue;
                    }

                    particle.tiltAngle += particle.tiltAngleIncremental;
                    particle.y += (Math.cos(angle + particle.d) + 3 + particle.r / 2) / 2;
                    particle.x += Math.sin(angle);
                    particle.tilt = (Math.sin(particle.tiltAngle - (i / 3))) * 15;

                    if (particle.y <= window.innerHeight) {
                        remainingFlakes++;
                    }

                    if ((particle.x > window.innerWidth + 20 || particle.x < -20 || particle.y > window.innerHeight) && confettiActive) {
                        if (i % 5 > 0 || i % 2 == 0) //66.67% of the flakes
                        {
                            repositionParticle(particle, Math.random() * window.innerWidth, -10, Math.floor(Math.random() * 10) - 20);
                        } else {
                            if (Math.sin(angle) > 0) {
                                //Enter from the left
                                repositionParticle(particle, -20, Math.random() * window.innerHeight, Math.floor(Math.random() * 10) - 20);
                            } else {
                                //Enter from the right
                                repositionParticle(particle, window.innerWidth + 20, Math.random() * window.innerHeight, Math.floor(Math.random() * 10) - 20);
                            }
                        }
                    }
                }

                if (remainingFlakes === 0) {
                    stopConfetti();
                }

                return results;
            }

            function repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
                particle.x = xCoordinate;
                particle.y = yCoordinate;
                particle.tilt = tilt;
            }

            function stopConfetti() {
                animationComplete = true;
                if (ctx === undefined) return;
                ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
                canvas.remove();
            }
        }

	}

	EffectCtrl.$inject = [
		'$scope',
		'$window',
		'$timeout',
		'$log'
	];

	function behaviorEffect() {
		return {
			restrict: 'AE',
			controller: EffectCtrl,
			controllerAs: 'effect',
			bindToController: true,
			templateUrl: 'app/behavior/components/display/behavior-effect.tmpl.html',
			scope: {
				options: '=',
				$close: '&'
			}
		};
	}

	angular.module('behavior').directive('behaviorEffect', behaviorEffect);

})(window.angular);