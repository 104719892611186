(function (angular) {
    'use strict';

    function
        personProfileService($window, apiResource) {
        var service = Object.create($window.EventEmitter.prototype);

        service.profiles = [];

        service.getPersons = getPersons;
        service.updatePerson = updatePerson;
        service.sendSignUpLink = sendSignUpLink;

        service.userPermissions = '';

        return service;

        function getPersons(externalId) {
            return apiResource.get('/api/personProfile/lookup', {
                params: {
                    'externalId': externalId,
                    'redeemUserToken': service.userPermissions.redeemUserToken || ''
                }
            })
                .then(function (data) {
                    service.profiles = angular.isArray(data) ? data : [];

                    return service.profiles;
                });
        }

        function updatePerson(person) {
            return apiResource.put('/api/personProfile/' + person.externalId, person);
        }

        function sendSignUpLink(person) {
            return apiResource.post('/api/personProfile/autoSignUpLink', person);
        }
    }

    personProfileService.$inject = [
        '$window',
        'apiResource'
    ];

    angular.module('lobby').factory('personProfileService', personProfileService);

})(window.angular);