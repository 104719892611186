(function (angular) {
	'use strict';

	function marquee($timeout) {
		return {
			restrict: 'A',
			scope: true,
			compile: function(tElement) {
				if (tElement.children().length === 0) {
					tElement.append('<div>' + tElement.text() + '</div>');
				}
				var content = tElement.children();
				var $element = angular.element(tElement);
				angular.element(tElement).empty();
				tElement.append('<div class="angular-marquee" style="float:left;">' + content.clone()[0].outerHTML + '</div>');
				var $item = $element.find('.angular-marquee');
				$item.clone().css('display','none').appendTo($element);
				$element.wrapInner('<div style="width:1000000px" class="angular-marquee-wrapper"></div>');
				return {
					post: function(scope, element, attrs) {
						var $element = angular.element(element);
						var $item = $element.find('.angular-marquee:first');
						var $marquee = $element.find('.angular-marquee-wrapper');
						var $cloneItem = $element.find('.angular-marquee:last');
						var duplicated = false;

						var containerWidth = parseInt($element.width());
						var itemWidth = parseInt($item.width());
						var defaultOffset = 20;
						var duration = 3000;
						var scroll = false;
						var pause = false;
						var animationCssName = '';

						function calculateWidthAndHeight() {
							containerWidth = parseInt($element.width());
							itemWidth = parseInt($item.width());
							if (itemWidth > containerWidth) {
								duplicated = true;
							} else {
								duplicated = false;
							}

							if (duplicated) {
								$cloneItem.show();
							} else {
								$cloneItem.hide();
							}

							$element.height($item.height());
						}

						function _objToString(obj) {
							var tabjson = [];
							for (var p in obj) {
								if (obj.hasOwnProperty(p)) {
									tabjson.push(p + ':' + obj[p]);
								}
							}
							tabjson.push();
							return '{' + tabjson.join(',') + '}';
						}

						function calculateAnimationDuration(newDuration) {
							var result = (itemWidth + containerWidth) / containerWidth * newDuration / 1000;
							if (duplicated) {
								result = result / 2;
							}
							return result;
						}

						function getAnimationPrefix() {
							var elm = document.body || document.createElement('div');
							var domPrefixes = ['webkit', 'moz','O','ms','Khtml'];

							for (var i = 0; i < domPrefixes.length; i++) {
								if (elm.style[domPrefixes[i] + 'AnimationName'] !== undefined) {
									var prefix = domPrefixes[i].toLowerCase();
									return prefix;
								}
							}
						}

						function createKeyframe(number) {
							var prefix = getAnimationPrefix();

							var margin = itemWidth;
							var keyframeString = '@-' + prefix + '-keyframes ' + 'simpleMarquee' + number;
							var css = {
								'margin-left': - (margin) +'px'
							};
							var keyframeCss = keyframeString + '{ 100%' + _objToString(css) + '}';
							var $styles = angular.element('style');
							if ($styles.length !== 0) {
								$styles.filter(':last').append(keyframeCss);
							} else {
								angular.element('head').append('<style>' + keyframeCss + '</style>');
							}
						}

						function stopAnimation() {
							$marquee.css('margin-left',0);
							if (animationCssName != '') {
								$marquee.css(animationCssName, '');
							}

						}

						function createAnimationCss(number) {
							var time = calculateAnimationDuration(duration);
							var prefix = getAnimationPrefix();
							animationCssName = '-' + prefix +'-animation';
							var cssValue = 'simpleMarquee' + number + ' ' + time + 's 0s linear infinite';
							$marquee.css(animationCssName, cssValue);
							if (duplicated) {
								$marquee.css('margin-left', 0);
							} else {
								var margin = containerWidth + defaultOffset;
								$marquee.css('margin-left', margin);
							}
						}

						function animate() {
							calculateWidthAndHeight();
							var number = Math.floor(Math.random() * 1000000);
							createKeyframe(number);
							createAnimationCss(number);
						}

						function pauseMarquee() {
							$marquee.css('animation-play-state', 'paused');
						}

						function resumeMarquee() {
							$marquee.css('animation-play-state', 'running');
						}

						scope.$watch(attrs.pause, function(pauseAttrValue) {
							pause = pauseAttrValue;
							toggleMarquee();
						});

						scope.$watch(attrs.scroll, function(scrollAttrValue) {
							scroll = scrollAttrValue;
							recalculateMarquee();
						});

						function recalculateMarquee() {
							if (scroll) {
								animate();
							} else {
								stopAnimation();
							}
						}

						function toggleMarquee() {
							if (pause) {
								pauseMarquee();
							} else {
								resumeMarquee();
							}
						}

						var timer;
						scope.$on('recalculateMarquee', function() {
							if (timer) {
								$timeout.cancel(timer);
							}
							timer = $timeout(function() {
								recalculateMarquee();
							}, 500);

						});

						scope.$watch(attrs.duration, function(durationText) {
							duration = parseInt(durationText);
							if (scroll) {
								animate();
							}
						});
					}
				};
			}
		};
	}

	marquee.$inject = [
		'$timeout'
	];

	angular.module('common').directive('marquee', marquee);

})(window.angular);