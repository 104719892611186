(function (angular) {
	'use strict';

	function run($rootScope, $state, $translate, authService, sessionService, $q, overlayService, utilityService, storageService, errorService, $filter, inactivityConfirmationService, kioskService, $uibModalStack, infoService, behaviorEffectService) {

		var isTransitionToInfoStateProgrammed = false,
			searchParams = utilityService.parseSearchQuery(),
			isPreview = utilityService.isPreviewToken(searchParams.ctoken),
			lobbyToken = searchParams.lobbytoken,
			translate = $filter('translate');

		$rootScope.authentication = authService.authentication;

		$translate.use(authService.authentication.locale);

		authService.on('auth.login', function () {
			$translate.use(authService.authentication.locale);
			startSession();
		});

		if (!authService.authentication.isAuth || authService.getTimeToExpirationToken() < 1) {
			//login
			authService.loginByCToken(searchParams.ctoken);
			//reset route as it is not reasonable to try to fetch any data without auth
			$state.go('campaignModeStart');
		} else if (!sessionService.isSessionKeyExist() && !sessionService.isSessionStarting()) {
			startSession();
		} else {
			sessionService.setPreviewFlag(isPreview);
		}

		if (lobbyToken) {
			//Launch service to track inactivity
			kioskService.getConfiguration(lobbyToken).then(function (configuration) {
				inactivityConfirmationService.extendOptions({
					confirmInactiveDuration: configuration.needMoreTimeDelay * 1000,
					stepInactiveDuration: configuration.idleTimeout * 1000
				});
				inactivityConfirmationService.start();
			});

			inactivityConfirmationService.on('elapsed', function () {
				//Close all modals
				$uibModalStack.dismissAll();
				//Move to kiosk lobby
				return kioskService.goToKiosk(lobbyToken);
			});
		}

		(function () {
			var stateNum = 0;

			$rootScope.$on('$stateChangeStart', function (event, toState) {
				stateNum++;

				//prevent login state
				if (toState.name === 'login') {
					event.preventDefault();
					return $state.go('info', {
						message: translate('The requested campaign is not available. Please check the link and try again later')
					});
				}

				//if we start from 'info' state then reset it and start from scratch
				if (stateNum === 1 && toState.name === 'info' && !isTransitionToInfoStateProgrammed) {
					event.preventDefault();
					return $state.go('campaignModeStart');
				}

				//bind deferred object to the new state
				var deferred = $q.defer();
				toState.__stateTransitionDeferred = deferred;
				//show overlay
				overlayService.show(deferred.promise);
				//console.log('$stateChangeStart', event);
			});

			$rootScope.$on('$stateChangeSuccess', function (event, toState) {
				if (toState.__stateTransitionDeferred) {
					//we need resolve/reject deferred object in order to hide overlay
					toState.__stateTransitionDeferred.resolve();
				}
				//close a cim effect if such exists
				behaviorEffectService.removeAll();
			});

			$rootScope.$on('$stateChangeError', function (event, toState) {
				if (toState.__stateTransitionDeferred) {
					//we need resolve/reject deferred object in order to hide overlay
					toState.__stateTransitionDeferred.reject();
				}
				//console.log('$stateChangeError', event);
			});
		})();

		sessionService.on('session.onStartGettingCurrentStep', function (deferred) {
			overlayService.show(deferred.promise);
		});

		sessionService.on('session.onStartGettingCampaign', function (deferred) {
			overlayService.show(deferred.promise);
		});

		// To implement CIM View Animation
		Array.prototype.push.apply(sessionService.STEP_STATE_NAMES, [
			'game',
			'question',
			'embedded',
			'swapReward',
			'chooseReward',
			'delivery',
			'redeem'
		]);

		sessionService.on('session.onChangeCurrentStep', function onChangeCurrentStep(step) {
			var STEPS = sessionService.STEPS;

			var stateOptions = {
				reload: true,
				inherit: false,
				notify: true
			};

			if (step.stepType === STEPS.QUESTION) {
				if ($state.current.name === 'question') {
					return $state.reload();
				}
				return $state.go('question', stateOptions);
			}

			if (step.stepType === STEPS.EMBEDDED) {
				if ($state.current.name === 'embedded') {
					return $state.reload();
				}
				return $state.go('embedded', stateOptions);
			}

			if (step.stepType === STEPS.GAME) {
				if ($state.current.name === 'game') {
					return $state.reload();
				}
				return $state.go('game', stateOptions);
			}

			if (step.stepType === STEPS.SWAP_REWARD) {
				if ($state.current.name === 'swapReward') {
					return $state.reload();
				}
				return $state.go('swapReward', stateOptions);
			}

			if (step.stepType === STEPS.CHOOSE_REWARD) {
				if ($state.current.name === 'chooseReward') {
					return $state.reload();
				}
				return $state.go('chooseReward', stateOptions);
			}

			if (step.stepType === STEPS.DELIVERY) {
				if ($state.current.name === 'delivery') {
					return $state.reload();
				}
				return $state.go('delivery', stateOptions);
			}

			if (step.stepType === STEPS.THANK_YOU) {
				if ($state.current.name === 'redeem') {
					return $state.reload();
				}
				return $state.go('redeem', stateOptions);
			}

			if (step.stepType === STEPS.COMPLETED) {
				return infoService.showMessageForCompletedStep();
			}

			throw 'Appropriate step was not found for Campaign mode';
		});

		function startSession() {
			return sessionService
				.startSessionInCampaignModeByToken(
				searchParams.ctoken,
				searchParams.externalid,
				isPreview,
				searchParams.h,
				true
			).catch(function (error) {
				//Remove app data from storage in order to start from beginning next time
				storageService.clearAll();

				if (error.status !== 401) {
					isTransitionToInfoStateProgrammed = true;
					$state.go('info', {
						message: errorService.parseErrorsToList(error.data).join(' ')
					}).finally(function () {
						isTransitionToInfoStateProgrammed = false;
					});
				}
			});
		}

	}

	run.$inject = [
		'$rootScope',
		'$state',
		'$translate',
		'authService',
		'sessionService',
		'$q',
		'overlayService',
		'utilityService',
		'storageService',
		'errorService',
		'$filter',
		'inactivityConfirmationService',
		'kioskService',
		'$uibModalStack',
		'infoService',
		'behaviorEffectService'
	];

	angular.module('campaignMode').run(run);

})(window.angular);