(function (angular) {
	'use strict';

	function chooseRewardService($log, apiSessionResource, sessionService) {
		var service = {};

		service.getRewards = getRewards;
		service.chooseReward = chooseReward;
		service.complete = complete;

		return service;

		function getRewards() {
			return apiSessionResource
				.get('/chooseReward')
				.then(function (data) {
					$log.debug('get rewards to choose', data);
					service.reward = data;
					service.rewards = data.items.map(function(reward) {
						return angular.extend(reward, {
							displayRewardExpirationDate: service.reward.displayRewardExpirationDate
						});
					});
					return data;
				});
		}

		function chooseReward(rewardId) {
			return apiSessionResource
				.post('/chooseReward', '\'' + rewardId + '\'')
				.then(complete);
		}

		function complete() {
			return sessionService.getCurrentStep();
		}
	}

	chooseRewardService.$inject = [
		'$log',
		'apiSessionResource',
		'sessionService'
	];

	angular.module('chooseReward').factory('chooseRewardService', chooseRewardService);

})(window.angular);