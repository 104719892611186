(function (angular) {
	'use strict';

	function config($stateProvider) {
		$stateProvider
			.state('chooseReward', {
				url: '/chooseReward',
				templateUrl: 'app/chooseReward/layout/choose-reward.html',
				resolve: {
					chooseReward: ['$q', 'infoService', 'chooseRewardService', function ($q, infoService, chooseRewardService) {
						var deferred = $q.defer();
						chooseRewardService.getRewards({
							showErrorInSeparateState: true
						}).then(function (response) {
							if (!chooseRewardService.rewards.length) {
								infoService.showCampaignIsNotConfiguredProperly();
								return deferred.reject();
							}
							deferred.resolve();
							return response;
						});
						return deferred.promise;
					}]
				}
			});
	}

	config.$inject = [
		'$stateProvider'
	];

	angular.module('chooseReward').config(config);

})(window.angular);