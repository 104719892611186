(function (angular) {
	'use strict';

	function config($stateProvider) {
		$stateProvider
			.state('redeem', {
				url: '/redeem',
				templateUrl: 'app/redeem/layout/redeem.html',
				controller: 'RedeemCtrl as redeemCtrl',
				resolve: {
					model: ['redeemService', function (redeemService) {
						return redeemService.getRedeemCode({
							showErrorInSeparateState: true
						});
					}],
					images: ['redeemService', 'model', function(redeemService, model) {
						return redeemService.preloadConfigDataSafely(model);
					}]
				}
			});
	}

	config.$inject = [
		'$stateProvider'
	];

	angular.module('redeem').config(config);

})(window.angular);