(function (angular) {
	'use strict';

	function campaignService($log, apiResource, apiSessionResource, sessionService) {
		var service = {};

		service.campaigns = [];
		service.getCampaigns = getCampaigns;
		service.getFacilitatedLobby = getFacilitatedLobby;
		service.startCampaign = startCampaign;
		service.complete = complete;

		return service;

		function getCampaigns() {
			return apiSessionResource.get('/campaigns').then(function (data) {
				$log.debug('get campaigns', data);
				return service.campaigns = data;
			});
		}

		function getFacilitatedLobby() {
			return apiResource
				.get('/api/lobby/default')
				.then(function (data) {
					if (!data.configuration) {
						data.configuration = {
							requiresPinCode: true
						};
					}
					data.slides = data.slides || [];
					service.facilitatedLobby = data;
					service.facilitatedLobby.slides.slides = service.facilitatedLobby.slides.mediaList || [];
					if (service.facilitatedLobby.slides.slides.length === 0) {
						service.facilitatedLobby.slides.slides = service.facilitatedLobby.slides.campaignImages || [];
					}
					return service.facilitatedLobby = data;
				});
		}

		function startCampaign(campaignId) {
			return sessionService.startCampaign(campaignId);
		}

		function complete() {
			return sessionService.getCurrentStep();
		}
	}

	campaignService.$inject = [
		'$log',
		'apiResource',
		'apiSessionResource',
		'sessionService'
	];

	angular.module('campaigns').factory('campaignService', campaignService);

})(window.angular);