(function (angular) {
	'use strict';

	function AcErrorCtrl() {
		var vm = this;

		vm.errorData = vm.interaction.model[0].rulePageConfigurations;
		vm.errorMessage = vm.interaction.model[0].text || vm.interaction.model[0].errorMessage;
		vm.pageConfig = vm.interaction.pageConfig;
	}

	function acError() {
		return {
			restrict: 'AE',
			controller: AcErrorCtrl,
			controllerAs: 'error',
			bindToController: true,
			templateUrl: 'app/accessControl/components/interaction/error/error.tmpl.html',
			scope: {
				interaction: '=',
				isShownConfirmButton: '=',
				confirmButtonText: '=',
				onClickConfirmButton: '&'
			}
		};
	}

	angular.module('accessControl').directive('acInteractionError', acError);

})(window.angular);