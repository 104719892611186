(function (angular) {
	'use strict';

	function floodlightPixelService($window, EventEmitter, $rootScope, authService) {
		var service = Object.create(EventEmitter.prototype),
			cache = {};

		service.tryRun = tryRun;

		return service;

        function tryRun(key) {
            key = key || '144706'; 
			if (!cache['bindToRouteChanges']) {
				bindToRouteChanges(key);
				cache['bindToRouteChanges'] = true;
			}
		}

		function bindToRouteChanges() {
            $rootScope.$on('$stateChangeSuccess', function () {
                var head = document.getElementsByTagName('head')[0];

                if (head) {
                    var img = document.getElementById('floodlightPixel');
                    if (img) {
                        head.removeChild(img);
                    }

                    img = document.createElement('img');
                    img.id = 'floodlightPixel';
                    img.src = authService.authentication.floodlightPixel;
                    head.appendChild(img);
                }
			});
		}
	}

    floodlightPixelService.$inject = [
		'$window',
		'EventEmitter',
        '$rootScope',
        'authService'
	];

    angular.module('common').factory('floodlightPixelService', floodlightPixelService);

})(window.angular);