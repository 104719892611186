(function (angular) {
	'use strict';

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('campaigns', {
				url: '/campaigns',
				templateUrl: 'app/campaigns/layout/campaigns.html',
				resolve: {
					campaigns: ['campaignService', function (campaignService) {
						return campaignService.getCampaigns();
					}],
					configuration: ['campaignService', function (campaignService) {
						return campaignService.facilitatedLobby && campaignService.facilitatedLobby.configuration
							|| campaignService.getFacilitatedLobby();
					}]
				}
			});

		$urlRouterProvider.otherwise('/');
	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider'
	];

	angular.module('campaigns').config(config);

})(window.angular);