(function (angular) {
	'use strict';

	function SubscriptionSearchCtrl($scope, $log, $state, formService, errorService, subscriptionService, $uibModal, utilityService, notifier,authService) {
		var vm = this,
			lookupModal;

        vm.userPermissions = subscriptionService.userPermissions;
		vm.model = {};
        vm.clientLogoUrl = authService.authentication.clientLogoUrl;
		vm.submitForm = submitForm;
		vm.showLookupModal = showLookupModal;

		$scope.$on('$destroy', function() {
			if (lookupModal && lookupModal.dismiss) {
				lookupModal.dismiss();
			}
		});

		function submitForm(form) {
			formService.makeFieldsDirty(form);

			if (form.$valid && !vm.isLoading) {
                var rewardCode = vm.model.rewardCode;
                if (utilityService.EMAIL_REGEXP.test(rewardCode)) {

					vm.errorsList = null;
					vm.isLoading = true;

                    subscriptionService.getSubscription(rewardCode).then(function (resp) {
                        if (resp.length > 0) {
                            showLookupModal(resp);
                        } else {
                            notifier.warning('No subscriptions found');
                        }
                    }).finally(function () {
                        vm.isLoading = false;
                    });
                } else {
                    notifier.error('Invalid email code: ' + rewardCode);
				}
			}
		}

		function showLookupModal(list) {
			lookupModal = $uibModal.open({
				templateUrl: 'app/email/components/search/lookup-subscriptions-modal.tmpl.html',
				controller: 'LookupSubscriptionsModalCtrl',
				controllerAs: 'search',
				backdrop: 'static',
				keyboard: false,
                size: 'lg',
                resolve: {
                    list: [function () {
                        return list;
                    }]
                }
			});

			lookupModal.result.then(function() {
				lookupModal = null;
			});

			return lookupModal;
		}
	}

    SubscriptionSearchCtrl.$inject = [
		'$scope',
		'$log',
		'$state',
		'formService',
		'errorService',
		'subscriptionService',
		'$uibModal',
		'utilityService',
		'notifier',
		'authService'
	];

    function subscriptionSearch() {
		return {
			restrict: 'AE',
            controller: SubscriptionSearchCtrl,
			controllerAs: 'search',
			bindToController: true,
			templateUrl: 'app/email/components/search/subscription-search.tmpl.html'
		};
	}

    angular.module('email').directive('subscriptionSearch', subscriptionSearch);

})(window.angular);