(function (angular) {

    function IntegrationLoginCtrl(dashboardService, formService, $stateParams, $state, $uibModal) {
        var vm = this;

        vm.userName = '';
        vm.password = '';
        vm.isLoading = false;
        vm.config = dashboardService.loginPageConfig;

        var inIframe = function() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        };

        inIframe() && window.Comlink && window.Comlink.expose(function (managerToken, playerId) {
            vm.isLoading = true;

                dashboardService.loginByManager(managerToken, playerId).then(function () {
                    return $state.go('custom-dashboard', {
                        dToken: dashboardService.dToken || $stateParams.dToken
                    });
                })
                    .finally(function () {
                        vm.isLoading = false;
                    });

            return true;
        }, window.Comlink.windowEndpoint(self.parent));

        vm.openSignUpModal = function () {
                return $uibModal.open({
                    animation: true,
                    controller: 'DashboardSignUpCtrl',
                    controllerAs: 'signUp',
                    bindToController: true,
                    keyboard: false,
                    backdrop: 'static',
                    templateUrl: 'app/dashboards/components/dashboardSignUp/dashboard-sign-up.tmpl.html',
                    resolve: {
                        formModelName: function() { return 'signUpFormModel'; }
                    }
                });
        };

        vm.openForgotPasswordModal = function () {
            return $uibModal.open({
                animation: true,
                controller: 'DashboardSignUpCtrl',
                controllerAs: 'signUp',
                bindToController: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/dashboards/components/dashboardSignUp/dashboard-sign-up.tmpl.html',
                resolve: {
                    formModelName: function() { return 'forgotPasswordFormModel'; }
                }
            });
        };

        vm.login = function (form) {
            formService.makeFieldsDirty(form);

            if (form && form.$invalid) {
                return false;
            }

            if (!vm.isLoading) {
                vm.error = null;
                vm.isLoading = true;

                return dashboardService.integrationLogin({
                    username: vm.userName,
                    password: vm.password
                }, dashboardService.dToken || $stateParams.dToken).then(function () {
                    return $state.go('custom-dashboard',{
                        dToken: dashboardService.dToken || $stateParams.dToken
                    });
                }).finally(function () {
                    vm.isLoading = false;
                });
            }
        };
    }

    IntegrationLoginCtrl.$inject = [
        'dashboardService',
        'formService',
        '$stateParams',
        '$state',
        '$uibModal'
    ];

    function integrationLogin() {
        return {
            controller: IntegrationLoginCtrl,
            controllerAs: 'login',
            bindToController: true,
            templateUrl: 'app/dashboards/components/integrationLogin/integration-login.tmpl.html'
        };
    }

    angular
        .module('dashboard')
        .directive('integrationLogin', integrationLogin);

})(window.angular);