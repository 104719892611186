(function (angular) {
    'use strict';

    function PreviewEmailsModalCtrl($uibModalInstance,$uibModal, $sce, template) {
        var vm = this;
        
        vm.template = $sce.trustAsHtml(template.templateMessageBody);
        vm.fields = template.notValidMergeFields;

        vm.cancel = function () {
            $uibModalInstance.close();
        };

    }

    PreviewEmailsModalCtrl.$inject = [
        '$uibModalInstance',
        '$uibModal',
        '$sce',
        'template'
    ];

    angular
        .module('email')
        .controller('PreviewEmailsModalCtrl', PreviewEmailsModalCtrl);

})(window.angular);