(function (angular) {
    'use strict';

    function ResetPasswordByIdCtrl(authService, $state, formService, errorService, $filter, $uibModalInstance, userName, notifier) {
        var vm = this,
            pinRegExp = new RegExp('^#[0-9]+$|^[0-9]+$');
        vm.userName = userName;
        vm.model = {};
        vm.model.oldPassword = null;
        vm.model.password = null;
        vm.model.confirmPassword = null;
        vm.saveNewPassword = saveNewPassword;
        vm.cancel = cancel;

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function saveNewPassword(formInstance) {

            formService.makeFieldsDirty(formInstance);

            if (formInstance.$valid && !vm.isLoading) {
                if (vm.model.password === vm.model.oldPassword) {
                    return notifier.error('Your old and new passwords are the same. Please come up with a new password.');
                }

                if (!pinRegExp.test(vm.model.pinCode) || vm.model.pinCode.length > 10) {
                    return notifier.error('PIN must have maximum 10 characters, only numbers or #');
                }

                vm.isLoading = true;
                vm.model.userName = vm.userName;

                authService.resetPinAndPassword(vm.model).then(
                    function () {
                        $uibModalInstance.close();
                    },
                    function () {

                    })
                    .finally(function () {
                        vm.isLoading = false;
                    });
            }
        }
    }

    ResetPasswordByIdCtrl.$inject = [
        'authService',
        '$state',
        'formService',
        'errorService',
        '$filter',
        '$uibModalInstance',
        'userName',
        'notifier'
    ];

    angular.module('auth').controller('resetUserPasswordById', ResetPasswordByIdCtrl);

})(window.angular);