(function (angular) {
	'use strict';

	function config($stateProvider, $urlRouterProvider) {
		$stateProvider
			.state('pickem', {
				url: '/pickem',
				templateUrl: 'app/pickem/layout/pickem.html',
				resolve: {
					question: ['pickemService', function (pickemService) {
						return pickemService.getPickemCim({
							showErrorInSeparateState: true
						});
					}]
				}
			});

		$urlRouterProvider.otherwise('/');
	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider'
	];

	angular.module('pickem').config(config);

})(window.angular);