(function (angular) {

	function OneLeaderAndListLeaderboardCtrl() {
		//var vm = this;

	}

	OneLeaderAndListLeaderboardCtrl.$inject = [];

	function oneLeaderAndListLeaderboard() {
		return {
			controller: OneLeaderAndListLeaderboardCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '=',
				countdownOptions: '=',
				countdownModelId: '=countdownId',
				onElapse: '&',
				isCountdownShown: '&'
			},
			templateUrl: 'app/dashboards/components/leaderboard/one-leader-and-list-leaderboard.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('oneLeaderAndListLeaderboard', oneLeaderAndListLeaderboard);

})(window.angular);