(function (angular) {
	'use strict';

	function config($httpProvider, $translateProvider) {
		$httpProvider.interceptors.push('errorInterceptorService');
		$translateProvider.useSanitizeValueStrategy('escapeParameters');
	}

	config.$inject = [
		'$httpProvider',
        '$translateProvider'
	];

	angular.module('common').config(config);

})(window.angular);