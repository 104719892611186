(function (angular) {
	'use strict';

	angular.module('lobby', [
		'ui.router',
		'common',
		'accessControl',
		'selfRedeem',
        'redemption',
        'email',
		'auth'
	]);

})(window.angular);