(function (angular) {
	'use strict';

	function CompletedCtrl() {
		//var vm = this;
	}

	CompletedCtrl.$inject = [];

	function completed() {
		return {
			restrict: 'AE',
			controller: CompletedCtrl,
			controllerAs: 'completed',
			bindToController: true,
			templateUrl: 'app/completed/components/completed/completed.tmpl.html'
		};
	}

	angular.module('completed').directive('completed', completed);

})(window.angular);