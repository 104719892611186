(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.init = function (ngModel, answers) {
			if (!ngModel || !answers) return;

			vm.onChange = function () {
                ngModel.$setViewValue({ value: vm.model });
				vm.onChangeModel({$inputType: 'text'});
			};

			ngModel.$render = function () {
				// Specify how UI should be updated
                vm.model = ngModel.$viewValue && ngModel.$viewValue[0] && ngModel.$viewValue[0].value;
			};
		};
	}

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-multiple-choice-dropdown.tmpl.html',
            require: ['answerMultipleChoiceDropdown', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				choices: '=answerChoices',
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				name: '=answerName',
				answerBackgroundColor: '=',
				layout: '=',
				answerDisabled: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);
			}
		};
	}

	angular.module('answer').directive('answerMultipleChoiceDropdown', answer);

})(window.angular);