(function (angular) {
  'use strict';

  function sessionService(
    $log,
    EventEmitter,
    $q,
    apiResource,
    apiSessionResource,
    storageService,
    $timeout,
    notifier,
    $state
  ) {
    var service = Object.create(EventEmitter.prototype);

    service.STEPS = {
      SELECT_CAMPAIGN: 'CampaignSelect',
      QUESTION: 'CimQuestion',
      EMBEDDED: 'CimEmbedded',
      GAME: 'CimGame',
      PICKEM: 'CimPickem',
      SWAP_REWARD: 'SwapReward',
      CHOOSE_REWARD: 'ChooseReward',
      DELIVERY: 'RewardDelivery',
      THANK_YOU: 'Redeem',
      COMPLETED: 'Completed',
      LOBBYHOME: 'Unspecified'
    };
    service.STEP_STATE_NAMES = []; // Each module which is responsible for STEPS must register their state names here
    service.currentStep = null;
    service.isStartingSession = false;
    service.startSessionByPinCode = startSessionByPinCode;
    service.startSessionInCampaignModeByToken =
      startSessionInCampaignModeByToken;
    service.startSessionInCampaignModeByCampaignId =
      startSessionInCampaignModeByCampaignId;
    service.startSessionWithPassThrough = startSessionWithPassThrough;
    service.setPreviewFlag = setPreviewFlag;
    service.startCampaign = startCampaign;
    service.finishCampaign = finishCampaign;
    service.getCurrentStep = getCurrentStep;
    service.triggerCurrentStep = triggerCurrentStep;
    service.getSessionKey = getSessionKey;
    service.isSessionKeyExist = isSessionKeyExist;
    service.isSessionStarting = isSessionStarting;
    service.resumeSessionById = resumeSessionById;
    service.isResuming = isResuming;
    service.removeSessionKey = removeSessionKey;
    service.setChallengeLobbyState = setChallengeLobbyState;
    service.removeChallengeLobbyState = removeChallengeLobbyState;
    service.gotoCampaignOverMessage = gotoCampaignOverMessage;
    service.temporarySessionKey = null;
    service.setCookedData = setCookedData;
    service.startSessionViaExecute = startSessionViaExecute;

    return service;

    function setCookedData(cooked) {
      service.cooked = cooked;
    }

    function startSessionByPinCode(pinCode, externalId) {
      setStartingSessionFlag();

      return apiResource
        .post(
          'api/instance',
          {
            pinCode: pinCode,
            externalId: externalId
          },
          {
            ignoreDefaultErrorNotifier: true
          }
        )
        .then(onSuccessStartSession, onErrorStartSession)
        .finally(resetStartingSessionFlag);
    }

    function startSessionInCampaignModeByToken(
      cToken,
      externalId,
      isPreview,
      externalsecurityverifier,
      ignoreDefaultErrorNotifier
    ) {
      var deferred = $q.defer();

      $log.debug('starting session by token');

      setStartingSessionFlag();

      apiResource
        .post(
          'api/instance',
          {
            isPreview: isPreview,
            cToken: cToken,
            externalId: externalId,
            externalSecurityVerifier: externalsecurityverifier
          },
          {
            ignoreDefaultErrorNotifier: ignoreDefaultErrorNotifier
          }
        )
        .then(
          function (response) {
            onSuccessStartSession(response);
            setPreviewFlag(isPreview);
            onSuccessStartSessionHandler(isPreview, response);
            deferred.resolve(response);
          },
          function (result) {
            onErrorStartSession(result);
            deferred.reject(result);
          }
        )
        .finally(resetStartingSessionFlag);

      fireEvent('session.onStartSession', deferred);

      return deferred.promise;
    }

    function startSessionInCampaignModeByCampaignId(
      campaignId,
      scheduleId,
      accessRequestId,
      isPreview,
      ignoreDefaultErrorNotifier
    ) {
      var deferred = $q.defer();

      $log.debug('starting session by campaignId');

      setStartingSessionFlag();

      apiResource
        .post(
          'api/instance/start',
          {
            campaignId: campaignId,
            accessRequestId: accessRequestId,
            schedulerId: scheduleId
          },
          {
            ignoreDefaultErrorNotifier: ignoreDefaultErrorNotifier
          }
        )
        .then(
          function (response) {
            onSuccessStartSession(response);
            setPreviewFlag(isPreview);
            deferred.resolve(response);
          },
          function (result) {
            onErrorStartSession(result);
            deferred.reject(result);
          }
        )
        .finally(resetStartingSessionFlag);

      fireEvent('session.onStartSession', deferred);

      return deferred.promise;
    }

    function startSessionWithPassThrough(
      campaignId,
      scheduleId,
      accessRequestId,
      isPreview,
      ignoreDefaultErrorNotifier
    ) {
      var deferred = $q.defer();

      $log.debug('starting session by campaignId');

      setStartingSessionFlag();

      apiResource
        .post(
          'api/instance/startWithPassThrough',
          {
            campaignId: campaignId,
            accessRequestId: accessRequestId,
            schedulerId: scheduleId
          },
          {
            ignoreDefaultErrorNotifier: ignoreDefaultErrorNotifier
          }
        )
        .then(
          function (data) {
            if (data.stepType === 'Redeem') {
              return deferred.resolve(data);
            }
            onSuccessStartSessionHandler(isPreview, data);
            deferred.resolve(data);
          },
          function (result) {
            onErrorStartSession(result);
            deferred.reject(result);
          }
        )
        .finally(resetStartingSessionFlag);

      fireEvent('session.onStartSession', deferred);

      return deferred.promise;
    }

    function onSuccessStartSessionHandler(isPreview, data) {
      onSuccessStartSession(data.instanceId);
      setPreviewFlag(isPreview);
    }

    function startSessionViaExecute(isPreview, unsafe, schedulerId) {
      var url =
        'api/ext/kiosk/couponBook/execute?lobbyToken=' + service.cooked.aToken;
      if (unsafe && service.cooked.apiKey) {
        url =
          'api/ext/couponBook/execute?api_key=' +
          encodeURIComponent(service.cooked.apiKey);
      }

      var deferred = $q.defer();

      $log.debug('starting session by accessToken');

      setStartingSessionFlag();

      apiResource
        .post(
          url,
          {
            id: service.cooked.accessToken,
            accessRequestId: service.cooked.accessRequestId,
            schedulerId: schedulerId || service.schedulerId
          },
          {
            ignoreDefaultErrorNotifier: true
          }
        )
        .then(
          function (data) {
            onSuccessStartSessionHandler(isPreview, data);
            deferred.resolve(data);
          },
          function (result) {
            onErrorStartSession(result);
            deferred.reject(result);
          }
        )
        .finally(function () {
          resetStartingSessionFlag();
          delete service.cooked;
        });

      fireEvent('session.onStartSession', deferred);

      return deferred.promise;
    }

    function resumeSessionById(sessionKey) {
      setSessionKey(sessionKey);
      service.isResumingSession = true;
      return getCurrentStep().finally(function () {
        service.isResumingSession = false;
      });
    }

    function isResuming() {
      return service.isResumingSession;
    }

    function setStartingSessionFlag() {
      service.isStartingSession = true;
    }

    function resetStartingSessionFlag() {
      service.isStartingSession = false;
    }

    function isSessionStarting() {
      return service.isStartingSession;
    }

    function onSuccessStartSession(sessionId) {
      setSessionKey(sessionId);
      fireEvent('session.onSuccessStartSession', sessionId);
      return getCurrentStep();
    }

    function onErrorStartSession(error) {
      removeSessionKey();
      fireEvent('session.onErrorStartSession', error);
      return $q.reject(error);
    }

    function setSessionKey(key) {
      storageService.set('sessionId', key);
    }

    function getSessionKey() {
      return storageService.get('sessionId');
    }

    function isSessionKeyExist() {
      return !!getSessionKey();
    }

    function removeSessionKey() {
      storageService.remove('sessionId');
    }

    function setPreviewFlag(flag) {
      service.isPreviewedMode = flag;
      fireEvent('session.onSetPreviewMode');
    }

    function setChallengeLobbyState(data) {
      storageService.set('accessRequestId', data);
      storageService.set('challengeLobby', 1);
    }

    function removeChallengeLobbyState() {
      storageService.remove('accessRequestId');
      storageService.remove('challengeLobby');
    }

    function startCampaign(campaignId) {
      var deferred = $q.defer();

      apiSessionResource
        .post('/campaigns', {
          campaignId: campaignId
        })
        .then(
          function (data) {
            $log.debug('campaign was started', service.campaign);
            fireEvent('session.onStartCampaign', data);
            deferred.resolve(data);
          },
          function (result) {
            deferred.reject(result);
          }
        )
        .finally(function () {
          fireEvent('session.onStopGettingCampaign', deferred);
        });

      fireEvent('session.onStartGettingCampaign', deferred);

      return deferred.promise;
    }

    function getCurrentStep(attempts) {
      var deferred = $q.defer();

      apiSessionResource
        .get('/')
        .then(
          function (data) {
            data.prevStep = service.currentStep;
            service.currentStep = data;
            if (data.stepType === service.STEPS.LOBBYHOME) {
              service.currentStep.accessRequestId =
                storageService.get('accessRequestId');
            }
            removeChallengeLobbyState();
            triggerCurrentStep(service.currentStep);
            deferred.resolve(service.currentStep);
          },
          function (result) {
            $state.go('info', {
              message: result.data.message
            });
            deferred.reject(result);
            if (result && result.errorType === 'MultiGetSetReward') {
              if (!attempts) {
                getCurrentStep(1);
              } else {
                notifier.error(result.data.message);
              }
            }
          }
        )
        .finally(function () {
          fireEvent('session.onStopGettingCurrentStep', deferred);
        });

      fireEvent('session.onStartGettingCurrentStep', deferred);

      return deferred.promise;
    }

    function gotoCampaignOverMessage(message) {
      var deferred = $q.defer();
      return fireEvent('session.onCampaignOver', {
        promise: deferred,
        message: message
      });
    }

    function triggerCurrentStep(step) {
      return fireEvent('session.onChangeCurrentStep', step);
    }

    function finishCampaign() {
      return fireEvent('session.onComplete', service.campaign);
    }

    function fireEvent(event, data) {
      return $timeout(function () {
        service.trigger(
          event,
          typeof data !== 'undefined' ? [data] : undefined
        );
      });
    }
  }

  sessionService.$inject = [
    '$log',
    'EventEmitter',
    '$q',
    'apiResource',
    'apiSessionResource',
    'storageService',
    '$timeout',
    'notifier',
    '$state'
  ];

  angular.module('session').factory('sessionService', sessionService);
})(window.angular);
