(function (angular) {

	function RawDataNoAvatarLeaderboardCtrl() {

	}

	RawDataNoAvatarLeaderboardCtrl.$inject = [];

	function rawDataNoAvatarLeaderboard() {
		return {
			controller: RawDataNoAvatarLeaderboardCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '=',
				countdownOptions: '=',
				countdownModelId: '=countdownId',
				onElapse: '&',
				isCountdownShown: '&'
			},
			templateUrl: 'app/dashboards/components/leaderboard/raw-data-no-avatar-leaderboard.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('rawDataNoAvatarLeaderboard', rawDataNoAvatarLeaderboard);

})(window.angular);