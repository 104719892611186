(function (angular) {

	function DashboardBoxCtrl(dashboardService, utilityService) {
		var vm = this;

		vm.dashboardService = dashboardService;
		vm.isActiveBox = isActiveBox;
		vm.convertToDecimalColor = convertToDecimalColor;

		function isActiveBox(boxType) {
			return vm.model && (vm.model.subtype || vm.model.type) === boxType;
		}

		function convertToDecimalColor(hex) {
			return hex ? utilityService.convertToDecimalColor(hex, 0.85) : null;
		}

	}

	DashboardBoxCtrl.$inject = [
		'dashboardService',
		'utilityService'
	];

	function dashboardBox() {
		return {
			replace: true,
			controller: DashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/dashboard/dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('dashboardBox', dashboardBox);

})(window.angular);