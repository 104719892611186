(function (angular) {
    'use strict';

    function RewardListCtrl() {
        var vm = this;

        vm.selectReward = selectReward;
        function selectReward (reward) {
            vm.selectedReward = reward;
        }

        vm.onChangeCurrentIndex = function (index) {
            if (index !== vm.currentRewardIndex) {
                var selectedReward = vm.rewardsList[index];
                if (selectedReward) {
                    vm.selectedReward = selectedReward;
                }
            }
        };

        vm.currentRewardIndex = 0;
        vm.onChangeCurrentIndex(vm.currentRewardIndex);

        vm.carouselResponsiveSettings = [
            {
                breakpoint: 10000,
                settings: {
                    slidesToShow: vm.carouselItemsCount,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 2560,
                settings: {
                    slidesToShow: vm.carouselItemsCount,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: vm.carouselItemsCount,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3 <= vm.carouselItemsCount ? 3 : vm.carouselItemsCount,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2 <= vm.carouselItemsCount ? 2 : vm.carouselItemsCount,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            }
        ];

    }

    RewardListCtrl.$inject = [
    ];

    function rewardList() {
        return {
            restrict: 'AE',
            controller: RewardListCtrl,
            controllerAs: 'rewards',
            bindToController: true,
            templateUrl: 'app/chooseReward/components/reward-list.tmpl.html',
            scope: {
                mode: '=',
                theme: '=',
                rewardsList: '=',
                selectedReward: '=',
                carouselItemsCount: '='
            }
        };
    }

    angular.module('chooseReward').directive('rewardList', rewardList);

})(window.angular);
