(function (angular) {
	'use strict';

	function ocCustomVideoCtrl($scope, $rootScope) {
		var vm = this;

		vm.STATES = {
			PLAY: 'play',
			PAUSE: 'pause',
            STOP: 'stop',
            READY: 'ready'
		};

		vm.element = null;

		vm.mediaElement = null;

		$rootScope.$on('pauseVideo', function () {
			vm.pause();
		});

		vm.setState = function (newState) {
			if (newState && newState != vm.currentState) {
				vm.currentState = newState;
			}
			if (newState === vm.STATES.PLAY) {
				$rootScope.$emit('videoplaying');
			}
            if (newState === vm.STATES.STOP) {
				$rootScope.$emit('videostopped');
			}
			return vm.currentState;
		};

		vm.onComplete = function () {
			vm.setState(vm.STATES.STOP);
			vm.isCompleted = true;
			vm.onEnded();
			$scope.$apply();
		};

		vm.onStartPlaying = function () {
			vm.setState(vm.STATES.PLAY);
			vm.onPlay();
			$scope.$apply();
		};

		vm.onPause = function () {
			var currentTime = vm.mediaElement[0].currentTime;

			if (currentTime == 0) {
				vm.setState(vm.STATES.STOP);
			} else {
				vm.setState(vm.STATES.PAUSE);
			}

			$scope.$apply();
		};

		vm.onVideoError = function (event) {
			vm.error = event;
			vm.onError({$event: event});
			$scope.$apply();
		};

		vm.play = function () {
			vm.mediaElement[0].play();
			vm.setState(vm.STATES.PLAY);
		};

		vm.pause = function () {
			if (vm.mediaElement[0].pause) {
                vm.mediaElement[0].pause();
                vm.setState(vm.STATES.PAUSE);
            }
		};

		vm.stop = function () {
			try {
				vm.mediaElement[0].pause();
				vm.mediaElement[0].currentTime = 0;
				vm.setState(vm.STATES.STOP);
			}
			catch (e) {
				return e;
			}
		};

		vm.onVideoReady = function () {
			vm.isReady = true;
			vm.setState(vm.STATES.READY);
		};
	}

	ocCustomVideoCtrl.$inject = [
		'$scope',
		'$rootScope'
	];

	function ocCustomVideo() {
		return {
			restrict: 'AE',
			templateUrl: 'app/common/directives/oc-custom-video.tmpl.html',
			controller: ocCustomVideoCtrl,
			controllerAs: 'video',
			bindToController: true,
			scope: {
				source: '=',
				options: '=',
				onError: '&',
				onEnded: '&',
				onPlay: '&'
			},
			link: {
				pre: function (scope, elem, attr, controller) {
					controller.videogularElement = angular.element(elem);
				}
			}
		};
	}

	angular.module('common').directive('ocCustomVideo', ocCustomVideo);

	/*------------------------------*/

	function ocCustomVideoService() {
		var service = {};

		service.availableMediaList = [];
		service.registerOcCustomMedia = registerOcCustomMedia;

		return service;

		function registerOcCustomMedia(srcRegExp, directiveName) {
			service.availableMediaList.push({
				srcRegExp: srcRegExp,
				directive: directiveName
			});
		}
	}

	angular.module('common').service('ocCustomVideoService', ocCustomVideoService);

	/*-----------------------------------*/

	function ocCustomMedia($compile, ocCustomVideoService, utilityService) {
		return {
			restrict: 'AE',
			require: '^ocCustomVideo',
			link: function (scope, elem, attrs, API) {
				var createChildVideoElementDebounce = utilityService.debounce(createChildVideoElement, 50);

				scope.$watch(function () {
					return API.source;
				}, function () {
					onChangeData(API.source);
				});

				// scope.$watch(function () {
					// return API.options;
				// }, function () {
					// onChangeData(API.source);
				// });

				function onChangeData(source) {
					var availableMedia = ocCustomVideoService.availableMediaList;
					if (source && source.src) {
						for (var i = availableMedia.length - 1, media; i >= 0; i--) {
							media = availableMedia[i];

							if (!source.src.match(media.srcRegExp)) {
								continue;
							}

							var childHtml = angular.element(media.directive);

							if (elem.children().scope()) {
								elem.children().scope().$destroy();
							}

							elem.empty();

							createChildVideoElementDebounce(childHtml);

							break;
						}
					}
				}

				function createChildVideoElement(childHtml) {
					elem.html(childHtml);

					var childScope = scope.$new();

					$compile(childHtml)(childScope);

					childScope.$applyAsync();
				}
			}
		};
	}

	ocCustomMedia.$inject = [
		'$compile',
		'ocCustomVideoService',
		'utilityService'
	];

	angular.module('common').directive('ocCustomMedia', ocCustomMedia);

	/*--------------------------*/

	(function () {
		var videoReg = /.+/;

		angular.module('common')
			.run(['ocCustomVideoService',
				function (ocCustomVideoService) {
					ocCustomVideoService.registerOcCustomMedia(videoReg, '<oc-custom-media-video/>');
				}
			]);

		function ocCustomMediaVideo() {
			return {
				restrict: 'AE',
				require: '^ocCustomVideo',
				template: '<video preload="metadata"></video>',
				link: function (scope, elem, attrs, API) {
					var playerVars = {},
						playerVarsDefault = {};

					if (API.currentState !== API.STATES.PLAY) {
						API.currentState = API.STATES.STOP;
					}

					API.mediaElement = elem.children();

					API.mediaElement[0].onloadeddata = onReady;

					extendOptions(API.options);

					scope.$watch(
						function () {
							return API.source;
						},
						function (newVal) {
							if (newVal && newVal.src) {
								onSourceChange();
							}
						}
					);

					scope.$watch(
						function () {
							return API.options;
						},
						extendOptions
					);

					scope.$on('$destroy', function () {
						removeListeners();
					});

					function extendOptions(value) {
						var opt = angular.copy(value || {});

						if(typeof opt === 'string'){
							opt = JSON.parse(opt);
						}

						if(opt.videoSettings){
							if (opt.videoSettings.startTime) {
								opt.start = opt.videoSettings.startTime;
								delete opt.startTime;
							}
							if (opt.videoSettings.endTime && opt.videoSettings.endTime>0) {
								opt.end = opt.videoSettings.endTime;
								delete opt.endTime;
							}
							if (opt.videoSettings.autoplay) {
								opt.autoplay = opt.videoSettings.autoplay && true || false;
								opt.playsinline = opt.autoplay;
								opt.muted = opt.autoplay;
							}
							if (opt.videoSettings.autorepeat) {
								opt.loop = opt.videoSettings.autorepeat && true || false;
							}
							if (opt.videoSettings.controls) {
								opt.controls = opt.videoSettings.controls && true || false;
							}
							if(opt.videoSettings.desktopSize){
								opt.width = opt.videoSettings.desktopSize.width || NaN;
								opt.height = opt.videoSettings.desktopSize.height || NaN;
							}
							delete opt.videoSettings;
						} else {
							if (opt.startTime) {
								opt.start = opt.startTime;
								delete opt.startTime;
							}
							if (opt.endTime && opt.endTime>0) {
								opt.end = opt.endTime;
								delete opt.endTime;
							}
							if (opt.autoplay) {
								opt.autoplay = opt.autoplay && true || false;
								opt.playsinline = opt.autoplay;
								opt.muted = opt.autoplay;
							}
							if (opt.autorepeat) {
								opt.loop = opt.autorepeat && true || false;
								delete opt.autorepeat;
							}
							if (opt.controls) {
								opt.controls = opt.controls && true || false;
							}
							if(!opt.controls && !opt.autoplay) {
								opt.autoplay = true;
							}
							if(opt.desktopSize){
								opt.width = opt.desktopSize.width || NaN;
								opt.height = opt.desktopSize.height || NaN;
							}

						}


						playerVars = angular.extend({}, playerVarsDefault, opt);

						Object.keys(playerVars).forEach(function (key) {
							if(playerVars[key]){
								API.mediaElement.attr(key, playerVars[key]);
							}
						});
					}
					if (!isNaN(playerVars.width) && !isNaN(playerVars.height)){
						API.mediaElement.css({
							'width': playerVars.width + 'px',
							'height': playerVars.height + 'px',
							'display': 'block',
							'margin': '0 auto'
						});
					} else if(playerVars.desktopSize && !isNaN(playerVars.desktopSize.width) && !isNaN(playerVars.desktopSize.height)){
						API.mediaElement.css({
							'width':playerVars.desktopSize.width + 'px',
							'height':playerVars.desktopSize.height + 'px',
							'display': 'block',
							'margin': '0 auto'
						});
					} else {
						API.mediaElement.css({
							'width': '100%',
							'height': 'unset'
						});
					}

					addListeners();

					function onSourceChange() {
						if (playerVars && playerVars.stopedByEnd) {
							delete playerVars.stopedByEnd;
						}
						API.mediaElement.attr('src', API.source.src);
						API.mediaElement.attr('type', API.source.type || 'video/mp4');
						API.mediaElement[0].load();
					}

					function addListeners() {
						API.mediaElement[0].addEventListener('ended', onFinish, false);
						API.mediaElement[0].addEventListener('play', onPlay, false);
						API.mediaElement[0].addEventListener('pause', onPause, false);
						API.mediaElement[0].addEventListener('timeupdate', onTimeupdate, false);
						API.mediaElement[0].addEventListener('error', onError, false);
					}

					function removeListeners() {
						API.mediaElement[0].removeEventListener('ended', onFinish, false);
						API.mediaElement[0].removeEventListener('play', onPlay, false);
						API.mediaElement[0].removeEventListener('pause', onPause, false);
						API.mediaElement[0].removeEventListener('timeupdate', onTimeupdate, false);
						API.mediaElement[0].removeEventListener('error', onError, false);
					}

					function onReady() {
                        if (playerVars.background || playerVars.autoplay) {
                            API.mediaElement[0].muted = true;
                        }
                        if (!playerVars.autoplay) {
							API.pause();
						}
						API.onVideoReady();
						if (playerVars.start) {
							API.mediaElement[0].currentTime = playerVars.start;
						}
					}

					function onPlay() {
						API.onStartPlaying();
					}

					function onTimeupdate() {
						var currentTime = API.mediaElement[0].currentTime;
						if (playerVars.end && currentTime >= playerVars.end && !playerVars.stopedByEnd) {
							// We allow only once stop operation
							if(playerVars.loop){
									API.mediaElement[0].currentTime = playerVars.start || 0;
							} else {
								playerVars.stopedByEnd = true;
								API.mediaElement[0].pause();
								onFinish();
							}

						}
					}

					function onPause() {
						API.onPause();
					}

					function onFinish() {
						API.onComplete();
					}

					function onError(event) {
						API.onVideoError(event);
					}
				}
			};
		}

		angular.module('common').directive('ocCustomMediaVideo', ocCustomMediaVideo);
	})();

	/*--------------------------*/

	(function () {
		var youtubeReg = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

		angular.module('common')
			.run(['ocCustomVideoService',
				function (ocCustomVideoService) {
					ocCustomVideoService.registerOcCustomMedia(youtubeReg, '<oc-custom-media-youtube/>');
				}
			])
			.run(['$rootScope', '$window',
				function ($rootScope, $window) {
					var scriptSrc = 'https://www.youtube.com/iframe_api';
					$rootScope.youtubeApiReady = false;
					$window.onYouTubeIframeAPIReady = function () {
						$rootScope.$apply(function () {
							$rootScope.youtubeApiReady = true;
						});
					};
					var isScriptInjected = Array.prototype.some.call(document.scripts, function (script) {
						return script.src === scriptSrc;
					});
					if (isScriptInjected) {
						return;
					}
					var tag = document.createElement('script');
					tag.async = true;
					tag.src = scriptSrc;
					var firstScriptTag = document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				}
			]);

		function ocCustomMediaYoutube($rootScope, $window) {
			return {
				restrict: 'AE',
				require: '^ocCustomVideo',
				template: '<div></div><div class="youtube-no-pause" ng-if="!$parent.video.options.controls"></div>',
				link: function (scope, elem, attrs, API) {
					var ytplayer,
						YT = $window.YT,
						playerVarsDefault = {
							enablejsapi: 1,
							rel: 0
						},
						playerVars = {};
					

					extendOptions(API.options);

					API.mediaElement = elem.children();

					scope.$watch(
						function () {
							return API.source;
						},
						function (newVal) {
							if (newVal && newVal.src) {
								onSourceChange(newVal.src.toString());
							} else {
								onSourceChange(null);
							}
						}
					);

					scope.$watch(
						function () {
							return API.options;
						},
						extendOptions
					);

					scope.$on('$destroy', function () {
						destroyYoutubePlayer();
					});

					function extendOptions(value) {
						var opt = angular.copy(value || {});

						if(typeof opt === 'string'){
							opt = JSON.parse(opt);
						}

						if(opt.videoSettings){
							if (opt.videoSettings.startTime) {
								opt.start = opt.videoSettings.startTime;
								delete opt.startTime;
							}
							if (opt.videoSettings.endTime && opt.videoSettings.endTime>0) {
								opt.end = opt.videoSettings.endTime;
								delete opt.endTime;
							}
							opt.autoplay = opt.videoSettings.autoplay ? 1 : 0;
							if(opt.videoSettings.autorepeat){
								opt.autorepeat = opt.videoSettings.autorepeat ? 1 : 0;
								if(!opt.playlist) {
									// playlist must be set in order to loop
									opt.playlist = /embed\/(.+)/.exec(API.source.src) ||
										/watch\?v=(.+)/.exec(API.source.src) ||
										/youtu.be\/(.+)/.exec(API.source.src);
									opt.playlist = opt.playlist && opt.playlist[1] || '';
								}
							}
							opt.controls = opt.videoSettings.controls ? 1 : 0;
							if(opt.videoSettings.desktopSize){
								opt.width = opt.videoSettings.desktopSize.width || NaN;
								opt.height = opt.videoSettings.desktopSize.height || NaN;
							}
							delete opt.videoSettings;
						} else {
							if (opt.startTime) {
								opt.start = opt.startTime;
								delete opt.startTime;
							}
							if (opt.endTime && opt.endTime>0) {
								opt.end = opt.endTime;
								delete opt.endTime;
							}
							opt.autoplay = opt.autoplay ? 1 : 0;
							if (opt.autorepeat) {
								opt.autorepeat = opt.autorepeat && 1 || 0;
								if(!opt.playlist) {
									// playlist must be set in order to loop
									opt.playlist = /embed\/(.+)/.exec(API.source.src) ||
										/watch\?v=(.+)/.exec(API.source.src) ||
										/youtu.be\/(.+)/.exec(API.source.src);
									opt.playlist = opt.playlist && opt.playlist[1] || '';
								}
							}
							opt.controls = opt.controls ? 1 : 0;
							if(opt.desktopSize){
								opt.width = opt.desktopSize.width || NaN;
								opt.height = opt.desktopSize.height || NaN;
							}
                        }

                        if (opt.autoplay === 1 || opt.background === 1) {
                            opt.mute = 1;
                        }

						playerVars = angular.extend({}, playerVarsDefault, opt);
					}

					function getYoutubeId(url) {
						return url.match(youtubeReg)[2];
					}

					function initYoutubePlayer(url) {
						if (ytplayer) {
							ytplayer.loadVideoById({
								videoId: getYoutubeId(url)
							});
						} else {
							$rootScope.$watch('youtubeApiReady', function (value) {
								if (value) {
									switch (playerVars.autorepeat){
										case 0:
											delete playerVars.autorepeat;
											delete playerVars.playlist;
											break;
										case 1:
											playerVars.loop = playerVars.autorepeat;
											delete playerVars.autorepeat;
									}
									if(!isNaN(playerVars.width) && !isNaN(playerVars.height)){
										ytplayer = new YT.Player(API.mediaElement[0], {
											videoId: getYoutubeId(url),
											width: playerVars.width,
											height: playerVars.height,
											playerVars: playerVars,
											events: {
												'onReady': onVideoReady,
												'onStateChange': onVideoStateChange
											}
										});
									} else {
										ytplayer = new YT.Player(API.mediaElement[0], {
											videoId: getYoutubeId(url),
											playerVars: playerVars,
											events: {
												'onReady': onVideoReady,
												'onStateChange': onVideoStateChange
											}
										});
										var embedCodeElem = angular.element(ytplayer.getVideoEmbedCode())[0];
										API.newHeight = embedCodeElem.height;
										API.newWidth = embedCodeElem.width;
									}

								}
							});
						}
					}

					function onVideoReady() {
						//Define some property, method for player
						API.mediaElement[0].__defineGetter__('currentTime', function () {
							return ytplayer.getCurrentTime();
						});
						API.mediaElement[0].__defineSetter__('currentTime', function (seconds) {
							return ytplayer.seekTo(seconds, true);
						});
						API.mediaElement[0].__defineGetter__('duration', function () {
							return ytplayer.getDuration();
						});
						API.mediaElement[0].__defineGetter__('paused', function () {
							return ytplayer.getPlayerState() != YT.PlayerState.PLAYING;
						});
						API.mediaElement[0].__defineGetter__('videoWidth', function () {
							return ytplayer.a.width;
						});
						API.mediaElement[0].__defineGetter__('videoHeight', function () {
							return ytplayer.a.height;
						});
						API.mediaElement[0].__defineGetter__('volume', function () {
							return ytplayer.getVolume() / 100.0;
						});
						API.mediaElement[0].__defineSetter__('volume', function (volume) {
							return ytplayer.setVolume(volume * 100.0);
						});
						API.mediaElement[0].play = function () {
							ytplayer.playVideo();
						};
						API.mediaElement[0].pause = function () {
                            ytplayer && ytplayer.pauseVideo();
						};

						if(!isNaN(playerVars.width) && !isNaN(playerVars.height)){
							angular.element(ytplayer.getIframe()).css({
								'width': playerVars.width + 'px',
								'height': playerVars.height + 'px',
								'display': 'block',
								'margin': '0 auto'
							});
						} else {
							var offset = angular.element(ytplayer.getIframe()).offset();
							if(offset.top === 0) {
								angular.element(ytplayer.getIframe()).css({
									'width': '100%',
									'height': '100%'
								});
							} else {
								$window.setTimeout(function() {
									var elem = angular.element(ytplayer.getIframe());
									if (elem) {
										var parent = angular.element(ytplayer.getIframe()).parent();
										parent = parent && parent.parent();
										if (parent) {
                                            var containerWidth = parent[0].offsetWidth;
                                            if (!containerWidth) {
                                                parent = parent.parent();
                                                containerWidth = parent && parent[0].offsetWidth;
                                            }

                                            if (containerWidth) {
                                                var w1 = angular.element(ytplayer.getIframe())[0].offsetWidth;
                                                var h1 = angular.element(ytplayer.getIframe())[0].offsetHeight;
                                                if (API.newWidth > 0 && API.newWidth > w1) {
                                                    var ratio = 1;
                                                    var oldNewWidth = API.newWidth;
                                                    if (API.newWidth > containerWidth && containerWidth > 0) {
                                                        API.newWidth = containerWidth;
                                                        ratio = API.newWidth / oldNewWidth;
                                                    }
                                                    w1 = API.newWidth;
                                                    h1 = ratio * API.newHeight;
                                                } else {
                                                    var isDashboard = angular.element('video-dashboard-box');
                                                    if (isDashboard && isDashboard.length) {
                                                        var elH1 = angular.element('.box-header')[0];

                                                        var deltaH1 = elH1 && elH1.offsetHeight;
                                                        if (deltaH1) {
                                                            deltaH1 += 20;
                                                            h1 -= deltaH1;
                                                        }
													}
												}
                                                angular.element(ytplayer.getIframe()).css({
                                                    'width': w1 + 'px',
                                                    'height': h1 + 'px'
                                                });
											}
										}
									}
								});
							}
						}


						API.onVideoReady();
					}

					function onVideoStateChange(event) {
						switch (event.data) {
							case YT.PlayerState.ENDED:
								API.onComplete();
								break;

							case YT.PlayerState.PLAYING:
								//API.setState(API.STATES.PLAY);
								API.onStartPlaying();
								break;

							case YT.PlayerState.PAUSED:
								//API.setState(API.STATES.PAUSE);
								API.onPause();
								break;

							case YT.PlayerState.BUFFERING:
								//No appropriate state
								break;

							case YT.PlayerState.CUED:
								//No appropriate state
								break;
						}
					}

					function isYoutube(url) {
						if (url) {
							return url.match(youtubeReg);
						}
						return false;
					}

					function onSourceChange(url) {
						if (isYoutube(url)) {
							initYoutubePlayer(url);
						} else if (ytplayer) {
							destroyYoutubePlayer();
						}
					}

					function destroyYoutubePlayer() {
						if (ytplayer) {
							ytplayer.destroy();
						}
						ytplayer = null;
					}
				}
			};
		}

		ocCustomMediaYoutube.$inject = [
			'$rootScope',
			'$window'
		];

		angular.module('common').directive('ocCustomMediaYoutube', ocCustomMediaYoutube);
	})();

	/*--------------------------*/

	(function () {
		var vimeoReg = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;

		angular.module('common')
			.run(['ocCustomVideoService',
				function (ocCustomVideoService) {
					ocCustomVideoService.registerOcCustomMedia(vimeoReg, '<oc-custom-media-vimeo/>');
				}
			])
			.run(['$rootScope',
				function ($rootScope) {
					var scriptSrc = 'https://f.vimeocdn.com/js/froogaloop2.min.js'; //https://player.vimeo.com/api/player.js'
					var isScriptInjected = Array.prototype.some.call(document.scripts, function (script) {
						return script.src === scriptSrc;
					});
					if (isScriptInjected) {
						$rootScope.vimeoApiReady = true;
						return;
					}
					var tag = document.createElement('script');
					tag.onload = function () {
						$rootScope.$apply(function () {
							$rootScope.vimeoApiReady = true;
						});
					};
					tag.async = true;
					tag.src = scriptSrc;
					var firstScriptTag = document.getElementsByTagName('script')[0];
					firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
				}
			]);

		function ocCustomMediaVimeo($rootScope, $window) {
			return {
				restrict: 'AE',
				require: '^ocCustomVideo',
				template: '<iframe src="" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe><div class="vimeo-no-pause" ng-if="!$parent.video.options.controls"></div>',
				link: function (scope, elem, attrs, API) {
					var playerId = Math.random().toString().slice(2),
						player,
						iframe = elem.children(),
						playerVarsDefault = {
							'api': 1,
							'player_id': playerId
						},
						playerVars = {},
						progress = {
							seconds: 0,
							percent: 0,
							duration: 0
						},
						readyToAddEvents = false;

					API.mediaElement = elem;

					iframe[0].id = playerId;

					extendOptions(API.options);

					scope.$watch(
						function () {
							return API.source;
						},
						function (newVal) {
							if (newVal && newVal.src) {
								onSourceChange();
							}
						}
					);

					scope.$on('$destroy', function () {
						destroyPlayer();
					});

					scope.$watch(
						function () {
							return API.options;
						},
						extendOptions
					);

					function extendOptions(value) {
						var opt = angular.copy(value || {});

						if(typeof opt === 'string'){
							opt = JSON.parse(opt);
						}

						if(opt.videoSettings){
							if (opt.videoSettings.startTime) {
								opt.start = opt.videoSettings.startTime;
								delete opt.startTime;
							}
							if (opt.videoSettings.endTime && opt.videoSettings.endTime>0) {
								opt.end = opt.videoSettings.endTime;
								delete opt.endTime;
							}
							opt.autoplay = opt.videoSettings.autoplay ? 1 : 0;
							opt.loop = opt.videoSettings.autorepeat ? 1 : 0;
							if(opt.videoSettings.desktopSize){
								opt.width = opt.videoSettings.desktopSize.width || NaN;
								opt.height = opt.videoSettings.desktopSize.height || NaN;
							}
							delete opt.videoSettings;
						} else {
							if (opt.startTime) {
								opt.start = opt.startTime;
								delete opt.startTime;
							}
							if (opt.endTime && opt.endTime>0) {
								opt.end = opt.endTime;
								delete opt.endTime;
							}
							if (opt.autoplay) {
								opt.autoplay = opt.autoplay && 1 || 0;
							}
							if (opt.autorepeat) {
								opt.loop = opt.autorepeat && 1 || 0;
								delete opt.autorepeat;
							}
							if(opt.desktopSize){
								opt.width = opt.desktopSize.width || NaN;
								opt.height = opt.desktopSize.height || NaN;
							}
						}

						if(!opt.height && !opt.width ) {
							var newHeight = angular.element('game').height() -
								angular.element('.top-details-spacer').height() -
								angular.element('header').height() -
								angular.element('form').height() - 100;
							opt.height = newHeight>199 ? newHeight : undefined;
						}

						playerVars = angular.extend({}, playerVarsDefault, opt);
					}

					function getVideoId(url) {
						return url.match(vimeoReg)[5];
					}

					function initPlayer(url) {
						if (player) {
							destroyPlayer();
							iframe[0].src = 'https://player.vimeo.com/video/' + getVideoId(url) + createUrlParamsByObject(playerVars);
						} else {
							$rootScope.$watch('vimeoApiReady', function (value) {
								if (value) {
									iframe[0].onload = function() {
										readyToAddEvents = true;
									};
									iframe[0].src = 'https://player.vimeo.com/video/' + getVideoId(url) + createUrlParamsByObject(playerVars);
									player = $window.$f(iframe[0]);
									player.addEvent('ready', onVideoReady);
								}
							});
						}
						if (playerVars && playerVars.stopedByEnd) {
							delete playerVars.stopedByEnd;
						}
					}

					function onVideoReady() {
						//Define some property, method for player
						API.mediaElement[0].__defineGetter__('currentTime', function () {
							return progress.seconds;
						});
						API.mediaElement[0].__defineSetter__('currentTime', function (seconds) {
							return player.api('seekTo', seconds);
						});
						API.mediaElement[0].__defineGetter__('duration', function () {
							return progress.duration;
						});
						API.mediaElement[0].__defineGetter__('paused', function () {
							return API.currentState != API.STATES.PLAY;
						});
						API.mediaElement[0].__defineGetter__('videoWidth', function () {
							return player.element.offsetWidth;
						});
						API.mediaElement[0].__defineGetter__('videoHeight', function () {
							return player.element.offsetHeight;
						});
						API.mediaElement[0].__defineGetter__('volume', function () {
							return player.getVolume();
						});
						API.mediaElement[0].__defineSetter__('volume', function (volume) {
							return player.api('setVolume', volume);
						});
						API.mediaElement[0].play = function () {
							player.api('play');
						};
						API.mediaElement[0].pause = function () {
                            player && player.api('pause');
						};

						if(readyToAddEvents) {
							addEvents();
						} else {
							player.element.onload = function() {
								addEvents();
							};
						}

						API.onVideoReady();

						if (playerVars.start) {
							player.api('seekTo', playerVars.start);
						}

						if (playerVars.autoplay) {
                            $window.setTimeout(onPlay, 10);
						}

                        if (playerVars.background) {
                            player.api('setVolume', 0);
                        }

						if (!isNaN(playerVars.width) && !isNaN(playerVars.height)) {
							iframe.css({
								'width':playerVars.width + 'px',
								'height':playerVars.height + 'px',
								'display': 'block',
								'margin': '0 auto'
							});
						} else {
							if(playerVars && playerVars.height > 0 && iframe.parent().parent().parent().offset().top !==0) {
								iframe.css({
									'height': playerVars.height + 'px',
									'display': 'block',
									'margin': '0 auto'
								});
							} else {
								iframe.css({
									'width': '100%',
									'height': '100%'
								});
							}
						}
					}

					function addEvents() {
						player.addEvent('play', onPlay);
						player.addEvent('pause', onPause);
						player.addEvent('finish', onFinish);
						player.addEvent('playProgress', onPlayProgress);
					}

					function removeEvents() {
						player.removeEvent('ready', onVideoReady);
						player.removeEvent('play', onPlay);
						player.removeEvent('pause', onPause);
						player.removeEvent('finish', onFinish);
						player.removeEvent('playProgress', onPlayProgress);
					}

					function onPlay() {
						//API.setState(API.STATES.PLAY);
						API.onStartPlaying();
					}

					function onPause() {
						//API.setState(API.STATES.PAUSE);
						API.onPause();
					}

					function onFinish() {
						API.onComplete();
					}

					function onPlayProgress(data) {
						progress = data;
						if (playerVars.end && progress.seconds >= playerVars.end && !playerVars.stopedByEnd) {
							// We allow only once stop operation
							playerVars.stopedByEnd = true;
							player.api('pause');
							onFinish();
						}
					}

					function onSourceChange() {
						initPlayer(API.source.src);
					}

					function destroyPlayer() {
						if (player) {
							removeEvents();
						}
						player = null;
					}

					function createUrlParamsByObject(obj) {
						var params = '';
						Object.keys(obj || {}).forEach(function (key) {
							params += params.length ? '&' : '?';
							params += key + '=' + obj[key];
						});
						return params;
					}
				}
			};
		}

		ocCustomMediaVimeo.$inject = [
			'$rootScope',
			'$window'
		];

		angular.module('common').directive('ocCustomMediaVimeo', ocCustomMediaVimeo);
	})();

})(window.angular);