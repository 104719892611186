(function (angular) {
	'use strict';

	var blankImage = 'https://oc-cobra-cdn-sc.azureedge.net/games/defaults/oc-FFFFFF-0.png';

	angular.module('settings', [])
		.constant('apiSettings', {
			ngAuthSettings: {
				apiServiceBaseUri: 'https://dev-auth.offercraft.net/',//'http://192.168.105.178:800/'
				clientId: 'cobra'
			},

			apiServiceBaseUri: 'https://dev-c-api.reward-access.com/',//'http://192.168.105.178:800/'

            playerManagementUri: 'https://dev-oc3-portal-ui.azurewebsites.net/session/login/',

			userWebBaseUri: 'https://dev.reward-access.com/',

			gamesBaseRoot: '/games/',

			clientId: 'pierre',

			drawingUrl: 'https://calm-sand-0fbd4511e.1.azurestaticapps.net'
		})
		.constant('blankImage', blankImage);
})(window.angular);
