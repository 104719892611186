(function (angular) {
    'use strict';

    function DashboardService($window, $rootScope, apiResource, $state, $q, utilityService, $translate, $filter, sessionStorageService, $http, notifier, $timeout, localIpService, apiSettings) {
        var service = $window.Object.create($window.EventEmitter.prototype),
            expireTimeout,
            attemptsToRefreshToken = 0;
        service.login = {};
        service.loginData = {};
        service.clientLogo = null;
        service.loginPageConfig = null;
        service.currentDashboard = {};
        service.getNotificationDeliveryTypes = getNotificationDeliveryTypes;
        service.saveNotificationDeliveryType = saveNotificationDeliveryType;
        service.getDashboard = getDashboard;
        service.getSecureDashboard = getSecureDashboard;
        service.setCurrentDashboard = setCurrentDashboard;
        service.getCustomDashboard = getCustomDashboard;
        service.integrationSignUp = integrationSignUp;
        service.integrationLogin = integrationLogin;
        service.loginByManager = loginByManager;
        service.loginCustomUser = loginCustomUser;
        service.loginByToken = loginByToken;
        service.logOut = logOut;

        service.getDashboardAuthType = getDashboardAuthType;

        service.integrationLogout = integrationLogout;
        service.refreshLogin = refreshLogin;
        service.feedTemplate = {
            location: 'top',
            animationType: 'fullScroll',
            speed: 2000,
            backgroundColor: '#ffffff',
            textColor: '#000000',
            items: [],
            isEnabled: false
        };

        return service;

        function logOut() {
            var token = service.currentDashboard.dToken;
            var authData = utilityService.parseSafeJSON(sessionStorageService.get(token + '.dashboardAuthentication'));

            var data = 'client_id=' + apiSettings.ngAuthSettings.clientId;
            data += '&token_type_hint=refresh_token';
            data += '&token=' + (authData && authData.refresh_token || '');

            return $http.post(apiSettings.ngAuthSettings.apiServiceBaseUri + 'revoke/?logout=1', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }/*,
                ignoreDefaultErrorNotifier: true*/
            }).success(function () {
                service.currentDashboard = {};
                service.loginPageConfig = null;
                sessionStorageService.remove(token + '.dashboardAuthentication');
                $state.reload();
            });
        }

        function getDashboardAuthType(token) {
            return apiResource
                .get('api/dashboard/auth/' + token);
        }

        function getSecureDashboard($stateParams) {
            var sessionLogin = utilityService.parseSafeJSON(sessionStorageService.get('dashboardAuthentication'));
            var sessionLoginNew = utilityService.parseSafeJSON(sessionStorageService.get($stateParams.dToken + '.dashboardAuthentication'));
            if (sessionLoginNew) {
                sessionStorageService.remove('dashboardAuthentication');
                sessionLogin = sessionLoginNew;
            }

            //service.login.userName = (sessionLogin && sessionLogin.userName) || service.login.userName;
            //service.login.password = (sessionLogin && sessionLogin.password) || service.login.password;

            return service.getDashboardAuthType($stateParams.dToken).then(function (auth) {
                service.dashboardAuthorizationType = auth.dashboardAuthorizationType;

                switch (auth.dashboardAuthorizationType) {
                    case 'PortalUsers':
                    case 'CustomUsers':
                        return service.loginCustomUser(/*auth.dashboardHash*/$stateParams.dToken).then(function () {

                            if (sessionLogin && (sessionLogin.token || sessionLogin.access_token)) {
                                return service.getDashboard($stateParams.dToken);
                            } else {
                                $state.go('dashboard-login', {
                                    dToken: $stateParams.dToken,
                                    //token: auth.dashboardHash,
                                    aType: auth.dashboardAuthorizationType,
                                    u: $stateParams.u /*|| service.login.userName*/,
                                    p: $stateParams.p /*|| service.login.password*/
                                });
                            }
                        });
                    case 'ExternalApi':
                        //return service.loginCustomUser(auth.dashboardHash).then(function () {
                        return service.getDashboard($stateParams.dToken).then(function () {
                            $state.go('integration-login', { dToken: $stateParams.dToken });
                        });
                    case 'None':
                        //if (userName && password) {

                        //} else {

                        return service.loginByToken({
                            grant_type: 'dashboard_token',
                            dtoken: encodeURIComponent(/*auth.dashboardHash*/$stateParams.dToken),
                            client_id: apiSettings.ngAuthSettings.clientId
                        }).then(function (authData) {
                            sessionStorageService.set($stateParams.dToken + '.dashboardAuthentication', angular.toJson(authData));
                            return service.getDashboard($stateParams.dToken, authData.access_token, $stateParams.u, $stateParams.p);
                        });
                    //}
                }
            });
        }

        function getDashboardRefreshToken(token, accessToken) {
            return service.loginByToken({
                grant_type: 'dashboard_access_token',
                dtoken: token,
                access_token: accessToken,
                client_id: apiSettings.ngAuthSettings.clientId
            }).then(function(response) {
                sessionStorageService.set(token + '.dashboardAuthentication', angular.toJson(response));
            });
        }

        function getDashboard(token, accessToken, userName, password) {
            var sessionLogin = utilityService.parseSafeJSON(sessionStorageService.get('dashboardAuthentication'));
            var sessionLoginNew = utilityService.parseSafeJSON(sessionStorageService.get(token + '.dashboardAuthentication'));

            if (sessionLoginNew) {
                sessionStorageService.remove('dashboardAuthentication');
                sessionLogin = sessionLoginNew;
            }


            //service.login.userName = userName || (sessionLogin && sessionLogin.userName) || service.login.userName;
            //service.login.password = password || (sessionLogin && sessionLogin.password) || service.login.password;
            service.login.token = accessToken || (sessionLogin && (sessionLogin.token || sessionLogin.access_token)) || service.login.token;

            accessToken = accessToken  || service.login.token|| service.currentDashboard.accessToken;

            var q = function(ipList) {
                var request = (!accessToken && !userName && !password) ?
                    apiResource
                        .get('api/dashboard/' + token, {
                            headers: {
                                'X-OC-Client-IP': ipList ? ipList.join(',') : ''
                            }
                        }) :
                    apiResource
                        .post('api/dashboard/' + token + '/?isFirstLogin=' + !!(userName && password), {
                            userName: service.login.userName,
                            password: service.login.password
                        }, {
                            headers: {
                                'X-OC-Client-IP': ipList ? ipList.join(',') : '',
                                'Authorization': 'Bearer ' + encodeURIComponent(accessToken)
                            }
                        }); //then() refresh a_token

                request = request.catch(function (err) {
                    if (err.status === 401) {
                        return refreshDashboardLogin(token);
                    } if (err.status === 400) {
                        return false;
                    } else if (err.status === 403) {
                        service.currentDashboard = {};
                        service.loginPageConfig = null;
                        sessionStorageService.remove(token + '.dashboardAuthentication');
                        $state.go('dashboard', { dToken: token });
                    }

                    return false;
                });

                return request;
            };

            if (/*!service.login.rToken*/!sessionLoginNew && sessionLogin) { //for old tokens - temp solution
                return getDashboardRefreshToken(token, accessToken).then(function() {
                    return getClientIpAddress()
                        .then(function (ipList) {
                            return q(ipList);
                        })
                        .then(function (dashboard) {
                            return dashboard && useDashboardData(dashboard, token, accessToken);
                        });
                });
            } else {
                return getClientIpAddress()
                    .then(function (ipList) {
                        return q(ipList);
                    })
                    .then(function (dashboard) {
                        return dashboard && useDashboardData(dashboard, token, accessToken);
                    });
            }
        }

        function useDashboardData(dashboard, token, accessToken) {
            if (dashboard.version === 'v2') {
                service.loginPageConfig = dashboard.dashboardLoginPageConfiguration;
                if (service.loginPageConfig) {
                    sessionStorageService.set('integrationLoginPageConfig', angular.toJson(service.loginPageConfig));
                }
                service.feed = utilityService.parseSafeJSON(dashboard.feed) || service.feedTemplate;

                applyLocale(dashboard.locale);

                if (!$state.includes('integration-login')) {
                    $state.go('integration-login', { dToken: token });
                }

            } else {

                if (dashboard.boxesLayout && dashboard.boxesLayout.length) {
                    dashboard.boxesLayout = utilityService.parseSafeJSON(dashboard.boxesLayout);
                }

                service.clientLogo = dashboard.loginClientLogo;
                service.loginPageConfig = dashboard.dashboardLoginPageConfiguration;

                applyLocale(dashboard.locale);

                //sessionStorageService.set(token + '.dashboardAuthentication', angular.toJson(service.login));

                //if (dashboard.requiresAuthorization) {
                //    if (dashboard.visitingUser) {
                //        sessionStorageService.set('dashboardAuthentication',
                //            angular.toJson(service.login));
                //    } else {
                //        //$state.go('dashboard-login', { dToken: token, aType: dashboard.authorizationType, token: accessToken });
                //        //return $q.reject({
                //        //    data: {
                //        //        error_description: $filter('translate')('User is not found. Please verify your credentials.')
                //        //    }
                //        //});
                //    }
                //}

                dashboard.dToken = token;
                dashboard.accessToken = accessToken;

                dashboard.feed = utilityService.parseSafeJSON(dashboard.feed) || service.feedTemplate;
                dashboard.uiSettings = utilityService.parseSafeJSON(dashboard.uiSettings);

                $rootScope.title = dashboard.uiSettings.snaphotTitle;

                dashboard.boxes.forEach(function (box) {
                    if(box.imageCarouselUISettings && box.imageCarouselItems) {
                        box.imageCarouselItems.forEach(function(imageItem) {
                            imageItem.image = utilityService.parseSafeJSON(imageItem.image);
                        });
                        box.imageCarouselItems = box.imageCarouselItems.filter(function(item) {return !!item.image.value;});
                        box.imageCarouselUISettings = utilityService.parseSafeJSON(box.imageCarouselUISettings);
                    }
                    if (box.uiSettings) {
                        box.uiSettings = utilityService.parseSafeJSON(box.uiSettings);
                    }
                    if (box.data) {
                        box.data = utilityService.parseSafeJSON(box.data);
                    }
                    if (box.values) {
                        box.values.forEach(function (value) {
                            if (typeof value.name == 'string') {
                                value.name = value.name.trim().replace(/\s+/, ' ');
                            }
                        });
                    }
                    if (dashboard.boxesLayout) {
                        dashboard.boxesLayout.boxes.forEach(function (boxLayout) {
                            if (boxLayout.dashboardBoxId === box.dashboardBoxId) {
                                Object.assign(box, boxLayout);
                            }
                        });
                    }
                });

                setCurrentDashboard(dashboard);

                return dashboard;
            }
        }

        function loginCustomUser(hash) {
            return apiResource
                .get('/api/dashboard/auth/login?hash=' + hash).then(function (conf) {
                    service.clientLogo = conf.loginClientLogo;
                    service.loginPageConfig = conf.dashboardLoginPageConfiguration;
                });
        }

        function loginByToken(tokenData, ignoreNotifier) {
            var data = Object.keys(tokenData).map(function (k) { return k + '=' + tokenData[k]; }).join('&');
            return $http
                .post(apiSettings.ngAuthSettings.apiServiceBaseUri + 'token', data, {
                    ignoreDefaultErrorNotifier: ignoreNotifier,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function (resp) {
                    sessionStorageService.set(tokenData.dtoken + '.dashboardAuthentication', angular.toJson(resp.data));
                    return resp.data;
                });
        }

        function getCustomDashboard(token) {
            var sessionAuthData = utilityService.parseSafeJSON(sessionStorageService.get('integrationAuthentication'));
            service.loginData = service.loginData && Object.keys(service.loginData).length ? service.loginData : sessionAuthData;

            if (!service.loginData || !service.loginData.access_token) {
                return refreshLogin(token);
            }

            return apiResource
                .get('/api/ext/dashboards/' + token, {
                    headers: {
                        'Authorization': service.loginData.token_type + ' ' + service.loginData.access_token
                    }
                }).then(function (dashboard) {

                    return getCustomDashboardProfile(token).then(function (profile) {
                        dashboard.data = profile.data;
                        if (dashboard.boxesLayout && dashboard.boxesLayout.length) {
                            dashboard.boxesLayout = utilityService.parseSafeJSON(dashboard.boxesLayout);
                        }

                        service.clientLogo = dashboard.loginClientLogo;

                        dashboard.dToken = token;

                        dashboard.uiSettings = utilityService.parseSafeJSON(dashboard.uiSettings);

                        dashboard.boxes.forEach(function (box) {
                            if (box.uiSettings) {
                                box.uiSettings = utilityService.parseSafeJSON(box.uiSettings);
                            }
                            if (box.data) {
                                box.data = utilityService.parseSafeJSON(box.data);
                            }
                            if (box.values) {
                                box.values.forEach(function (value) {
                                    if (typeof value.name == 'string') {
                                        value.name = value.name.trim().replace(/\s+/, ' ');
                                    }
                                });
                            }

                            if (dashboard.boxesLayout) {
                                dashboard.boxesLayout.boxes.forEach(function (boxLayout) {
                                    if (boxLayout.dashboardBoxId === box.dashboardBoxId) {
                                        Object.assign(box, boxLayout);
                                    }
                                });

                            }
                        });

                        setCurrentDashboard(dashboard);
                        attemptsToRefreshToken = 0;
                        return dashboard;
                    }, function (err) {
                        if (err.status === 401) {
                            attemptsToRefreshToken++;
                            return refreshLogin(token);
                        }
                    });
                }, function (err) {
                    if (err.status === 401) {
                        attemptsToRefreshToken++;
                        return refreshLogin(token);
                    }
                });
        }

        function getCustomDashboardProfile(token) {
            return apiResource
                .get('/api/ext/dashboards/' + token + '/profile', {
                    headers: {
                        'Authorization': service.loginData.token_type + ' ' + service.loginData.access_token
                    }
                });
        }

        function integrationSignUp(data, formModelName) { //TODO: relocate all auth  logic to the authService
            data = angular.copy(data) || {};
            data.profileSourceApiType = service.loginPageConfig.signUpApiType;
            var dateFields = [];
            for (var field in data) {
                if (angular.isDate(data[field])) {
                    data[field] = utilityService.convertDateToISOFormat(data[field]);
                    dateFields.push(field);
                }
            }
            return $http
                .post(service.loginPageConfig.forms[formModelName].postEndpoint,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Target': service.loginPageConfig.tenantKey
                    }
                }).then(function () {
                    notifier.success('Operation is succeed');
                });
        }



        function integrationLogin(data, token) {
            return $http
                .post(service.loginPageConfig.tokenEndpoint, 'grant_type=password&username=' + data.username + '&password=' + data.password + '&client_id=cobra', {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Target': service.loginPageConfig.tenantKey
                    }
                }).then(function (res) {
                    service.loginData = res.data;
                    service.isLoggedOut = false;
                    expireTimeout && $timeout.cancel(expireTimeout);
                    expireTimeout = $timeout(function () {
                        refreshLogin(token);
                    }, res.data.expires_in * 1000);
                    sessionStorageService.set('integrationAuthentication', angular.toJson(service.loginData));
                });
        }


        function loginByManager(access_token, userId) {
            var data = 'grant_type=token-exchange'
                + '&actor_token=' + encodeURIComponent(access_token)
                + '&impersonated_sub=' + encodeURIComponent(userId)
                + '&client_id=player-portal';

            return $http
                .post(service.loginPageConfig.tokenEndpoint, data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Target': service.loginPageConfig.tenantKey
                    }
                }).then(function (res) {
                    service.loginData = res.data;
                    service.isLoggedOut = false;
                    sessionStorageService.set('integrationAuthentication', angular.toJson(service.loginData));
                });
        }

        function refreshDashboardLogin(token) {
            service.loginData = utilityService.parseSafeJSON(sessionStorageService.get(token + '.dashboardAuthentication'));
            
            return $http
                .post(apiSettings.ngAuthSettings.apiServiceBaseUri + 'token', 'grant_type=refresh_token&client_id=cobra&refresh_token=' + service.loginData.refresh_token, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function (res) {
                    service.loginData = res.data;
                    service.isLoggedOut = false;
                    //expireTimeout = $timeout(function () {
                    //    refreshDashboardLogin(token);
                    //}, res.data.expires_in * 1000);
                    sessionStorageService.set(token + '.dashboardAuthentication', angular.toJson(service.loginData));

                    return getDashboard(token);
                },
                function (err) {
                    if (err.status === 400/* && err.data.error === 'invalid_grant'*/) {
                        sessionStorageService.remove(token + '.dashboardAuthentication');
                        service.currentDashboard = {};
                        service.loginPageConfig = null;
                        service.dashboardAuthorizationType ? $state.go('dashboard-login', { dToken: token, aType: service.dashboardAuthorizationType }) : $state.go('dashboard', { dToken: token });
                    }

                    return false;
                });
        }

        function refreshLogin(token) {
            token = token ? token : (service.currentDashboard.dToken || $state.params.dToken);
            if (!service.loginData) {
                service.loginData = utilityService.parseSafeJSON(sessionStorageService.get('integrationAuthentication'));
            }
            if (!service.loginPageConfig) {
                service.loginPageConfig = utilityService.parseSafeJSON(sessionStorageService.get('integrationLoginPageConfig'));
                if (!service.loginPageConfig) {
                    $state.go('dashboard', { dToken: token });
                }
            }

            return $http
                .post(service.loginPageConfig.tokenEndpoint, 'grant_type=refresh_token&client_id=cobra&refresh_token=' + service.loginData.refresh_token, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Target': service.loginPageConfig.tenantKey
                    }
                }).then(function (res) {
                        if(attemptsToRefreshToken >= 3){
                            attemptsToRefreshToken = 0;
                            if (!$state.includes('integration-login')) {
                                notifier.error('Sorry, but we have problems with authentication. Please try again later.');
                                return integrationLogout();
                            } else {
                                notifier.error('We have problems with logging in. Please try again later.');
                                return $q.reject();
                            }
                        } else {

                            service.loginData = res.data;
                            service.isLoggedOut = false;
                            expireTimeout && $timeout.cancel(expireTimeout);
                            expireTimeout = $timeout(function () {
                                refreshLogin(token);
                            }, res.data.expires_in * 1000);
                            sessionStorageService.set('integrationAuthentication', angular.toJson(service.loginData));
                            if (!$state.includes('custom-dashboard')) {
                                return $state.go('custom-dashboard', {dToken: token});
                            }
                        }
                    },
                    function (err) {
                        if (err.status === 400 && err.data.error === 'invalid_grant') {
                            if (!$state.includes('integration-login')) {
                                return integrationLogout();
                            }
                        }
                    });
        }

        function integrationLogout() {
            service.isLoggedOut = true;
            if (!service.loginPageConfig) {
                service.loginPageConfig = utilityService.parseSafeJSON(sessionStorageService.get('integrationLoginPageConfig'));
            }
            return $http
                .post(service.loginPageConfig.tokenRevocationEndpoint + '/?logout', 'token_type_hint=refresh_token&client_id=cobra&token=' + service.loginData.refresh_token, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Target': service.loginPageConfig.tenantKey
                    }
                }).then(function () {
                    $timeout.cancel(expireTimeout);
                    sessionStorageService.remove('integrationAuthentication');
                    sessionStorageService.remove('integrationLoginPageConfig');
                    service.login = {};
                    service.loginData = {};
                    service.loginPageConfig = {};
                    service.currentDashboard = {};
                    notifier.success('You are logged out.');
                }).finally(function () {
                    return $state.go('dashboard', {dToken: $state.params.dToken});
                });
        }

        function applyLocale(locale) {
            service.locale = locale;
            if (locale && typeof locale == 'string') {
                $translate.use(locale);
            }
        }

        function getNotificationDeliveryTypes() {
            return apiResource
                .get('api/dashboard/notificationDeliveryTypes')
                .then(function (data) {
                    service.deliveryTypes = data;
                    return data;
                });
        }

        function saveNotificationDeliveryType(dToken, userId, deliveryType, deliveryValue) {
            return apiResource
                .put('api/dashboard/' + dToken + '/submitDeliveryType/' + userId, {
                    type: deliveryType,
                    deliveryValue: deliveryValue
                });
        }

        function setCurrentDashboard(dashboard) {
            if (dashboard !== service.currentDashboard) {
                angular.copy(dashboard, service.currentDashboard);
                updateDashboardBoxesReferences(service.currentDashboard);
            }
            fireEvent('onSetCurrentDashboard', service.currentDashboard);
        }

        function fireEvent(event, data) {
            service.trigger(event, (typeof data !== 'undefined') ? [data] : undefined);
            $rootScope.$applyAsync();
        }

        function updateDashboardBoxesReferences(currentDashboard) {
            if (!currentDashboard || !angular.isArray(currentDashboard.boxes)) {
                return false;
            }

            if (!currentDashboard.dashboardBoxes) {
                currentDashboard.dashboardBoxes = {};
            } else {
                angular.copy({}, currentDashboard.dashboardBoxes);
            }

            currentDashboard.boxes.forEach(function (box) {
                currentDashboard.dashboardBoxes[box.index] = box;
            });
        }

        function getClientIpAddress() {
            var deferred = $q.defer();
            localIpService.getAddresses(function (ips) {
                deferred.resolve(ips);
            });

            return deferred.promise;
        }
    }

    DashboardService.$inject = [
        '$window',
        '$rootScope',
        'apiResource',
        '$state',
        '$q',
        'utilityService',
        '$translate',
        '$filter',
        'sessionStorageService',
        '$http',
        'notifier',
        '$timeout',
        'localIpService',
        'apiSettings'
    ];

    angular.module('dashboard').factory('dashboardService', DashboardService);
})(window.angular);