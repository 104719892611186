(function (angular) {

    function apiResource($http, apiSettings, utilityService) {
		var baseUri = apiSettings.apiServiceBaseUri;

        if (utilityService.isIE) {
            if (!$http.defaults.headers.get) {
                $http.defaults.headers.get = {};
            }

            $http.defaults.headers.get['If-Modified-Since'] = '0';
            $http.defaults.headers.get['Cache-Control'] = 'no-cache';
            $http.defaults.headers.get['Pragma'] = 'no-cache';
        }

		function getURL(url1, url2) {
			if (!/\/$/.test(url1) && !/^\//.test(url2)) {
				url2 = '/' + url2;
			}
			return (url1 + url2).replace(/(.)(\/+)/g, function (str, p1) {
				if (p1 === ':') return str;
				return p1 + '\/';
			});
		}

		function resource(config) {
			config.url = baseUri ? getURL(baseUri, config.url) : config.url;
			return $http.apply($http, arguments).then(function (response) {
				return response.data;
			});
		}

		Object.keys($http).filter(function (key) {
			return (typeof $http[key] === 'function');
		}).forEach(function (key) {
			resource[key] = function (url) {
				var args = [].slice.call(arguments, 1);
				url = baseUri ? getURL(baseUri, url) : url;
				args.unshift(url);
				return $http[key].apply($http, args).then(function (response) {
					return response.data;
				});
			};
		});

		return resource;
	}

	apiResource.$inject = [
		'$http',
        'apiSettings',
        'utilityService'
	];

	angular.module('common').factory('apiResource', apiResource);

})(window.angular);