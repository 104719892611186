(function(angular) {
	'use strict';

	function ocDashboardLandscape($window) {
		return {
			restrict: 'AE',
			scope: {
				maxWidth: '@ocMaxWidth'
			},
			link: function (scope, ele) {
				$window.addEventListener('resize', _onResize, false);

				_onResize();

				function _onResize() {
					if($window.innerWidth <= parseInt(scope.maxWidth) && $window.innerWidth > $window.innerHeight) {
						ele.css('padding-top', $window.innerHeight);
					}
				}
			}
		};
	}

	ocDashboardLandscape.$inject = ['$window'];

	angular.module('common').directive('ocDashboardLandscape', ocDashboardLandscape);


})(window.angular);
