(function (angular) {
	'use strict';

	var modalTmpl = '<div class="modal-body">\
						<p>\
							<strong>{{modal.model.message | translate}}</strong>\
						</p>\
					</div>\
					<div class="modal-footer">\
						<button class="btn {{modal.model.okBtnClass}}" ng-show="modal.model.okBtnText" ng-click="$close()">{{modal.model.okBtnText | translate}}</button>\
						<button class="btn {{modal.model.cancelBtnClass}}" ng-if="modal.model.cancelBtnText" ng-click="$dismiss()">{{modal.model.cancelBtnText | translate}}</button>\
					</div>';

	function ModalCtrl(model) {
		var vm = this;

		vm.model = model;
	}

	ModalCtrl.$inject = [
		'model'
	];

	function confirmationModalService($uibModal) {
		var service = {},
			defaultOpts = {
				message: 'Are you sure?',
				okBtnText: 'Ok',
				cancelBtnText: 'Cancel',
				okBtnClass: 'btn-primary',
				cancelBtnClass: 'btn-default'
			};

		service.confirm = confirm;

		return service;

		function confirm(options) {
			options = angular.extend({}, defaultOpts, options || {});

			return $uibModal.open({
				template: modalTmpl,
				controller: ModalCtrl,
				controllerAs: 'modal',
				backdrop: 'static',
				keyboard: false,
				resolve: {
					model: function () {
						return options;
					}
				}
			});
		}
	}

	confirmationModalService.$inject = [
		'$uibModal'
	];

	angular.module('common').service('confirmationModalService', confirmationModalService);

})(window.angular);