(function (angular) {
  function HtmlTemplateDashboardBoxCtrl(
    $timeout,
    $filter,
    $uibModal,
    $scope,
    $rootScope,
    $element
  ) {
    var vm = this;
    var dateFilter = $filter('date');
    var numberFilter = $filter('number');
    vm.$timeout = $timeout;
    vm.isIFrameModalVisible = false;

    var $ = window['jQuery'];

    //vm.html = $sce.trustAsHtml(vm.model.data);

    vm.tokens = {};
    setTokens();

    function applyData(el) {
      $timeout(function () {
        $(el)
          .find('nrt-content-list[data-key]')
          .each(function (i, list) {
            var key = $(list).attr('data-key');
            var model = { model: vm };
            if (key) {
              var obj;
              var keys = key.split('.');

              keys.forEach(function (key) {
                obj = (obj && obj[key]) || model[key];
              });

              list.data = obj || [];

              var filterKey = $(list).attr('filter-by-key');
              var filterValue = $(list).attr('filter-by-value');
              var filterCondition = $(list).attr('filter-by-condition');

              if (filterKey) {
                var filteringValues = filterValue.split(',').map(function (x) {
                  return x.trim().toLowerCase();
                });

                list.data = list.data.filter(function (v) {
                  var value = (v[filterKey] || '').toLowerCase();
                  return (
                    (!filterValue && !value) ||
                    (!filterCondition || filterCondition === 'contains'
                      ? filteringValues.includes(value)
                      : !filteringValues.includes(value))
                  );
                });
              }
            } else {
              list.data = [model];
            }

            $(list).children().first().css('display', 'none');
          });

        $(el)
          .find('nrt-if')
          .each(function (i, ifElement) {
            ifElement.addEventListener('resultChanged', function (res) {
              if (!res.detail) {
                $(res.target).children().first().css('display', 'none');
              } else {
                $(res.target).children().first().css('display', '');
              }
            });

            var key = $(ifElement).attr('data-key');
            var operator = $(ifElement).attr('data-operator');
            var value = $(ifElement).attr('data-value');
            if (key && operator && value) {
              ifElement.model = { model: vm };
              var keys = key.split('.');
              key = '${' + key + '}';
              var keyValue = undefined;
              keys.forEach(function (tkey) {
                keyValue = keyValue ? keyValue[tkey] : ifElement.model[tkey];
              });
              if (typeof keyValue === 'string') key = '"' + key + '"';
              ifElement.condition = key + operator + value;
            } else {
              ifElement.condition = 'false';
            }
          });
      });
    }

    function setTokens() {
      angular.forEach(vm.tokensData, function (section, sectionName) {
        if (!vm[sectionName]) vm[sectionName] = {};
        angular.forEach(vm.tokensData[sectionName], function (item, name) {
          if (item.type === 'datetime' && item.format) {
            item.value = dateFilter(item.value, item.format);
          }

          if (item.type === 'number' && item.format) {
            item.value = numberFilter(item.value, item.format);
          }

          if (!angular.isArray(item)) {
            vm[sectionName][name] = item.value;
          } else {
            vm[sectionName][name] = item.map(function (i) {
              var arr = {};
              angular.forEach(i, function (im, key) {
                if (im.type === 'datetime' && im.format) {
                  im.value = dateFilter(im.value, im.format);
                }

                if (im.type === 'number' && im.format) {
                  im.value = numberFilter(im.value, im.format);
                }

                arr[key] = im.value;
              });

              return arr;
            });
          }
        });
      });

      applyData($element);

      if (vm.model.contentUrl) {
        var link = vm.model.contentUrl;

        link = window.elements.utils.interpolateTemplateResult(link, {
          model: vm
        });

        //angular.forEach(vm.tokensData.systemInternals, function (tokenValue, name) {
        //    var token = '${model.systemInternals.' + name + '}';
        //    var value = tokenValue.value && encodeURIComponent(tokenValue.value);
        //    link = link.replace(token, value);
        //});

        vm.iFrame = link;
      }
    }

    vm.getHeight = function () {
      var height = vm.model.uiSettings.height;
      return height > 0 ? height + 'px' : '100%';
    };

    $scope.$watch('model.model', function (newModel, oldModel) {
      newModel.isExpanded = oldModel.isExpanded;
      newModel.isExpanded && $rootScope.$broadcast('toggle_expand', newModel);
    });

    vm.showMore = function () {
      vm.showMoreActive = !vm.showMoreActive;
      //vm.model.html = vm.html;
      if (
        angular.isDefined(vm.model.uiSettings.overflowMode) &&
        vm.model.uiSettings.overflowMode === 1 &&
        vm.model.uiSettings.overflowBehaviour === 1
      ) {
        vm.model.isExpanded = vm.showMoreActive;
        $rootScope.$broadcast('toggle_expand', vm.model);
      } else {
        var modal = $uibModal.open({
          templateUrl:
            'app/dashboards/components/htmlTemplate/html-template-modal-dashboard-box.tmpl.html',
          controller: 'HtmlTemplateModalDashboardBoxCtrl',
          bindToController: true,
          controllerAs: 'model',
          keyboard: false,
          size: getModalSize(vm.model.uiSettings.popupFullScreen),
          backdrop: true,
          resolve: {
            model: vm.model,
            tokensData: vm.tokensData,
            parentUiSettings: vm.parentUiSettings
          },
          windowClass:
            vm.model.uiSettings.popupFullScreen === 1
              ? 'html-template-modal full-screen-modal'
              : ''
        });

        modal.result.finally(function () {
          vm.showMoreActive = false;
        });

        modal.rendered.then(function () {
          applyData(
            $(
              vm.model.uiSettings.popupFullScreen === 1
                ? '.html-template-modal'
                : '.modal'
            )
          );
        });

        return modal;
      }
    };

    function getModalSize(id) {
      switch (id) {
        case 2:
          return 'lg';
        case 3:
          return 'md';
        case 4:
          return 'sm';
        default:
          return '';
      }
    }

    vm.toggleModal = function (show) {
      var backdrop = document.getElementsByClassName('modal-backdrop')[0];
      backdrop &&
        (show
          ? backdrop.classList.remove('hidden')
          : backdrop.classList.add('hidden'));

      var mod = document.getElementsByClassName('modal_' + vm.model.index)[0];
      mod && (show ? mod.classList.add('hidden') : mod.classList.add('hidden'));

      vm.isIFrameModalVisible = show;
    };

    vm.closeModal = function () {
      vm.toggleModal(false);
    };

    vm.showIFrame = function (preload) {
      if (vm.iFrame) {
        if (!preload) {
          var backdrop = document.getElementsByClassName('modal-backdrop')[0];
          if (backdrop) {
            backdrop.classList.remove('hidden');
          }
        }

        var mod = document.getElementsByClassName('modal_' + vm.model.index)[0];
        if (mod) {
          mod.classList.remove('hidden');
          vm.isIFrameModalVisible = true;
        } else {
          var modal = $uibModal.open({
            templateUrl:
              'app/dashboards/components/htmlTemplate/html-template-modal-iframe-dashboard-box.tmpl.html',
            template: '',
            keyboard: false,
            backdrop: 'static',
            backdropClass: 'hidden',
            windowClass: 'html-template-modal hidden modal_' + vm.model.index,
            controller: 'HtmlTemplateModalIFrameDashboardBoxCtrl',
            controllerAs: 'ctrl',
            bindToController: true,
            resolve: {
              parent: vm
            }
          });

          return modal;
        }
      } else {
        //vm.isMobile && vm.showMore();
      }
    };

    $timeout(function () {
      vm.iFrame && vm.showIFrame(true);
    });
  }

  HtmlTemplateDashboardBoxCtrl.$inject = [
    '$timeout',
    '$filter',
    '$uibModal',
    '$scope',
    '$rootScope',
    '$element'
  ];

  function HtmlTemplateModalDashboardBoxCtrl(
    $uibModalInstance,
    model,
    tokensData,
    parentUiSettings,
    $filter
  ) {
    var vm = this;
    var dateFilter = $filter('date');
    var numberFilter = $filter('number');
    vm.model = model;
    vm.tokensData = tokensData;
    vm.parentUiSettings = parentUiSettings;

    setTokens();

    function setTokens() {
      angular.forEach(vm.tokensData, function (section, sectionName) {
        if (!vm[sectionName]) vm[sectionName] = {};
        angular.forEach(vm.tokensData[sectionName], function (item, name) {
          if (item.type === 'datetime' && item.format) {
            item.value = dateFilter(item.value, item.format);
          }
          if (item.type === 'number' && item.format) {
            item.value = numberFilter(item.value, item.format);
          }
          if (!angular.isArray(item)) {
            vm[sectionName][name] = item.value;
          } else {
            vm[sectionName][name] = item.map(function (i) {
              var arr = {};
              angular.forEach(i, function (im, key) {
                if (im.type === 'datetime' && im.format) {
                  im.value = dateFilter(im.value, im.format);
                }

                if (im.type === 'number' && im.format) {
                  im.value = numberFilter(im.value, im.format);
                }

                arr[key] = im.value;
              });

              return arr;
            });
          }
        });
      });
    }

    vm.showLess = function () {
      $uibModalInstance.close();
    };
  }

  HtmlTemplateModalDashboardBoxCtrl.$inject = [
    '$uibModalInstance',
    'model',
    'tokensData',
    'parentUiSettings',
    '$filter'
  ];

  function HtmlTemplateModalIFrameDashboardBoxCtrl($scope, parent, $sce) {
    var vm = this;
    vm.parent = parent;

    vm.closeModal = function () {
      parent.closeModal();
    };

    vm.trustSrc = function (src) {
      return $sce.trustAsResourceUrl(src);
    };
  }

  HtmlTemplateModalIFrameDashboardBoxCtrl.$inject = [
    '$scope',
    'parent',
    '$sce'
  ];

  function htmlTemplateDashboardBox() {
    return {
      controller: HtmlTemplateDashboardBoxCtrl,
      controllerAs: 'model',
      bindToController: true,
      scope: {
        model: '=',
        tokensData: '=',
        parentUiSettings: '=',
        isMobile: '='
      },
      templateUrl:
        'app/dashboards/components/htmlTemplate/html-template-dashboard-box.tmpl.html',
      link: function (scope, element) {
        var container =
          element[0] && element[0].getElementsByClassName('box-body');
        if (container && container[0]) {
          scope.model.$timeout(function () {
            var boxClass = 'box-' + scope.model.model.index;
            var box = document.getElementsByClassName(boxClass)[0];

            if (
              container[0].scrollHeight >
              box.offsetHeight /*container[0].offsetHeight*/
            ) {
              scope.model.scroll = true;
            }
          }, 500);
        }
      }
    };
  }

  angular
    .module('dashboard')
    .controller(
      'HtmlTemplateModalDashboardBoxCtrl',
      HtmlTemplateModalDashboardBoxCtrl
    )
    .controller(
      'HtmlTemplateModalIFrameDashboardBoxCtrl',
      HtmlTemplateModalIFrameDashboardBoxCtrl
    )
    .directive('htmlTemplateDashboardBox', htmlTemplateDashboardBox);
})(window.angular);
