(function(angular) {
	'use strict';

	function UserActivityService(utilityService, $log, $rootScope) {
		var service = {};

        service.init = init;
        service.suspend = suspend;

        var interval;
        var anyActivity = false;
        var prevActivity = false;
        
        service.idleTime = 0;

        var onActivity = utilityService.debounce(function() {
            anyActivity = true;
            broadcastActivity(anyActivity);
            initInterval();
        }, 50);

        function activityMessage() {
            $rootScope.$broadcast('UserActivityService.isUserActive', anyActivity);
        }

        var broadcastActivity = utilityService.debounce(function(activity)
        {
            localStorage.setItem('isUserActive', activity);
            localStorage.removeItem('isUserActive');
            $log.debug('Set activity: ' + activity);
        }, 50);

        function activityHandler(ev) {
            if (ev.key !== 'isUserActive' || !ev.newValue) return;
            var ifForeignActive = ev.newValue === 'true';
            
            $log.debug('Foreign activity: ' + ifForeignActive);

            ifForeignActive && (anyActivity = prevActivity = true) && initInterval();
        }

        function init(idleTime) {
            service.idleTime = idleTime;
            //idleTime = 5000; //for test

            if(interval || !idleTime) {
                $log.warn('Service already initialized or not enabled!');
            } else {
                anyActivity = true;

                document.addEventListener('click', onActivity);
                document.addEventListener('keydown', onActivity);
                document.addEventListener('mousemove', onActivity);
                document.addEventListener('touchstart', onActivity);
                document.addEventListener('scroll', onActivity);
        
                window.addEventListener('storage', activityHandler);

                initInterval(idleTime);
                
                $log.log('Activity service initialized with idle time: ' +  idleTime + ' min');
            }
        }

        function initInterval() {
            interval && clearInterval(interval);
            interval = setInterval(function() {
                if (prevActivity !== anyActivity) {
                    $log.debug('IS ACTIVE: ' + anyActivity);
                    activityMessage();
                }
                prevActivity = anyActivity;
                anyActivity = false;
            }, service.idleTime*60*1000/2);
        }

		function suspend() {
            if(!interval) {
                $log.warn('Service is not initialized!');
            } else {
                document.removeEventListener('click', onActivity);
                document.removeEventListener('keydown', onActivity);
                document.removeEventListener('mousemove', onActivity);
                document.removeEventListener('touchstart', onActivity);
                document.removeEventListener('scroll', onActivity);
    
                window.removeEventListener('storage', activityHandler);

                clearInterval(interval);
                interval = null;
            }
        }


		return service;
	}

	UserActivityService.$inject = ['utilityService', '$log', '$rootScope'];

	angular
		.module('auth')
		.factory('userActivityService', UserActivityService);
})(window.angular);
