(function (angular) {
	'use strict';

	/* LobbyCampaignsCtrl */

	function LobbyCampaignsCtrl($state, lobbyService) {

		this.$state = $state;
		this.lobbyService = lobbyService;

		this.settings = lobbyService.config;
		this.settings.showCancelButton = this.settings.showCancelButton === undefined ? true : this.settings.showCancelButton;
		this.settings.showDetailedCampaignInfo = lobbyService.requiresPinCode;
		this.campaigns = lobbyService.campaigns;
		this.campaignsMessage = lobbyService.campaignsMessage;
		this.campaignsCssClass = this.settings.uiConfiguration.noActiveCampaignsCssClass || '';

        if (this.campaigns.length === 1 && !this.settings.uiConfiguration.displaySingleCampaignAsCoupon) {
			this.startCampaign(this.campaigns[0]);
		}
	}

	LobbyCampaignsCtrl.prototype = {
		constructor: LobbyCampaignsCtrl,

		startCampaign: function(campaign) {
			this.lobbyService.campaignId = campaign.campaignId;
			// Start Campaign Method
		},

		cancel: function() {
			this.$state.go('lobby.init');
		}
	};

	LobbyCampaignsCtrl.$inject = [
		'$state',
		'lobbyService'
	];

	/* LobbyAcCampaignsCtrl */

	function LobbyAcCampaignsCtrl($state, lobbyService) {
		LobbyCampaignsCtrl.call(this, $state, lobbyService);
	}

	LobbyAcCampaignsCtrl.prototype = Object.create(LobbyCampaignsCtrl.prototype);
	LobbyAcCampaignsCtrl.prototype.constructor = LobbyAcCampaignsCtrl;

	LobbyAcCampaignsCtrl.prototype.startCampaign = function (campaign) {
		var lobbyService = this.lobbyService,
			lobbySettings = this.settings,
			canStartSession = true;

		if (lobbySettings.isChallengeLobby) {
			canStartSession = campaign.challengeStatus === lobbySettings.challengeCampaignActiveStatus;
		}

		lobbyService.campaignId = campaign.campaignId;
		lobbyService.scheduleId = campaign.schedule.scheduleId;

		if (canStartSession) {
            lobbyService.startCampaign(campaign.campaignId, campaign.schedule.scheduleId, lobbyService.aToken, lobbyService.accessRequestId);
        }
	};

	LobbyAcCampaignsCtrl.$inject = [
		'$state',
		'lobbyService'
	];

	/* LobbyCampaignsAcCtrl */

	function LobbyCampaignsAcCtrl($state, lobbyService, $stateParams) {
		this.$stateParams = $stateParams;
		LobbyCampaignsCtrl.call(this, $state, lobbyService);
	}

	LobbyCampaignsAcCtrl.prototype = Object.create(LobbyCampaignsCtrl.prototype);
	LobbyCampaignsAcCtrl.prototype.constructor = LobbyCampaignsAcCtrl;

	LobbyCampaignsAcCtrl.prototype.startCampaign = function (campaign) {
		var lobbyService = this.lobbyService;

		lobbyService.campaignId = campaign.campaignId;

		lobbyService.campaignAccessControlId = campaign.accessControlId;

		this.$state.go('lobby.campaigns-ac-interaction', {
			isPreviewMode: this.$stateParams.isPreviewMode
		});
	};

	LobbyCampaignsAcCtrl.$inject = [
		'$state',
		'lobbyService',
		'$stateParams'
	];

	/* LobbyCampaignsNotAcCtrl */

	function LobbyCampaignsNotAcCtrl($state, lobbyService) {
		LobbyCampaignsCtrl.call(this, $state, lobbyService);
	}

	LobbyCampaignsNotAcCtrl.prototype = Object.create(LobbyCampaignsCtrl.prototype);
	LobbyCampaignsNotAcCtrl.prototype.constructor = LobbyCampaignsNotAcCtrl;

	LobbyCampaignsNotAcCtrl.prototype.startCampaign = function (campaign) {
		var lobbyService = this.lobbyService;

		lobbyService.campaignId = campaign.campaignId;

		lobbyService.startCampaignWithoutAC(campaign.campaignId);
	};

	LobbyCampaignsNotAcCtrl.$inject = [
		'$state',
		'lobbyService'
	];

	function lobbyAcCampaigns() {
		return {
			restrict: 'AE',
			controller: LobbyAcCampaignsCtrl,
			controllerAs: 'campaignsList',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyCampaigns/lobby-campaigns-ac.tmpl.html'
		};
	}

	function lobbyCampaignsAc() {
		return {
			restrict: 'AE',
			controller: LobbyCampaignsAcCtrl,
			controllerAs: 'campaignsList',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyCampaigns/lobby-campaigns-ac.tmpl.html'
		};
	}

	function lobbyCampaignsNotAc() {
		return {
			restrict: 'AE',
			controller: LobbyCampaignsNotAcCtrl,
			controllerAs: 'campaignsList',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyCampaigns/lobby-campaigns-ac.tmpl.html'
		};
	}

	angular.module('lobby').directive('lobbyAcCampaigns', lobbyAcCampaigns);

	angular.module('lobby').directive('lobbyCampaignsAc', lobbyCampaignsAc);

	angular.module('lobby').directive('lobbyCampaignsNotAc', lobbyCampaignsNotAc);

})(window.angular);