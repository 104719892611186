(function (angular) {
	'use strict';

	function config($stateProvider,
					$urlRouterProvider,
					utilityServiceProvider,
					localStorageServiceProvider,
					appModeServiceProvider,
					$translateProvider) {

		var searchParams = utilityServiceProvider.parseSearchQuery();

		//load campaign mode using cToken
		appModeServiceProvider.setCTokenMode();

		localStorageServiceProvider.setPrefix(searchParams.ctoken + (searchParams.externalid ? '_' + searchParams.externalid : ''));

		$stateProvider
			.state('campaignModeStart', {
				url: '/',
				resolve: {
					getSessionStep: ['sessionService', 'authService', function (sessionService, authService) {
						getSessionStep(sessionService, authService);
					}]
				}
			});

		$urlRouterProvider.otherwise(function ($injector) {
			var sessionService = $injector.get('sessionService');
			var authService = $injector.get('authService');
			getSessionStep(sessionService, authService);
		});

		//Translation settings
		$translateProvider.useStaticFilesLoader({
			prefix: 'locales/locale_',
			suffix: '.json'
		});
		$translateProvider.preferredLanguage('en-US');

		function getSessionStep(sessionService, authService) {
			if (!authService.isLoginInProcess() &&
				sessionService.isSessionKeyExist() && !sessionService.isSessionStarting()) {

				sessionService.getCurrentStep();
			}
		}
	}

	config.$inject = [
		'$stateProvider',
		'$urlRouterProvider',
		'utilityServiceProvider',
		'localStorageServiceProvider',
		'appModeServiceProvider',
		'$translateProvider'
	];

	angular.module('campaignMode').config(config);

})(window.angular);