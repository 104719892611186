(function (angular) {
	'use strict';

	function config($stateProvider, $httpProvider) {

		$httpProvider.interceptors.push('authInterceptorService');

		$stateProvider
			.state('login', {
				url: '/login?path',
				templateUrl: 'app/auth/layout/login.html'
			})
			.state('forgotPassword', {
				url: '/forgot-password?:comebackState&:email',
				templateUrl: 'app/auth/layout/forgot-password.html'
			})
			.state('resetPassword', {
				url: '/reset-password?resetcode',
				templateUrl: 'app/auth/layout/reset-password.html'
			})
			.state('passwordReset', {
					url: '/password-reset',
					templateUrl: 'app/auth/layout/password-reset.html'
			});
	}

	config.$inject = [
		'$stateProvider',
		'$httpProvider'
	];

	angular.module('auth').config(config);

})(window.angular);