(function (angular) {
	'use strict';

	function countdownTimer($filter) {
		return {
			restrict: 'AE',
			templateUrl: 'app/countdown/components/countdownTimer/countdown-timer.tmpl.html',
			require: 'countdownModel',
			link: function (scope, element, attr, countdownModel) {
				var $counterEl = element.find('.item-value'),
					translate = $filter('translate'),
					DAY = translate('day'),
					DAYS = translate('days'),
					HOUR = translate('hour'),
					HOURS = translate('hours'),
					MINUTE = translate('minute'),
					MINUTES = translate('minutes'),
					SECOND = translate('second'),
					SECONDS = translate('seconds');

				
				countdownModel.render = function render(range) {
					var str = '';

					if (range.days > 0) {
						str += range.days + ' ' + (range.days > 1 ? DAYS : DAY);
					}

					if (str.length > 0 || range.hours > 0) {
						str += (str.length ? ' ' : '') + range.hours + ' ' + (range.hours > 1 ? HOURS : HOUR);
					}

					if (str.length > 0 || range.minutes > 0) {
						str += (str.length ? ' ' : '') + range.minutes + ' ' + (range.minutes > 1 ? MINUTES : MINUTE);
					}

					if (str.length > 0 || range.seconds > 0) {
						str += (str.length ? ' ' : '') + range.seconds + ' ' + (range.seconds > 1 ? SECONDS : SECOND);
					}

					$counterEl.html(str);
				};
			}
		};
	}

	countdownTimer.$inject = [
		'$filter'
	];

	angular.module('countdown').directive('countdownTimer', countdownTimer);

})(window.angular);