(function (angular) {
	'use strict';

	function qrCodeRecognizer($window, $log, $timeout) {
		return {
			restrict: 'AE',
			require: 'ngModel',
			templateUrl: 'app/redemption/components/qrCodeRecognizer/qr-code-recognizer.tmpl.html',
			scope: {
				isNotSupported: '=',
				streamError: '=',
				onRecognize: '&'
			},
			link: function (scope, element, attrs, ngModel) {
				var qrcode = $window.qrcode;
				var gCtx = null;
				var gCanvas = element.find('#qr-canvas')[0];
				var stype = 0;
				var videoSourceId = null;
				var v = element.find('#v')[0];

				scope.streamError = true;
				scope.isNotSupported = false;

				navigator.getUserMedia = navigator.getUserMedia ||
				navigator.webkitGetUserMedia ||
				navigator.mozGetUserMedia ||
				navigator.msGetUserMedia;

				if (typeof MediaStreamTrack !== 'undefined') {
					MediaStreamTrack.getSources(handleSources);
				} else {
					$log.debug('This browser does not support MediaStreamTrack');
					init();
				}

				function init() {
					if (navigator.getUserMedia) {
						initCanvas(800, 600);
						qrcode.callback = read;
						setwebcam();
					} else {
						scope.isNotSupported = true;
					}
				}

				function initCanvas(w, h) {
					gCanvas.style.width = w + 'px';
					gCanvas.style.height = h + 'px';
					gCanvas.width = w;
					gCanvas.height = h;
					gCtx = gCanvas.getContext('2d');
					gCtx.clearRect(0, 0, w, h);
				}

				function captureToCanvas() {
					if (stype != 1)
						return;
					if (!scope.streamError) {
						try {
							gCtx.drawImage(v, 0, 0);
							try {
								qrcode.decode();
							}
							catch (e) {
								$log.debug(e);
								setTimeout(captureToCanvas, 500);
							}
						}
						catch (e) {
							$log.debug(e);
							setTimeout(captureToCanvas, 500);
						}
					}
				}

				function read(value) {
					scope.$apply(function () {
						ngModel.$setViewValue(value);
					});
					$timeout(function () {
						scope.onRecognize({$value: value});
					});
				}

				function success(stream) {
					v.src = ($window.URL || $window.webkitURL).createObjectURL(stream);

					scope.streamError = false;
					scope.$apply();

					setTimeout(captureToCanvas, 500);
				}

				function error(error) {
					$log.debug('Stream error', error);
					scope.streamError = true;
					scope.$apply();
				}

				function setwebcam() {
					if (stype == 1) {
						setTimeout(captureToCanvas, 500);
						return;
					}

					var videoOption = true;

					if (videoSourceId) {
						videoOption = {
							optional: [{sourceId: videoSourceId}]
						};
					}

					navigator.getUserMedia({
						video: videoOption,
						audio: false
					}, success, error);

					stype = 1;

					setTimeout(captureToCanvas, 500);
				}

				function handleSources(sourceInfos) {
					var videoSources = [];

					for (var i = 0; i !== sourceInfos.length; ++i) {
						var sourceInfo = sourceInfos[i];
						if (sourceInfo.kind === 'video') {
							videoSources.push(sourceInfo);
						}
					}

					if (videoSources.length > 1) {
						videoSources.forEach(function (sourceInfo) {
							if (sourceInfo.facing === 'environment') {
								videoSourceId = sourceInfo.id;
							}
						});
					}

					init();
				}
			}
		};
	}

	qrCodeRecognizer.$inject = [
		'$window',
		'$log',
		'$timeout'
	];

	angular.module('redemption').directive('qrCodeRecognizer', qrCodeRecognizer);

})(window.angular);