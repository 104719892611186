(function (angular) {
	'use strict';

	function ChooseRewardCtrl(chooseRewardService, authService) {
		var vm = this;

		vm.reward = chooseRewardService.reward;
        vm.newLayout = vm.reward.winnerChoiceConfiguration.layoutMode !== 'None' || vm.reward.winnerChoiceConfiguration.layoutTheme !== 'None';
		vm.rewards = chooseRewardService.rewards;
		vm.clientLogoUrl = authService.authentication.clientLogoUrl;
		vm.choose = choose;

        vm.selectedReward = vm.rewards && vm.rewards.length && vm.rewards[0];

		function choose() {
			if (!vm.selectedReward || vm.isLoading) {
				return false;
			}

			vm.isLoading = true;

			chooseRewardService.chooseReward(vm.selectedReward.id)
				.finally(function () {
					vm.isLoading = false;
				});
		}

        vm.currentRewardIndex = 0;


        vm.onChangeCurrentIndex = function (currentIndex) {
            vm.currentRewardIndex = currentIndex;
            selectReward(vm.rewards[currentIndex]);
        };

        vm.onChangeCurrentIndex(vm.currentRewardIndex);

        function selectReward(reward) {
            vm.selectedReward = reward;
        }
	}

	ChooseRewardCtrl.$inject = [
		'chooseRewardService',
		'authService'
	];

	function chooseRewardDirective() {
		return {
			restrict: 'AE',
			controller: ChooseRewardCtrl,
			controllerAs: 'chooseReward',
			bindToController: true,
			templateUrl: 'app/chooseReward/components/choose-reward.tmpl.html'
		};
	}

	angular.module('chooseReward').directive('chooseReward', chooseRewardDirective);

})(window.angular);