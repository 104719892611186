(function (angular) {
	'use strict';

	function modelCustomParser($parse) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attr, ctrl) {
				var getter = $parse(attr.modelCustomParser);

				ctrl.$parsers.push(function (value) {
					return getter(scope, {$value: value});
				});
			}
		};
	}

	modelCustomParser.$inject = [
		'$parse'
	];

	angular.module('common').directive('modelCustomParser', modelCustomParser);

})(window.angular);