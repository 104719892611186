(function (angular) {
	'use strict';

	function LobbyCampaignsAcInteractionCtrl(lobbyService, $state, sessionService) {
		var vm = this;

		vm.onSuccess = onSuccess;
		vm.onError = onError;

		function onSuccess(accessRequestId) {
			lobbyService.campaignAccessRequestId = accessRequestId;

			if (sessionService.cooked) {
                lobbyService.startCampaignViaExecute();
			} else {
                lobbyService.startCampaign(lobbyService.campaignId, lobbyService.scheduleId, lobbyService.aToken, lobbyService.campaignAccessRequestId);
			}
		}

		function onError() {
			$state.go('lobby.init');
		}
	}

	LobbyCampaignsAcInteractionCtrl.$inject = [
		'lobbyService',
		'$state',
		'sessionService'
	];

	function lobbyCampaignsAcInteraction() {
		return {
			restrict: 'AE',
			controller: LobbyCampaignsAcInteractionCtrl,
			controllerAs: 'lobbyInteraction',
			bindToController: true,
			templateUrl: 'app/lobby/components/lobbyCampaignsAcInteraction/lobby-campaigns-ac-interaction.tmpl.html'
		};
	}

	angular.module('lobby').directive('lobbyCampaignsAcInteraction', lobbyCampaignsAcInteraction);

})(window.angular);