(function (angular) {

	function VideoDashboardBoxCtrl($scope) {
		var vm = this;
        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };
		$scope.$watch('box.model.data', function() {
			if(vm.model.data !== vm.videoSource){
				vm.videoSource = vm.model.data;
			}
        });
	}

	VideoDashboardBoxCtrl.$inject = [
		'$scope'
	];

	function videoDashboardBox() {
		return {
			controller: VideoDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/video/video-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('videoDashboardBox', videoDashboardBox);

})(window.angular);
