(function (angular) {
	'use strict';

	function customSortable($window, $parse) {
		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ngModel) {
				var $ = $window.jQuery,
					temp,
					receivedElement,
					opts = $parse(attrs.customSortable)(scope) || {},
					options = angular.extend({}, opts, {
						containment: 'parent',
						receive: function (event, ui) {
							var self = this;
							scope.$apply(function () {
								receivedElement = $(self).data().uiSortable.currentItem;
								var model = ui.item.scope().$eval(ui.item.attr('ng-model'));
								var index = receivedElement.index();

								if (model) {
									ngModel.$viewValue.splice(index, 0, model);
									if (typeof opts.receive == 'function') {
										opts.receive.call(self, model, index, event, ui);
									}
								}
							});
						},
						start: function (event, ui) {
							var self = this;
							scope.$apply(function () {
								var index = ui.item.index();
								temp = ngModel.$viewValue[index];
								if (typeof opts.start == 'function') {
									opts.start.call(self, event, ui);
								}
							});
						},
						stop: function (event, ui) {
							var self = this;
							scope.$apply(function () {
								if (receivedElement && receivedElement[0] === ui.item[0]) {
									receivedElement = null;
									ui.item.remove();
								} else {
									var ind = ui.item.index();
									var c = ngModel.$viewValue.splice(ngModel.$viewValue.indexOf(temp), 1);

									ngModel.$viewValue.splice(ind, 0, c[0]);
								}
								if (typeof opts.stop == 'function') {
									opts.stop.call(self, event, ui);
								}
							});
						},
						sort: function (event, ui) {
                            var $target = $(event.target);
                            if (!/html|body/i.test($target.offsetParent()[0].tagName)) {
                                var top = event.pageY - $target.offsetParent().offset().top - (ui.helper.outerHeight(true) / 2);
                                ui.helper.css({'top' : top + 'px'});
                            }
                        }
					});

				$(element).sortable(options);
			}
		};
	}

	customSortable.$inject = [
		'$window',
		'$parse'
	];

	angular.module('common').directive('ocCustomSortable', customSortable);

})(window.angular);