(function (angular) {
  'use strict';

  function AcQuestionCtrl(
    $scope,
    $window,
    $timeout,
    $filter,
    formService,
    authService,
    notifier
  ) {
    var vm = this,
      showSubmitBtnTimer,
      Howl = $window.Howl,
      backgroundMusic,
      translate = $filter('translate'),
      validationRequiredErrorMessage = translate(
        'Please give an answer on the required question.'
      ),
      validationNotMatchedErrorMessage = translate(
        'Entered values do not match.'
      ),
      validationErrorMessage = translate('Entered values require adjustment.'),
      validationConsentRequiredErrorMessage = translate(
        'Please agree to receive additional communications.'
      );

    vm.isSubmitButtonEnabled = true;
    vm.$timeout = $timeout;

    vm.onChangeAnswer = onChangeAnswer;
    vm.onSubmit = onSubmit;
    vm.country = { phoneCode: authService.authentication.phoneCode };

    $scope.$watch(
      function () {
        return vm.model;
      },
      function () {
        onInitModel(vm.model);
      }
    );

    $scope.$on('$destroy', stopBackgroundMusic);

    function onInitModel(model) {
      stopBackgroundMusic();
      checkTimerToShowSubmitButton();

      if (model[0].uiCustomization) {
        if (
          model[0].uiCustomization.backgroundMusic &&
          model[0].uiCustomization.backgroundMusic.value
        ) {
          backgroundMusic = new Howl({
            urls: [model[0].uiCustomization.backgroundMusic.value],
            autoplay: true,
            loop: true
          });
        }

        model[0].uiCustomization.backgroundColor =
          model[0].uiCustomization.backgroundColor || 'transparent';

        //model[0].uiCustomization.displayCountryCodeDropdown = model[0].uiCustomization.displayCountryCodeDropdown === 1;
      }

      //Predefine answer
      model.forEach(function (quest) {
        if (quest.questionType === 'LeaderboardName') {
          quest.answer = quest.choices[0];
        }

        if (quest.questionType === 'LeaderboardAvatar') {
          quest.answer = quest.choices.filter(function (choice) {
            var isSelected = choice.value === choice.mediaUrl.value;
            choice.value = '';
            return isSelected;
          })[0];
        }

        if (model.ruleType === 'Autocomplete') {
          model.questionType = 'Autocomplete';
        }

        quest.uiCustomization = quest.uiCustomization || {};

        quest.uiCustomization.displayCountryCodeDropdown =
          quest.uiCustomization.displayCountryCodeDropdown === 1;
      });
    }

    function stopBackgroundMusic() {
      if (backgroundMusic) {
        backgroundMusic.unload();
      }
    }

    function checkTimerToShowSubmitButton() {
      var submissionDelay = vm.model[0].submissionDelaySec;

      $timeout.cancel(showSubmitBtnTimer);

      if (submissionDelay > 0) {
        vm.isSubmitButtonEnabled = false;
        showSubmitBtnTimer = $timeout(function () {
          vm.isSubmitButtonEnabled = true;
        }, submissionDelay * 1000);
      } else {
        vm.isSubmitButtonEnabled = true;
      }
    }

    function onChangeAnswer($inputType, questionsForm, question) {
      if (question.questionType === 'LocationNumber') {
        if (vm.model && vm.model.length === 1) {
          $timeout(function () {
            onSubmit(questionsForm);
          });
          return;
        }
      }
      switch ($inputType) {
        case 'radio':
          if (
            vm.model &&
            vm.model.length === 1 &&
            !!vm.model[0].isAutoAdvance
          ) {
            //It's radio button. Let's send answer automatically
            //Use timeout cause vm.model is not ready yet
            $timeout(function () {
              onSubmit(questionsForm);
            });
          }

          break;

        case 'textarea':
        case 'text':
          if (
            question.uiCustomization &&
            question.uiCustomization.autoNext &&
            question.uiCustomization.autoNextTimeout &&
            !vm.onQuestionSubmitStarted
          ) {
            vm.timeout = question.uiCustomization.autoNextTimeout;

            $timeout.cancel(vm.autoNextTimer);
            vm.autoNextTimer = $timeout(function () {
              !vm.onQuestionSubmitStarted && onSubmit(questionsForm);
            }, question.uiCustomization.autoNextTimeout);
          }

          break;

        default:
      }
    }

    function onSubmit(questionsForm) {
      if (vm.onQuestionSubmitStarted) return;
      vm.onQuestionSubmitStarted = true;
      vm.timeout = vm.timeout || 0;

      formService.makeFieldsDirty(questionsForm);

      if (questionsForm.$error && questionsForm.$error.required) {
        if (
          questionsForm.isConsentRequired &&
          questionsForm.isConsentRequired.$invalid &&
          questionsForm.$error.required.length === 1
        ) {
          $timeout(function () {
            delete vm.onQuestionSubmitStarted;
          }, vm.timeout);
          return notifier.warning(validationConsentRequiredErrorMessage);
        } else {
          $timeout(function () {
            delete vm.onQuestionSubmitStarted;
          }, vm.timeout);
          return notifier.warning(validationRequiredErrorMessage);
        }
      }

      if (
        Object.keys(questionsForm.$error).filter(function (e) {
          return e.indexOf('verif_') !== -1;
        }).length > 0
      ) {
        $timeout(function () {
          delete vm.onQuestionSubmitStarted;
        }, vm.timeout);
        return notifier.warning(validationNotMatchedErrorMessage);
      }

      if (
        questionsForm.$invalid &&
        Object.keys(questionsForm.$error).length === 0
      ) {
        questionsForm.$invalid = false;
      }

      if (
        questionsForm.$invalid ||
        Object.keys(questionsForm.$error).length != 0
      ) {
        $timeout(function () {
          delete vm.onQuestionSubmitStarted;
        }, vm.timeout);
        return notifier.warning(validationErrorMessage);
      }

      var answer = [];
      var answerValue = null;

      var emptySignature = false;

      vm.model &&
        vm.model.length &&
        vm.model.forEach(function (quest) {
          delete quest.errorMessage;

          if (quest.ruleType === 'Signature') {
            if (
              quest.answer.value &&
              quest.answer.contentType &&
              quest.answer.fileName
            ) {
              answerValue = JSON.stringify(quest.answer);
            } else {
              if (quest.requireAnswer) {
                emptySignature = true;
              } else {
                answerValue = JSON.stringify({});
              }
            }
          } else {
            answerValue = quest.answer
              ? typeof quest.answer.value === 'number'
                ? quest.answer.value
                : quest.answer.value.slice(0)
              : '';

            if (
              quest.uiCustomization &&
              quest.uiCustomization.displayCountryCodeDropdown &&
              vm.country &&
              vm.country.phoneCode &&
              quest.answer &&
              quest.answer.value
            ) {
              answerValue = vm.country.phoneCode + answerValue;
            }
          }

          var answerObject = {
            ruleId: quest.ruleId,
            value:
              answerValue ||
              (quest.answer &&
                quest.answer.mediaUrl &&
                quest.answer.mediaUrl.value),
            isConsented: quest.isConsented
          };

          answer.push(answerObject);
        });

      if (emptySignature) {
        $timeout(function () {
          delete vm.onQuestionSubmitStarted;
        }, vm.timeout);
        return notifier.warning('Empty signature');
      }

      vm.externalSubmit({
        $value: answer
      }).then(function (answer) {
        formService.makeFieldsPristine(questionsForm);
        if (
          vm.model &&
          vm.model[0].uiCustomization.keepFocus &&
          answer &&
          answer[0] &&
          answer[0].errorMessage &&
          vm.input
        ) {
          $timeout(function () {
            vm.input.focus();
          });
        }
        $timeout(function () {
          delete vm.onQuestionSubmitStarted;
        }, vm.timeout);
      });
    }
  }

  AcQuestionCtrl.$inject = [
    '$scope',
    '$window',
    '$timeout',
    '$filter',
    'formService',
    'authService',
    'notifier'
  ];

  function acQuestion() {
    return {
      restrict: 'AE',
      controller: AcQuestionCtrl,
      controllerAs: 'question',
      bindToController: true,
      templateUrl:
        'app/accessControl/components/interaction/question/question.tmpl.html',
      scope: {
        model: '=',
        isLoading: '=',
        externalSubmit: '&submit'
      },
      link: function (scope, element) {
        var $timeout = scope.question.$timeout;

        scope.$watch('question.model[0]', function () {
          scope.question.model.forEach(function (question) {
            if (question.ruleType === 'Autocomplete') {
              question.questionType = 'Autocomplete';
            }
          });

          if (scope.question.model[0].uiCustomization.keepFocus) {
            $timeout(function () {
              $timeout(function () {
                var inputs = element.find('input:text, textarea');
                if (inputs && inputs.length === 1) {
                  scope.question.input = inputs[0];
                  document.addEventListener('keypress', function () {
                    if (
                      scope.question.input &&
                      document.activeElement !== scope.question.input
                    ) {
                      scope.question.input.focus();
                    }
                  });

                  document.addEventListener('click', function () {
                    if (
                      scope.question.input &&
                      document.activeElement !== scope.question.input
                    ) {
                      scope.question.input.focus();
                    }
                  });

                  document.addEventListener('touchend', function () {
                    if (
                      scope.question.input &&
                      document.activeElement !== scope.question.input
                    ) {
                      scope.question.input.focus();
                    }
                  });
                }
              });
            });
          }
        });
      }
    };
  }

  angular
    .module('accessControl')
    .directive('acInteractionQuestion', acQuestion);
})(window.angular);
