(function (angular) {
	//'use strict';

	var Player = (function () {

		function Player($document, audioOpts) {
			this.document = $document[0];
			this.audio = this.createAudioElement(audioOpts);
			this.playing = false;
			this.audio.addEventListener('ended', Player.prototype.stop.bind(this));
		}

		Player.prototype.createAudioElement = function (attrs) {
			var audio = this.document.createElement('AUDIO');

			if (Object.prototype.toString.call(attrs) === '[object Object]') {
				if (attrs.src != null) {
					for (var attrName in attrs) {
						if (attrs.hasOwnProperty(attrName)) {
							audio.setAttribute(attrName, attrs[attrName]);
						}
					}
				}
			}

			return audio;
		};

		Player.prototype.load = function () {
			this.audio.load && this.audio.load();
		};

		Player.prototype.play = function (src) {
			var player = this;

			if (player.playing) {
				player.stop();
			}

			if (src) {
				player.audio.setAttribute('src', src);
				player.audio.load();
			}

			if (player.audio.getAttribute('src')) {
				player.audio.play();
				player.playing = true;
			}

			return player;
		};

		Player.prototype.stop = function () {
			var player = this;

			if (player.playing) {
				player.audio.pause();
				if (player.audio.readyState > 0) {
					player.audio.currentTime = 0;
				}
				player.playing = false;
			}

			return player;
		};

		Player.prototype.pause = Player.prototype.stop;

		return Player;
	})();

	function audioPlayerService($document) {

		return {
			create: create
		};

		function create(attrs) {
			return new Player($document, attrs);
		}
	}

	audioPlayerService.$inject = [
		'$document'
	];

	angular.module('common').factory('audioPlayerService', audioPlayerService);

})(window.angular);