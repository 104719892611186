(function (angular) {
	'use strict';

	function embeddedService($log, apiSessionResource, sessionService, behaviorEffectService) {
		var service = {};

		service.embedded = null;
		service.getEmbedded = getEmbedded;
		service.confirmEmbedded = confirmEmbedded;
		service.complete = complete;

		return service;

		function getEmbedded(options) {
			return apiSessionResource.get('/embedded', options || {})
				.then(function (data) {
					$log.debug('get embedded', data);
					return service.embedded = data;
				});
		}

		function confirmEmbedded(data) {
			return apiSessionResource.post('/embedded', data)
				.then(function (data) {
					$log.debug('embedded was confirmed', data);
					return data;
				});
		}

		function complete() {
			var behaviorData = sessionService.currentStep || {},
				reward = service.reward || {};

			//Show Effect if needed
			return behaviorEffectService
				.addEffect({
					effectDuration: behaviorData.effectDuration,
					effectSound: behaviorData.effectSound,
					effectType: behaviorData['effectType'],
					showDismissButton: behaviorData.showDismissButton,
					dismissImage: behaviorData.displayBehaviorDismissImage,
					dismissButtonText: behaviorData.displayBehaviorDismissText,
					dismissButtonColor: behaviorData.displayBehaviorDismissButtonColor,
					dismissButtonTextColor: behaviorData.displayBehaviorDismissTextColor,
					showReward: behaviorData.showReward,
					reward: reward
				}).then(function () {
					return sessionService.getCurrentStep();
				});

			//return sessionService.getCurrentStep();
		}
	}

	embeddedService.$inject = [
		'$log',
		'apiSessionResource',
		'sessionService',
		'behaviorEffectService'
	];

	angular.module('embedded').factory('embeddedService', embeddedService);

})(window.angular);
