(function (angular) {
    'use strict';

    function ModalCtrl(model, $scope, $window) {
        var vm = this, Howl = $window.Howl;
        vm.model = model;

        var timeout = vm.model.uiState.uiCustomization.autoRedirectTimeout;
        vm.remaining = timeout;

        if (vm.model.uiState.uiCustomization && vm.model.uiState.uiCustomization.backgroundMusic && vm.model.uiState.uiCustomization.backgroundMusic.value) {
            vm.backgroundMusic = new Howl({
                urls: [vm.model.uiState.uiCustomization.backgroundMusic.value],
                autoplay: true,
                loop: true
            });
        }

        if (timeout > 0) {
            vm.timer = setInterval(function () {

                if (vm.remaining === 1) {
                    vm.redirect();
                }

                vm.remaining--;
                $scope.$apply();
            }, 1000);
        }

        vm.redirect = function () {
            vm.timer && clearTimeout(vm.timer);
            $window.location.href = vm.model.title;
        };

        $scope.$on('$destroy', function () {
            vm.timer && clearTimeout(vm.timer);
            vm.backgroundMusic && vm.backgroundMusic.unload();
        });
    }

    ModalCtrl.$inject = [
        'model',
        '$scope',
        '$window'
    ];

    function AcRedirectCtrl($window, $uibModal) {
        var vm = this;

        if (vm.model && vm.model.title && vm.model.title.length > 0) {
            var search = $window.location.search.substring(1);
            var queryString = JSON.parse('{"' + search.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
            delete queryString.lobbyToken;
            delete queryString.aToken;
            if(Object.keys(queryString).length > 0) {
                vm.model.title += '&'+$window.jQuery.param(queryString);
            }

            if (vm.model.uiState.displayUI) {
                $uibModal.open({
                    templateUrl: 'app/accessControl/components/interaction/redirect/redirect-message.html',
                    controller: ModalCtrl,
                    controllerAs: 'modal',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        model: function () {
                            return vm.model;
                        }
                    }
                });
            } else {
                $window.location.href = vm.model.title;
            }
        }
    }

    AcRedirectCtrl.$inject = [
        '$window',
        '$uibModal'
    ];

    function acRedirect() {
        return {
            restrict: 'AE',
            controller: AcRedirectCtrl,
            controllerAs: 'redirect',
            bindToController: true,
            template: '',
            scope: {
                model: '='
            }
        };
    }

    angular.module('accessControl').directive('acInteractionRedirect', acRedirect);

})(window.angular);
