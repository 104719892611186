(function (angular) {
	'use strict';

    function DashboardLoginCtrl($state, $stateParams, dashboardService, formService, apiSettings) {
		var vm = this;

		vm.clientLogoUrl = dashboardService.clientLogo;
        vm.userName = '' || $stateParams.u;
        vm.password = '' || $stateParams.p;
		vm.isLoading = false;
        vm.pageConfig = dashboardService.loginPageConfig;

		vm.login = function (form) {
			formService.makeFieldsDirty(form);

			if (form && form.$invalid) {
				return false;
			}

            if (!vm.isLoading) {
                vm.error = null;
                vm.isLoading = true;

                var type = $stateParams.aType;
                var token = $stateParams.dToken;
                //var dashToken = $stateParams.token;

                var loginData = {
                    grant_type: 'dashboard_token',
                    dtoken: encodeURIComponent(/*dashToken*/token),
                    client_id: apiSettings.ngAuthSettings.clientId,
                    username: encodeURIComponent(vm.userName),
                    password: encodeURIComponent(vm.password)
                };

                var req;
                switch (type) {
                    case 'PortalUsers':
                    case 'CustomUsers':
                        req = dashboardService.loginByToken(loginData, true);
                        break;
                }

                req.then(function (authData) {
                    return dashboardService.getDashboard(token, authData.access_token, vm.userName, vm.password)
                        .then(function (dashboard) {
                            if (dashboard && dashboard.isFirstLogin) {
                                $state.go('dashboard-delivery', {
                                    dToken: token,
                                    userId: dashboard.visitingUser
                                });
                            } else {
                                $state.go('dashboard', { dToken: token });
                            }
                        });
                }, function (response) {
                    vm.error = response.data && response.data.error_description || 'Error. Please try again.';
                })
                    .finally(function () {
                    vm.isLoading = false;
                });
            }
        };

        if ($stateParams.u && $stateParams.p) {
            vm.login();
        }
	}

	DashboardLoginCtrl.$inject = [
		'$state',
		'$stateParams',
		'dashboardService',
        'formService',
        'apiSettings'
	];

	function dashboardLogin() {
		return {
			restrict: 'AE',
			templateUrl: 'app/dashboards/components/login/dashboard-login.tmpl.html',
			controllerAs: 'login',
			controller: DashboardLoginCtrl
		};
	}

	angular.module('dashboard').directive('dashboardLogin', dashboardLogin);

})(window.angular);