(function (angular) {
	'use strict';

	function ResetPinModalCtrl($uibModalInstance, formService, redemptionService, errorService) {
		var vm = this;

		vm.newPin = null;
		vm.confirmNewPin = null;

		vm.resetPin = resetPin;

		function resetPin(formInstance) {
			formService.makeFieldsDirty(formInstance);
			if (formInstance.$valid && !vm.isLoading) {
				if (vm.newPin !== vm.confirmNewPin) {
					return vm.error = 'PIN\'s are not the same. Check them again';
				}
				if (vm.newPin === vm.oldPin) {
					return vm.error = 'Your old and new PIN\'s are the same. Please come up with a new PIN.';
				}
				for (var i in vm.newPin) {
					if (vm.newPin[+i + 1]) {
						if (vm.newPin[i] == vm.newPin[+i + 1]) {
							return vm.error = 'PIN must have non-repetitive characters.';
						}
						if (+vm.newPin[+i + 1] == +vm.newPin[i] + 1 &&
							+vm.newPin[+i + 2] == +vm.newPin[i] + 2) {
							return vm.error = 'PIN must have non-sequential characters.';
						}
					}
				}

				vm.isLoading = true;
				vm.error = null;

				redemptionService.changePin({
					oldPinCode: vm.oldPin,
					newPinCode: vm.newPin,
					repeatPinCode: vm.confirmNewPin
				}).then(function () {
					vm.error = null;
					$uibModalInstance.close();
				}, function (error) {
					vm.error = errorService.parseErrorsToList(error);
				}).finally(function () {
					vm.isLoading = false;
				});
			}
		}
	}

	ResetPinModalCtrl.$inject = [
		'$uibModalInstance',
		'formService',
		'redemptionService',
		'errorService'
	];

	angular.module('auth').controller('resetPinModalCtrl', ResetPinModalCtrl);

})(window.angular);