(function (angular) {
  'use strict';

  function TriviaGameCtrl(
    $scope,
    $log,
    $q,
    gameService,
    formService,
    notifier,
    $uibModal,
    $uibModalStack,
    $timeout,
    $window,
    $filter,
    $rootScope,
    $interval,
    utilityService
  ) {
    var vm = this,
      Howl = $window.Howl,
      gameInputParams = gameService.getGameInputParams(vm.game),
      backgroundMusic,
      correctSound,
      incorrectSound,
      winnerSound,
      loserSound,
      nextGame,
      translate = $filter('translate'),
      showSubmitBtnTimer;
    vm.$interval = $interval;
    vm.gameService = gameService;

    vm.isShownSubmitButton = false;
    vm.isShownBackButton = false;
    vm.gameInputParams = gameInputParams;
    vm.isWaitingToGoToNextQuestion = false;
    vm.isPreviousQuestion = gameService.isPreviousQuestion;
    vm.startGame = startGame;
    vm.onChangeAnswer = onChangeAnswer;
    vm.accept = accept;
    vm.collect = collect;
    vm.goToNextQuestion = goToNextQuestion;
    vm.isShownReward = false;
    vm.autoCollectTimer = null;
    vm.isMuted = false;
    vm.displayStartScreen = !!vm.gameInputParams.displayStartScreen;
    vm.soundsToPlay = false;
    vm.cssOverrides = gameInputParams.cssOverride;
    vm.progressStyle = gameInputParams.progressStyle;
    vm.progressBar = progressBar;
    vm.goBack = goBack;
    vm.goForward = goForward;
    vm.gameStart = gameStart;

    if (typeof gameInputParams.muteButtonURL == 'string') {
      vm.muteButton = gameInputParams.muteButtonURL;
      vm.unmuteButton = gameInputParams.unmuteButtonURL;
    } else {
      vm.muteButton = '/images/mute1.png';
      vm.unmuteButton = '/images/unmute1.png';
    }

    if (!utilityService.isMobile()) {
      startBackgroundMusic();
    }

    correctSound = new Howl({
      urls: [gameInputParams.correctMusicURL || '']
    });

    incorrectSound = new Howl({
      urls: [gameInputParams.incorrectMusicURL || '']
    });

    winnerSound = new Howl({
      urls: [gameInputParams.winnerMusicURL || '']
    });

    loserSound = new Howl({
      urls: [gameInputParams.loserMusicURL || '']
    });

    $scope.$on('$destroy', function () {
      backgroundMusic.unload();
      correctSound.unload();
      incorrectSound.unload();
      winnerSound.unload();
      loserSound.unload();

      $timeout.cancel(showSubmitBtnTimer);
    });

    function gameStart() {
      return gameService
        .getTriviaGame(vm.game.cimGameId)
        .then(function (result) {
          if (result) {
            setModel(result);
            if (
              !(
                typeof vm.gameInputParams.displayStartScreen == 'undefined' ||
                !!vm.gameInputParams.displayStartScreen
              )
            ) {
              vm.startGame();
            }
          }
          if (!result || !vm.game.question) {
            showReward();
          }
        });
    }

    function startBackgroundMusic() {
      backgroundMusic = new Howl({
        urls: [gameInputParams.backgroundMusicURL || ''],
        loop: true,
        autoplay: true
      });
    }

    function startTimer(duration) {
      var timeDurationInMs = duration * 1000;
      vm.triviaTimer = setTimeout(function () {
        stopTimer();
      }, timeDurationInMs);
    }

    function showRewardOnStopTimer() {
      return showReward().then(function () {
        vm.isWaitingToGoToNextQuestion = false;
      });
    }

    function clearTimer() {
      clearTimeout(vm.triviaTimer);
    }

    function stopTimer() {
      vm.game = {
        timeisup: true,
        cimGameId: vm.game.cimGameId,
        question: {
          explanationTime: undefined,
          questionId: vm.game.question.questionId
        }
      };
      showAnswerInfo(
        false,
        "You've run out of time",
        "You've run out of time",
        undefined,
        showRewardOnStopTimer
      );
    }

    function startGame() {
      if (vm.gameInputParams.duration) {
        startTimer(vm.gameInputParams.duration);
      }

      if (utilityService.isMobile()) {
        startBackgroundMusic();
      }

      vm.bonusTimeStart = new Date();

      if (vm.game.question && vm.game.question.bonusTimerInSec) {
        vm.elapsedTimeInSec = vm.game.question.bonusTimerInSec;
        if (
          ['MatchColumns', 'InfoCard'].indexOf(
            vm.game.question.questionType
          ) === -1
        ) {
          vm.toggleInterval();
        }
      }

      vm.displayStartScreen = false;
      checkTimerForSubmitButton();
    }

    function onChangeAnswer(answer) {
      if (utilityService.isIE && answer) {
        vm.game.question.answer = answer;
      }
      //Use timeout cause vm.model is not ready yet
      $timeout(function () {
        accept(vm.game.question.bonusTimerInSec && vm.elapsedTimeInSec <= 0);
      });
    }

    function accept(timeIsUp, skipShowAnswerInfo) {
      if (vm.game.timeisup) return;
      var form = vm.triviaForm,
        choices = vm.game.question.choices,
        matches = vm.game.question.matches,
        answer = vm.game.question.answer,
        cimGameId = vm.game.cimGameId,
        questionId = vm.game.question.questionId,
        sequenceId = vm.game.question.sequenceId;

      formService.makeFieldsDirty(form);

      if (form.$error && form.$error.required) {
        notifier.warning(
          translate('Please give an answer on the required question.')
        );
      }

      if (vm.isLoading || vm.game.question.isAnswered || form.$invalid) {
        return;
      }

      vm.isLoading = true;

      var elapsedTime = Math.floor((new Date() - vm.bonusTimeStart) / 1000);
      if (isNaN(elapsedTime)) elapsedTime = 0;
      if (
        vm.game.question &&
        vm.game.question.bonusTimerInSec &&
        vm.game.question.questionType !== 'InfoCard'
      ) {
        if (!timeIsUp && !skipShowAnswerInfo) {
          vm.toggleInterval();
        }
        elapsedTime = vm.game.question.bonusTimerInSec - vm.elapsedTimeInSec;
      }

      gameService
        .answerToTriviaQuestion(
          cimGameId,
          questionId,
          answer,
          elapsedTime,
          sequenceId
        )
        .then(function (result) {
          var answers = angular.isArray(answer) ? answer : [answer],
            correctAnswers = result.prevCorrectAnswers,
            incorrectChoices;

          vm.game.question.isAnswered = true;

          nextGame = result;

          if (correctAnswers) {
            if (vm.game.question.questionType === 'MatchColumns') {
              setCorrectAndIncorrectMatches(
                choices,
                matches,
                answers,
                correctAnswers
              );
            } else {
              setCorrectAndIncorrectChoices(choices, answers, correctAnswers);
            }

            incorrectChoices = getIncorrectChoices(choices);

            if (vm.game.question.questionType === 'InfoCard') {
              $rootScope.$emit('videostopped');
              return goToNextQuestion();
            }

            if (skipShowAnswerInfo) {
              goToNextQuestion();
            } else {
              if (
                incorrectChoices.length ||
                correctAnswers.length !== answers.length
              ) {
                showAnswerInfo(
                  false,
                  vm.game.question.explanation,
                  timeIsUp
                    ? translate('Time Expired.')
                    : false /*timeIsUp && 'Sorry time\'s up'*/,
                  vm.game.question.questionType
                );
              } else {
                showAnswerInfo(
                  true,
                  vm.game.question.explanation,
                  timeIsUp ? translate('Time Expired.') : undefined,
                  vm.game.question.questionType
                );
              }

              vm.isWaitingToGoToNextQuestion = true;
            }
          } else {
            $log.error('Server must return correct answers.');
          }
        })
        .finally(function () {
          vm.isLoading = false;
        });
    }

    function collect() {
      $timeout.cancel(vm.autoCollectTimer);
      vm.onCompleteGame();
    }

    function showAnswerInfo(
      isCorrect,
      explanation,
      title,
      questionType,
      targetAction
    ) {
      if (isCorrect) {
        correctSound.play();
      } else {
        incorrectSound.play();
      }

      if (!targetAction) {
        targetAction = goToNextQuestion;
      }

      if (explanation && explanation.video) {
        $rootScope.$emit('pauseVideo');
      }

      $uibModalStack.dismissAll();

      return $uibModal
        .open({
          templateUrl:
            'app/game/components/triviaGame/answer-info-modal.tmpl.html',
          controller: 'AnswerInfoModalCtrl',
          controllerAs: 'answer',
          keyboard: false,
          backdrop: 'static',
          windowClass: 'modal-vertical-align-tablet',
          resolve: {
            isCorrect: function () {
              return isCorrect;
            },
            explanation: function () {
              return explanation;
            },
            explanationTime: function () {
              return vm.game.question.explanationTime;
            },
            title: function () {
              return title;
            },
            questionType: function () {
              return questionType;
            }
          }
        })
        .result.then(targetAction);
    }

    function showReward() {
      vm.isLoading = true;

      clearTimer();

      return getReward()
        .then(function () {
          vm.isLoading = false;
          vm.isShownReward = true;
          vm.displayStartScreen = false;

          backgroundMusic.stop();

          if (gameInputParams.reward.isWinningReward) {
            winnerSound.play();
          } else {
            loserSound.play();
          }
        })
        .finally(function () {
          /*if (vm.gameInputParams.autoCollectDelay > 0) {
					vm.autoCollectTimer = $timeout(function () {
						vm.onCompleteGame();
					}, vm.gameInputParams.autoCollectDelay * 1000);
				}*/
          vm.onCompleteGame();
        });
    }

    function getReward() {
      var deferred = $q.defer();

      if (gameInputParams.reward) {
        deferred.resolve(gameInputParams.reward);
      } else {
        gameService.getReward(vm.game.cimGameId).then(function (reward) {
          gameInputParams.reward = reward;
          deferred.resolve(gameInputParams.reward);
        });
      }

      return deferred.promise;
    }

    function setModel(model) {
      angular.extend(vm.game, model, {
        question: model.question || vm.game.question
      });

      vm.isShownBackButton =
        model.prevCorrectAnswers && gameInputParams.displayBackButton;
      vm.isShownForwardButton = gameInputParams.displayBackButton;

      if (vm.game.question && !vm.game.question.questionId) {
        vm.game.question.questionId = Math.random().toString().slice(2);
      }

      if (
        !vm.displayStartScreen &&
        vm.game.question &&
        !vm.game.question.isAnswered
      ) {
        checkTimerForSubmitButton();
      }

      if (!vm.displayStartScreen) {
        // Indicates game has started.
        vm.bonusTimeStart = new Date();
      }

      vm.displayMediaBeforeQuestions = true;
      if (
        vm.gameInputParams &&
        vm.gameInputParams.displayMediaBeforeQuestions !== undefined
      ) {
        vm.displayMediaBeforeQuestions =
          vm.gameInputParams.displayMediaBeforeQuestions;
      }

      return vm.game;
    }

    function checkTimerForSubmitButton() {
      var delay;

      if (!vm.game || !vm.game.question) {
        return false;
      }

      delay = vm.game.question.submissionDelaySec || 0;

      $timeout.cancel(showSubmitBtnTimer);

      if (delay > 0) {
        vm.isShownSubmitButton = false;
        showSubmitBtnTimer = $timeout(function () {
          vm.isShownSubmitButton = true;
        }, delay * 1000);
      } else {
        vm.isShownSubmitButton = true;
      }
    }

    function setCorrectAndIncorrectChoices(
      choices,
      selectedChoice,
      correctAnswers
    ) {
      var correctValues = (correctAnswers || []).map(function (answer) {
        return answer && answer.value;
      });
      var selectedValues = (selectedChoice || []).map(function (answer) {
        return answer && answer.choiceValueId;
      });
      choices.forEach(function (choice) {
        if (~correctValues.indexOf(choice.choiceValueId)) {
          return (choice.isCorrect = true);
        }
        if (
          ~selectedChoice.indexOf(choice) ||
          ~selectedValues.indexOf(choice.choiceValueId)
        ) {
          return (choice.isIncorrect = true);
        }
      });
      return choices;
    }

    function setCorrectAndIncorrectMatches(
      choices,
      matches,
      selectedChoice,
      correctAnswers
    ) {
      if (
        angular.isArray(selectedChoice) &&
        selectedChoice.length > 0 &&
        selectedChoice[0].choice &&
        selectedChoice[0].match
      ) {
        var correctValues = {};
        angular.forEach(correctAnswers || [], function (answer) {
          var answerSplitted = answer.value.split(' => ');
          correctValues[answerSplitted[0]] = answerSplitted[1];
        });
        var selectedValues = {};
        angular.forEach(selectedChoice || [], function (choice) {
          selectedValues[choice.choice.choiceValueId] =
            choice.match.choiceValueId;
        });
        choices.forEach(function (choice) {
          if (
            correctValues[choice.choiceValueId] ===
            selectedValues[choice.choiceValueId]
          ) {
            angular.forEach(matches, function (match) {
              if (
                match.choiceValueId === selectedValues[choice.choiceValueId]
              ) {
                return (match.isCorrect = true);
              }
            });
            return (choice.isCorrect = true);
          } else {
            angular.forEach(matches, function (match) {
              if (
                match.choiceValueId === selectedValues[choice.choiceValueId]
              ) {
                return (match.isIncorrect = true);
              }
            });
            return (choice.isIncorrect = true);
          }
        });
      }
      return choices;
    }

    function getIncorrectChoices(choices) {
      return choices.filter(function (choice) {
        return choice.isIncorrect;
      });
    }

    function goToNextQuestion($event) {
      if ($event && $event.preventDefault) {
        $event.preventDefault();
      }

      formService.makeFieldsPristine(vm.triviaForm);

      if (nextGame) {
        setModel(nextGame);
      }

      if (!nextGame.question) {
        return showReward().then(function () {
          vm.isWaitingToGoToNextQuestion = false;
        });
      }

      vm.isWaitingToGoToNextQuestion = false;
    }

    function goBack() {
      gameService
        .getTriviaPreviousQuestion(
          vm.game.cimGameId,
          vm.game.question.sequenceId
        )
        .then(function (result) {
          if (result) {
            setModel(result);
            if (!checkIsIntervalInactive()) {
              vm.toggleInterval();
              vm.savedElapsedTime = vm.elapsedTimeInSec;
            }
            //setPrevModel(result);
          }
        });
    }

    function goForward() {
      accept(undefined, true);
    }

    function progressBar(count) {
      return new Array(count);
    }

    vm.toggleInterval = toggleInterval;
    function toggleInterval() {
      if (
        vm.game &&
        vm.game.question &&
        vm.game.question.bonusTimerInSec &&
        vm.game.question.questionType !== 'InfoCard'
      ) {
        if (checkIsIntervalInactive()) {
          if (vm.savedElapsedTime !== undefined) {
            vm.elapsedTimeInSec = vm.savedElapsedTime;
          } else {
            vm.elapsedTimeInSec = vm.game.question.bonusTimerInSec;
          }
          vm.interval = $interval(function () {
            if (vm.elapsedTimeInSec > 0) {
              vm.elapsedTimeInSec--;
            } else {
              $interval.cancel(vm.interval);
              delete vm.savedElapsedTime;
              if (!vm.game.question.waitForAnswer) {
                vm.accept(true);
              }
            }
          }, 1000);
        } else {
          $interval.cancel(vm.interval);
        }
      } else {
        $interval.cancel(vm.interval);
      }
    }

    function checkIsIntervalInactive() {
      return (
        !vm.interval ||
        (vm.interval.$$state &&
          vm.interval.$$state.value &&
          vm.interval.$$state.value === 'canceled')
      );
    }
  }

  TriviaGameCtrl.$inject = [
    '$scope',
    '$log',
    '$q',
    'gameService',
    'formService',
    'notifier',
    '$uibModal',
    '$uibModalStack',
    '$timeout',
    '$window',
    '$filter',
    '$rootScope',
    '$interval',
    'utilityService'
  ];

  function triviaGame(viewAnimationService, $animateCss) {
    return {
      restrict: 'AE',
      controller: TriviaGameCtrl,
      controllerAs: 'trivia',
      bindToController: true,
      required: 'triviaGame',
      templateUrl: 'app/game/components/triviaGame/trivia-game.tmpl.html',
      scope: {
        game: '=model',
        isGameLoading: '=',
        onCompleteGame: '&'
      },
      link: function (scope, $element) {
        var gamePreviewLoader = $element.find('.game-preview-pre-load')[0];
        onLoadedGame();
        scope.$watch('trivia.game.question', function (newQuestion) {
          if (!newQuestion) return;
          scope.trivia.isPreviousQuestion =
            scope.trivia.gameService.isPreviousQuestion;
          if (
            scope.trivia.game.question &&
            scope.trivia.game.currentQuestion &&
            scope.trivia.game.currentQuestion > 1
          ) {
            scope.trivia.elapsedTimeInSec =
              scope.trivia.game.question.bonusTimerInSec;
            if (
              ['MatchColumns', 'InfoCard'].indexOf(
                scope.trivia.game.question.questionType
              ) === -1
            ) {
              !scope.trivia.isPreviousQuestion && scope.trivia.toggleInterval();
            }
          }
        });

        function onLoadedGame() {
          gamePreviewLoader.hidden = true;

          $animateCss($element, {
            addClass: 'loaded'
          })
            .start()
            .then(function () {
              if (viewAnimationService.enterVideoUrl) {
                viewAnimationService
                  .showVideoInstead('game', viewAnimationService.enterVideoUrl)
                  .then(function () {
                    viewAnimationService.startEnterTransition($element);
                    scope.trivia.gameStart();
                  });
              } else {
                viewAnimationService.startEnterTransition($element);
                scope.trivia.gameStart();
              }
            });
        }
      }
    };
  }

  triviaGame.$inject = ['viewAnimationService', '$animateCss'];

  angular.module('game').directive('triviaGame', triviaGame);
})(window.angular);
