(function (angular) {
	'use strict';

	function AnswerCtrl(addressService, authService) {
		var vm = this;

		vm.showAddressFields = true;
		vm.isLoadingRegions = false;
		vm.countries = [];
		vm.countryRegions = [];
		vm.model = getEmptyModel();

		addressService.getCountryCodes().then(function(countries) {
			vm.countries = countries;
			var cId = parseInt(authService.authentication.countryId);

			var countryId = vm.countries.filter(function (x) {
				return x.id === cId;
			})[0];

			vm.model.countryId = countryId ? countryId.id : undefined;

			vm.onChangeCountry(vm.model.countryId);
		});

		vm.init = function (ngModel) {
			if (!ngModel) return;

			vm.onChange = function () {
				setViewValue(vm.model);
				vm.onChangeModel({$inputType: 'Address'});
			};

			vm.onChangeCountry = function(countryId) {
				angular.isDefined(countryId) && getCountryRegions(countryId).then(function(countryRegions) {
					vm.model.countryRegion = '';
					vm.countryRegions = countryRegions;
				});
			};

			vm.modelParser = function (value) {
				if (!!vm.forceUppercase && value && value.toUpperCase) {
					value = value.toUpperCase();
				}
				return value;
			};

			ngModel.$render = function () {
				// Specify how UI should be updated when new question was applied
				vm.model = getEmptyModel();
				vm.onChangeCountry(vm.model.countryId);
				setViewValue(vm.model);
			};

			function setViewValue(model) {
				var json;

				try {
					json = JSON.stringify(model);
				} catch (e) {
					json = null;
				}

				ngModel.$setViewValue({
					value: json
				});
			}
		};

		function getCountryRegions(countryId) {
			vm.isLoadingRegions = true;
			return addressService.getCountryRegions(countryId).finally(function() {
				vm.isLoadingRegions = false;
			});
		}

		function getEmptyModel() {
			return {
				countryId: vm.countryId,
				name: '',
				address: '',
				address2: '',
				city: '',
				countryRegion: '',
				postalCode: ''
			};
		}
	}

	AnswerCtrl.$inject = [
		'addressService', 'authService'
	];

	function answer() {
		//function answer($window)
		//var google = $window.google,
			//$ = $window.jQuery;

		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-address.tmpl.html',
			require: ['answerAddress', 'ngModel', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				onChangeModel: '&answerOnChangeModel',
				isRequired: '=answerRequired',
				form: '=answerFormInstance',
				enableAutoFocus: '=answerEnableAutoFocus',
				forceUppercase: '=answerForceUppercase',
				countryId: '=',
				answerDisabled: '='
			},
			link: function (scope, element, attr, ctrls) {
				ctrls[0].init(ctrls[1], ctrls[2]);

				//var addressCtrl = ctrls[0];

				/*var componentForm = {
					street_number: 'short_name',
					route: 'long_name',
					locality: 'long_name',
					administrative_area_level_1: 'long_name',
					country: 'long_name',
					postal_code: 'short_name'
				};
				var mapping = {
					street_number: 'number',
					route: 'street',
					locality: 'city',
					administrative_area_level_1: 'state',
					country: 'country',
					postal_code: 'zip'
				};

				var options = {
					types: attr.googlePlace ? attr.googlePlace.split(',') : [],
					componentRestrictions: {}
				};
				*/

				//scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

				/*
				scope.gPlace = new google.maps.places.Autocomplete($('#usAddressAutocomplete')[0], options);

				google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
					var place = scope.gPlace.getPlace();
					var details = place.geometry && place.geometry.location ? {
						latitude: place.geometry.location.lat(),
						longitude: place.geometry.location.lng()
					} : {};

					// Get each component of the address from the place details
					// and fill the corresponding field on the form.
					for (var i = 0; i < place.address_components.length; i++) {
						var addressType = place.address_components[i].types[0];
						if (componentForm[addressType]) {
							details[mapping[addressType]] = place.address_components[i][componentForm[addressType]];
						}
					}
					details.formatted = place.formatted_address;
					details.placeId = place.place_id;

					scope.$apply(function () {
						addressCtrl.model.address = details['number'] + ' ' + details['street'];
						addressCtrl.model.city = details['city'];
						addressCtrl.model.state = details['state'];
						addressCtrl.model.zip = details['zip'];
						addressCtrl.showAddressFields = true;
					});
				});*/
			}
		};
	}

	//answer.$inject = ['$window'];

	angular.module('answer').directive('answerAddress', answer);

})(window.angular);
