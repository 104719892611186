(function (angular) {
	'use strict';

	function storageService() {
		var service = this;
		var storage = {};

		service.set = setData;
		service.get = getData;
		service.remove = removeData;
		service.clearAll = clearAll;

		return service;

		function setData(key, data) {
			storage[key] = data;
		}

		function getData(key) {
			return storage[key];
		}

		function removeData(key) {
			return delete storage[key];
		}

		function clearAll() {
			storage = {};
		}
	}

	angular.module('appStorage').service('storageService', storageService);

})(window.angular);