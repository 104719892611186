(function (angular) {

    function DashboardSignUpCtrl(dashboardService, $uibModalInstance, $timeout, formService, notifier, formModelName) {
        var vm = this;
        vm.config = dashboardService.loginPageConfig;
        vm.formModelName = formModelName;
        vm.isLoading = false;
        vm.getInputType = function (dataType) {
            switch (dataType){
                case 'number':
                case 'text-single-line':
                default:
                    return 'text';
                case 'date':
                case 'password':
                    return dataType;
            }
        };

        vm.submit = function (form) {
            var passwordIncluded = false, confirmPassIncluded = false;
            formService.makeFieldsDirty(form);

            if (form && form.$invalid) {
                notifier.error('Please enter required information.');
                return false;
            }

            vm.config.forms[vm.formModelName].fields.forEach(function (field) {
                if(field.name === 'password'){
                    passwordIncluded = true;
                }
                if(field.name === 'confirmPassword'){
                    confirmPassIncluded = true;
                }
            });

            if(passwordIncluded && confirmPassIncluded){
                if(vm.formData.password !== vm.formData.confirmPassword){
                    notifier.error('Password and confirm password don\'t match');
                    return false;
                }
            }

            vm.isLoading = true;
            return dashboardService.integrationSignUp(vm.formData, vm.formModelName)
                .finally(function () {
                    vm.isLoading = false;
                    $timeout(function () {
                        $uibModalInstance.close();
                    }, 3000);
                });
            };
            vm.close = function() {
                return $uibModalInstance.dismiss();
            };
    }

    DashboardSignUpCtrl.$inject = [
        'dashboardService',
        '$uibModalInstance',
        '$timeout',
        'formService',
        'notifier',
        'formModelName'
    ];

    angular
        .module('dashboard')
        .controller('DashboardSignUpCtrl', DashboardSignUpCtrl);

})(window.angular);