(function (angular, $) {
  'use strict';

  function gameService(
    $log,
    $q,
    apiResource,
    apiSessionResource,
    sessionService,
    apiSettings,
    utilityService,
    infoService,
    $uibModal,
    behaviorEffectService,
    $animate,
    viewAnimationService,
    $rootScope,
    $state
  ) {
    var service = {},
      gamesBaseRoot = apiSettings.gamesBaseRoot;

    service.game = null;
    service.displayCollect = false;
    service.gameConfirmed = false;
    service.getGame = getGame;
    service.confirmGame = confirmGame;
    service.complete = complete;
    service.getGameResourceURL = getGameResourceURL;
    service.getTriviaGame = getTriviaGame;
    service.getReward = getRewardOnDemand;
    service.answerToTriviaQuestion = answerToTriviaQuestion;
    service.showCollectModal = showCollectModal;
    service.getGameInputParams = getGameInputParams;
    service.getTriviaPreviousQuestion = getTriviaPreviousQuestion;
    service.triviaChoiceColors = [
      //In order to add additional color you must define appropriate css class
      'orange',
      'green',
      'yellow',
      'turquoise',
      'blue',
      'purple',
      'brown',
      'pink'
    ];
    service.getColorNameForTriviaChoiceByIndex =
      getColorNameForTriviaChoiceByIndex;
    service.collectReward = collectReward;

    return service;

    function getGame(options) {
      return apiSessionResource.get('/game', options || {}).then(
        function (data) {
          if (
            data.cimGameId === '4f506388-90da-417b-8b38-af8b183c7417' ||
            data.cimGameId === '4bcfa58b-e071-4682-af23-2f35faad276a'
          ) {
            $rootScope.$emit('cosmopolitanCampaign');
          }
          service.game = data;
          $log.debug('get game cim', service.game);
          service.game = prepareGame(service.game);
          service.reward = service.game.reward;
          onGetGameSuccess(service.game);
          return service.game;
        },
        function (result) {
          $state.go('info', {
            message: result.data.message
          });
        }
      );
    }

    function getTriviaGame(cimGameId) {
      var instanceId = sessionService.getSessionKey();

      return apiResource
        .get('api/trivia/' + instanceId + '/' + cimGameId)
        .then(function (data) {
          $log.debug('get trivia game', data);
          if (data.question) {
            data.question.explanation = utilityService.parseSafeJSON(
              data.question.explanation
            );
            data.question.mediaConfiguration = utilityService.parseSafeJSON(
              data.question.mediaConfiguration
            );
          }
          onGetGameSuccess(data);
          return (service.game = data);
        });
    }

    function getTriviaPreviousQuestion(cimGameId, sequenceId) {
      var instanceId = sessionService.getSessionKey();

      return apiResource
        .post(
          'api/trivia/' + instanceId + '/' + cimGameId + '/previousQuestion',
          { sequenceId: sequenceId }
        )
        .then(function (data) {
          $log.debug('get trivia game', data);
          if (data.question) {
            data.question.explanation = utilityService.parseSafeJSON(
              data.question.explanation
            );
            data.question.mediaConfiguration = utilityService.parseSafeJSON(
              data.question.mediaConfiguration
            );
          }
          onGetGameSuccess(data, true);
          return (service.game = data);
        });
    }

    function onGetGameSuccess(game, isPreviousQuestion) {
      service.displayCollect = game && game.displayCollectModal;
      service.isPreviousQuestion = isPreviousQuestion;
    }

    function getRewardOnDemand(cimGameId, data) {
      var instanceId = sessionService.getSessionKey(),
        dataStr =
          "'instanceId': '" +
          instanceId +
          "', 'cimGameId': '" +
          cimGameId +
          "'";

      data = data || {};

      if (
        service.game.gameType === 'SprintClubNitro' &&
        (data.leaderboardScore || data.leaderboardScore === 0)
      ) {
        service.totalScore = data.leaderboardScore;
      } else if (data.score || data.score === 0) {
        service.totalScore = data.score;
      }

      for (var key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          dataStr += ", '" + key + "': '" + data[key] + "'";
        }
      }

      dataStr = '{' + dataStr + '}';

      return apiSessionResource
        .put('/' + cimGameId + '/reward', '=' + dataStr, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then(
          function (data) {
            service.reward = data;
            $log.debug('get game reward', data);
            return service.reward;
          },
          function (error) {
            var data = error.data;
            infoService.showMessage(data.alternativeMessage || data.message);
          }
        );
    }

    function answerToTriviaQuestion(
      cimGameId,
      questionId,
      choices,
      elapsedTime,
      sequenceId
    ) {
      var instanceId = sessionService.getSessionKey(),
        answers;

      if (!instanceId) {
        return $q.reject({});
      }

      choices = angular.isArray(choices) ? choices : [choices];

      if (choices.length === 0) {
        choices = [null];
      }

      answers = choices.map(function (choice) {
        var resultChoice = (choice && choice.choiceValueId) || null;
        var resultMediaType = (choice && choice.mediaType) || 'None';
        if (
          choice &&
          choice.choice &&
          choice.match &&
          choice.choice.choiceValueId &&
          choice.match.choiceValueId
        ) {
          resultChoice =
            choice.choice.choiceValueId + ' => ' + choice.match.choiceValueId;
          resultMediaType = choice.choice.mediaType || 'None';
        }
        return {
          value: resultChoice,
          mediaType: resultMediaType,
          questionId: questionId,
          elapsedTime: elapsedTime
        };
      });

      return apiResource
        .post('api/trivia/' + instanceId + '/' + cimGameId, {
          answers: answers,
          sequenceId: sequenceId
        })
        .then(function (data) {
          $log.debug('get trivia answer', data);
          if (data && data.question) {
            data.question.mediaConfiguration = utilityService.parseSafeJSON(
              data.question.mediaConfiguration
            );
            if (data.answers && data.answers.length) {
              service.isPreviousQuestion = true;
            } else {
              delete service.isPreviousQuestion;
            }
          }
          return data;
        });
    }

    function showCollectModal() {
      return $uibModal.open({
        templateUrl:
          'app/game/components/collectModal/collect-reward-modal.tmpl.html',
        controller: 'CollectRewardModalCtrl',
        controllerAs: 'ctrl',
        backdrop: 'static',
        keyboard: false,
        size: 'sm',
        resolve: {
          game: function () {
            return service.game;
          },
          reward: function () {
            return service.reward;
          }
        }
      }).result;
    }

    function getGameResourceURL(gameType) {
      var baseGamePath = gamesBaseRoot.replace(/\/$/, '');

      switch (gameType.toLowerCase()) {
        case 'scratchoff':
          return baseGamePath + '/ScratchOffGame/';
        case 'balloons':
          return baseGamePath + '/ObjectsGame/';
        case 'fishinggame':
          return baseGamePath + '/FishingGame/';
        case 'memory':
          return baseGamePath + '/MemoryMatchGame/';
        case 'spinv1':
          return baseGamePath + '/SpinGameV1/';
        case 'spinv2':
          //return baseGamePath + '/SpinGameV2/';
          return baseGamePath + '/SpinGameV2WithSpinAgain/';
        case 'spinv3':
          return baseGamePath + '/SpinGameV3/';
        case 'pickadoor':
          return baseGamePath + '/PickADoorGame/';
        case 'pickabriefcase':
          return baseGamePath + '/PickABriefcaseGame/';
        case 'feelinglucky':
          return baseGamePath + '/FeelingLucky/';
        case 'stopthemadness':
          return baseGamePath + '/StopTheMadness/';
        case 'targets':
          return baseGamePath + '/ReZ/';
        case 'racer':
          return baseGamePath + '/HexGL/';
        case 'slotv1':
          return baseGamePath + '/SlotGameV1/';
        case 'slotv2':
          return baseGamePath + '/SlotGameV2/';
        case 'basketball':
          return baseGamePath + '/Basketball/';
        case 'blackjack':
          return baseGamePath + '/Blackjack/';
        case 'letsgofishing':
          return baseGamePath + '/LetsGoFishing/';
        case 'lobstermadness':
          return baseGamePath + '/LobsterMadness/';
        case 'hiddenobject':
        case 'buriedtreasure':
        case 'raidthevault':
        case 'thanksgivingfeast':
        case 'winterwonderland':
          return baseGamePath + '/HiddenObject/';
        case 'golf':
          return baseGamePath + '/Golf/';
        case 'sprintclubnitro':
          return baseGamePath + '/SprintClubNitro/';
        case 'speedpoolking':
          return baseGamePath + '/Pool/';
        case 'zoopinball':
          return baseGamePath + '/Pinball/';
        case 'spotthedifference':
          return baseGamePath + '/SpotTheDifference/';
        case 'penaltykicks':
          return baseGamePath + '/PenaltyKicks/';
        case 'americanfootballkicks':
          return baseGamePath + '/FootballKicks/';
        case 'roulette':
          return baseGamePath + '/Roulette/';
        case 'videopoker':
          return baseGamePath + '/VideoPoker/';
        case 'bowling':
          return baseGamePath + '/Bowling/';
        case 'grandprixglory':
          return baseGamePath + '/GrandPrixGlory/';
        case 'completethegraph':
          return baseGamePath + '/CompleteTheGraph/';
        case 'threecardmonte':
          return baseGamePath + '/ThreeCardMonte/';
        case 'robotreveal':
          return baseGamePath + '/RobotReveal/';
        case 'baseballpro':
          return baseGamePath + '/BaseballPro/';
        case 'battleship':
          return baseGamePath + '/Battleship/';
        case 'sweetymemory':
          return baseGamePath + '/SpeedMemoryMatch/';
        case 'baccaratcasino':
          return baseGamePath + '/BaccaratCasino/';
        case 'monsterbash':
        case 'whackamole':
          return baseGamePath + '/WhackAMole/';
        case 'horseracing':
          return baseGamePath + '/HorseRacing/';
        case 'hockeyshots':
          return baseGamePath + '/HockeyShots/';
        case 'losttreasure':
          return $rootScope.authentication.clientName === 'FoxwoodsONLINE'
            ? baseGamePath + '/LostTreasure/'
            : baseGamePath + '/VideoSlots/';
        case 'enchantedforest':
          return $rootScope.authentication.clientName === 'FoxwoodsONLINE'
            ? baseGamePath + '/LostTreasure/'
            : baseGamePath + '/VideoSlots/';
        case 'videoslots':
          return baseGamePath + '/VideoSlots/';
        case 'plinko':
          return baseGamePath + '/Plinko/';
        case 'videoobjects':
          return baseGamePath + '/VideoObjects/';
        case 'wordsearch':
          return baseGamePath + '/WordSearch/';
        case 'wordbuilder':
          return baseGamePath + '/WordBuilder/';
        case 'luckylottery':
          return baseGamePath + '/LuckyLottery/';
        case 'wordfactory':
          return baseGamePath + '/WordFactory/';
        case 'mineswiper':
          return baseGamePath + '/MineSwiper/';
        case 'darts':
          return baseGamePath + '/Darts/';
        case 'sphereoffortune':
          return baseGamePath + '/SphereOfFortune/';
        default:
          return baseGamePath + '/Default/';
      }
    }

    function prepareGame(game) {
      game.items.forEach(function (item) {
        try {
          item.propertyValue = JSON.parse(item.propertyValue);
          item.propertyModel.propertyValue = JSON.parse(
            item.propertyModel.propertyValue
          );
        } catch (e) {
          //
        }
        try {
          item.propertyValue.value = JSON.parse(item.propertyValue.value);
          item.propertyValue.value = JSON.parse(item.propertyValue.value.value);
          item.propertyModel.propertyValue.value = JSON.parse(
            item.propertyModel.propertyValue.value
          );
        } catch (e) {
          try {
            if (~item.propertyValue.value.indexOf('threshold')) {
              item.propertyValue.value = +item.propertyValue.value
                .slice(1, -1)
                .split(':')[1];
            }
          } catch (e) {
            //
          }
        }

        if (
          item.propertyModel.propertyId == 'threshold' &&
          typeof item.propertyValue.value == 'object' &&
          item.propertyValue.value !== null
        ) {
          item.propertyValue.value = item.propertyValue.value.threshold;
        }
      });

      return game;
    }

    function getGameInputParams(game) {
      var params = {};

      game.items.forEach(function (item) {
        params[item.propertyModel.propertyId] = item.propertyValue.value;
      });

      angular.extend(params, {
        reward: game.reward,
        availableRewards: game.availableRewards,
        description: game.description
      });

      return params;
    }

    function getColorNameForTriviaChoiceByIndex(index) {
      var colors = service.triviaChoiceColors;
      return colors[index % colors.length];
    }

    function confirmGame() {
      if (service.gameConfirmed) {
        return $q.reject();
      } else {
        service.gameConfirmed = true;
        return apiSessionResource
          .post('/game')
          .then(function (data) {
            $log.debug('game was confirmed');
            return data;
          })
          .finally(function () {
            service.gameConfirmed = false;
          });
      }
    }

    function complete() {
      var behaviorData = sessionService.currentStep || {},
        reward = service.reward || {};

      //Show Effect if needed
      return behaviorEffectService
        .addEffect({
          effectDuration: behaviorData.effectDuration,
          effectSound: behaviorData.effectSound,
          effectType:
            behaviorData[
              reward.isWinningReward || service.game.gameKind === 'ChanceBased'
                ? 'effectType'
                : 'consolationEffectType'
            ],
          showDismissButton: behaviorData.showDismissButton,
          dismissImage: behaviorData.displayBehaviorDismissImage,
          dismissButtonText: behaviorData.displayBehaviorDismissText,
          dismissButtonColor: behaviorData.displayBehaviorDismissButtonColor,
          dismissButtonTextColor: behaviorData.displayBehaviorDismissTextColor,
          showReward: behaviorData.showReward,
          showScore: behaviorData.showScore,
          totalScore: service.totalScore,
          leaderboardId: behaviorData.leaderboardId || null,
          cssOverride: behaviorData.cssOverride,
          reward: reward
        })
        .then(function () {
          if (viewAnimationService.isLeaveAnimationPresent()) {
            return $animate.leave($('.cim-transition')).done(function () {
              if (viewAnimationService.leaveVideoUrl) {
                viewAnimationService
                  .showVideoInstead('game', viewAnimationService.leaveVideoUrl)
                  .then(function () {
                    return sessionService.getCurrentStep();
                  });
              } else {
                return sessionService.getCurrentStep();
              }
            });
          } else if (
            !viewAnimationService.isLeaveAnimationPresent() &&
            viewAnimationService.leaveVideoUrl
          ) {
            viewAnimationService
              .showVideoInstead('game', viewAnimationService.leaveVideoUrl)
              .then(function () {
                return sessionService.getCurrentStep();
              });
          } else {
            return sessionService.getCurrentStep();
          }
        }).finally(function(){
          window.parent.postMessage(JSON.stringify(reward), '*');
        });
      //.finally(function() {
      //console.log("FINALLY")
      //if (!viewAnimationService.enterVideoUrl) {
      //    return sessionService.getCurrentStep();
      //}
      //});
    }

    function collectReward() {
      service.displayCollect = false;
      return sessionService.triggerCurrentStep(sessionService.currentStep);
    }
  }

  gameService.$inject = [
    '$log',
    '$q',
    'apiResource',
    'apiSessionResource',
    'sessionService',
    'apiSettings',
    'utilityService',
    'infoService',
    '$uibModal',
    'behaviorEffectService',
    '$animate',
    'viewAnimationService',
    '$rootScope',
    '$state'
  ];

  angular.module('game').factory('gameService', gameService);
})(window.angular, window.jQuery);
