(function (angular, $) {
	'use strict';

	function NotifierService($window, defaultOptions) {
		var service = {};

		service.alert = notify('alert');
		service.success = notify('success');
		service.information = notify('information');
		service.warning = notify('warning');
		service.error = notify('error');
		service.closeAll = closeAll;
		service.clearQueue = clearQueue;

		return service;

		function notify(type) {
			var _options = angular.extend({}, defaultOptions, {
				type: type
			});

			return function (options) {
				if (typeof $window.noty !== 'function') {
					return false;
				}
				if (angular.isString(options)) {
					$window.noty(angular.extend({}, _options, {
						text: options
					}));
				} else if (angular.isObject(options)) {
					$window.noty(angular.extend({}, _options, options));
				} else {
					throw new Error('notifier expects string or object as input parameter');
				}
			};
		}

		function closeAll() {
			return $.noty.closeAll();
		}

		function clearQueue() {
			return $.noty.clearQueue();
		}
	}

	function notifierProvider() {
		var that = this;
		var animationOptions = {
			open: {height: 'toggle'}, // or Animate.css class names like: 'animated bounceInLeft'
			close: {height: 'toggle'}, // or Animate.css class names like: 'animated bounceOutLeft'
			easing: 'swing',
			speed: 500 // opening & closing animation speed
		};
		var animationCSSOptions = {
			open: 'animated flipInX', // Animate.css class names
			close: 'animated flipOutX', // Animate.css class names
			easing: 'swing', // easing
			speed: 500 // opening & closing animation speed
		};

		that.defaultOptions = {
			layout: 'topRight',
			theme: 'relax',
			animation: isCssAnimationSupported() ? animationCSSOptions : animationOptions,
			timeout: 5000
		};

		that.$get = ['$window', function ($window) {
			return new NotifierService($window, that.defaultOptions);
		}];
	}

	function isCssAnimationSupported() {
		var animation = false,
			elm = document.createElement('div'),
			domPrefixes = 'Webkit Moz O ms Khtml'.split(' ');

		if (elm.style.animationName !== undefined) {
			animation = true;
		}

		if (animation === false) {
			for (var i = 0; i < domPrefixes.length; i++) {
				if (elm.style[domPrefixes[i] + 'AnimationName'] !== undefined) {
					animation = true;
					break;
				}
			}
		}

		return animation;
	}

	angular.module('common').provider('notifier', notifierProvider);

})(window.angular, window.jQuery);