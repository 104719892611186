(function (angular) {
	'use strict';

	function httpBufferService($injector) {
		var service = {};
		var buffer = [];
		var $http;

		service.append = append;
		service.rejectAll = rejectAll;
		service.retryAll = retryAll;
		service.isEmpty = isEmpty;

		return service;

		function retryHttpRequest(config, deferred) {
			function successCallback(response) {
				deferred.resolve(response);
			}

			function errorCallback(response) {
				deferred.reject(response);
			}

			$http = $http || $injector.get('$http');
			$http(config).then(successCallback, errorCallback);
		}

		// Appends HTTP request configuration object with deferred response attached to buffer
		function append(config, deferred) {
			buffer.push({
				config: config,
				deferred: deferred
			});
		}

		// Abandon or reject (if reason provided) all the buffered requests
		function rejectAll(reason) {
			if (reason) {
				for (var i = 0; i < buffer.length; ++i) {
					buffer[i].deferred.reject(reason);
				}
			}
			buffer = [];
		}

		// Retries all the buffered requests clears the buffer
		function retryAll() {
			for (var i = 0; i < buffer.length; ++i) {
				retryHttpRequest(buffer[i].config, buffer[i].deferred);
			}
			buffer = [];
		}

		// Indicates whether buffer is empty or not
		function isEmpty() {
			return buffer.length === 0;
		}
	}

	httpBufferService.$inject = [
		'$injector'
	];

	angular.module('auth').factory('httpBufferService', httpBufferService);

})(window.angular);