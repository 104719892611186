(function (angular) {
  'use strict';

  angular.module('redemption', [
    'ui.toggle',
    'ui.router',
    'common',
    'countdown'
  ]);
})(window.angular);
