(function (angular) {
	'use strict';

	angular.module('dashboard', [
		'common',
		'countdown',
		'appStorage',
        'ui.router',
        'angular-intro',
		'gridster'
	]);

})(window.angular);