(function (angular) {
  'use strict';

  function redemptionService(
    $q,
    $log,
    apiResource,
    authService,
    localIpService
  ) {
    var service = {},
      pinCode = null;

    service.rewardInfo = null;
    service.getRewardInfo = getRewardInfoByRewardCode;
    service.redeemReward = redeemReward;
    service.cancelBookingReward = cancelBookingReward;
    service.checkPinCodeForRedemption = checkPinCodeForRedemption;
    service.getCurrentPinCodeForRedemption = getCurrentPinCodeForRedemption;
    service.searchRewards = searchRewards;
    service.extendRewardWithMessages = extendReward;
    service.changePin = changePin;
    service.lastRedeemedReward = {};
    service.userPermissions = null;
    service.getUserInfo = getUserInfo;
    service.getLobbies = getLobbies;
    service.generateBookingReward = generateBookingReward;
    service.searchBookingOffers = searchBookingOffers;
    service.searchValue = '';

    return service;

    function getLobbies() {
      return apiResource.get('api/internal/guest/lookup/lobbies');
    }

    function generateBookingReward(externalId, posCode) {
      return apiResource.post('api/internal/guest/booking', {
        externalId: externalId,
        posCode: posCode
      });
    }

    function searchBookingOffers(externalId) {
      return apiResource.get(
        'api/internal/guest/couponBook?externalId=' + externalId
      );
    }

    function getClientIpAddress() {
      var authData = authService.getAuthenticationData();
      var collectLocalIp = (authData && authData.collectLocalIp) || false;

      $log.debug('collectLocalIp', collectLocalIp);

      if (!collectLocalIp) return $q.when(null);

      var deferred = $q.defer();
      localIpService.getAddresses(function (ips) {
        deferred.resolve(ips);
      });

      return deferred.promise;
    }

    function getRewardInfoByRewardCode(rewardCode, defaultErrorNotifier) {
      //console.log(localStorageService.get('authorizationData'));
      return getClientIpAddress()
        .then(function (ipList) {
          $log.debug('get client ip', ipList);
          return apiResource.get('api/redemption/' + rewardCode, {
            ignoreDefaultErrorNotifier: angular.isUndefined(
              defaultErrorNotifier
            )
              ? true
              : defaultErrorNotifier,
            headers: {
              'X-OC-Client-IP': ipList ? ipList.join(',') : '',
              redeemUserToken: service.userPermissions
                ? service.userPermissions.redeemUserToken || ''
                : ''
            }
          });
        })
        .then(function (response) {
          $log.debug('get info about qrCode', response);
          service.rewardInfo = extendReward(response);
          return service.rewardInfo;
        });
    }

    function redeemReward(rewardCode, redemptionNote, redeemAmount) {
      return getClientIpAddress()
        .then(function (ipList) {
          $log.debug('get client ip', ipList);
          return apiResource.post(
            'api/redemption/' + rewardCode,
            {
              redemptionNote: redemptionNote,
              redeemAmount: redeemAmount,
              pinCode: pinCode
            },
            {
              headers: ipList ? { 'X-OC-Client-IP': ipList.join(',') } : null
            }
          );
        })
        .then(function (response) {
          $log.debug('qrCode was marked as redeemed', response);
          service.lastRedeemedReward = response;
          return response;
        });
    }

    function cancelBookingReward(rewardCode) {
      return getClientIpAddress()
        .then(function (ipList) {
          $log.debug('get client ip', ipList);
          return apiResource.post(
            'api/internal/guest/cancel/' + rewardCode,
            {
              pinCode: pinCode
            },
            {
              headers: ipList ? { 'X-OC-Client-IP': ipList.join(',') } : null
            }
          );
        })
        .then(function (response) {
          $log.debug('reward code was marked as canceled', response);
          service.lastRedeemedReward = response;
          return response;
        });
    }

    function checkPinCodeForRedemption(code) {
      pinCode = code;
      service.userPermissions = null;
      return getClientIpAddress()
        .then(function (ipList) {
          return apiResource.post(
            'api/redemption/checkPin',
            {
              pinCode: code
            },
            {
              ignoreDefaultErrorNotifier: true,
              headers: ipList ? { 'X-OC-Client-IP': ipList.join(',') } : null
            }
          );
        })
        .then(function (data) {
          if (data) {
            service.userPermissions = data;
            return data;
          }
        });
    }

    function searchRewards(deliveryValue, externalIdValue) {
      var lookupTypes = {
          delivery: 'DeliveryValue',
          external: 'ExternalId'
        },
        lookupType = lookupTypes.delivery,
        lookupValue = deliveryValue;

      if (externalIdValue) {
        lookupType = lookupTypes.external;
        lookupValue = externalIdValue;
      }

      return apiResource
        .get('api/redemption/lookup', {
          params: {
            'query.lookupType': lookupType,
            'query.lookupValue': lookupValue,
            redeemUserToken: service.userPermissions.redeemUserToken || ''
          }
        })
        .then(function (data) {
          return data.rewards;
        });
    }

    function getUserInfo() {
      return apiResource.get('api/redemption/userInfo');
    }

    function getCurrentPinCodeForRedemption() {
      return pinCode;
    }

    function extendReward(reward) {
      var messages = (function (messages, hash) {
          messages.forEach(function (message) {
            hash[message.key] = message.value;
          });
          return hash;
        })(reward.auxMessages || [], {}),
        countDownReward = reward.actualReward;

      if (reward.rewardStates) {
        if (~reward.rewardStates.indexOf('Gifted')) {
          reward.rewardState = 'Gifted';
        } else if (~reward.rewardStates.indexOf('Redeemed')) {
          reward.rewardState = 'Redeemed';
        } else if (~reward.rewardStates.indexOf('Expired')) {
          reward.rewardState = 'Expired';
        } else if (~reward.rewardStates.indexOf('Voided')) {
          reward.rewardState = 'Voided';
        } else if (~reward.rewardStates.indexOf('NotStarted')) {
          reward.rewardState = 'NotStarted';
        }
      }

      if (reward.auxMessages) {
        switch (reward.rewardState) {
          case 'Gifted':
            reward.statusMessage = messages['Gifted'];
            break;
          case 'Expired':
            reward.statusMessage = messages['RewardExpired'];
            break;
          case 'Redeemed':
            reward.statusMessage = messages['RedemptionCompleted'];
            break;
          case 'Voided':
            reward.statusMessage = 'Void';
            break;
        }
      }

      if (reward.offerType == 'BookingReward') {
        switch (reward.rewardStateExt) {
          case 'Invited':
            reward.statusMessage = 'Invited';
            break;
          case 'Booked':
            reward.statusMessage = 'Booked';
            break;
          case 'Arrived':
            reward.statusMessage = 'Arrived';
            break;
          case 'Completed':
            reward.statusMessage = 'Completed';
            break;
          case 'None':
            reward.statusMessage = '';
            break;
        }
      }

      if (countDownReward && !countDownReward.rewardState) {
        countDownReward.rewardState = reward.rewardState;
      }

      if (countDownReward && !countDownReward.statusMessage) {
        countDownReward.statusMessage = reward.statusMessage;
      }

      return reward;
    }

    function changePin(values) {
      return apiResource.put('api/redemption/changePin', values);
    }
  }

  redemptionService.$inject = [
    '$q',
    '$log',
    'apiResource',
    'authService',
    'localIpService'
  ];

  angular.module('redemption').factory('redemptionService', redemptionService);
})(window.angular);
