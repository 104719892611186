(function (angular) {
	'use strict';

	function posCodePreview($window, $timeout, loadResourceService) {
		return {
			restrict: 'AE',
			replace: false,
			templateUrl: 'app/common/directives/pos-code-preview.tmpl.html',
			scope: {
				type: '=',
				code: '=',
				warning: '=',
				scale: '=',
				loaded: '=?'
			},
			link: function (scope, $element) {
				var destroyed = false;

				scope.$on('$destroy', function () {
					destroyed = true;
				});

				//Check if bwip.js was loaded
				if ($window['BWIPJS']) {
					init();
				} else {
					//load bwip.js
					loadResourceService.loadSyncScripts([
						'/scripts/vendor/bwip-js/freetype.js',
						'/scripts/vendor/bwip-js/bwip.js',
						'/scripts/vendor/bwip-js/lib/canvas.js',
						'/scripts/vendor/bwip-js/lib/symdesc.js',
						'/scripts/vendor/bwip-js/lib/canvas-toblob.js',
						'/scripts/vendor/bwip-js/lib/filesaver.js'
					]).then(function () {
						if (!destroyed) {
							init();
						}
					}).finally(function () {
						scope.isLoading = false;
					});

					scope.isLoading = true;
				}

				function init() {
					var symdesc = $window.symdesc,
						BWIPJS = $window.BWIPJS,
						Bitmap = $window.Bitmap,
						canvasId = Math.random().toString().slice(2);

					$element.find('canvas').attr('id', canvasId);

					scope.$watch('type', function () {
						render();
					});

					scope.$watch('code', function () {
						render();
					});

					function render() {
						var text = scope.code || '',
							elt = symdesc[scope.type],
							opts = {
								paddingwidth: 10,
								paddingheight: 10
							},
							scl = scope.scale || 2,
							bw,
							rot;

						if (!scope.type) {
							return;
						}

						bw = new BWIPJS;

						// Add any hard-coded options required to fix problems in the javascript
						// emulation.
						opts.inkspread = bw.value(0);

						if (elt && (elt.sym.indexOf('upc') === 0 || elt.sym.indexOf('ean') === 0)) {
							opts.height = 0.4;
						}

						rot = 'N';

						bw.bitmap(new Bitmap);

						bw.scale(scl, scl);
						bw.push(text);
						bw.push(opts);

						bw.call(elt.sym, function (e) {
							if (e) {
								if (typeof e === 'string') {
									$timeout(function () {
										scope.warning = e;
									});
								} else if (e.stack) {
									$timeout(function () {
										scope.warning = e.message;
									});
								}
							} else {
								bw.bitmap().show(canvasId, rot);
							}

							$timeout(function () {
								scope.isLoading = false;
								scope.loaded = true;
							});
						});

						scope.isLoading = true;
						scope.warning = null;
					}
				}
			}
		};
	}

	posCodePreview.$inject = [
		'$window',
		'$timeout',
		'loadResourceService'
	];

	angular.module('common').directive('posCodePreview', posCodePreview);

})(window.angular);