(function (angular) {
	'use strict';

	function EmbeddedCtrl(embeddedService, authService) {
		var vm = this;

		vm.model = embeddedService.embedded;
		vm.clientLogoUrl = authService.authentication.clientLogoUrl;
		vm.confirm = confirm;

		function confirm() {
			vm.isLoading = true;

			embeddedService
				.confirmEmbedded()
				.then(function () {
					embeddedService.complete()
						.then(function () {
							vm.isLoading = false;
						});
				});
		}
	}

	EmbeddedCtrl.$inject = [
		'embeddedService',
		'authService'
	];

	function embedded() {
		return {
			restrict: 'AE',
			controller: EmbeddedCtrl,
			controllerAs: 'embedded',
			bindToController: true,
			templateUrl: 'app/embedded/components/embedded/embedded.tmpl.html'
		};
	}

	angular.module('embedded').directive('embedded', embedded);

})(window.angular);