(function (angular) {
  'use strict';

  function LobbyStartCtrl(
    inactivityConfirmationService,
    lobbyService,
    redemptionService,
    $state,
    $document,
    $scope,
    screensaverService,
    $timeout,
    $stateParams,
    $uibModal,
    authService,
    subscriptionService,
    errorService,
    notifier,
    personProfileService,
    apiSettings
  ) {
    var vm = this,
      onTouchDocumentTimer = null,
      slides = lobbyService.campaignsSlides.slides,
      slidesInterval = lobbyService.campaignsSlides.interval,
      screenSaverTimeout = lobbyService.config.screenSaverDelay * 1000 || 0,
      activeUser = null;

    vm.skipScreensaver = lobbyService.config.skipScreensaver;
    vm.isLoadingInteraction = false;
    vm.keyboardCaptureDisabled = false;
    vm.requiresPinCode = lobbyService.requiresPinCode;
    vm.checkForUniquesType = checkForUniquesType;
    vm.rewardCode = $stateParams.rewardCode;
    vm.pinCode = '';
    vm.displayEnterPinMessage = true;
    vm.lobbyEnabled = lobbyService.config.hasLobby;

    vm.onClickCampaigns = getCampaignsByPinCode;
    vm.onClickRedeem = onClickRedeem;
    vm.onClickEmail = onClickEmail;
    vm.onClickDrawings = onClickDrawings;
    vm.onClickCancel = onClickCancel;
    vm.onClickStartButton = startAccessControl;
    /*vm.onClickPersonLookup = onClickPersonLookup;*/
    vm.onClickAccountActivation = onClickAccountActivation;

    vm.login = login;
    vm.isShowEmailButton = isShowEmailButton;
    vm.isShowDrawingsButton = isShowDrawingsButton;

    vm.logout = logout;
    vm.tryLogout = tryLogout;
    vm.cancelLogout = cancelLogout;

    vm.users = authService.getFullAuthenticationData();
    activeUser = authService.getAuthenticationData();

    vm.selectActiveUser = selectActiveUser;
    vm.getNameAbbreviation = getNameAbbreviation;

    redemptionService.userPermissions = null;

    inactivityConfirmationService.stop();

    if (vm.requiresPinCode) {
      // Start ScreenSaver
      initScreenSaver();
    }

    authService.on('auth.onChangeAuthData', function () {
      vm.users = authService.getFullAuthenticationData();
    });

    authService.on('auth.logout', function () {
      vm.users = authService.getFullAuthenticationData();
    });

    function initScreenSaver() {
      $scope.$on('$destroy', function () {
        $document[0].removeEventListener('click', onTouchDocument, true);
        stopScreenSaver();
      });
      $document[0].addEventListener('click', onTouchDocument, true);
      startScreenSaverTimer();
    }

    function onTouchDocument() {
      stopScreenSaver();
      startScreenSaverTimer();
    }

    function startScreenSaverTimer() {
      onTouchDocumentTimer = $timeout(showScreenSaver, screenSaverTimeout);
    }

    function showScreenSaver() {
      if (slides && slides.length) {
        screensaverService.show({
          slides: angular.copy(slides),
          interval: slidesInterval
        });
      }
    }

    function stopScreenSaver() {
      $timeout.cancel(onTouchDocumentTimer);
      screensaverService.hide();
    }

    function getCampaignsByPinCode(pinCode) {
      lobbyService.pinCode = pinCode;

      if (lobbyService.isLandingPageLobby) {
        //Get Landing Page Campaigns
        return $state.go('lobby.campaigns-ac', {
          isPreviewMode: lobbyService.isPreviewMode
        });
      }

      if (lobbyService.hasAccessControl) {
        return startAccessControl(pinCode);
      }

      // So it is old lobby without access control.
      // So let's handle it as we did it before
      return lobbyService.startSessionByPinCode(pinCode);
    }

    function startAccessControl() {
      vm.isLoadingInteraction = true;

      if (lobbyService.isLandingPageLobby) {
        //Get Landing Page Campaigns
        return $state.go('lobby.campaigns-ac', {
          isPreviewMode: lobbyService.isPreviewMode
        });
      }

      return $state
        .go('lobby.ac.interaction', {
          accessControlId: lobbyService.accessControlId,
          isPreviewMode: lobbyService.isPreviewMode
        })
        .finally(function () {
          vm.isLoadingInteraction = false;
        });
    }

    function getNameAbbreviation(user) {
      var name =
        user.userInfo &&
        user.userInfo.employeeFirstName &&
        user.userInfo.employeeLastName
          ? user.userInfo.employeeFirstName +
            ' ' +
            user.userInfo.employeeLastName
          : user.displayName;

      if (name) {
        var nameParts = name.split(' ');
        if (nameParts.length >= 2) {
          return nameParts[0][0] + nameParts[1][0];
        } else {
          return nameParts[0][0];
        }
      }

      return '';
    }

    function onClickRedeem(pinCode) {
      return redemptionService
        .checkPinCodeForRedemption(pinCode || vm.pinCode)
        .then(function () {
          if (vm.rewardCode) {
            return $state.go('redemption.details', {
              rewardCode: vm.rewardCode
            });
          } else {
            return $state.go('redemption.search');
          }
        });
    }

    function onClickDrawings(pinCode) {
      return redemptionService
        .checkPinCodeForRedemption(pinCode || vm.pinCode)
        .catch(function (error) {
          var errorMessage =
            errorService.parseErrorsToList(error.data).join(' ') ||
            'Unexpected error.';
          notifier.error(errorMessage);
          vm.pinCode = '';
        })
        .then(function (perm) {
          if (perm) {
            window
              .open(
                apiSettings.drawingUrl +
                  '/auth?refreshToken=' +
                  activeUser.refreshToken,
                '_blank'
              )
              .focus();
          }
          return $state.go();
        });
    }

    function onClickEmail(pinCode) {
      return redemptionService
        .checkPinCodeForRedemption(pinCode || vm.pinCode)
        .catch(function (error) {
          var errorMessage =
            errorService.parseErrorsToList(error.data).join(' ') ||
            'Unexpected error.';
          notifier.error(errorMessage);
          vm.pinCode = '';
        })
        .then(function (perm) {
          if (perm) {
            subscriptionService.userPermissions = perm;
            return $state.go('email.search');
          }
        });
    }

    /*function onClickPersonLookup(pinCode) {

            return redemptionService
                .checkPinCodeForRedemption(pinCode || vm.pinCode)
                .catch(function (error) {
                    var errorMessage = errorService.parseErrorsToList(error.data).join(' ') || 'Unexpected error.';
                    notifier.error(errorMessage);
                    vm.pinCode = '';
                })
                .then(function (perm) {
                    if (perm) {
                        personProfileService.userPermissions = perm;
                        return $state.go('lobby.person-lookup');
                    }
                });
        }*/

    function onClickAccountActivation(pinCode) {
      return redemptionService
        .checkPinCodeForRedemption(pinCode || vm.pinCode)
        .catch(function (error) {
          var errorMessage =
            errorService.parseErrorsToList(error.data).join(' ') ||
            'Unexpected error.';
          notifier.error(errorMessage);
          vm.pinCode = '';
        })
        .then(function (perm) {
          if (perm) {
            personProfileService.userPermissions = perm;
            return $state.go('lobby.account-activation');
          }
        });
    }

    function login() {
      vm.keyboardCaptureDisabled = true;

      $uibModal
        .open({
          templateUrl:
            'app/auth/components/userLoginModal/user-login-modal.html',
          controller: 'userLoginModalCtrl',
          controllerAs: 'ctrl',
          backdrop: true,
          keyboard: true,
          resolve: {}
        })
        .result.then(function () {
          vm.users = authService.getFullAuthenticationData();
          activeUser = authService.getAuthenticationData();
        })
        .finally(function () {
          vm.keyboardCaptureDisabled = false;
        });
    }

    function tryLogout(user) {
      vm.users.forEach(function (u) {
        u.loggingOut = false;
      });
      user.loggingOut = true;
    }

    function cancelLogout(user) {
      user.loggingOut = false;
    }

    function logout(user) {
      authService.logoutUser(user);
      vm.users = authService.getFullAuthenticationData();

      if (vm.users.length === 0) {
        authService.logout();
      }
    }

    function selectActiveUser(user) {
      vm.users.forEach(function (u) {
        u.isActive = false;
        !user.loggingOut && (u.loggingOut = false);
      });

      user.isActive = true;
      activeUser = user;
      authService.setActiveUser(activeUser, true);
    }

    function isShowEmailButton() {
      var user = activeUser || vm.users[0];
      return (
        user &&
        user.userInfo &&
        user.userInfo.uiCustomization &&
        user.userInfo.uiCustomization.isShowEmailButton.toLowerCase() === 'true'
      );
    }

    function isShowDrawingsButton() {
      var user = activeUser || vm.users[0];
      return (
        user &&
        user.userInfo &&
        user.userInfo.uiCustomization &&
        user.userInfo.uiCustomization.isShowDrawingsButton.toLowerCase() ===
          'true'
      );
    }

    function checkForUniquesType() {
      return (
        authService.getFullAuthenticationData().filter(function (auth) {
          return auth && auth.userInfo && auth.userInfo.pinUniquenessType !== 0;
        }).length > 0
      );
    }

    function onClickCancel() {
      return $state.go('lobby.start', {
        rewardCode: null
      });
    }
  }

  LobbyStartCtrl.$inject = [
    'inactivityConfirmationService',
    'lobbyService',
    'redemptionService',
    '$state',
    '$document',
    '$scope',
    'screensaverService',
    '$timeout',
    '$stateParams',
    '$uibModal',
    'authService',
    'subscriptionService',
    'errorService',
    'notifier',
    'personProfileService',
    'apiSettings'
  ];

  function lobbyStart() {
    return {
      restrict: 'AE',
      transclude: true,
      controller: LobbyStartCtrl,
      controllerAs: 'lobbyStartScreen',
      bindToController: true,
      templateUrl: 'app/lobby/components/lobbyStart/lobby-start.tmpl.html',
      scope: {
        slideshow: '=',
        continueImage: '='
      }
    };
  }

  angular.module('lobby').directive('lobbyStart', lobbyStart);
})(window.angular);
