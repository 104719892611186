(function (angular) {
	'use strict';

	function LeaderboardCtrl($scope, $window, $timeout, utilityService) {
		var vm = this;
		vm.convertToDecimalColor = convertToDecimalColor;
		vm.uiSettings = vm.data.uiSettings;
		for(var i = 0; i < vm.data.boxes.length; i++) {
			if(vm.data.boxes[i].type == 'Leaderboard') {
				vm.model = vm.data.boxes[i];
				break;
			}
		}

		function convertToDecimalColor(hex) {
			return hex ? utilityService.convertToDecimalColor(hex, 0.85) : null;
		}

	}

	LeaderboardCtrl.$inject = 
	[
	'$scope', 
	'$window', 
	'$timeout',
	'utilityService'
	];

	function behaviorLeaderboard() {
		return {
			restrict: 'AE',
			controller: LeaderboardCtrl,
			controllerAs: 'box',
			bindToController: true,
			templateUrl: 'app/behavior/components/leaderboard/behavior-leaderboard.tmpl.html',
			scope: {
				data: '=',
				score:'=',
				$close: '&'
			}
		};
	}

	angular.module('behavior').directive('behaviorLeaderboard', behaviorLeaderboard);
})(window.angular);