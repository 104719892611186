(function (angular) {
	'use strict';

	function ocInFocus($parse) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {

				element[0].addEventListener('focus', onFocus, true);

				function onFocus() {
					// Execute Expression
					scope.$applyAsync(function() {
						$parse(attrs.ocInFocus)(scope);
					});
				}

			}
		};
	}

	ocInFocus.$inject = [
		'$parse'
	];

	angular.module('common').directive('ocInFocus', ocInFocus);

})(window.angular);