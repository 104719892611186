(function (angular) {
	'use strict';

	function CollectRewardModalCtrl($scope, $uibModalInstance, game, reward, gameService, $timeout) {
		var vm = this,
			autoCollectTimer;

		vm.reward = reward;
		vm.collect = collect;

		$scope.$on('$destroy', function() {
			$timeout.cancel(autoCollectTimer);
		});

		if (game.autoCollectDelay > 0) {
			autoCollectTimer = $timeout(collect, game.autoCollectDelay * 1000);
		}

		function collect() {
			$timeout.cancel(autoCollectTimer);

			return gameService
				.collectReward(reward)
				.then(function() {
					$uibModalInstance.close();
				});
		}
	}

	CollectRewardModalCtrl.$inject = [
		'$scope',
		'$uibModalInstance',
		'game',
		'reward',
		'gameService',
		'$timeout'
	];

	angular.module('game').controller('CollectRewardModalCtrl', CollectRewardModalCtrl);

})(window.angular);