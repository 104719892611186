(function (angular) {
	'use strict';

	function config($urlRouterProvider, appModeServiceProvider, localStorageServiceProvider, utilityServiceProvider, finishInstanceModalServiceProvider, $translateProvider) {

		var $state,
			searchParams = utilityServiceProvider.parseSearchQuery(),
			lobbyToken = searchParams.lobbytoken,
			storageKey = lobbyToken && (lobbyToken + (searchParams.externalid ? '_' + searchParams.externalid : ''));

		//load common mode
		appModeServiceProvider.setCommonMode();

		if (storageKey) {
			localStorageServiceProvider.setPrefix(storageKey);
		}

		$urlRouterProvider.otherwise(function ($injector) {
			$state = $state || $injector.get('$state');
			$state.go('lobby.init');
		});

		finishInstanceModalServiceProvider.defaultOptions.success = function ($injector) {
			$state = $state || $injector.get('$state');
			return $state.go('lobby.init');
		};

		//Translation settings
		$translateProvider.useStaticFilesLoader({
			prefix: 'locales/locale_',
			suffix: '.json'
		});
		$translateProvider.preferredLanguage('en-US');

	}

	config.$inject = [
		'$urlRouterProvider',
		'appModeServiceProvider',
		'localStorageServiceProvider',
		'utilityServiceProvider',
		'finishInstanceModalServiceProvider',
		'$translateProvider'
	];

	angular.module('commonMode').config(config);

})(window.angular);