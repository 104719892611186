(function (angular) {
    'use strict';

    function LoginCtrl($location, authService, $timeout, redemptionService, $scope, $attrs, $state, $uibModal, $translate, errorService) {
        var vm = this;

        vm.loginData = {};
        vm.isShownSubmitButton = false;
        vm.isVerificationRequired = false;
        vm.isLoading = false;

        vm.onSuccess = $scope.onSuccess;
        vm.onError = $scope.onError;
        vm.currentLang = $translate.use();

        //To fix strange issue with fancyButton `ngTransclude:orphan`
        $timeout(function () {
            vm.isShownSubmitButton = true;
        }, 0);

        vm.forgotPass = function () {
            $state.go('forgotPassword',{
                comebackState: $scope.comebackState || 'login'
            });
        };

        vm.switchLang = function (lang) {
            $translate.use(lang);
        };

        vm.login = function (event) {
            event && event.preventDefault && event.preventDefault();


            vm.error = null;
            vm.submitted = true;

            if (!vm.isLoading) {
                vm.isLoading = true;

                authService.login(vm.loginData, true).then(function () {
                    return redemptionService.getUserInfo().then(function (resp) {
                        if (resp) {
                            authService.extendAuthenticationData({userInfo: resp});

                            if (!$attrs.onSuccess) {
                                var path = $location.search().path || '/';
                                vm.error = null;
                                $location.search('path', null);
                                $location.path(path);
                            } else {
                                vm.onSuccess();
                            }
                        }
                    });
                }, function (response) {
                    vm.error = response.data && response.data.error_description;
                    vm.isVerificationRequired = response.isVerificationRequired;
                    if (response && response.data && response.data.error == 'password_expired') {
                        setTimeout(function () {
                            /*$state.go('forgotPassword', {
                                email: vm.loginData.userName
                            });*/
                            authService.sendResetPasswordRequest(vm.loginData.userName, 'loginForm')
                                .then(function (data) {
                                    vm.error = null;
                                    if(data && data.directReset) {
                                        $uibModal.open({
                                            templateUrl: 'app/auth/components/resetPasswordById/reset-password-by-id.tmpl.html',
                                            controller: 'resetUserPasswordById',
                                            controllerAs: 'resetPass',
                                            backdrop: 'static',
                                            keyboard: false,
                                            resolve: {
                                                userName: function () {
                                                    return vm.loginData.userName;
                                                }
                                            }
                                        }).result.then(function () {
                                            $state.go('login');
                                        });
                                    } else {
                                        errorService.lastErrorMessage = data && data.message && data.message.message;
                                        $state.go('passwordReset');
                                        /*$state.go('forgotPassword', {
                                            email: vm.loginData.userName
                                        });*/
                                    }
                                }, function (error) {
                                    vm.error = errorService.parseErrorsToList(error);
                                    vm.onError();
                                }).finally(function () {
                                    vm.isLoading = false;
                            });
                        }, 1000);
                    } else {
                        vm.onError();
                    }

                }).finally(function () {
                    vm.isLoading = false;
                });
            }
        };
    }

    LoginCtrl.$inject = [
        '$location',
        'authService',
        '$timeout',
        'redemptionService',
        '$scope',
        '$attrs',
        '$state',
        '$uibModal',
        '$translate',
        'errorService'
    ];

    function userLogin() {
        return {
            restrict: 'AE',
            templateUrl: 'app/auth/components/userLogin/user-login.tmpl.html',
            controllerAs: 'auth',
            controller: LoginCtrl,
            scope: {
                onSuccess: '&',
                onError: '&',
                comebackState: '=?'
            }
        };
    }

    angular.module('auth').directive('userLogin', userLogin);

})(window.angular);