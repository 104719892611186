(function (angular) {
  'use strict';

  function AnswerCtrl(utilityService, authService) {
    var vm = this;

    vm.dateFormat = authService.dateTimeFormat() || vm.dateFormat || 'MM/dd/yy';
    vm.init = function (ngModel) {
      if (!ngModel) return;

      vm.onChange = function () {
        ngModel.$setViewValue({
          value:
            vm.monthModel.component +
            '/' +
            vm.dayModel.component +
            '/' +
            vm.yearModel.component
        });
        vm.onChangeModel({ $inputType: 'DateFull' });
      };

      vm.onChangeYear = vm.onChangeMonth = function () {
        updateDayValue();
        vm.onChange();
      };

      vm.onChangeDay = function () {
        vm.onChange();
      };

      createOrderedEntities();

      ngModel.$render = function () {
        // Specify how UI should be updated when new question was applied
        vm.yearModel.component =
          vm.monthModel.component =
          vm.dayModel.component =
            null;
      };

      if (vm.isUseTodayDate) {
        var date = new Date();

        vm.dayModel.component = date.getDate();
        vm.monthModel.component = date.getMonth() + 1;
        vm.yearModel.component = date.getFullYear();

        vm.onChange();
      }
    };

    function updateDayValue() {
      var days = utilityService.getDaysInMonth(
        vm.monthModel.component,
        vm.yearModel.component
      );
      if (vm.dayModel.components.length !== days) {
        vm.dayModel.components = utilityService.getRange(
          days,
          function (el, index) {
            return index + 1;
          }
        );
      }
      if (vm.dayModel.component > days) {
        vm.dayModel.component = days;
      }
      return true;
    }

    function createOrderedEntities() {
      var years = [];

      if (vm.isUseDateRange) {
        var tmp = vm.dateRange.dateRange.map(function (year) {
          var date = new Date(year);
          return date.getUTCFullYear();
        });
        if (vm.dateRange && vm.dateRange.type === 'Range') {
          for (var i = tmp[0]; i <= tmp[1]; i++) {
            years.push(i);
          }
        }

        if (vm.dateRange && vm.dateRange.type === 'Sequence') {
          years = vm.dateRange.dateRange.map(function (year) {
            var date = new Date(year);
            return date.getUTCFullYear();
          });
        }
      } else {
        if (
          vm.automaticYear ||
          vm.automaticYear === undefined ||
          !vm.endYear ||
          !vm.startYear
        ) {
          years = utilityService.getRange(100, function (el, index) {
            var today = new Date();
            today.setFullYear(today.getFullYear());
            return today.getFullYear() - index;
          });
        } else {
          years = utilityService.getRange(
            vm.endYear - vm.startYear + 1,
            function (el, index) {
              var today = vm.endYear;
              return today - index;
            }
          );
        }
      }

      vm.dayModel = {
        onChange: vm.onChangeDay,
        label: 'Day',
        component: null,
        components: utilityService.getRange(30, function (el, index) {
          return index + 1;
        })
      };
      vm.monthModel = {
        onChange: vm.onChangeMonth,
        label: 'Month',
        component: null,
        components: utilityService.getRange(12, function (el, index) {
          return index + 1;
        })
      };
      vm.yearModel = {
        onChange: vm.onChangeYear,
        label: 'Year',
        component: null,
        components: years
      };
      switch (vm.dateFormat) {
        case 'dd/MM/yy':
          vm.orderedDateModels = [vm.dayModel, vm.monthModel, vm.yearModel];
          break;
        case 'MM/dd/yy':
        default:
          vm.orderedDateModels = [vm.monthModel, vm.dayModel, vm.yearModel];
      }
    }
  }

  AnswerCtrl.$inject = ['utilityService', 'authService'];

  function answer() {
    return {
      restrict: 'E',
      templateUrl:
        'app/answer/components/answer/answerItems/answer-date-full.tmpl.html',
      require: ['answerDateFull', 'ngModel', '^answers'],
      controller: AnswerCtrl,
      controllerAs: 'answer',
      bindToController: true,
      scope: {
        onChangeModel: '&answerOnChangeModel',
        isRequired: '=answerRequired',
        form: '=answerFormInstance',
        dateFormat: '=',
        isUseTodayDate: '=',
        answerDisabled: '=',
        answerName: '=',
        isUseDateRange: '=',
        dateRange: '=',
        startYear: '=',
        endYear: '=',
        automaticYear: '='
      },
      link: function (scope, element, attr, ctrls) {
        ctrls[0].init(ctrls[1], ctrls[2]);
      }
    };
  }

  angular.module('answer').directive('answerDateFull', answer);
})(window.angular);
