(function (angular) {
	'use strict';

	function DashboardDeliveryCtrl($state, $stateParams, dashboardService, formService) {
		var vm = this;

		vm.clientLogoUrl = '';
		vm.deliveryTypes = dashboardService.deliveryTypes;
		vm.deliveryType = vm.deliveryTypes[0];
		vm.deliveryValue = null;

		vm.delivery = function (form) {
			formService.makeFieldsDirty(form);

			if (form && form.$invalid) {
				return false;
			}

			if (!vm.isLoading) {
				vm.error = null;
				vm.isLoading = true;

				dashboardService.saveNotificationDeliveryType($stateParams.dToken, $stateParams.userId, vm.deliveryType.type, vm.deliveryValue)
					.then(function () {
                        $state.go('dashboard', {dToken: $stateParams.dToken});
					}).finally(function () {
						vm.isLoading = false;
					});
			}
		};

		vm.onChangeDeliveryType = function() {
			// Reset delivery value
			vm.deliveryValue = null;
		};
	}

	DashboardDeliveryCtrl.$inject = [
		'$state',
		'$stateParams',
		'dashboardService',
		'formService'
	];

	function dashboardDelivery() {
		return {
			restrict: 'AE',
			templateUrl: 'app/dashboards/components/delivery/dashboard-delivery.tmpl.html',
			controllerAs: 'delivery',
			controller: DashboardDeliveryCtrl,
			scope: {}
		};
	}

	angular.module('dashboard').directive('dashboardDelivery', dashboardDelivery);

})(window.angular);