(function (angular) {

    function LobbyDashboardBoxCtrl($window, dashboardService, $timeout, $log) {
		var vm = this,
            $ = $window.jQuery,
			location = $window.location;

		vm.model.link = location.protocol + '//' + location.host + '?lobbyToken=' + vm.model.lobbyAccessToken;

		if(vm.model.requiredRequestParameters && vm.model.requiredRequestParameters.length){
            vm.model.requiredRequestParameters.forEach(function (parameter) {
                vm.model.link = vm.model.link + '&' + parameter + '=' + dashboardService.currentDashboard.accessControlParameters[parameter];
            });
		}

        vm.model.contentCssOverrides && $timeout(function () {
            $('#lobby_box_' + vm.model.index).load(function () {
                try {
                    var iframe = $(this.contentDocument);
                    var head = iframe.find('head');
                    var style = document.createElement('style');
                    style.appendChild(document.createTextNode(vm.model.contentCssOverrides));
                    head.append(style);
                } catch (e) {
                    $log.debug('Could not inject styles into iframe: ' + e);
                }
            });
        });

        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };

	}

	LobbyDashboardBoxCtrl.$inject = [
		'$window',
        'dashboardService',
        '$timeout',
        '$log'
	];

	function lobbyDashboardBox() {
		return {
			controller: LobbyDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/lobby/lobby-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
		.directive('lobbyDashboardBox', lobbyDashboardBox);

})(window.angular);