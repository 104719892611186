(function (angular) {
	'use strict';

	function RedeemCtrl(redeemService,authService) {
		var vm = this;
        vm.clientLogoUrl = authService.authentication.clientLogoUrl;
		vm.model = redeemService.rewardData;
		vm.redirectNow = vm.model.thankYouAutoRedirectEnabled && !vm.model.thankYouAutoRedirectTimer;

		if (vm.redirectNow) {
			return redeemService.goToExternalUrl(vm.model.thankYouAutoRedirectLinkUrl);
		}

		vm.useNewThankYouPage = vm.model.useNewThankYouPage;
	}

	RedeemCtrl.$inject = [
		'redeemService',
		'authService'
	];

	angular.module('redeem').controller('RedeemCtrl', RedeemCtrl);

})(window.angular);