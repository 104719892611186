(function (angular) {
	'use strict';

	function AnswerCtrl() {
		var vm = this;

		vm.signature =  {
            contentType: 'image/png',
            fileName: 'signature.png',
            value: ''
        };
	}

	function answer() {
		return {
			restrict: 'E',
			templateUrl: 'app/answer/components/answer/answerItems/answer-signature.tmpl.html',
			require: ['answerSignature', '^answers'],
			controller: AnswerCtrl,
			controllerAs: 'answer',
			bindToController: true,
			scope: {
				signature: '=',
                uniqueId: '='
			}
		};
	}

	angular.module('answer').directive('answerSignature', answer);

})(window.angular);