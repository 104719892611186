(function (angular) {
	'use strict';

    function InformationCtrl($stateParams, appModeService, infoService) {
		var vm = this;

        vm.message = infoService.errorData.message || $stateParams.message;
        vm.cssOverrides = infoService.errorData.cssOverrides;

		vm.isDoneBtnShown = appModeService.isCommonMode();
	}

	InformationCtrl.$inject = [
		'$stateParams',
        'appModeService',
        'infoService'
	];

	function information() {
		return {
			restrict: 'AE',
			controller: InformationCtrl,
			controllerAs: 'info',
			bindToController: true,
			templateUrl: 'app/info/components/information/information.tmpl.html'
		};
	}

	angular.module('info').directive('information', information);

})(window.angular);