(function (angular) {

	function LeaderboardDashboardBoxCtrl($timeout, $scope, countdownService, utilityService) {
		var vm = this,
			initTimeRangeObj = countdownService.convertDateRangeStringToTimeRangeObj(vm.model.leaderboardCountdown),
			timer,
			timerDuration = 30000,
			isShownFullLeaderboard = true,
			leaderboardCount = 10;

		vm.model.isCountdownElapsed = countdownService.isTimeRangeStringElapsed(vm.model.leaderboardCountdown);
        vm.model.values = vm.model.values || [];
		vm.model.limit = vm.model.rowsNumberToShow || vm.model.values.length;
		vm.loadMore = loadMore;
		vm.showText = '';
        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };
		//vm.model.leaderboardValues = new Array();

		
		if(utilityService.isMobile()) {
			limitLeaderboard();
		} else {
			vm.model.leaderboardValues = vm.model.values.slice(0);
		}

		$scope.$watch('box.model.lastUpdated', function() {
            if (!Array.isArray(vm.model.values)) vm.model.values = [];
			if(utilityService.isMobile()) {
				isShownFullLeaderboard = !isShownFullLeaderboard;
				limitLeaderboard();
			} else {
				vm.model.leaderboardValues = vm.model.values.slice(0);
			}
		});


		vm.countdownOptions = {
			days: initTimeRangeObj.days > 0,
			hours: true,
			minutes: true,
			seconds: initTimeRangeObj.days === 0
		};

		vm.onElapse = function() {
			vm.model.isCountdownElapsed = true;
		};

		vm.isCountdownShown = function() {
			return !countdownService.isTimeRangeStringElapsed(vm.model.leaderboardCountdown)
				&& !vm.model.isCountdownElapsed;
		};

		vm.destroy = function() {
			$timeout.cancel(timer);
		};

		startTimerToCheckCountdownOpts();

		function startTimerToCheckCountdownOpts() {
			if (!vm.model.isCountdownElapsed) {
				timer = $timeout(function() {
					var currentTimeRangeObj = countdownService.getCurrentTimeRangeByModelId(vm.countdownModelId);
					checkCountdownOpts(currentTimeRangeObj);
				}, timerDuration);
			}
		}

		function checkCountdownOpts(currentTimeRangeObj) {
			if (currentTimeRangeObj && currentTimeRangeObj.days <= 0) {
				vm.countdownOptions = {
					days: false,
					hours: true,
					minutes: true,
					seconds: true
				};
			}

			startTimerToCheckCountdownOpts();
		}

		function loadMore() {
			limitLeaderboard();
		}


		function limitLeaderboard() {

			if(isShownFullLeaderboard) {
				vm.model.leaderboardValues = vm.model.values.slice(0, leaderboardCount);
				vm.showText = 'Show More';
				isShownFullLeaderboard = false;
			} else {
				vm.model.leaderboardValues = vm.model.values.slice();
				vm.showText = 'Show Less';
				isShownFullLeaderboard = true;
			}
			if(vm.model.values.length <= 10) {
				vm.showText = '';
			}

		}


	}

	LeaderboardDashboardBoxCtrl.$inject = [
		'$timeout',
		'$scope',
		'countdownService',
		'utilityService'
	];

	function leaderboardDashboardBox() {
		return {
			controller: LeaderboardDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
				model: '='
			},
			templateUrl: 'app/dashboards/components/leaderboard/leaderboard-dashboard-box.tmpl.html',
			require: 'leaderboardDashboardBox',
			link: function(scope, $elem, attr, ctrl) {
				scope.$on('$destroy', ctrl.destroy);
			}
		};
	}

	angular
		.module('dashboard')
		.directive('leaderboardDashboardBox', leaderboardDashboardBox);

})(window.angular);