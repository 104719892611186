(function (angular) {

    function WeatherDashboardBoxCtrl($scope, weatherService, notifier) {
        var vm = this;

        vm.message = '';
        vm.hasState = '';

        vm.iconBaseUrl = 'https://openweathermap.org/img/w/';

        var apiKey = vm.model.data.apiKey;

        if (!apiKey) {
            notifier.error('Weather Api Key is missing.');
        }

        var zipCode = vm.tokensData && vm.tokensData.profile && vm.tokensData.profile.zipCode && vm.tokensData.profile.zipCode.value || '98944' /*washington*/;

        weatherService.getWeatherForecast(apiKey, zipCode).then(function (res) {
            vm.forecast = res.data;
        });

        vm.setLocation = function (loc) {
            vm.location = loc;
            vm.getForecastByLocation();
        };

        vm.getIconImageUrl = function (iconName) {
            return (iconName ? vm.iconBaseUrl + iconName + '.png' : '');
        };

        vm.isNumber = function isNumber(value) {
            return typeof value === 'number';
        };

	}

    WeatherDashboardBoxCtrl.$inject = ['$scope', 'weatherService', 'notifier'];

    function weatherDashboardBox() {
		return {
            controller: WeatherDashboardBoxCtrl,
			controllerAs: 'box',
			bindToController: true,
			scope: {
                model: '=',
                tokensData: '='
			},
			templateUrl: 'app/dashboards/components/weather/weather-dashboard-box.tmpl.html'
		};
	}

	angular
		.module('dashboard')
        .directive('weatherDashboardBox', weatherDashboardBox);

})(window.angular);