(function (angular) {
	'use strict';

    function KeypadCtrl($log, $q, $filter, errorService, utilityService, scope, authService) {

		var vm = this,
			translate = $filter('translate');

		vm.enteringCode = true;
		vm.displayEnterPinMessage = true;
        vm.errorsList = [];

        var authData = authService.getAuthenticationData();
        vm.showAccountActivationFeature = authData.userInfo.allowLookupPlayerForSignUp;

		vm.onClickCodeButton = onClickCodeButton;
		vm.onClickCleanButton = onClickCleanButton;
        vm.onClickCancelButton = onClickCancelButton;
        vm.onClickEmailButton = onClickEmailButton;
        /*vm.onClickPersonLookupButton = onClickPersonLookupButton;*/
        vm.onClickAccountActivationButton = onClickAccountActivationButton;
        vm.onClickDrawingsButton = onClickDrawingsButton;


		vm.getFormattedCode = getFormattedCode;
		vm.performAction = performAction;
        vm.getRewardCode = getRewardCode;
        vm.isValidPinCode = isValidPinCode;
		vm.isHiddenCampaignLobbyButton = isHiddenCampaignLobbyButton;

        //keyboard support
        document.addEventListener('keydown', captureKeyboard);

        scope.$on('$destroy', function () {
            document.removeEventListener('keydown', captureKeyboard);    
        });

        function captureKeyboard(e) {
            if (vm.keyboardDisabled) {
                return;
            }

            if ((e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105) /*0-9 keys*/ ||
                (e.shiftKey && e.keyCode === 56/* * */) ||
                (e.shiftKey && e.keyCode === 51 /* # */)) {

                onClickCodeButton(e.key);
                scope.$apply();
            }

            if (e.keyCode === 8 /*backspace*/) {
                if (vm.pinCode && vm.pinCode.length > 0) {
                    vm.pinCode = vm.pinCode.slice(0, -1);
                    scope.$apply();
                }
            }

            if (!vm.pinCode || vm.pinCode.length === 0) {
                vm.displayEnterPinMessage = true;
                scope.$apply();
            }
        }
        
		function onClickCodeButton(code) {
			vm.enteringCode = true;
			vm.displayEnterPinMessage = false;

			if (vm.errorsList.length) {
				resetError();
				cleanPinCode();
			}

			if (vm.isLoading || vm.done) {
				return;
			}

			if (typeof vm.pinCode != 'string') {
				vm.pinCode = '';
			}

			vm.pinCode += code;
		}

		function onClickCancelButton() {
			return vm.onClickCancel();
		}

		function onClickCampaigns(code) {
			// Must return promise
			return vm.onClickCampaigns({$pinCode: code});
		}

		function onClickRedeem(code) {
			// Must return promise
			return vm.onClickRedeem({$pinCode: code});
		}

        function onClickEmailButton(code) {
            // Must return promise
            return vm.onClickEmail({ $pinCode: code });
        }

        /*function onClickPersonLookupButton() {
            return vm.onClickPersonLookup();
        }*/

        function onClickAccountActivationButton() {
            return vm.onClickAccountActivation();
		}

		function onClickDrawingsButton(code) {
			
			return vm.onClickDrawings({ $pinCode: code });
		}

		function performAction(actionType) {
			vm.enteringCode = false;

			vm.displayEnterPinMessage = false;

			if (vm.errorsList.length) {
				resetError();
				cleanPinCode();
			}

			if (vm.isLoading || vm.done) {
				return;
			}

			vm.isLoading = true;

			(function () {
				switch (actionType) {
					case 'campaigns':
						return onClickCampaigns(vm.pinCode);
					case 'redemption':
						return onClickRedeem(vm.pinCode);
					default:
						return $q.reject('There is no such action to perform');
				}

			})().then(onSuccess, onError).finally(function () {
				vm.isLoading = false;
			});
		}

		function onClickCleanButton() {
			if (vm.isLoading || vm.done) {
				return;
			}
			cleanPinCode();
			resetError();
		}

		function cleanPinCode() {
			vm.pinCode = '';
		}

		function isHiddenCampaignLobbyButton() {
			return !!getRewardCode() || !vm.lobbyEnabled;
		}
        
        function isValidPinCode() {
            return vm.pinCode.length >= 7 && utilityService.isStringContains(vm.pinCode, 'ABCD');
        }

		function getRewardCode() {
			return vm.rewardCode;
		}

		function onSuccess(result) {
			$log.debug('You entered correct code!', result);
			resetError();
			cleanPinCode();
			vm.done = true;
		}

		function onError(response) {
			vm.errorsList = errorService.parseErrorsToList(response.data);
			vm.errorsList = !vm.errorsList.length && [translate('Invalid pin')] || vm.errorsList;
			cleanPinCode();
		}

		function resetError() {
			vm.errorsList = [];
			vm.done = false;
		}

		function getFormattedCode(code) {
			return $filter('pinCode')(code);
		}
	}

	KeypadCtrl.$inject = [
		'$log',
		'$q',
		'$filter',
        'errorService',
        'utilityService',
        '$scope',
		'authService'
	];

	function keypad() {
		return {
			restrict: 'E',
			controller: KeypadCtrl,
			controllerAs: 'keypad',
			bindToController: true,
			templateUrl: 'app/pin/components/keypad/keypad.tmpl.html',
			scope: {
				pinCode: '=pin',
				rewardCode: '=',
				lobbyEnabled: '=',
				onClickCampaigns: '&',
				onClickRedeem: '&',
                onClickCancel: '&',
                onClickEmail: '&',
                /*onClickPersonLookup: '&',*/
                onClickAccountActivation: '&',
                onClickDrawings: '&',
                emailButtonVisible: '=',
                drawingButtonVisible: '=',
                keyboardDisabled: '='
			}
		};
	}

	angular.module('pin').directive('keypad', keypad);

})(window.angular);